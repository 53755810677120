import React, { FunctionComponent, ReactElement } from 'react';
import {
  KeyboardAvoidingView,
  KeyboardAvoidingViewProps,
  ScrollViewProps,
  StyleProp,
  View,
  ViewStyle,
} from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import tw from '~/shared/theme';
import { ImageBackground, ImageSourcePropType } from '~/shared/ui/image';
import { GapSpacer } from '~/shared/ui/spacing';
import { useInsetsOrPadding } from '~/shared/utils/use-insets-or-padding';

interface BaseLayoutProps extends ScrollViewProps {
  avoidKeyboard?: boolean;
  contentContainerStyle?: StyleProp<ViewStyle>;
  // decoration element on top of everything, (position absolute) outside the scrolling view
  decorationTop?: ReactElement;
  // decoration at the top of the scrolling view, pulling itself out of the padding
  decorationHeader?: ReactElement;
  // decoration below everything, (position absolute) outside of the scrolling view
  decorationBottom?: ReactElement;
  background?: ImageSourcePropType;
}

export const BaseLayout: FunctionComponent<BaseLayoutProps> = ({
  children,
  avoidKeyboard = false,
  contentContainerStyle,
  decorationTop,
  decorationHeader,
  decorationBottom,
  background = require('~/assets/images/default-bg.png'),
  ...props
}) => {
  const Wrapper = avoidKeyboard ? KeyboardAvoidingView : React.Fragment;
  const wrapperProps: KeyboardAvoidingViewProps = avoidKeyboard
    ? { behavior: 'padding', style: tw`flex-1` }
    : {};
  const insets = useInsetsOrPadding();
  const decoration = tw`absolute z-10`;
  const { left, right, top, bottom } = insets;

  return (
    <>
      {decorationTop && <View style={[decoration, { left, top, right }]}>{decorationTop}</View>}

      <Wrapper {...wrapperProps}>
        <ImageBackground source={background} resizeMode="cover" style={tw`w-full h-full`}>
          <ScrollView
            style={tw`flex`}
            // centerContent
            showsHorizontalScrollIndicator={false}
            contentContainerStyle={[
              // padding-top = menu-bar height + 10
              tw`w-full py-28 tablet:py-32`,
              { paddingLeft: left, paddingRight: right },
              contentContainerStyle,
            ]}
            scrollIndicatorInsets={{
              right: 0,
            }}
            {...props}
          >
            {decorationHeader && (
              <View style={[tw`-mt-10 mb-8`, { marginLeft: -left, marginRight: -right }]}>
                {decorationHeader}
              </View>
            )}
            <GapSpacer>{children}</GapSpacer>
          </ScrollView>
        </ImageBackground>
      </Wrapper>

      {decorationBottom && (
        <View style={[decoration, { left, bottom, right }]}>{decorationBottom}</View>
      )}
    </>
  );
};
