import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import tw from '~/shared/theme';
import { TextVariant } from '../../text-variant';

export interface TickProps {
  index: number;
  label?: string;
  showLine?: boolean;
}

export const Tick: FunctionComponent<TickProps> = ({ index, label, showLine = true }) => {
  return (
    <View style={[tw`relative w-0.5 h-4 left-2`, showLine && tw`bg-black`]}>
      <TextVariant
        variant="body"
        style={tw`absolute text-sm tablet:text-md top-full mt-4 tablet:mt-8 -left-12 -right-12 text-center`}
      >
        {label || index}
      </TextVariant>
    </View>
  );
};
