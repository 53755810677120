import AsyncStorage from '@react-native-async-storage/async-storage';
import { useMemo } from 'react';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { analyticsTracker } from '~/shared/tracking/analytics';
import Asset from '~/shared/utils/asset';
import { getMediaPreview, getMediaVideoResource } from '~/shared/utils/media';
import {
  IDownloadableMedia,
  IMedia,
  IMediaBook,
  IMediaGame,
  IMediaSong,
  IMediaVideo,
  MediaDownloadStatus,
  MediaType,
} from '~/types';
import { getLocalUris } from './utils/get-local-uris';
import { updateDownloadProgress } from './utils/update-download-progress';

interface IDownloadsState {
  _hasHydrated: boolean;
  items: IDownloadableMedia[];
  deleteRequest?: {
    media: 'all' | IMedia;
  };
}

interface IDownloadsProducers {
  downloadMedia(media: IMediaVideo | IMediaGame | IMediaSong | IMediaBook): void;
  requestDeleteMedia(media: 'all' | IMedia | undefined): void;
  deleteMedia(media: 'all' | IMedia): void;
}

type IDownloadsModel = IDownloadsState & IDownloadsProducers;

export const useDownloads = create<IDownloadsModel>()(
  persist(
    (set, get) => ({
      _hasHydrated: false,
      items: [],

      downloadMedia(media) {
        const previousDownload = get().items.find((item) => item.id === media.id);
        if (previousDownload) {
          return;
        }

        const preview = getMediaPreview(media);

        set(({ items }) => ({
          items: [
            ...items,
            {
              ...media,
              downloadMeta: {
                progress: [
                  0,
                  media.__typename === MediaType.Book
                    ? media.bookPages?.length || 0 + (preview ? 1 : 0)
                    : 2,
                ],
                videoProgress: 0,
                status: MediaDownloadStatus.Downloading,
              },
            },
          ],
        }));

        if (preview) {
          const previewAsset = new Asset({ uri: preview.source, type: 'image', options: {} });
          previewAsset.download({}).then(({ localUri }) => {
            set(({ items }) => ({
              items: items.map((item) => {
                if (item.id === media.id) {
                  return {
                    ...item,
                    downloadMeta: updateDownloadProgress(item),
                    previews: { ...media.previews, local: { ...preview, source: localUri } },
                  };
                }

                return item;
              }),
            }));
          });
        }

        if (media.__typename === MediaType.Book) {
          Promise.all(
            media.bookPages?.map((page) => {
              const pageAsset = new Asset({ uri: page, type: 'image', options: {} });
              return pageAsset.download({});
            }) || [],
          ).then((pages) => {
            const localUris = pages.map((p) => p.localUri);
            set(({ items }) => ({
              items: items.map((item) => {
                if (item.id === media.id && item.__typename === MediaType.Book) {
                  return {
                    ...item,
                    downloadMeta: updateDownloadProgress(item, pages.length),
                    resources: localUris,
                  };
                }

                return item;
              }),
            }));
          });
        } else if (media.__typename === MediaType.Game) {
          const resourceAsset = new Asset({
            uri: media.gameDownloadSource as string,
            type: 'zip',
            options: {},
          });
          resourceAsset.download({}).then(({ localUri }) => {
            set(({ items }) => ({
              items: items.map((item) => {
                if (item.id === media.id) {
                  return {
                    ...item,
                    downloadMeta: updateDownloadProgress(item),
                    gameSource: localUri,
                  };
                }

                return item;
              }),
            }));
          });
        } else {
          const resource = getMediaVideoResource(media, 'medium');
          if (!resource) return;

          const resourceAsset = new Asset({
            uri: resource.source as string,
            type: 'video',
            options: {},
          });
          resourceAsset
            .download({
              onProgress(e) {
                if (e === 100) return;
                set(({ items }) => ({
                  items: items.map((item) => {
                    if (item.id === media.id) {
                      return {
                        ...item,
                        downloadMeta: {
                          /**
                           * @todo fix any, eg. check if something like this does not break anything:
                           *
                           *     progress: [],
                           *     status: MediaDownloadStatus.Downloading,
                           *     ...item.downloadMeta,
                           *     videoProgress: e,
                           */
                          ...(item.downloadMeta as any),
                          videoProgress: e,
                        },
                      };
                    }

                    return item;
                  }),
                }));
              },
            })
            .then(({ localUri }) => {
              set(({ items }) => ({
                items: items.map((item) => {
                  if (
                    item.id === media.id &&
                    (item.__typename === MediaType.Video || item.__typename === MediaType.Song)
                  ) {
                    return {
                      ...item,
                      downloadMeta: updateDownloadProgress(item),
                      resources: {
                        ...item.resources,
                        local: {
                          ...resource,
                          source: localUri,
                        },
                      },
                    };
                  }

                  return item;
                }),
              }));
            })
            .catch((_e) => {
              set(({ items }) => ({
                items: items.map((item) => {
                  if (item.id === media.id) {
                    return {
                      ...item,
                      downloadMeta: { progress: [], status: MediaDownloadStatus.Failed },
                    };
                  }

                  return item;
                }),
              }));
            });
        }

        analyticsTracker.trackContent('download-add', media);
      },

      requestDeleteMedia(media: 'all' | IMedia | undefined) {
        set({
          deleteRequest: media ? { media } : undefined,
        });
      },

      deleteMedia(media: 'all' | IMedia) {
        const items = get().items;
        const uris =
          media === 'all'
            ? items.flatMap(getLocalUris)
            : getLocalUris(items.find((item) => item.id === media.id));
        uris.forEach((uri) => {
          try {
            Asset.delete(uri);
          } catch (e) {}
        });

        set({
          items: media === 'all' ? [] : items.filter((item) => item.id !== media.id),
          deleteRequest: undefined,
        });

        if (media === 'all') {
          items.forEach((item) => {
            analyticsTracker.trackContent('download-remove', item);
          });
        } else {
          analyticsTracker.trackContent('download-remove', media);
        }
      },
    }),
    {
      name: 'studio100go-downloads',
      storage: createJSONStorage(() => AsyncStorage),
      onRehydrateStorage: () => () => {
        const { items } = useDownloads.getState();
        useDownloads.setState({
          _hasHydrated: true,
          items: items.filter(
            (item) => item.downloadMeta?.status === MediaDownloadStatus.Downloaded,
          ),
        });
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      partialize: ({ deleteRequest, _hasHydrated, ...state }) => state,
    },
  ),
);

export function useDownloadedMedia({
  includePending = false,
}: {
  includePending?: boolean;
} = {}): IDownloadableMedia[] {
  const items = useDownloads((state) => state.items);

  return useMemo(() => {
    return items.filter((item) => {
      return (
        item.downloadMeta?.status === MediaDownloadStatus.Downloaded ||
        (includePending && item.downloadMeta?.status === MediaDownloadStatus.Downloading)
      );
    });
  }, [items, includePending]);
}

export function useDownloadedMediaItem({
  media,
  includePending = false,
}: {
  media: { id: string };
  includePending?: boolean;
}): IDownloadableMedia | undefined {
  const items = useDownloads((state) => state.items);

  return useMemo(() => {
    return items.find((item) => {
      return (
        item.id === media.id &&
        (item.downloadMeta?.status === MediaDownloadStatus.Downloaded ||
          (includePending && item.downloadMeta?.status === MediaDownloadStatus.Downloading))
      );
    });
  }, [items, media, includePending]);
}
