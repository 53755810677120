import { NavigationProp, useIsFocused, useNavigation } from '@react-navigation/core';
import { useEffect } from 'react';
import { OnboardingStep, useViewer } from '~/entities/viewer';
import { OnboardingRoutes, RootRoutes, RootStackParamList } from '~/navigation';

export function useForceUnboarding(): boolean {
  const shouldCheck = useIsFocused();
  const onboardingStep = useViewer((state) => state.onboardingStep);
  const navigation = useNavigation<NavigationProp<RootStackParamList>>();

  const allowed = onboardingStep === OnboardingStep.Finished;

  useEffect(() => {
    if (!shouldCheck) return;

    setTimeout(() => {
      if (onboardingStep !== OnboardingStep.Finished) {
        if (onboardingStep === OnboardingStep.Profile) {
          navigation.navigate(RootRoutes.Onboarding, { screen: OnboardingRoutes.Profile });
        } else {
          navigation.navigate(RootRoutes.Onboarding, { screen: OnboardingRoutes.Main });
        }
      }
    }, 100); // @todo This timeout seems necessary. Try another way when more time is available.
  }, [onboardingStep, shouldCheck, navigation]);

  return allowed;
}
