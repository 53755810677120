import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import tw from '~/shared/theme';
import { Spinner } from '~/shared/ui/preloader';

interface GridPaginationLoaderProps {}

export const GridPaginationLoader: FunctionComponent<GridPaginationLoaderProps> = () => {
  return (
    <View style={tw`flex flex-row justify-center`}>
      <Spinner />
    </View>
  );
};
