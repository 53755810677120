import React, { FunctionComponent } from 'react';
import { View, Pressable } from 'react-native';
import { PressablesBaseProps } from './pressable';

interface PressablesGrowProps extends PressablesBaseProps {
  maxScale?: number;
  minScale?: number;
  hoveredRotation?: string;
  pressedRotation?: string;
  children?: React.ReactNode;
}

export const PressableGrow: FunctionComponent<PressablesGrowProps> = ({
  children,
  maxScale = 1.1,
  minScale = 0.95,
  hoveredRotation = '6deg',
  pressedRotation = '-4deg',
  ...props
}) => {
  return (
    <Pressable {...props}>
      {({ hovered, pressed }) => (
        <View
          style={[
            hovered && {
              transform: [
                {
                  rotate: hoveredRotation,
                },
                {
                  scale: maxScale,
                },
              ],
            },
            pressed && {
              transform: [
                {
                  rotate: pressedRotation,
                },
                {
                  scale: minScale,
                },
              ],
            },
          ]}
        >
          {children}
        </View>
      )}
    </Pressable>
  );
};
