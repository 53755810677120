import Amplitude from 'amplitude-js';
import BaseTrackerAdapter from './base';

export default class AmplitudeTracker extends BaseTrackerAdapter {
  tracker: Amplitude.AmplitudeClient;

  constructor(apiKey: string) {
    super('amplitude');
    this.tracker = Amplitude.getInstance();
    this.tracker.init(apiKey);
  }

  setUserId(userId: string): void {
    this.tracker.setUserId(userId);
    super.setUserId(userId);
  }

  logEvent(event: string, params: Record<string, unknown>): void {
    this.tracker.logEvent(event, this.augmentEventParams(params));
    super.logEvent(event, params);
  }

  setUserProperty(property: string, value: string): void {
    const safeValue = this.getSafePropertyValue(value);
    const identify = new Amplitude.Identify();
    identify.set(property, value);
    this.tracker.identify(identify);
    super.setUserProperty(property, safeValue);
  }

  setUserPropertyOnce(property: string, value: string): void {
    const safeValue = this.getSafePropertyValue(value);
    const identify = new Amplitude.Identify();
    identify.setOnce(property, value);
    this.tracker.identify(identify);
    super.setUserPropertyOnce(property, safeValue);
  }

  logRevenue(productIdentifier: string, quantity: number, amount: number): void {
    super.logRevenue(productIdentifier, quantity, amount);
  }

  addToUserProperty(property: string, amount: number): void {
    const identify = new Amplitude.Identify();
    identify.add(property, amount);
    this.tracker.identify(identify);
    super.addToUserProperty(property, amount);
  }
}
