import React, { FunctionComponent } from 'react';
import { StackScreenProps } from '@react-navigation/stack';
import { MediaRoutes, MediaStackParamList } from '~/navigation';
import { BaseLayout } from '~/widgets/base-layout';
import { ScrollingMenuBar } from '~/shared/ui/menu';
import { MainMenu } from '~/widgets/menu/main-menu';
import { Fill } from '~/shared/ui/spacing';
import { UserMenu } from '~/widgets/menu/user-menu';
import { FeedLayout } from '~/widgets/feed-layout';
import { useDownloadFeeds } from '~/features/media/list-downloads';
import { isFeedListEmpty } from '~/shared/utils/media';
import { PageError } from '~/shared/ui/page-error';
import { useTranslation } from 'react-i18next';

export const DownloadsPage: FunctionComponent<
  StackScreenProps<MediaStackParamList, MediaRoutes.Downloads>
> = () => {
  const { t } = useTranslation();
  const { feeds } = useDownloadFeeds();

  return (
    <BaseLayout
      decorationTop={
        <ScrollingMenuBar>
          <MainMenu />
          <Fill />
          <UserMenu />
        </ScrollingMenuBar>
      }
    >
      {isFeedListEmpty(feeds) ? (
        <PageError title={t('notFoundTitle')} message={t('downloadsNoResultsErrorMessage')} />
      ) : (
        <FeedLayout feeds={feeds} />
      )}
    </BaseLayout>
  );
};
