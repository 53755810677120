import React, { FunctionComponent } from 'react';
import { StackScreenProps } from '@react-navigation/stack';
import { MediaRoutes, MediaStackParamList } from '~/navigation';
import { MediaMedium, MEDIA_ALL_BRANDS } from '~/types';
import { OverviewHomePage } from './overview-home.page';
import { OverviewBrandPage } from './overview-brand.page';
import { OverviewMediumPage } from './overview-medium.page';
import { useNetInfo } from '@react-native-community/netinfo';
import { MediaOfflinePage } from './media-offline.page';

export const MediaOverviewPage: FunctionComponent<
  StackScreenProps<MediaStackParamList, MediaRoutes.Overview>
> = (props) => {
  const { isConnected } = useNetInfo();

  if (isConnected === false) {
    return <MediaOfflinePage {...props} />;
  }

  const { params } = props.route;
  if (params?.brandSlug && params.brandSlug !== MEDIA_ALL_BRANDS) {
    return <OverviewBrandPage {...props} />;
  }

  if (params?.medium && params?.medium !== MediaMedium.All) {
    return <OverviewMediumPage {...props} />;
  }

  return <OverviewHomePage {...props} />;
};
