import { IMedia } from '~/types';

export function getLocalUris(item?: IMedia): string[] {
  if (!item) return [];

  const localAssets: string[] = [];

  if (item.previews?.local?.source) {
    localAssets.push(item.previews.local.source);
  }
  if ('posters' in item && item.posters?.local?.source) {
    localAssets.push(item.posters.local.source);
  }
  if ('resources' in item) {
    if (Array.isArray(item.resources)) {
      localAssets.push(...item.resources);
    } else if (item.resources?.local?.source) {
      localAssets.push(item.resources.local.source);
    }
  }
  if ('bookPages' in item && item.bookPages) {
    localAssets.push(...item.bookPages);
  }
  if ('gameSource' in item && item.gameSource) {
    localAssets.push(item.gameSource);
  }

  return localAssets;
}
