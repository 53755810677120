import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import tw from '~/shared/theme';
import { Gradient } from '../gradient';
import { Fill } from '../spacing';

export interface ImageOverlayProps {
  children?: React.ReactNode;
}

export const ImageOverlay: FunctionComponent<ImageOverlayProps> = ({ children }) => {
  return (
    <>
      <Fill />
      <Gradient style={tw`w-full h-8 tablet:h-16 opacity-15`} variant="fade-to-black" angle={180} />
      <View style={tw`w-full px-8 tablet:px-16 pb-8 tablet:pb-8 bg-black-999 bg-opacity-15`}>
        {children}
      </View>
    </>
  );
};
