import React, { FunctionComponent } from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import tw from '~/shared/theme';
import { AspectRatioContainer } from '../aspect-ratio-container';
import { Inline } from '../spacing';

export interface SwimlaneSkeletonProps {
  style?: StyleProp<ViewStyle>;
  containerStyle?: StyleProp<ViewStyle>;
  children?: React.ReactNode;
}

export const SwimlaneSkeleton: FunctionComponent<SwimlaneSkeletonProps> = ({
  style,
  containerStyle,
  children,
}) => {
  return (
    <Inline spacing="md" style={[tw`w-[117%]`, containerStyle]}>
      <View style={[tw`flex-1`]}>
        <AspectRatioContainer aspectRatio="landscape" style={style}>
          {children}
        </AspectRatioContainer>
      </View>
      <View style={[tw`flex-1`]}>
        <AspectRatioContainer aspectRatio="landscape" style={style} />
      </View>
      <View style={[tw`flex-1`]}>
        <AspectRatioContainer aspectRatio="landscape" style={style} />
      </View>
      <View style={[tw`flex-1`]}>
        <AspectRatioContainer aspectRatio="landscape" style={style} />
      </View>
      <View style={[tw`flex-1`]}>
        <AspectRatioContainer aspectRatio="landscape" style={style} />
      </View>
    </Inline>
  );
};
