import AsyncStorage from '@react-native-async-storage/async-storage';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

interface ICookieState {
  _hasHydrated: boolean;
  accepted: boolean;
}
interface ICookieProducers {
  acceptCookies(): void;
}
type ICookieModel = ICookieState & ICookieProducers;

const defaultState = {
  accepted: false,
};

export const useCookies = create<ICookieModel>()(
  persist(
    (set, _get) => ({
      _hasHydrated: false,
      ...defaultState,

      acceptCookies() {
        set({ accepted: true });
      },

      reset() {
        set(defaultState);
      },
    }),
    {
      name: 'studio100go-cookies',
      storage: createJSONStorage(() => AsyncStorage),
      onRehydrateStorage: () => (_state) => {
        useCookies.setState({ _hasHydrated: true });
      },
      partialize: ({ _hasHydrated, ...state }) => state,
    },
  ),
);
