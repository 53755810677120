import React, { FunctionComponent, ReactNode } from 'react';
import { View } from 'react-native';
import tw, { config } from '~/shared/theme';
import { Icon, IconProps } from '../icon';

export interface CoinProps {
  icon?: IconProps['name'];
  coinVariant?: keyof typeof config.custom.coinVariants;
  children?: ReactNode;
}

export const Coin: FunctionComponent<CoinProps> = ({ coinVariant = 'default', icon, children }) => {
  const { container: containerStyle, icon: iconStyle } = config.custom.coinVariants[coinVariant];
  return (
    <View
      style={[
        // NOTE: no overflow hidden because this also hides shadows on native
        tw`w-8 h-8 tablet:w-12 tablet:h-12 rounded-full`,
        tw.style(containerStyle),
        {
          shadowColor: config.theme.colors.gray[500],
          shadowOffset: {
            width: 0,
            height: 2,
          },
          shadowOpacity: 0.3,
          shadowRadius: 1,
          elevation: 1,
        },
      ]}
    >
      <View style={tw`absolute inset-0 rounded-full items-center justify-center overflow-hidden`}>
        {icon && <Icon name={icon} style={[tw`text-md tablet:text-xl`, tw.style(iconStyle)]} />}
        {children}
        <View
          style={[
            tw`absolute -top-0.5 -left-1 -right-1 -bottom-2 border-4 rounded-full border-white opacity-30`,
          ]}
          pointerEvents="none"
        />
      </View>
    </View>
  );
};
