import * as Types from '~/shared/api/models.main.generated';

import { gql } from '@apollo/client';
import { ListFeedFragmentDoc } from '../../../../shared/api/fragments/list-feed.main.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {"context":{"clientName":"main"}} as const;
export type IGQLGetSerieFeedsQueryVariables = Types.Exact<{
  serieId: Types.Scalars['ID']['input'];
}>;


export type IGQLGetSerieFeedsQueryData = (
  { __typename: 'Query' }
  & { serieFeeds: Types.Maybe<(
    { __typename: 'SerieFeedResult' }
    & { serie: (
      { __typename: 'Serie' }
      & Pick<Types.IGQLSerie, 'id' | 'title'>
      & { brand: (
        { __typename: 'Brand' }
        & Pick<Types.IGQLBrand, 'id' | 'slug'>
      ), banners: Types.Maybe<(
        { __typename: 'MediaBanners' }
        & { large: Types.Maybe<(
          { __typename: 'Asset' }
          & Pick<Types.IGQLAsset, 'source'>
        )>, medium: Types.Maybe<(
          { __typename: 'Asset' }
          & Pick<Types.IGQLAsset, 'source'>
        )> }
      )> }
    ), items: Array<(
      { __typename: 'Feed' }
      & Pick<Types.IGQLFeed, 'id' | 'itemTileType' | 'feedLayoutType'>
      & { title: (
        { __typename: 'Text' }
        & Pick<Types.IGQLText, 'type' | 'content'>
      ), icon: Types.Maybe<(
        { __typename: 'Icon' }
        & Pick<Types.IGQLIcon, 'name' | 'color'>
      )>, button: Types.Maybe<(
        { __typename: 'Button' }
        & Pick<Types.IGQLButton, 'title'>
        & { link: (
          { __typename: 'Link' }
          & Pick<Types.IGQLLink, 'screen' | 'params'>
        ) }
      )>, result: Types.Maybe<(
        { __typename: 'FeedResult' }
        & Pick<Types.IGQLFeedResult, 'fetchMoreToken'>
        & { items: Array<(
          { __typename: 'Book' }
          & Pick<Types.IGQLBook, 'id' | 'title' | 'isFeatured' | 'isLocked'>
          & { tile: (
            { __typename: 'TileProperties' }
            & Pick<Types.IGQLTileProperties, 'title' | 'subTitle'>
          ), brand: (
            { __typename: 'Brand' }
            & Pick<Types.IGQLBrand, 'id' | 'slug' | 'name'>
          ), previews: Types.Maybe<(
            { __typename: 'MediaPreviews' }
            & { medium: Types.Maybe<(
              { __typename: 'Asset' }
              & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
            )> }
          )> }
        ) | (
          { __typename: 'Brand' }
          & Pick<Types.IGQLBrand, 'id' | 'slug' | 'name'>
          & { images: Types.Maybe<(
            { __typename: 'BrandPreviews' }
            & { medium: Types.Maybe<(
              { __typename: 'Asset' }
              & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
            )> }
          )> }
        ) | (
          { __typename: 'Game' }
          & Pick<Types.IGQLGame, 'id' | 'title' | 'isFeatured' | 'isLocked'>
          & { tile: (
            { __typename: 'TileProperties' }
            & Pick<Types.IGQLTileProperties, 'title' | 'subTitle'>
          ), brand: (
            { __typename: 'Brand' }
            & Pick<Types.IGQLBrand, 'id' | 'slug' | 'name'>
          ), previews: Types.Maybe<(
            { __typename: 'MediaPreviews' }
            & { medium: Types.Maybe<(
              { __typename: 'Asset' }
              & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
            )> }
          )> }
        ) | (
          { __typename: 'Playlist' }
          & Pick<Types.IGQLPlaylist, 'itemCount' | 'id' | 'title' | 'isFeatured' | 'isLocked'>
          & { tile: (
            { __typename: 'TileProperties' }
            & Pick<Types.IGQLTileProperties, 'title' | 'subTitle'>
          ), brand: (
            { __typename: 'Brand' }
            & Pick<Types.IGQLBrand, 'id' | 'slug' | 'name'>
          ), previews: Types.Maybe<(
            { __typename: 'MediaPreviews' }
            & { medium: Types.Maybe<(
              { __typename: 'Asset' }
              & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
            )> }
          )> }
        ) | (
          { __typename: 'Serie' }
          & Pick<Types.IGQLSerie, 'id' | 'title' | 'isFeatured' | 'isLocked'>
          & { posters: Types.Maybe<(
            { __typename: 'MediaPosters' }
            & { medium: Types.Maybe<(
              { __typename: 'Asset' }
              & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
            )>, small: Types.Maybe<(
              { __typename: 'Asset' }
              & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
            )> }
          )>, tile: (
            { __typename: 'TileProperties' }
            & Pick<Types.IGQLTileProperties, 'title' | 'subTitle'>
          ), brand: (
            { __typename: 'Brand' }
            & Pick<Types.IGQLBrand, 'id' | 'slug' | 'name'>
          ), previews: Types.Maybe<(
            { __typename: 'MediaPreviews' }
            & { medium: Types.Maybe<(
              { __typename: 'Asset' }
              & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
            )> }
          )> }
        ) | (
          { __typename: 'Song' }
          & Pick<Types.IGQLSong, 'duration' | 'id' | 'title' | 'isFeatured' | 'isLocked'>
          & { songContentType: Types.IGQLSong['contentType'] }
          & { tile: (
            { __typename: 'TileProperties' }
            & Pick<Types.IGQLTileProperties, 'title' | 'subTitle'>
          ), brand: (
            { __typename: 'Brand' }
            & Pick<Types.IGQLBrand, 'id' | 'slug' | 'name'>
          ), previews: Types.Maybe<(
            { __typename: 'MediaPreviews' }
            & { medium: Types.Maybe<(
              { __typename: 'Asset' }
              & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
            )> }
          )> }
        ) | (
          { __typename: 'Video' }
          & Pick<Types.IGQLVideo, 'duration' | 'seasonId' | 'serieId' | 'id' | 'title' | 'isFeatured' | 'isLocked'>
          & { videoContentType: Types.IGQLVideo['contentType'] }
          & { posters: Types.Maybe<(
            { __typename: 'MediaPosters' }
            & { medium: Types.Maybe<(
              { __typename: 'Asset' }
              & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
            )>, small: Types.Maybe<(
              { __typename: 'Asset' }
              & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
            )> }
          )>, tile: (
            { __typename: 'TileProperties' }
            & Pick<Types.IGQLTileProperties, 'title' | 'subTitle'>
          ), brand: (
            { __typename: 'Brand' }
            & Pick<Types.IGQLBrand, 'id' | 'slug' | 'name'>
          ), previews: Types.Maybe<(
            { __typename: 'MediaPreviews' }
            & { medium: Types.Maybe<(
              { __typename: 'Asset' }
              & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
            )> }
          )> }
        )> }
      )> }
    )> }
  )> }
);


export const GetSerieFeedsOperation = gql`
    query GetSerieFeeds($serieId: ID!) {
  serieFeeds(id: $serieId) {
    serie {
      id
      title
      brand {
        id
        slug
      }
      banners {
        large {
          source
        }
        medium {
          source
        }
      }
    }
    items {
      ...ListFeed
    }
  }
}
    ${ListFeedFragmentDoc}`;
export function useGetSerieFeedsQuery(baseOptions: Apollo.QueryHookOptions<IGQLGetSerieFeedsQueryData, IGQLGetSerieFeedsQueryVariables> & ({ variables: IGQLGetSerieFeedsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGQLGetSerieFeedsQueryData, IGQLGetSerieFeedsQueryVariables>(GetSerieFeedsOperation, options);
      }
export function useGetSerieFeedsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGQLGetSerieFeedsQueryData, IGQLGetSerieFeedsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGQLGetSerieFeedsQueryData, IGQLGetSerieFeedsQueryVariables>(GetSerieFeedsOperation, options);
        }
export function useGetSerieFeedsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGQLGetSerieFeedsQueryData, IGQLGetSerieFeedsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGQLGetSerieFeedsQueryData, IGQLGetSerieFeedsQueryVariables>(GetSerieFeedsOperation, options);
        }
export type GetSerieFeedsQueryHookResult = ReturnType<typeof useGetSerieFeedsQuery>;
export type GetSerieFeedsLazyQueryHookResult = ReturnType<typeof useGetSerieFeedsLazyQuery>;
export type GetSerieFeedsSuspenseQueryHookResult = ReturnType<typeof useGetSerieFeedsSuspenseQuery>;
export type GetSerieFeedsQueryResult = Apollo.QueryResult<IGQLGetSerieFeedsQueryData, IGQLGetSerieFeedsQueryVariables>;