import { useMemoizedActiveProfilePreferences } from '~/entities/profiles';
import { IRetryQuery, useRetryQuery } from '~/shared/utils/use-retry-query';
import { NetworkStatus, MediaMedium, MediaMediumMapper } from '~/types';
import { IGQLGetBrandFeedsQueryData, useGetBrandFeedsQuery } from '../api/queries.main.generated';

export function useBrandFeeds({ brandId, medium }: { brandId?: string; medium?: MediaMedium }): {
  feeds?: Exclude<IGQLGetBrandFeedsQueryData['brandFeeds'], null>['items'];
  networkStatus: NetworkStatus;
  retryQuery: IRetryQuery;
} {
  const preferences = useMemoizedActiveProfilePreferences(20);
  const { data, ...query } = useGetBrandFeedsQuery({
    variables: {
      brandId: brandId as string,
      medium: medium ? MediaMediumMapper[medium] : undefined,
      ...preferences,
    },
    skip: !brandId,
  });
  const { networkStatus, ...retryQuery } = useRetryQuery(query);

  return { feeds: data?.brandFeeds?.items, networkStatus, retryQuery };
}
