import { useIsFocused } from '@react-navigation/core';
import { IRetryQuery, useRetryQuery } from '~/shared/utils/use-retry-query';
import { NetworkStatus } from '~/types';
import { IGQLGetSerieFeedsQueryData, useGetSerieFeedsQuery } from '../api/queries.main.generated';

export function useSerieFeeds({ serieId }: { serieId: string }): {
  feeds?: Exclude<IGQLGetSerieFeedsQueryData['serieFeeds'], null>['items'];
  serie?: Exclude<IGQLGetSerieFeedsQueryData['serieFeeds'], null>['serie'];
  networkStatus: NetworkStatus;
  retryQuery: IRetryQuery;
} {
  const inFocus = useIsFocused();
  const { data, ...query } = useGetSerieFeedsQuery({
    variables: {
      serieId,
    },
    skip: !inFocus,
  });
  const { networkStatus, ...retryQuery } = useRetryQuery(query);

  return {
    feeds: data?.serieFeeds?.items,
    networkStatus,
    serie: data?.serieFeeds?.serie,
    retryQuery,
  };
}
