import React, { FunctionComponent } from 'react';
import { TouchableOpacity } from 'react-native-gesture-handler';
import tw from '~/shared/theme';
import { TextVariant } from '~/shared/ui/text-variant';
import { LanguageSelectorOptionProps } from './language-selector.types';

export const LanguageSelectorToggleOption: FunctionComponent<LanguageSelectorOptionProps> = ({
  locale,
  onSelect,
  currentLanguage,
  isFirst,
  isLast,
}) => {
  const isActive = locale === currentLanguage;

  return (
    <TouchableOpacity
      onPress={() => {
        onSelect(locale);
      }}
      style={[
        tw`py-2 px-3 border-2 border-blue`,
        isActive ? tw`bg-blue` : tw`bg-white`,
        isFirst && tw`pl-4 rounded-tl-full rounded-bl-full`,
        isLast && tw`pr-4 rounded-tr-full rounded-br-full`,
      ]}
    >
      <TextVariant variant="h4" style={[tw`relative`, isActive ? tw`text-white` : tw`text-blue`]}>
        {locale.toUpperCase()}
      </TextVariant>
    </TouchableOpacity>
  );
};
