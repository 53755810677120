import React, { FunctionComponent } from 'react';
import { StyleProp, ViewProps, ViewStyle } from 'react-native';
import tw from '~/shared/theme';
import { useInsetsOrPadding } from '~/shared/utils/use-insets-or-padding';
import { Inline } from '../spacing';

export interface MenuBarProps {
  variant?: keyof typeof menuBarVariants;
  onLayout?: ViewProps['onLayout'];
  style?: StyleProp<ViewStyle>;
  children?: React.ReactNode;
}

const menuBarVariants = {
  default: tw`bg-green-450 bg-opacity-95`,
  black: tw`bg-black bg-opacity-30`,
  transparent: tw``,
};

export const MenuBar: FunctionComponent<MenuBarProps> = ({
  children,
  variant = 'default',
  style,
  ...props
}) => {
  const insets = useInsetsOrPadding();
  return (
    <Inline
      style={[
        // layout
        tw`h-18 tablet:h-22 py-3 items-center z-10`,
        {
          marginLeft: -insets.left,
          marginRight: -insets.right,
          paddingLeft: insets.left,
          paddingRight: insets.right,
        },
        // background
        menuBarVariants[variant],
        // custom
        style,
      ]}
      {...props}
    >
      {children}
    </Inline>
  );
};
