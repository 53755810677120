import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import appInfo, { useSystemIdentifier } from '~/shared/app-info';
import tw from '~/shared/theme';
import { TextVariant } from '../text-variant';

interface AppInfoProps {}

export const AppInfo: FunctionComponent<AppInfoProps> = () => {
  const systemIdentifier = useSystemIdentifier();

  return (
    <View style={tw`flex flex-row justify-between`}>
      <TextVariant variant="body" style={tw`text-xs`}>
        Studio 100 GO - v{appInfo.marketingVersion} [{appInfo.nativeCodeVersion || ''}
        {' : '}
        {appInfo.otaCodeVersion || ''}]
      </TextVariant>
      <TextVariant variant="meta" style={tw`text-right max-w-md opacity-40`}>
        {systemIdentifier}
      </TextVariant>
    </View>
  );
};
