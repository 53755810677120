import React, { FunctionComponent } from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import tw from '~/shared/theme';
import { Icon } from '../icon';
import { GapSpacer, Inline } from '../spacing';
import { TextVariant } from '../text-variant';

export interface AdvantageListProps {
  advantages: string[];
  style?: StyleProp<ViewStyle>;
}

export const AdvantageList: FunctionComponent<AdvantageListProps> = ({ advantages, style }) => {
  return (
    <View style={style}>
      <GapSpacer spacing="sm">
        {advantages.map((line, i) => (
          <Inline key={i} style={tw`items-start`}>
            <Icon name="check" color={tw.color('green')} style={tw`pt-2 mr-4`} />
            <TextVariant variant="list" style={tw`flex-1`}>
              {line}
            </TextVariant>
          </Inline>
        ))}
      </GapSpacer>
    </View>
  );
};
