import { StackScreenProps } from '@react-navigation/stack';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import {
  GridPaginationLoader,
  usePaginateFinalGridFeed,
} from '~/features/media/list-paginated-feed';
import { useSerieFeeds } from '~/features/media/list-serie-feeds';
import { MediaRoutes, MediaStackParamList } from '~/navigation';
import { MenuBack } from '~/shared/ui/menu';
import { Preloader } from '~/shared/ui/preloader';
import { getMediaSerieBanner, isFeedListEmpty } from '~/shared/utils/media';
import { NetworkStatus } from '~/types';
import { FeedLayout, FeedLayoutSkeleton } from '~/widgets/feed-layout';
import { UserMenu } from '~/widgets/menu/user-menu';
import { ImageBackground, Image } from '~/shared/ui/image';
import tw from '~/shared/theme';
import { useGetImageOrPlaceholder } from '~/shared/utils/use-get-image-or-placeholder';
import { TextVariant } from '~/shared/ui/text-variant';
import { MergingMenuLayout } from '~/widgets/merging-menu-layout';

export const OverviewSeriePage: FunctionComponent<
  StackScreenProps<MediaStackParamList, MediaRoutes.SerieOverview>
> = ({ route, navigation }) => {
  const {
    serie,
    feeds: initialFeeds,
    networkStatus,
    retryQuery,
  } = useSerieFeeds({ serieId: route.params.id });
  const {
    feeds,
    scrollableProps,
    networkStatus: paginationNetworkStatus,
  } = usePaginateFinalGridFeed({ feeds: initialFeeds });
  const { t } = useTranslation();
  const imageOrPlaceholder = useGetImageOrPlaceholder({
    placeholder: require('~/assets/images/placeholders/header.jpg'),
    getImageFunction: getMediaSerieBanner,
  });

  const preloaderState = {
    loading: networkStatus < NetworkStatus.ready,
    error: networkStatus === NetworkStatus.error ? t('generalErrorMessage') : undefined,
  };

  return (
    <MergingMenuLayout
      menuLeft={
        <MenuBack
          label={t('menuItemBack')}
          onPress={() => {
            if (navigation.canGoBack()) {
              navigation.goBack();
            } else {
              navigation.navigate(MediaRoutes.Overview, {});
            }
          }}
        />
      }
      menuCenter={
        serie && (
          <TextVariant variant="page-title" style={tw`text-white text-center`}>
            {serie?.title}
          </TextVariant>
        )
      }
      menuRight={<UserMenu />}
      header={
        <Preloader
          {...preloaderState}
          empty={!serie}
          skeleton={
            <ImageBackground
              source={imageOrPlaceholder()}
              resizeMode="cover"
              style={tw`h-100 rounded-none w-full`}
            />
          }
          renderEmpty={() => <></>}
          renderError={() => <></>}
        >
          {() => (
            <Image
              source={imageOrPlaceholder(serie)}
              style={{ height: 400, width: '100%' }}
              resizeMode="cover"
            />
          )}
        </Preloader>
      }
      {...scrollableProps}
    >
      <Preloader
        {...preloaderState}
        empty={isFeedListEmpty(feeds)}
        skeleton={<FeedLayoutSkeleton feedLayoutType="GRID" />}
        errorRetryButton={retryQuery}
      >
        {() =>
          feeds && (
            <>
              <FeedLayout feeds={feeds} />
            </>
          )
        }
      </Preloader>
      {paginationNetworkStatus === NetworkStatus.loading && <GridPaginationLoader />}
    </MergingMenuLayout>
  );
};
