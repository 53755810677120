import React, { FunctionComponent, ReactElement } from 'react';
import { Pressable, StyleProp, View, ViewStyle } from 'react-native';
import tw, { config } from '~/shared/theme';
import { GapSpacer } from '../spacing';
import { TextVariant } from '../text-variant';
import { Coin } from './coin.component';
import { useMenuItemTextColor } from './menu-item-color.context';

export interface MenuItemProps {
  label?: string;
  active?: boolean;
  onPress?: () => void;
  children: ReactElement<typeof Coin>;
  contentAfter?: React.ReactNode;
  style?: StyleProp<ViewStyle>;
}

export const MenuItem: FunctionComponent<MenuItemProps> = ({
  label,
  active = false,
  onPress,
  children,
  contentAfter,
  style,
}) => {
  const { activeTextStyle, inactiveTextStyle } = useMenuItemTextColor();
  return (
    <Pressable
      style={({ hovered }) => [
        tw`items-center -mt-3 -mb-4 self-start`,
        tw`w-18 px-1 pt-2 pb-6 rounded-br-[36px] rounded-bl-[36px]`,
        tw`tablet:w-20 tablet:pb-4 tablet:rounded-br-[40px] tablet:rounded-bl-[40px]`,
        hovered && tw`bg-white bg-opacity-10`,
        active && tw`bg-white`,
        active && {
          shadowOffset: {
            width: 0,
            height: 2,
          },
          shadowRadius: 4,
          shadowColor: config.theme.colors.gray[700],
          shadowOpacity: 0.3,
          elevation: 4,
        },
        style,
      ]}
      onPress={onPress}
    >
      {({ hovered, pressed }) => (
        <GapSpacer spacing="xs">
          <View
            style={[
              hovered && {
                transform: [
                  {
                    rotate: '6deg',
                  },
                  {
                    scale: 1.1,
                  },
                ],
              },
              pressed && {
                transform: [
                  {
                    rotate: '-4deg',
                  },
                  {
                    scale: 0.95,
                  },
                ],
              },
            ]}
          >
            {children}
          </View>
          {label && (
            <TextVariant
              variant="h5"
              style={[tw`text-center`, active ? activeTextStyle : inactiveTextStyle]}
            >
              {label}
            </TextVariant>
          )}
          {contentAfter}
        </GapSpacer>
      )}
    </Pressable>
  );
};
