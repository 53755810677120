import * as Types from '~/shared/api/models.main.generated';

import { gql } from '@apollo/client';
import { ListFeedFragmentDoc } from '../../../../shared/api/fragments/list-feed.main.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {"context":{"clientName":"main"}} as const;
export type IGQLGetMediumFeedsQueryVariables = Types.Exact<{
  language: Types.Scalars['String']['input'];
  medium: Types.IGQLMediaMedium;
  favoriteBrands: Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input'];
  favoriteMedia: Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input'];
  recentlyViewed: Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input'];
}>;


export type IGQLGetMediumFeedsQueryData = (
  { __typename: 'Query' }
  & { mediumFeeds: Types.Maybe<(
    { __typename: 'Feeds' }
    & { items: Array<(
      { __typename: 'Feed' }
      & Pick<Types.IGQLFeed, 'id' | 'itemTileType' | 'feedLayoutType'>
      & { title: (
        { __typename: 'Text' }
        & Pick<Types.IGQLText, 'type' | 'content'>
      ), icon: Types.Maybe<(
        { __typename: 'Icon' }
        & Pick<Types.IGQLIcon, 'name' | 'color'>
      )>, button: Types.Maybe<(
        { __typename: 'Button' }
        & Pick<Types.IGQLButton, 'title'>
        & { link: (
          { __typename: 'Link' }
          & Pick<Types.IGQLLink, 'screen' | 'params'>
        ) }
      )>, result: Types.Maybe<(
        { __typename: 'FeedResult' }
        & Pick<Types.IGQLFeedResult, 'fetchMoreToken'>
        & { items: Array<(
          { __typename: 'Book' }
          & Pick<Types.IGQLBook, 'id' | 'title' | 'isFeatured' | 'isLocked'>
          & { tile: (
            { __typename: 'TileProperties' }
            & Pick<Types.IGQLTileProperties, 'title' | 'subTitle'>
          ), brand: (
            { __typename: 'Brand' }
            & Pick<Types.IGQLBrand, 'id' | 'slug' | 'name'>
          ), previews: Types.Maybe<(
            { __typename: 'MediaPreviews' }
            & { medium: Types.Maybe<(
              { __typename: 'Asset' }
              & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
            )> }
          )> }
        ) | (
          { __typename: 'Brand' }
          & Pick<Types.IGQLBrand, 'id' | 'slug' | 'name'>
          & { images: Types.Maybe<(
            { __typename: 'BrandPreviews' }
            & { medium: Types.Maybe<(
              { __typename: 'Asset' }
              & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
            )> }
          )> }
        ) | (
          { __typename: 'Game' }
          & Pick<Types.IGQLGame, 'id' | 'title' | 'isFeatured' | 'isLocked'>
          & { tile: (
            { __typename: 'TileProperties' }
            & Pick<Types.IGQLTileProperties, 'title' | 'subTitle'>
          ), brand: (
            { __typename: 'Brand' }
            & Pick<Types.IGQLBrand, 'id' | 'slug' | 'name'>
          ), previews: Types.Maybe<(
            { __typename: 'MediaPreviews' }
            & { medium: Types.Maybe<(
              { __typename: 'Asset' }
              & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
            )> }
          )> }
        ) | (
          { __typename: 'Playlist' }
          & Pick<Types.IGQLPlaylist, 'itemCount' | 'id' | 'title' | 'isFeatured' | 'isLocked'>
          & { tile: (
            { __typename: 'TileProperties' }
            & Pick<Types.IGQLTileProperties, 'title' | 'subTitle'>
          ), brand: (
            { __typename: 'Brand' }
            & Pick<Types.IGQLBrand, 'id' | 'slug' | 'name'>
          ), previews: Types.Maybe<(
            { __typename: 'MediaPreviews' }
            & { medium: Types.Maybe<(
              { __typename: 'Asset' }
              & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
            )> }
          )> }
        ) | (
          { __typename: 'Serie' }
          & Pick<Types.IGQLSerie, 'id' | 'title' | 'isFeatured' | 'isLocked'>
          & { posters: Types.Maybe<(
            { __typename: 'MediaPosters' }
            & { medium: Types.Maybe<(
              { __typename: 'Asset' }
              & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
            )>, small: Types.Maybe<(
              { __typename: 'Asset' }
              & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
            )> }
          )>, tile: (
            { __typename: 'TileProperties' }
            & Pick<Types.IGQLTileProperties, 'title' | 'subTitle'>
          ), brand: (
            { __typename: 'Brand' }
            & Pick<Types.IGQLBrand, 'id' | 'slug' | 'name'>
          ), previews: Types.Maybe<(
            { __typename: 'MediaPreviews' }
            & { medium: Types.Maybe<(
              { __typename: 'Asset' }
              & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
            )> }
          )> }
        ) | (
          { __typename: 'Song' }
          & Pick<Types.IGQLSong, 'duration' | 'id' | 'title' | 'isFeatured' | 'isLocked'>
          & { songContentType: Types.IGQLSong['contentType'] }
          & { tile: (
            { __typename: 'TileProperties' }
            & Pick<Types.IGQLTileProperties, 'title' | 'subTitle'>
          ), brand: (
            { __typename: 'Brand' }
            & Pick<Types.IGQLBrand, 'id' | 'slug' | 'name'>
          ), previews: Types.Maybe<(
            { __typename: 'MediaPreviews' }
            & { medium: Types.Maybe<(
              { __typename: 'Asset' }
              & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
            )> }
          )> }
        ) | (
          { __typename: 'Video' }
          & Pick<Types.IGQLVideo, 'duration' | 'seasonId' | 'serieId' | 'id' | 'title' | 'isFeatured' | 'isLocked'>
          & { videoContentType: Types.IGQLVideo['contentType'] }
          & { posters: Types.Maybe<(
            { __typename: 'MediaPosters' }
            & { medium: Types.Maybe<(
              { __typename: 'Asset' }
              & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
            )>, small: Types.Maybe<(
              { __typename: 'Asset' }
              & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
            )> }
          )>, tile: (
            { __typename: 'TileProperties' }
            & Pick<Types.IGQLTileProperties, 'title' | 'subTitle'>
          ), brand: (
            { __typename: 'Brand' }
            & Pick<Types.IGQLBrand, 'id' | 'slug' | 'name'>
          ), previews: Types.Maybe<(
            { __typename: 'MediaPreviews' }
            & { medium: Types.Maybe<(
              { __typename: 'Asset' }
              & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
            )> }
          )> }
        )> }
      )> }
    )> }
  )> }
);


export const GetMediumFeedsOperation = gql`
    query GetMediumFeeds($language: String!, $medium: MediaMedium!, $favoriteBrands: [ID!]!, $favoriteMedia: [ID!]!, $recentlyViewed: [ID!]!) {
  mediumFeeds(
    medium: $medium
    profilePreferences: {language: $language, favoriteBrands: $favoriteBrands, favoriteMedia: $favoriteMedia, recentlyViewed: $recentlyViewed}
  ) {
    items {
      ...ListFeed
    }
  }
}
    ${ListFeedFragmentDoc}`;
export function useGetMediumFeedsQuery(baseOptions: Apollo.QueryHookOptions<IGQLGetMediumFeedsQueryData, IGQLGetMediumFeedsQueryVariables> & ({ variables: IGQLGetMediumFeedsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGQLGetMediumFeedsQueryData, IGQLGetMediumFeedsQueryVariables>(GetMediumFeedsOperation, options);
      }
export function useGetMediumFeedsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGQLGetMediumFeedsQueryData, IGQLGetMediumFeedsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGQLGetMediumFeedsQueryData, IGQLGetMediumFeedsQueryVariables>(GetMediumFeedsOperation, options);
        }
export function useGetMediumFeedsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGQLGetMediumFeedsQueryData, IGQLGetMediumFeedsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGQLGetMediumFeedsQueryData, IGQLGetMediumFeedsQueryVariables>(GetMediumFeedsOperation, options);
        }
export type GetMediumFeedsQueryHookResult = ReturnType<typeof useGetMediumFeedsQuery>;
export type GetMediumFeedsLazyQueryHookResult = ReturnType<typeof useGetMediumFeedsLazyQuery>;
export type GetMediumFeedsSuspenseQueryHookResult = ReturnType<typeof useGetMediumFeedsSuspenseQuery>;
export type GetMediumFeedsQueryResult = Apollo.QueryResult<IGQLGetMediumFeedsQueryData, IGQLGetMediumFeedsQueryVariables>;