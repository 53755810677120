import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppConfig } from '~/entities/app-config';
import { useCookies } from '~/entities/cookies';
import tw from '~/shared/theme';
import { Button } from '~/shared/ui/button';
import { Card } from '~/shared/ui/card';
import { TextVariant } from '~/shared/ui/text-variant';

export interface ApproveCookiesProps {}

export const ApproveCookies: FunctionComponent<ApproveCookiesProps> = () => {
  const { t, i18n } = useTranslation();
  const { acceptCookies, accepted } = useCookies();
  const config = useAppConfig();

  if (accepted) {
    return null;
  }

  return (
    <Card style={tw`absolute bottom-0 left-0 w-full max-w-md p-8 rounded-none rounded-tr-lg`}>
      <TextVariant variant="h2" style={tw`text-blue`}>
        {t('cookiesOverlayTitle')}
      </TextVariant>
      <TextVariant variant="body" style={tw`mb-4`}>
        {t('cookiesOverlayDescription')}{' '}
        <a
          style={tw`text-blue`}
          href={config?.privacyPolicyUrl[i18n.language as keyof typeof config.privacyPolicyUrl]}
          target="_blank"
        >
          {t('cookiesOverlayDescriptionLink')}
        </a>
      </TextVariant>

      <Button variant="submit" onPress={acceptCookies}>
        {t('cookiesOverlayButtonLabel')}
      </Button>
    </Card>
  );
};
