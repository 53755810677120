import React, { FunctionComponent } from 'react';
import { StackScreenProps } from '@react-navigation/stack';
import { SettingsRoutes, SettingsStackParamList } from '~/navigation';
import { ScrollingMenuBar } from '~/shared/ui/menu';
import { MainMenu } from '~/widgets/menu/main-menu';
import { Fill, Gap, Inline } from '~/shared/ui/spacing';
import { UserMenu } from '~/widgets/menu/user-menu';
import { SettingsLayout } from '~/widgets/settings-layout';
import { TextVariant } from '~/shared/ui/text-variant';
import { useTranslation } from 'react-i18next';
import { Avatar } from '~/shared/ui/avatar';
import { View } from 'react-native';
import { Button } from '~/shared/ui/button';
import { Icon } from '~/shared/ui/icon';
import tw from '~/shared/theme';
import { IProfile, useActiveProfile, useProfiles } from '~/entities/profiles';

export const ProfileOverviewPage: FunctionComponent<
  StackScreenProps<SettingsStackParamList, SettingsRoutes.ProfileOverview>
> = ({ navigation }) => {
  const { t, i18n } = useTranslation();
  const { profiles, setActiveProfile, requestDeleteProfile } = useProfiles((state) => ({
    profiles: state.items,
    setActiveProfile: state.setActiveProfile,
    requestDeleteProfile: state.requestDeleteProfile,
  }));
  const profilesForLanguage = profiles.filter((p) => p.language === i18n.language);
  const { activeProfile } = useActiveProfile();
  const openSlots = new Array(4 - profilesForLanguage.length).fill(0).map((_, index) => index);
  const onDeleteProfile = (profile: IProfile) => {
    requestDeleteProfile(profile);
  };
  const onEditProfile = (profile: IProfile) => {
    navigation.navigate(SettingsRoutes.ProfileEdit, { id: profile.id });
  };
  const onAddProfile = () => {
    navigation.navigate(SettingsRoutes.ProfileCreate);
  };

  return (
    <SettingsLayout
      contentContainerStyle={tw`tablet:max-w-4xl min-h-11/12 justify-center`}
      decorationTop={
        <ScrollingMenuBar>
          <MainMenu />
          <Fill />
          <UserMenu />
        </ScrollingMenuBar>
      }
    >
      <TextVariant variant="h1">{t('profilesLabel')}</TextVariant>
      <Inline style={tw`items-stretch justify-between flex-wrap -mx-4`} spacing="lg">
        {profilesForLanguage.map((profile, index) => {
          return (
            <View key={`${profile.name}-${index}`} style={tw`items-center w-32 tablet:w-48 mb-4`}>
              <Avatar
                selected={profile === activeProfile}
                name={profile.avatar}
                onPress={profile === activeProfile ? undefined : () => setActiveProfile(profile)}
              />
              <Gap spacing="lg" />
              <TextVariant variant="h2" style={tw`text-center`}>
                {profile.name}
              </TextVariant>
              <Gap spacing="sm" />
              <Fill />
              <Inline spacing="sm">
                <Button style={tw`p-0`} onPress={() => onDeleteProfile(profile)}>
                  <Icon name="delete" color={tw.color('red')} />
                </Button>
                <Button style={tw`p-0`} onPress={() => onEditProfile(profile)}>
                  <Icon name="edit" color={tw.color('yellow')} />
                </Button>
              </Inline>
            </View>
          );
        })}
        {openSlots.map((slot, index) => {
          return (
            <View key={`${slot}-${index}`} style={tw`items-center w-32 tablet:w-48 mb-4`}>
              <Avatar name="avatargo" onPress={onAddProfile}>
                <View
                  style={[
                    tw`absolute bottom-0 left-1/2 bg-white rounded-full items-center justify-center`,
                    tw`-m-3 w-6 h-6`,
                    tw`tablet:-m-6 tablet:w-12 tablet:h-12`,
                  ]}
                >
                  <Icon name="add" color={tw.color('green')} style={tw`text-md tablet:text-2xl`} />
                </View>
              </Avatar>
            </View>
          );
        })}
      </Inline>
    </SettingsLayout>
  );
};
