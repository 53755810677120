import { useMemoizedActiveProfilePreferences } from '~/entities/profiles';
import { IRetryQuery, useRetryQuery } from '~/shared/utils/use-retry-query';
import { NetworkStatus } from '~/types';
import { IGQLGetHomeFeedsQueryData, useGetHomeFeedsQuery } from '../api/queries.main.generated';

export function useHomeFeeds(): {
  feeds?: Exclude<IGQLGetHomeFeedsQueryData['homeFeeds'], null>['items'];
  networkStatus: NetworkStatus;
  retryQuery: IRetryQuery;
  preferences: ReturnType<typeof useMemoizedActiveProfilePreferences>;
} {
  const preferences = useMemoizedActiveProfilePreferences(20);
  const { data, ...query } = useGetHomeFeedsQuery({
    variables: preferences,
  });
  const { networkStatus, ...retryQuery } = useRetryQuery(query);

  return { feeds: data?.homeFeeds?.items, networkStatus, retryQuery, preferences };
}
