import { StackScreenProps } from '@react-navigation/stack';
import { FunctionComponent, useEffect } from 'react';
import { useProximus } from '~/entities/proximus';
import { MediaRoutes, RootRoutes, RootStackParamList } from '~/navigation';
import { IProximusTokenInfo } from '~/shared/api/proximus.api';
import { useDynamicLinkTo } from '~/navigation/use-dynamic-link-to';

export const OAuthPage: FunctionComponent<
  StackScreenProps<RootStackParamList, RootRoutes.OAuth>
> = ({ route, navigation }) => {
  const linkTo = useDynamicLinkTo();
  const { init } = useProximus();

  useEffect(() => {
    const { status, access_token, expires_in, refresh_token } = route.params;

    if (!access_token || !expires_in || !refresh_token) {
      if (status) {
        // @todo handle error
      }
      return;
    }

    const fallbackNavigate = () =>
      navigation.navigate(RootRoutes.Media, { screen: MediaRoutes.Overview, params: {} });

    init(route.params as IProximusTokenInfo)
      .then(({ loginSource }) => {
        if (!loginSource || !linkTo(loginSource)) {
          fallbackNavigate();
        }
      })
      .catch(() => fallbackNavigate());
  }, [route.params, navigation, init, linkTo]);

  return null;
};
