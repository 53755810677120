export interface IConfig {
  proximus: {
    authorizeUrl: string;
    logoutUrl: string;
    refreshTokenUrl: string;
    revokeTokenUrl: string;
    validateTokenUrl: string;
    environmentIdentifier: string;
  };

  wieni: {
    apiUrl: string;
    apiKey: string;
  };

  privacyPolicyUrl: {
    nl: string;
    fr: string;
  };

  faqUrl: {
    nl: string;
    fr: string;
  };

  brandMenuCoinImages: {
    nl: string[];
    fr: string[];
  };
}

export const DEFAULT_CONFIG: IConfig = {
  proximus: {
    authorizeUrl:
      'https://www.proximus.be/auth/oauth2/authorize?client_id=IaAbDWlIpZL3HfQ&redirect_uri=https://api.zender.media/auth/login&response_type=code&state={{stateIdentifier}}&scope=assignedproducts%20UFS_VODWANAGG',
    logoutUrl:
      'https://www.proximus.be/logout?ru=https://api.zender.media/auth2/logout?state={{stateIdentifier}}',
    refreshTokenUrl: `${process.env.REACT_APP_PROXIMUS_ENDPOINT}/refresh-token`,
    validateTokenUrl: `${process.env.REACT_APP_PROXIMUS_ENDPOINT}/validate-token`,
    revokeTokenUrl: `${process.env.REACT_APP_PROXIMUS_ENDPOINT}/revoke-token`,
    environmentIdentifier: process.env.REACT_APP_PROXIMUS_ENVIRONMENT || 'prod',
  },

  wieni: {
    apiUrl: 'https://accountapiv2.go.studio100.com',
    apiKey: 'DG33K4cMeZ9Ge4smOKQ816Gmg8g3Kn6c719kNEbS',
  },

  privacyPolicyUrl: {
    fr: 'https://studio100.com/be/fr/go/vie-privee',
    nl: 'https://studio100.com/be/nl/go/privacy',
  },

  faqUrl: {
    fr: 'https://studio100.com/be/fr/go/faq',
    nl: 'https://studio100.com/be/nl/go/faq',
  },

  brandMenuCoinImages: {
    fr: [
      'https://cdn.zender.media/assets/brands/BU00_Char_Button@2x.png', //  Bumba
      'https://cdn.zender.media/assets/brands/DN00_Char_Button@2x.png', //  Garde ne Nuit
      'https://cdn.zender.media/assets/brands/MM00_Char_Button@2x.png', //  Mega Mindy
      'https://cdn.zender.media/assets/brands/FRSA_Char_Button@2x.png', //  Fred & Samsons
      'https://cdn.zender.media/assets/brands/MB3D_Char_Button@2x.png', //  Maya
    ],
    nl: [
      'https://cdn.zender.media/assets/brands/BU00_Char_Button@2x.png', //  Bumba
      'https://cdn.zender.media/assets/brands/DN00_Char_Button@2x.png', //  Nachtwacht
      'https://s3.eu-west-1.amazonaws.com/cdn.zender.media/assets/brands/5d8b8c95-e380-4663-8f0b-f516b906b075.medium.png', //  K3
      'https://cdn.zender.media/assets/brands/eca33c2e-e7b1-497d-9b32-5a54cc4cdd7b.medium.png', //  Samson & Marie
      'https://cdn.zender.media/assets/brands/MB3D_Char_Button@2x.png', //  Maya
    ],
  },
};

export default new (class Config {
  proximus: IConfig['proximus'];
  wieni: IConfig['wieni'];
  privacyPolicyUrl: IConfig['privacyPolicyUrl'];
  faqUrl: IConfig['faqUrl'];
  brandMenuCoinImages: IConfig['brandMenuCoinImages'];

  constructor() {
    this.proximus = DEFAULT_CONFIG.proximus;
    this.wieni = DEFAULT_CONFIG.wieni;
    this.privacyPolicyUrl = DEFAULT_CONFIG.privacyPolicyUrl;
    this.faqUrl = DEFAULT_CONFIG.faqUrl;
    this.brandMenuCoinImages = DEFAULT_CONFIG.brandMenuCoinImages;
  }

  updateConfig(config: IConfig): Config {
    Object.assign(this, config);
    return this;
  }
})();
