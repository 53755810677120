import { StackScreenProps } from '@react-navigation/stack';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediumFeeds } from '~/features/media/list-medium-feeds';
import {
  GridPaginationLoader,
  usePaginateFinalGridFeed,
} from '~/features/media/list-paginated-feed';
import { MediaRoutes, MediaStackParamList } from '~/navigation';
import { ScrollingMenuBar } from '~/shared/ui/menu';
import { Preloader } from '~/shared/ui/preloader';
import { Fill } from '~/shared/ui/spacing';
import { isFeedListEmpty } from '~/shared/utils/media';
import { NetworkStatus, SpecificMediaMedium } from '~/types';
import { BaseLayout } from '~/widgets/base-layout';
import { FeedLayout, FeedLayoutSkeleton } from '~/widgets/feed-layout';
import { MainMenu } from '~/widgets/menu/main-menu';
import { UserMenu } from '~/widgets/menu/user-menu';

export const OverviewMediumPage: FunctionComponent<
  StackScreenProps<MediaStackParamList, MediaRoutes.Overview>
> = ({ route }) => {
  const { medium } = route.params;
  const {
    feeds: initialFeeds,
    networkStatus,
    retryQuery,
  } = useMediumFeeds({
    medium: medium as SpecificMediaMedium,
  });
  const {
    feeds,
    scrollableProps,
    networkStatus: paginationNetworkStatus,
  } = usePaginateFinalGridFeed({ feeds: initialFeeds });
  const { t } = useTranslation();

  return (
    <BaseLayout
      decorationTop={
        <ScrollingMenuBar>
          <MainMenu />
          <Fill />
          <UserMenu />
        </ScrollingMenuBar>
      }
      {...scrollableProps}
    >
      <Preloader
        loading={networkStatus < NetworkStatus.ready}
        error={networkStatus === NetworkStatus.error ? t('generalErrorMessage') : undefined}
        errorRetryButton={retryQuery}
        skeleton={<FeedLayoutSkeleton repeatCount={3} />}
        empty={isFeedListEmpty(feeds)}
      >
        {() => feeds && <FeedLayout feeds={feeds} />}
      </Preloader>
      {paginationNetworkStatus === NetworkStatus.loading && <GridPaginationLoader />}
    </BaseLayout>
  );
};
