import BaseTracker, { ITracker, ITrackerAdapter } from './adapters/base';
import AmplitudeTracker from './adapters/amplitude';
import FirebaseTracker from './adapters/firebase';
import { Route } from '@react-navigation/routers';
import { INavigationEventMeta, IProfileMeta } from './types';
import { IMedia } from '~/types';
import { linkingMap } from '~/navigation/linking';
import { compile } from 'path-to-regexp';
import GoogleAnalyticsTracker from './adapters/google-analytics';

class AnalyticsTracker extends BaseTracker {
  trackers: ITrackerAdapter[];

  navigationEventMeta?: INavigationEventMeta;

  constructor() {
    super('group');
    this.trackers = [new FirebaseTracker()];

    if (process.env.REACT_APP_AMPLITUDE_API_KEY) {
      this.trackers.push(new AmplitudeTracker(process.env.REACT_APP_AMPLITUDE_API_KEY));
    }
    if (process.env.REACT_APP_GA_PROPERTY) {
      this.trackers.push(new GoogleAnalyticsTracker(process.env.REACT_APP_GA_PROPERTY));
    }

    (BaseTracker.methods as (keyof ITracker)[]).forEach((method) => {
      this[method] = (...params: any[]): void => {
        this.trackers.forEach((tracker) => {
          try {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            tracker[method](...params);
          } catch (e) {
            console.warn(e);
          }
        });
      };
    });
  }

  setNavigationEventMeta(navigationEventMeta: INavigationEventMeta) {
    this.navigationEventMeta = navigationEventMeta;
  }

  getRoute = (route: Route<string> | undefined): { name: string; path?: string } | undefined => {
    if (!route) return;

    const name = route.name;
    const config = linkingMap[name];
    if (!config) {
      return { name };
    }
    try {
      const path = compile(config.path)(route.params);
      return { name, path: path === '/all/all' ? '/' : path };
    } catch (e) {
      return { name };
    }
  };

  trackContent = (
    event:
      | 'view'
      | 'favorite-add'
      | 'favorite-remove'
      | 'download-add'
      | 'download-remove'
      | 'view-locked',
    media: IMedia,
  ) => {
    const route = this.getRoute(this.navigationEventMeta?.route);

    this.logEvent(event === 'view' ? 'view_dimensions' : event, {
      s100_content_brand_name: media.brand?.slug,
      s100_content_category: `${media.meta?.category}-${media.meta?.subCategory}`.toLowerCase(),
      s100_content_type: media.__typename,
      s100_content_id: media.id,
      s100_content_title: media.title,
      s100_content_season_id: 'seasonId' in media ? media.seasonId : undefined,
      s100_content_serie_id: 'serieId' in media ? media.serieId : undefined,

      source_id: this.navigationEventMeta?.id,
      source_type: this.navigationEventMeta?.type,
      source_view_name: route?.name,
      source_view_path: route?.path,
    });
  };

  trackProfile = (
    event: 'profile-add' | 'profile-remove' | 'profile-update',
    profile: IProfileMeta,
  ) => {
    this.logEvent(event, {
      profile_age: profile.age,
      profile_language: profile.language,
    });
  };

  trackNavigationStateChange = (prevRoute?: Route<string>, currentRoute?: Route<string>) => {
    try {
      if (!currentRoute || prevRoute?.key === currentRoute?.key) {
        return;
      }

      this.trackPageview(currentRoute);
    } catch (e) {
      console.warn(e);
    }
  };

  trackPageview = (route: Route<string>): void => {
    try {
      this.logCurrentScreen(route.name);
      this.logEvent('pageview', {
        view_name: route.name,
        path: this.getRoute(route)?.path || '/',
        ...route.params,
      });
    } catch (e) {
      console.warn(e);
    }
  };
}

export const analyticsTracker = new AnalyticsTracker();
