/*
1° de pictogrammen worden op een duidelijke en contrasterende wijze gedurende
ten minste vijf seconden ononderbroken in beeld gebracht in het begin van het
programma en na elke hervatting van het programma na een reclamepauze;

2° de pictogrammen worden rechts bovenaan of links bovenaan in beeld gebracht
en voldoen aan de volgende minimumnormen:
  a) grootte :
    1) HD diameter 75 px,
    2) SD diameter 50 px,
    3) OTT diameter 40 px;
  b) kleur: full white RGB (255.255.255), HEX #FFFFFF;
  c) transparantie: 0%.
*/

export function useClassificationIconSize() {
  // const windowHeight = useWindowDimensions().height;

  // if (windowHeight < 768) {
  //   return 50;
  // }
  // return 75;

  return 40;
}
