import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { LanguageSelector } from '~/features/onboarding/language-selector';
import tw from '~/shared/theme';
import { Button } from '~/shared/ui/button';
import { Card } from '~/shared/ui/card';
import { PageDecoration } from '~/shared/ui/page-decoration';
import { GapSpacer } from '~/shared/ui/spacing';
import { TextVariant } from '~/shared/ui/text-variant';
import { CenteredLayout } from '~/widgets/centered-layout';

interface CTAToNativeAppProps {}

function getConfig(language: string) {
  if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
    return {
      storeUrl: `https://apps.apple.com/be/app/studio-100-go/id${process.env.REACT_APP_SEO_IOS_STORE_ID}?l=${language}`,
      uriScheme: `${process.env.REACT_APP_SEO_IOS_URI_SCHEME}`,
    };
  } else {
    return {
      storeUrl: `https://play.google.com/store/apps/details?id=${process.env.REACT_APP_SEO_IOS_STORE_ID}&hl=${language}`,
      uriScheme: `${process.env.REACT_APP_SEO_ANDROID_URI_SCHEME}`,
    };
  }
}

export const CTAToNativeAppPage: FunctionComponent<CTAToNativeAppProps> = () => {
  const { t, i18n } = useTranslation();
  const config = getConfig(i18n.language);

  const openApp = () => {
    setTimeout(() => {
      // eslint-disable-next-line no-alert
      alert(t('appNotInstalled'));
    }, 1000);
    window.location.href = `${config.uriScheme}${window.location.pathname}`.replace('///', '//');
  };
  const downloadApp = () => {
    window.location.href = config.storeUrl;
  };

  return (
    <SafeAreaProvider style={tw`w-full h-full flex`}>
      <CenteredLayout
        decorationTopRight={
          <View style={tw`px-4`}>
            <LanguageSelector />
          </View>
        }
      >
        <View style={tw`max-w-lg tablet:max-w-2xl`}>
          <Card style={tw`py-10`}>
            <TextVariant variant="h2" style={tw`text-blue mb-10 text-center`}>
              {t('youNeedToDownloadTheApp')}
            </TextVariant>
            <GapSpacer spacing="sm">
              <Button variant="secondary" size="lg" onPress={openApp}>
                {t('openApp')}
              </Button>
              <Button variant="submit" size="lg" onPress={downloadApp}>
                {t('downloadApp')}
              </Button>
            </GapSpacer>
          </Card>
          <PageDecoration name="book" style={tw`left-1/6 -top-4`} />
          <PageDecoration name="play" style={tw`right-24 -top-12`} />
          <PageDecoration name="game" style={tw`top-8 -right-32`} />
          <PageDecoration name="music" style={tw`right-1/6 -bottom-8`} />
          <PageDecoration name="blocks" style={tw`-bottom-32 left-18`} />
        </View>
      </CenteredLayout>
    </SafeAreaProvider>
  );
};
