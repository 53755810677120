import * as Types from '~/shared/api/models.main.generated';

import { gql } from '@apollo/client';
import { ListMediaItemFragmentDoc } from '../../../../shared/api/fragments/list-media-item.main.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {"context":{"clientName":"main"}} as const;
export type IGQLGetMediaQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type IGQLGetMediaQueryData = (
  { __typename: 'Query' }
  & { mediaItem: Types.Maybe<(
    { __typename: 'Book' }
    & Pick<Types.IGQLBook, 'id' | 'title' | 'description' | 'isLocked' | 'isFeatured' | 'meta'>
    & { bookPages: Types.IGQLBook['resources'] }
    & { brand: (
      { __typename: 'Brand' }
      & Pick<Types.IGQLBrand, 'id' | 'slug' | 'name'>
    ), previews: Types.Maybe<(
      { __typename: 'MediaPreviews' }
      & { medium: Types.Maybe<(
        { __typename: 'Asset' }
        & Pick<Types.IGQLAsset, 'type' | 'source' | 'width' | 'height'>
      )>, large: Types.Maybe<(
        { __typename: 'Asset' }
        & Pick<Types.IGQLAsset, 'type' | 'source' | 'width' | 'height'>
      )> }
    )>, tile: (
      { __typename: 'TileProperties' }
      & Pick<Types.IGQLTileProperties, 'title' | 'subTitle'>
    ) }
  ) | (
    { __typename: 'Game' }
    & Pick<Types.IGQLGame, 'id' | 'title' | 'description' | 'isLocked' | 'isFeatured' | 'meta'>
    & { gameSource: Types.IGQLGame['resources'], gameDownloadSource: Types.IGQLGame['downloadUrl'] }
    & { brand: (
      { __typename: 'Brand' }
      & Pick<Types.IGQLBrand, 'id' | 'slug' | 'name'>
    ), previews: Types.Maybe<(
      { __typename: 'MediaPreviews' }
      & { medium: Types.Maybe<(
        { __typename: 'Asset' }
        & Pick<Types.IGQLAsset, 'type' | 'source' | 'width' | 'height'>
      )>, large: Types.Maybe<(
        { __typename: 'Asset' }
        & Pick<Types.IGQLAsset, 'type' | 'source' | 'width' | 'height'>
      )> }
    )>, tile: (
      { __typename: 'TileProperties' }
      & Pick<Types.IGQLTileProperties, 'title' | 'subTitle'>
    ) }
  ) | (
    { __typename: 'Playlist' }
    & Pick<Types.IGQLPlaylist, 'id' | 'title' | 'description' | 'isLocked' | 'isFeatured' | 'meta'>
    & { items: Array<(
      { __typename: 'Book' }
      & Pick<Types.IGQLBook, 'id' | 'title' | 'description' | 'isLocked' | 'isFeatured' | 'meta'>
      & { bookPages: Types.IGQLBook['resources'] }
      & { brand: (
        { __typename: 'Brand' }
        & Pick<Types.IGQLBrand, 'id' | 'slug' | 'name'>
      ), previews: Types.Maybe<(
        { __typename: 'MediaPreviews' }
        & { medium: Types.Maybe<(
          { __typename: 'Asset' }
          & Pick<Types.IGQLAsset, 'type' | 'source' | 'width' | 'height'>
        )>, large: Types.Maybe<(
          { __typename: 'Asset' }
          & Pick<Types.IGQLAsset, 'type' | 'source' | 'width' | 'height'>
        )> }
      )>, tile: (
        { __typename: 'TileProperties' }
        & Pick<Types.IGQLTileProperties, 'title' | 'subTitle'>
      ) }
    ) | (
      { __typename: 'Game' }
      & Pick<Types.IGQLGame, 'id' | 'title' | 'description' | 'isLocked' | 'isFeatured' | 'meta'>
      & { gameSource: Types.IGQLGame['resources'], gameDownloadSource: Types.IGQLGame['downloadUrl'] }
      & { brand: (
        { __typename: 'Brand' }
        & Pick<Types.IGQLBrand, 'id' | 'slug' | 'name'>
      ), previews: Types.Maybe<(
        { __typename: 'MediaPreviews' }
        & { medium: Types.Maybe<(
          { __typename: 'Asset' }
          & Pick<Types.IGQLAsset, 'type' | 'source' | 'width' | 'height'>
        )>, large: Types.Maybe<(
          { __typename: 'Asset' }
          & Pick<Types.IGQLAsset, 'type' | 'source' | 'width' | 'height'>
        )> }
      )>, tile: (
        { __typename: 'TileProperties' }
        & Pick<Types.IGQLTileProperties, 'title' | 'subTitle'>
      ) }
    ) | (
      { __typename: 'Playlist' }
      & Pick<Types.IGQLPlaylist, 'id' | 'title' | 'description' | 'isLocked' | 'isFeatured' | 'meta'>
      & { brand: (
        { __typename: 'Brand' }
        & Pick<Types.IGQLBrand, 'id' | 'slug' | 'name'>
      ), previews: Types.Maybe<(
        { __typename: 'MediaPreviews' }
        & { medium: Types.Maybe<(
          { __typename: 'Asset' }
          & Pick<Types.IGQLAsset, 'type' | 'source' | 'width' | 'height'>
        )>, large: Types.Maybe<(
          { __typename: 'Asset' }
          & Pick<Types.IGQLAsset, 'type' | 'source' | 'width' | 'height'>
        )> }
      )>, tile: (
        { __typename: 'TileProperties' }
        & Pick<Types.IGQLTileProperties, 'title' | 'subTitle'>
      ) }
    ) | (
      { __typename: 'Serie' }
      & Pick<Types.IGQLSerie, 'id' | 'title' | 'description' | 'isLocked' | 'isFeatured' | 'meta'>
      & { brand: (
        { __typename: 'Brand' }
        & Pick<Types.IGQLBrand, 'id' | 'slug' | 'name'>
      ), previews: Types.Maybe<(
        { __typename: 'MediaPreviews' }
        & { medium: Types.Maybe<(
          { __typename: 'Asset' }
          & Pick<Types.IGQLAsset, 'type' | 'source' | 'width' | 'height'>
        )>, large: Types.Maybe<(
          { __typename: 'Asset' }
          & Pick<Types.IGQLAsset, 'type' | 'source' | 'width' | 'height'>
        )> }
      )>, tile: (
        { __typename: 'TileProperties' }
        & Pick<Types.IGQLTileProperties, 'title' | 'subTitle'>
      ) }
    ) | (
      { __typename: 'Song' }
      & Pick<Types.IGQLSong, 'id' | 'title' | 'description' | 'isLocked' | 'isFeatured' | 'meta' | 'duration' | 'ageClassification' | 'dangerousContentClassification'>
      & { brand: (
        { __typename: 'Brand' }
        & Pick<Types.IGQLBrand, 'id' | 'slug' | 'name'>
      ), previews: Types.Maybe<(
        { __typename: 'MediaPreviews' }
        & { medium: Types.Maybe<(
          { __typename: 'Asset' }
          & Pick<Types.IGQLAsset, 'type' | 'source' | 'width' | 'height'>
        )>, large: Types.Maybe<(
          { __typename: 'Asset' }
          & Pick<Types.IGQLAsset, 'type' | 'source' | 'width' | 'height'>
        )> }
      )>, tile: (
        { __typename: 'TileProperties' }
        & Pick<Types.IGQLTileProperties, 'title' | 'subTitle'>
      ), resources: Types.Maybe<(
        { __typename: 'MediaResources' }
        & { medium: Types.Maybe<(
          { __typename: 'Asset' }
          & Pick<Types.IGQLAsset, 'type' | 'source' | 'width' | 'height'>
        )>, large: Types.Maybe<(
          { __typename: 'Asset' }
          & Pick<Types.IGQLAsset, 'type' | 'source' | 'width' | 'height'>
        )>, stream: Types.Maybe<(
          { __typename: 'Asset' }
          & Pick<Types.IGQLAsset, 'type' | 'source' | 'width' | 'height'>
        )> }
      )>, playerOverlays: Types.Maybe<Array<(
        { __typename: 'PlayerOverlay' }
        & { label: (
          { __typename: 'Text' }
          & Pick<Types.IGQLText, 'type' | 'content'>
        ), visibleDuring: (
          { __typename: 'TimeSpan' }
          & Pick<Types.IGQLTimeSpan, 'start' | 'end'>
        ) }
      )>> }
    ) | (
      { __typename: 'Video' }
      & Pick<Types.IGQLVideo, 'id' | 'title' | 'description' | 'isLocked' | 'isFeatured' | 'meta' | 'duration' | 'seasonId' | 'serieId' | 'ageClassification' | 'dangerousContentClassification'>
      & { brand: (
        { __typename: 'Brand' }
        & Pick<Types.IGQLBrand, 'id' | 'slug' | 'name'>
      ), previews: Types.Maybe<(
        { __typename: 'MediaPreviews' }
        & { medium: Types.Maybe<(
          { __typename: 'Asset' }
          & Pick<Types.IGQLAsset, 'type' | 'source' | 'width' | 'height'>
        )>, large: Types.Maybe<(
          { __typename: 'Asset' }
          & Pick<Types.IGQLAsset, 'type' | 'source' | 'width' | 'height'>
        )> }
      )>, tile: (
        { __typename: 'TileProperties' }
        & Pick<Types.IGQLTileProperties, 'title' | 'subTitle'>
      ), resources: Types.Maybe<(
        { __typename: 'MediaResources' }
        & { medium: Types.Maybe<(
          { __typename: 'Asset' }
          & Pick<Types.IGQLAsset, 'type' | 'source' | 'width' | 'height'>
        )>, large: Types.Maybe<(
          { __typename: 'Asset' }
          & Pick<Types.IGQLAsset, 'type' | 'source' | 'width' | 'height'>
        )>, stream: Types.Maybe<(
          { __typename: 'Asset' }
          & Pick<Types.IGQLAsset, 'type' | 'source' | 'width' | 'height'>
        )> }
      )>, playerOverlays: Types.Maybe<Array<(
        { __typename: 'PlayerOverlay' }
        & { label: (
          { __typename: 'Text' }
          & Pick<Types.IGQLText, 'type' | 'content'>
        ), visibleDuring: (
          { __typename: 'TimeSpan' }
          & Pick<Types.IGQLTimeSpan, 'start' | 'end'>
        ) }
      )>> }
    )>, brand: (
      { __typename: 'Brand' }
      & Pick<Types.IGQLBrand, 'id' | 'slug' | 'name'>
    ), previews: Types.Maybe<(
      { __typename: 'MediaPreviews' }
      & { medium: Types.Maybe<(
        { __typename: 'Asset' }
        & Pick<Types.IGQLAsset, 'type' | 'source' | 'width' | 'height'>
      )>, large: Types.Maybe<(
        { __typename: 'Asset' }
        & Pick<Types.IGQLAsset, 'type' | 'source' | 'width' | 'height'>
      )> }
    )>, tile: (
      { __typename: 'TileProperties' }
      & Pick<Types.IGQLTileProperties, 'title' | 'subTitle'>
    ) }
  ) | (
    { __typename: 'Serie' }
    & Pick<Types.IGQLSerie, 'id' | 'title' | 'description' | 'isLocked' | 'isFeatured' | 'meta'>
    & { brand: (
      { __typename: 'Brand' }
      & Pick<Types.IGQLBrand, 'id' | 'slug' | 'name'>
    ), previews: Types.Maybe<(
      { __typename: 'MediaPreviews' }
      & { medium: Types.Maybe<(
        { __typename: 'Asset' }
        & Pick<Types.IGQLAsset, 'type' | 'source' | 'width' | 'height'>
      )>, large: Types.Maybe<(
        { __typename: 'Asset' }
        & Pick<Types.IGQLAsset, 'type' | 'source' | 'width' | 'height'>
      )> }
    )>, tile: (
      { __typename: 'TileProperties' }
      & Pick<Types.IGQLTileProperties, 'title' | 'subTitle'>
    ) }
  ) | (
    { __typename: 'Song' }
    & Pick<Types.IGQLSong, 'id' | 'title' | 'description' | 'isLocked' | 'isFeatured' | 'meta' | 'duration' | 'ageClassification' | 'dangerousContentClassification'>
    & { brand: (
      { __typename: 'Brand' }
      & Pick<Types.IGQLBrand, 'id' | 'slug' | 'name'>
    ), previews: Types.Maybe<(
      { __typename: 'MediaPreviews' }
      & { medium: Types.Maybe<(
        { __typename: 'Asset' }
        & Pick<Types.IGQLAsset, 'type' | 'source' | 'width' | 'height'>
      )>, large: Types.Maybe<(
        { __typename: 'Asset' }
        & Pick<Types.IGQLAsset, 'type' | 'source' | 'width' | 'height'>
      )> }
    )>, tile: (
      { __typename: 'TileProperties' }
      & Pick<Types.IGQLTileProperties, 'title' | 'subTitle'>
    ), resources: Types.Maybe<(
      { __typename: 'MediaResources' }
      & { medium: Types.Maybe<(
        { __typename: 'Asset' }
        & Pick<Types.IGQLAsset, 'type' | 'source' | 'width' | 'height'>
      )>, large: Types.Maybe<(
        { __typename: 'Asset' }
        & Pick<Types.IGQLAsset, 'type' | 'source' | 'width' | 'height'>
      )>, stream: Types.Maybe<(
        { __typename: 'Asset' }
        & Pick<Types.IGQLAsset, 'type' | 'source' | 'width' | 'height'>
      )> }
    )>, playerOverlays: Types.Maybe<Array<(
      { __typename: 'PlayerOverlay' }
      & { label: (
        { __typename: 'Text' }
        & Pick<Types.IGQLText, 'type' | 'content'>
      ), visibleDuring: (
        { __typename: 'TimeSpan' }
        & Pick<Types.IGQLTimeSpan, 'start' | 'end'>
      ) }
    )>> }
  ) | (
    { __typename: 'Video' }
    & Pick<Types.IGQLVideo, 'id' | 'title' | 'description' | 'isLocked' | 'isFeatured' | 'meta' | 'duration' | 'seasonId' | 'serieId' | 'ageClassification' | 'dangerousContentClassification'>
    & { brand: (
      { __typename: 'Brand' }
      & Pick<Types.IGQLBrand, 'id' | 'slug' | 'name'>
    ), previews: Types.Maybe<(
      { __typename: 'MediaPreviews' }
      & { medium: Types.Maybe<(
        { __typename: 'Asset' }
        & Pick<Types.IGQLAsset, 'type' | 'source' | 'width' | 'height'>
      )>, large: Types.Maybe<(
        { __typename: 'Asset' }
        & Pick<Types.IGQLAsset, 'type' | 'source' | 'width' | 'height'>
      )> }
    )>, tile: (
      { __typename: 'TileProperties' }
      & Pick<Types.IGQLTileProperties, 'title' | 'subTitle'>
    ), resources: Types.Maybe<(
      { __typename: 'MediaResources' }
      & { medium: Types.Maybe<(
        { __typename: 'Asset' }
        & Pick<Types.IGQLAsset, 'type' | 'source' | 'width' | 'height'>
      )>, large: Types.Maybe<(
        { __typename: 'Asset' }
        & Pick<Types.IGQLAsset, 'type' | 'source' | 'width' | 'height'>
      )>, stream: Types.Maybe<(
        { __typename: 'Asset' }
        & Pick<Types.IGQLAsset, 'type' | 'source' | 'width' | 'height'>
      )> }
    )>, playerOverlays: Types.Maybe<Array<(
      { __typename: 'PlayerOverlay' }
      & { label: (
        { __typename: 'Text' }
        & Pick<Types.IGQLText, 'type' | 'content'>
      ), visibleDuring: (
        { __typename: 'TimeSpan' }
        & Pick<Types.IGQLTimeSpan, 'start' | 'end'>
      ) }
    )>> }
  )> }
);

export type IGQLGetRelatedMediaQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  language: Types.Scalars['String']['input'];
  favoriteBrands: Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input'];
  favoriteMedia: Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input'];
  recentlyViewed: Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input'];
}>;


export type IGQLGetRelatedMediaQueryData = (
  { __typename: 'Query' }
  & { relatedMedia: (
    { __typename: 'FeedResult' }
    & Pick<Types.IGQLFeedResult, 'fetchMoreToken'>
    & { items: Array<(
      { __typename: 'Book' }
      & Pick<Types.IGQLBook, 'id' | 'title' | 'isFeatured' | 'isLocked'>
      & { bookPages: Types.IGQLBook['resources'] }
      & { tile: (
        { __typename: 'TileProperties' }
        & Pick<Types.IGQLTileProperties, 'title' | 'subTitle'>
      ), brand: (
        { __typename: 'Brand' }
        & Pick<Types.IGQLBrand, 'id' | 'slug' | 'name'>
      ), previews: Types.Maybe<(
        { __typename: 'MediaPreviews' }
        & { medium: Types.Maybe<(
          { __typename: 'Asset' }
          & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
        )> }
      )> }
    ) | { __typename: 'Brand' } | (
      { __typename: 'Game' }
      & Pick<Types.IGQLGame, 'id' | 'title' | 'isFeatured' | 'isLocked'>
      & { gameSource: Types.IGQLGame['resources'], gameDownloadSource: Types.IGQLGame['downloadUrl'] }
      & { tile: (
        { __typename: 'TileProperties' }
        & Pick<Types.IGQLTileProperties, 'title' | 'subTitle'>
      ), brand: (
        { __typename: 'Brand' }
        & Pick<Types.IGQLBrand, 'id' | 'slug' | 'name'>
      ), previews: Types.Maybe<(
        { __typename: 'MediaPreviews' }
        & { medium: Types.Maybe<(
          { __typename: 'Asset' }
          & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
        )> }
      )> }
    ) | (
      { __typename: 'Playlist' }
      & Pick<Types.IGQLPlaylist, 'itemCount' | 'id' | 'title' | 'isFeatured' | 'isLocked'>
      & { tile: (
        { __typename: 'TileProperties' }
        & Pick<Types.IGQLTileProperties, 'title' | 'subTitle'>
      ), brand: (
        { __typename: 'Brand' }
        & Pick<Types.IGQLBrand, 'id' | 'slug' | 'name'>
      ), previews: Types.Maybe<(
        { __typename: 'MediaPreviews' }
        & { medium: Types.Maybe<(
          { __typename: 'Asset' }
          & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
        )> }
      )> }
    ) | (
      { __typename: 'Serie' }
      & Pick<Types.IGQLSerie, 'id' | 'title' | 'isFeatured' | 'isLocked'>
      & { posters: Types.Maybe<(
        { __typename: 'MediaPosters' }
        & { medium: Types.Maybe<(
          { __typename: 'Asset' }
          & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
        )>, small: Types.Maybe<(
          { __typename: 'Asset' }
          & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
        )> }
      )>, tile: (
        { __typename: 'TileProperties' }
        & Pick<Types.IGQLTileProperties, 'title' | 'subTitle'>
      ), brand: (
        { __typename: 'Brand' }
        & Pick<Types.IGQLBrand, 'id' | 'slug' | 'name'>
      ), previews: Types.Maybe<(
        { __typename: 'MediaPreviews' }
        & { medium: Types.Maybe<(
          { __typename: 'Asset' }
          & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
        )> }
      )> }
    ) | (
      { __typename: 'Song' }
      & Pick<Types.IGQLSong, 'duration' | 'id' | 'title' | 'isFeatured' | 'isLocked' | 'ageClassification' | 'dangerousContentClassification'>
      & { songContentType: Types.IGQLSong['contentType'] }
      & { tile: (
        { __typename: 'TileProperties' }
        & Pick<Types.IGQLTileProperties, 'title' | 'subTitle'>
      ), brand: (
        { __typename: 'Brand' }
        & Pick<Types.IGQLBrand, 'id' | 'slug' | 'name'>
      ), previews: Types.Maybe<(
        { __typename: 'MediaPreviews' }
        & { medium: Types.Maybe<(
          { __typename: 'Asset' }
          & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
        )> }
      )>, resources: Types.Maybe<(
        { __typename: 'MediaResources' }
        & { medium: Types.Maybe<(
          { __typename: 'Asset' }
          & Pick<Types.IGQLAsset, 'type' | 'source' | 'width' | 'height'>
        )>, large: Types.Maybe<(
          { __typename: 'Asset' }
          & Pick<Types.IGQLAsset, 'type' | 'source' | 'width' | 'height'>
        )>, stream: Types.Maybe<(
          { __typename: 'Asset' }
          & Pick<Types.IGQLAsset, 'type' | 'source' | 'width' | 'height'>
        )> }
      )>, playerOverlays: Types.Maybe<Array<(
        { __typename: 'PlayerOverlay' }
        & { label: (
          { __typename: 'Text' }
          & Pick<Types.IGQLText, 'type' | 'content'>
        ), visibleDuring: (
          { __typename: 'TimeSpan' }
          & Pick<Types.IGQLTimeSpan, 'start' | 'end'>
        ) }
      )>> }
    ) | (
      { __typename: 'Video' }
      & Pick<Types.IGQLVideo, 'duration' | 'seasonId' | 'serieId' | 'id' | 'title' | 'isFeatured' | 'isLocked' | 'ageClassification' | 'dangerousContentClassification'>
      & { videoContentType: Types.IGQLVideo['contentType'] }
      & { posters: Types.Maybe<(
        { __typename: 'MediaPosters' }
        & { medium: Types.Maybe<(
          { __typename: 'Asset' }
          & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
        )>, small: Types.Maybe<(
          { __typename: 'Asset' }
          & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
        )> }
      )>, tile: (
        { __typename: 'TileProperties' }
        & Pick<Types.IGQLTileProperties, 'title' | 'subTitle'>
      ), brand: (
        { __typename: 'Brand' }
        & Pick<Types.IGQLBrand, 'id' | 'slug' | 'name'>
      ), previews: Types.Maybe<(
        { __typename: 'MediaPreviews' }
        & { medium: Types.Maybe<(
          { __typename: 'Asset' }
          & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
        )> }
      )>, resources: Types.Maybe<(
        { __typename: 'MediaResources' }
        & { medium: Types.Maybe<(
          { __typename: 'Asset' }
          & Pick<Types.IGQLAsset, 'type' | 'source' | 'width' | 'height'>
        )>, large: Types.Maybe<(
          { __typename: 'Asset' }
          & Pick<Types.IGQLAsset, 'type' | 'source' | 'width' | 'height'>
        )>, stream: Types.Maybe<(
          { __typename: 'Asset' }
          & Pick<Types.IGQLAsset, 'type' | 'source' | 'width' | 'height'>
        )> }
      )>, playerOverlays: Types.Maybe<Array<(
        { __typename: 'PlayerOverlay' }
        & { label: (
          { __typename: 'Text' }
          & Pick<Types.IGQLText, 'type' | 'content'>
        ), visibleDuring: (
          { __typename: 'TimeSpan' }
          & Pick<Types.IGQLTimeSpan, 'start' | 'end'>
        ) }
      )>> }
    )> }
  ) }
);

export type IGQLMediaItem_Book_Fragment = (
  { __typename: 'Book' }
  & Pick<Types.IGQLBook, 'id' | 'title' | 'description' | 'isLocked' | 'isFeatured' | 'meta'>
  & { bookPages: Types.IGQLBook['resources'] }
  & { brand: (
    { __typename: 'Brand' }
    & Pick<Types.IGQLBrand, 'id' | 'slug' | 'name'>
  ), previews: Types.Maybe<(
    { __typename: 'MediaPreviews' }
    & { medium: Types.Maybe<(
      { __typename: 'Asset' }
      & Pick<Types.IGQLAsset, 'type' | 'source' | 'width' | 'height'>
    )>, large: Types.Maybe<(
      { __typename: 'Asset' }
      & Pick<Types.IGQLAsset, 'type' | 'source' | 'width' | 'height'>
    )> }
  )>, tile: (
    { __typename: 'TileProperties' }
    & Pick<Types.IGQLTileProperties, 'title' | 'subTitle'>
  ) }
);

export type IGQLMediaItem_Game_Fragment = (
  { __typename: 'Game' }
  & Pick<Types.IGQLGame, 'id' | 'title' | 'description' | 'isLocked' | 'isFeatured' | 'meta'>
  & { gameSource: Types.IGQLGame['resources'], gameDownloadSource: Types.IGQLGame['downloadUrl'] }
  & { brand: (
    { __typename: 'Brand' }
    & Pick<Types.IGQLBrand, 'id' | 'slug' | 'name'>
  ), previews: Types.Maybe<(
    { __typename: 'MediaPreviews' }
    & { medium: Types.Maybe<(
      { __typename: 'Asset' }
      & Pick<Types.IGQLAsset, 'type' | 'source' | 'width' | 'height'>
    )>, large: Types.Maybe<(
      { __typename: 'Asset' }
      & Pick<Types.IGQLAsset, 'type' | 'source' | 'width' | 'height'>
    )> }
  )>, tile: (
    { __typename: 'TileProperties' }
    & Pick<Types.IGQLTileProperties, 'title' | 'subTitle'>
  ) }
);

export type IGQLMediaItem_Playlist_Fragment = (
  { __typename: 'Playlist' }
  & Pick<Types.IGQLPlaylist, 'id' | 'title' | 'description' | 'isLocked' | 'isFeatured' | 'meta'>
  & { brand: (
    { __typename: 'Brand' }
    & Pick<Types.IGQLBrand, 'id' | 'slug' | 'name'>
  ), previews: Types.Maybe<(
    { __typename: 'MediaPreviews' }
    & { medium: Types.Maybe<(
      { __typename: 'Asset' }
      & Pick<Types.IGQLAsset, 'type' | 'source' | 'width' | 'height'>
    )>, large: Types.Maybe<(
      { __typename: 'Asset' }
      & Pick<Types.IGQLAsset, 'type' | 'source' | 'width' | 'height'>
    )> }
  )>, tile: (
    { __typename: 'TileProperties' }
    & Pick<Types.IGQLTileProperties, 'title' | 'subTitle'>
  ) }
);

export type IGQLMediaItem_Serie_Fragment = (
  { __typename: 'Serie' }
  & Pick<Types.IGQLSerie, 'id' | 'title' | 'description' | 'isLocked' | 'isFeatured' | 'meta'>
  & { brand: (
    { __typename: 'Brand' }
    & Pick<Types.IGQLBrand, 'id' | 'slug' | 'name'>
  ), previews: Types.Maybe<(
    { __typename: 'MediaPreviews' }
    & { medium: Types.Maybe<(
      { __typename: 'Asset' }
      & Pick<Types.IGQLAsset, 'type' | 'source' | 'width' | 'height'>
    )>, large: Types.Maybe<(
      { __typename: 'Asset' }
      & Pick<Types.IGQLAsset, 'type' | 'source' | 'width' | 'height'>
    )> }
  )>, tile: (
    { __typename: 'TileProperties' }
    & Pick<Types.IGQLTileProperties, 'title' | 'subTitle'>
  ) }
);

export type IGQLMediaItem_Song_Fragment = (
  { __typename: 'Song' }
  & Pick<Types.IGQLSong, 'id' | 'title' | 'description' | 'isLocked' | 'isFeatured' | 'meta' | 'duration' | 'ageClassification' | 'dangerousContentClassification'>
  & { brand: (
    { __typename: 'Brand' }
    & Pick<Types.IGQLBrand, 'id' | 'slug' | 'name'>
  ), previews: Types.Maybe<(
    { __typename: 'MediaPreviews' }
    & { medium: Types.Maybe<(
      { __typename: 'Asset' }
      & Pick<Types.IGQLAsset, 'type' | 'source' | 'width' | 'height'>
    )>, large: Types.Maybe<(
      { __typename: 'Asset' }
      & Pick<Types.IGQLAsset, 'type' | 'source' | 'width' | 'height'>
    )> }
  )>, tile: (
    { __typename: 'TileProperties' }
    & Pick<Types.IGQLTileProperties, 'title' | 'subTitle'>
  ), resources: Types.Maybe<(
    { __typename: 'MediaResources' }
    & { medium: Types.Maybe<(
      { __typename: 'Asset' }
      & Pick<Types.IGQLAsset, 'type' | 'source' | 'width' | 'height'>
    )>, large: Types.Maybe<(
      { __typename: 'Asset' }
      & Pick<Types.IGQLAsset, 'type' | 'source' | 'width' | 'height'>
    )>, stream: Types.Maybe<(
      { __typename: 'Asset' }
      & Pick<Types.IGQLAsset, 'type' | 'source' | 'width' | 'height'>
    )> }
  )>, playerOverlays: Types.Maybe<Array<(
    { __typename: 'PlayerOverlay' }
    & { label: (
      { __typename: 'Text' }
      & Pick<Types.IGQLText, 'type' | 'content'>
    ), visibleDuring: (
      { __typename: 'TimeSpan' }
      & Pick<Types.IGQLTimeSpan, 'start' | 'end'>
    ) }
  )>> }
);

export type IGQLMediaItem_Video_Fragment = (
  { __typename: 'Video' }
  & Pick<Types.IGQLVideo, 'id' | 'title' | 'description' | 'isLocked' | 'isFeatured' | 'meta' | 'duration' | 'seasonId' | 'serieId' | 'ageClassification' | 'dangerousContentClassification'>
  & { brand: (
    { __typename: 'Brand' }
    & Pick<Types.IGQLBrand, 'id' | 'slug' | 'name'>
  ), previews: Types.Maybe<(
    { __typename: 'MediaPreviews' }
    & { medium: Types.Maybe<(
      { __typename: 'Asset' }
      & Pick<Types.IGQLAsset, 'type' | 'source' | 'width' | 'height'>
    )>, large: Types.Maybe<(
      { __typename: 'Asset' }
      & Pick<Types.IGQLAsset, 'type' | 'source' | 'width' | 'height'>
    )> }
  )>, tile: (
    { __typename: 'TileProperties' }
    & Pick<Types.IGQLTileProperties, 'title' | 'subTitle'>
  ), resources: Types.Maybe<(
    { __typename: 'MediaResources' }
    & { medium: Types.Maybe<(
      { __typename: 'Asset' }
      & Pick<Types.IGQLAsset, 'type' | 'source' | 'width' | 'height'>
    )>, large: Types.Maybe<(
      { __typename: 'Asset' }
      & Pick<Types.IGQLAsset, 'type' | 'source' | 'width' | 'height'>
    )>, stream: Types.Maybe<(
      { __typename: 'Asset' }
      & Pick<Types.IGQLAsset, 'type' | 'source' | 'width' | 'height'>
    )> }
  )>, playerOverlays: Types.Maybe<Array<(
    { __typename: 'PlayerOverlay' }
    & { label: (
      { __typename: 'Text' }
      & Pick<Types.IGQLText, 'type' | 'content'>
    ), visibleDuring: (
      { __typename: 'TimeSpan' }
      & Pick<Types.IGQLTimeSpan, 'start' | 'end'>
    ) }
  )>> }
);

export type IGQLMediaItemFragment = IGQLMediaItem_Book_Fragment | IGQLMediaItem_Game_Fragment | IGQLMediaItem_Playlist_Fragment | IGQLMediaItem_Serie_Fragment | IGQLMediaItem_Song_Fragment | IGQLMediaItem_Video_Fragment;

export type IGQLMediaSource_Book_Fragment = (
  { __typename: 'Book' }
  & Pick<Types.IGQLBook, 'id'>
  & { bookPages: Types.IGQLBook['resources'] }
);

export type IGQLMediaSource_Game_Fragment = (
  { __typename: 'Game' }
  & Pick<Types.IGQLGame, 'id'>
  & { gameSource: Types.IGQLGame['resources'], gameDownloadSource: Types.IGQLGame['downloadUrl'] }
);

export type IGQLMediaSource_Playlist_Fragment = { __typename: 'Playlist' };

export type IGQLMediaSource_Serie_Fragment = { __typename: 'Serie' };

export type IGQLMediaSource_Song_Fragment = (
  { __typename: 'Song' }
  & Pick<Types.IGQLSong, 'duration'>
  & { resources: Types.Maybe<(
    { __typename: 'MediaResources' }
    & { medium: Types.Maybe<(
      { __typename: 'Asset' }
      & Pick<Types.IGQLAsset, 'type' | 'source' | 'width' | 'height'>
    )>, large: Types.Maybe<(
      { __typename: 'Asset' }
      & Pick<Types.IGQLAsset, 'type' | 'source' | 'width' | 'height'>
    )>, stream: Types.Maybe<(
      { __typename: 'Asset' }
      & Pick<Types.IGQLAsset, 'type' | 'source' | 'width' | 'height'>
    )> }
  )> }
);

export type IGQLMediaSource_Video_Fragment = (
  { __typename: 'Video' }
  & Pick<Types.IGQLVideo, 'duration' | 'seasonId' | 'serieId'>
  & { resources: Types.Maybe<(
    { __typename: 'MediaResources' }
    & { medium: Types.Maybe<(
      { __typename: 'Asset' }
      & Pick<Types.IGQLAsset, 'type' | 'source' | 'width' | 'height'>
    )>, large: Types.Maybe<(
      { __typename: 'Asset' }
      & Pick<Types.IGQLAsset, 'type' | 'source' | 'width' | 'height'>
    )>, stream: Types.Maybe<(
      { __typename: 'Asset' }
      & Pick<Types.IGQLAsset, 'type' | 'source' | 'width' | 'height'>
    )> }
  )> }
);

export type IGQLMediaSourceFragment = IGQLMediaSource_Book_Fragment | IGQLMediaSource_Game_Fragment | IGQLMediaSource_Playlist_Fragment | IGQLMediaSource_Serie_Fragment | IGQLMediaSource_Song_Fragment | IGQLMediaSource_Video_Fragment;

export type IGQLPlayerOverlays_Book_Fragment = { __typename: 'Book' };

export type IGQLPlayerOverlays_Game_Fragment = { __typename: 'Game' };

export type IGQLPlayerOverlays_Playlist_Fragment = { __typename: 'Playlist' };

export type IGQLPlayerOverlays_Serie_Fragment = { __typename: 'Serie' };

export type IGQLPlayerOverlays_Song_Fragment = (
  { __typename: 'Song' }
  & Pick<Types.IGQLSong, 'ageClassification' | 'dangerousContentClassification'>
  & { playerOverlays: Types.Maybe<Array<(
    { __typename: 'PlayerOverlay' }
    & { label: (
      { __typename: 'Text' }
      & Pick<Types.IGQLText, 'type' | 'content'>
    ), visibleDuring: (
      { __typename: 'TimeSpan' }
      & Pick<Types.IGQLTimeSpan, 'start' | 'end'>
    ) }
  )>> }
);

export type IGQLPlayerOverlays_Video_Fragment = (
  { __typename: 'Video' }
  & Pick<Types.IGQLVideo, 'ageClassification' | 'dangerousContentClassification'>
  & { playerOverlays: Types.Maybe<Array<(
    { __typename: 'PlayerOverlay' }
    & { label: (
      { __typename: 'Text' }
      & Pick<Types.IGQLText, 'type' | 'content'>
    ), visibleDuring: (
      { __typename: 'TimeSpan' }
      & Pick<Types.IGQLTimeSpan, 'start' | 'end'>
    ) }
  )>> }
);

export type IGQLPlayerOverlaysFragment = IGQLPlayerOverlays_Book_Fragment | IGQLPlayerOverlays_Game_Fragment | IGQLPlayerOverlays_Playlist_Fragment | IGQLPlayerOverlays_Serie_Fragment | IGQLPlayerOverlays_Song_Fragment | IGQLPlayerOverlays_Video_Fragment;

export type IGQLAssetFragment = (
  { __typename: 'Asset' }
  & Pick<Types.IGQLAsset, 'type' | 'source' | 'width' | 'height'>
);

export const AssetFragmentDoc = gql`
    fragment Asset on Asset {
  type
  source
  width
  height
}
    `;
export const MediaSourceFragmentDoc = gql`
    fragment MediaSource on Media {
  ... on Video {
    resources {
      medium {
        ...Asset
      }
      large {
        ...Asset
      }
      stream {
        ...Asset
      }
    }
    duration
    seasonId
    serieId
  }
  ... on Game {
    id
    gameSource: resources
    gameDownloadSource: downloadUrl
  }
  ... on Book {
    id
    bookPages: resources
  }
  ... on Song {
    resources {
      medium {
        ...Asset
      }
      large {
        ...Asset
      }
      stream {
        ...Asset
      }
    }
    duration
  }
}
    ${AssetFragmentDoc}`;
export const PlayerOverlaysFragmentDoc = gql`
    fragment PlayerOverlays on Media {
  ... on Song {
    ageClassification
    dangerousContentClassification
    playerOverlays {
      label {
        type
        content
      }
      visibleDuring {
        start
        end
      }
    }
  }
  ... on Video {
    ageClassification
    dangerousContentClassification
    playerOverlays {
      label {
        type
        content
      }
      visibleDuring {
        start
        end
      }
    }
  }
}
    `;
export const MediaItemFragmentDoc = gql`
    fragment MediaItem on Media {
  ...MediaSource
  ...PlayerOverlays
  __typename
  id
  title
  description
  isLocked
  isFeatured
  brand {
    id
    slug
    name
  }
  previews {
    medium {
      ...Asset
    }
    large {
      ...Asset
    }
  }
  tile {
    title
    subTitle
  }
  meta
}
    ${MediaSourceFragmentDoc}
${PlayerOverlaysFragmentDoc}
${AssetFragmentDoc}`;
export const GetMediaOperation = gql`
    query GetMedia($id: ID!) {
  mediaItem(id: $id) {
    ...MediaItem
    ... on Playlist {
      items {
        ...MediaItem
      }
    }
  }
}
    ${MediaItemFragmentDoc}`;
export function useGetMediaQuery(baseOptions: Apollo.QueryHookOptions<IGQLGetMediaQueryData, IGQLGetMediaQueryVariables> & ({ variables: IGQLGetMediaQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGQLGetMediaQueryData, IGQLGetMediaQueryVariables>(GetMediaOperation, options);
      }
export function useGetMediaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGQLGetMediaQueryData, IGQLGetMediaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGQLGetMediaQueryData, IGQLGetMediaQueryVariables>(GetMediaOperation, options);
        }
export function useGetMediaSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGQLGetMediaQueryData, IGQLGetMediaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGQLGetMediaQueryData, IGQLGetMediaQueryVariables>(GetMediaOperation, options);
        }
export type GetMediaQueryHookResult = ReturnType<typeof useGetMediaQuery>;
export type GetMediaLazyQueryHookResult = ReturnType<typeof useGetMediaLazyQuery>;
export type GetMediaSuspenseQueryHookResult = ReturnType<typeof useGetMediaSuspenseQuery>;
export type GetMediaQueryResult = Apollo.QueryResult<IGQLGetMediaQueryData, IGQLGetMediaQueryVariables>;
export const GetRelatedMediaOperation = gql`
    query GetRelatedMedia($id: ID!, $language: String!, $favoriteBrands: [ID!]!, $favoriteMedia: [ID!]!, $recentlyViewed: [ID!]!) {
  relatedMedia(
    id: $id
    profilePreferences: {language: $language, favoriteBrands: $favoriteBrands, favoriteMedia: $favoriteMedia, recentlyViewed: $recentlyViewed}
  ) {
    fetchMoreToken
    items {
      ...ListMediaItem
      ...MediaSource
      ...PlayerOverlays
    }
  }
}
    ${ListMediaItemFragmentDoc}
${MediaSourceFragmentDoc}
${PlayerOverlaysFragmentDoc}`;
export function useGetRelatedMediaQuery(baseOptions: Apollo.QueryHookOptions<IGQLGetRelatedMediaQueryData, IGQLGetRelatedMediaQueryVariables> & ({ variables: IGQLGetRelatedMediaQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGQLGetRelatedMediaQueryData, IGQLGetRelatedMediaQueryVariables>(GetRelatedMediaOperation, options);
      }
export function useGetRelatedMediaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGQLGetRelatedMediaQueryData, IGQLGetRelatedMediaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGQLGetRelatedMediaQueryData, IGQLGetRelatedMediaQueryVariables>(GetRelatedMediaOperation, options);
        }
export function useGetRelatedMediaSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGQLGetRelatedMediaQueryData, IGQLGetRelatedMediaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGQLGetRelatedMediaQueryData, IGQLGetRelatedMediaQueryVariables>(GetRelatedMediaOperation, options);
        }
export type GetRelatedMediaQueryHookResult = ReturnType<typeof useGetRelatedMediaQuery>;
export type GetRelatedMediaLazyQueryHookResult = ReturnType<typeof useGetRelatedMediaLazyQuery>;
export type GetRelatedMediaSuspenseQueryHookResult = ReturnType<typeof useGetRelatedMediaSuspenseQuery>;
export type GetRelatedMediaQueryResult = Apollo.QueryResult<IGQLGetRelatedMediaQueryData, IGQLGetRelatedMediaQueryVariables>;