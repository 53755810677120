import React, { FunctionComponent, ReactNode, useMemo, useState } from 'react';
import {
  Animated,
  NativeScrollEvent,
  NativeSyntheticEvent,
  View,
  ScrollView,
  Platform,
} from 'react-native';

import tw from '~/shared/theme';
import { ImageBackground, ImageSourcePropType } from '~/shared/ui/image';
import { MenuBar, MenuItemTextColorContextProvider } from '~/shared/ui/menu';
import { Fill, Inline } from '~/shared/ui/spacing';
import { useInsetsOrPadding } from '~/shared/utils/use-insets-or-padding';
import EnteringMenuWrapper from './entering-menu-wrapper.component';

export interface MergingMenuLayoutProps {
  menuLeft?: ReactNode;
  menuRight?: ReactNode;
  header: ReactNode;
  menuCenter: ReactNode;
  background?: ImageSourcePropType;
  onScroll?(event: NativeSyntheticEvent<NativeScrollEvent>): void;
  scrollViewRef?: React.RefObject<ScrollView>;
  children?: React.ReactNode;
}

interface WrapProps {
  hide?: boolean;
  children?: React.ReactNode;
}
const WrapLeft: FunctionComponent<WrapProps> = ({ hide, children }) => (
  <Fill
    style={[tw`flex-row self-stretch`, hide && tw`opacity-0 pointer-events-none`]}
    pointerEvents={hide ? 'none' : 'auto'}
  >
    {children}
  </Fill>
);
const WrapRight: FunctionComponent<WrapProps> = ({ hide, children }) => (
  <Fill
    style={[tw`flex-row justify-end self-stretch`, hide && tw`opacity-0 pointer-events-none`]}
    pointerEvents={hide ? 'none' : 'auto'}
  >
    {children}
  </Fill>
);

export const MergingMenuLayout: FunctionComponent<MergingMenuLayoutProps> = ({
  header,
  menuLeft,
  menuRight,
  menuCenter,
  children,
  background = require('~/assets/images/default-bg.png'),
  onScroll,
  scrollViewRef,
}) => {
  const { left, right } = useInsetsOrPadding();
  const [headerHeight, setHeaderHeight] = useState<number>(200);
  const [navHeight, setNavHeight] = useState<number>(94);
  const scrollOffset = useMemo(() => new Animated.Value(0), []);
  const mergeOut = scrollOffset.interpolate({
    inputRange: [headerHeight - navHeight, headerHeight],
    outputRange: [1, 0],
    extrapolate: 'clamp',
  });
  const mergeIn = scrollOffset.interpolate({
    inputRange: [headerHeight - navHeight, headerHeight],
    outputRange: [0, 1],
    extrapolate: 'clamp',
  });

  return (
    <ImageBackground source={background} resizeMode="cover" style={tw`w-full h-full`}>
      <MenuBar
        variant="transparent"
        style={[tw`absolute items-start`, { left, right }]}
        onLayout={(event) => {
          setNavHeight(event.nativeEvent.layout.height);
        }}
      >
        <Animated.View
          style={[tw`absolute inset-0 bg-black bg-opacity-30`, { opacity: mergeOut }]}
        />
        <Animated.View
          style={[tw`absolute inset-0 bg-green-450 bg-opacity-95`, { opacity: mergeIn }]}
        />
        <WrapLeft>{menuLeft}</WrapLeft>
        <EnteringMenuWrapper mergeProgress={mergeIn} navHeight={navHeight}>
          {menuCenter}
        </EnteringMenuWrapper>
        <WrapRight>
          <Animated.View
            style={[tw`flex-row items-start`, { opacity: mergeOut, zIndex: mergeOut }]}
          >
            <MenuItemTextColorContextProvider inactiveTextStyle={tw`text-white`}>
              {menuRight}
            </MenuItemTextColorContextProvider>
          </Animated.View>
          <Animated.View
            style={[tw`flex-row absolute top-0 right-0`, { opacity: mergeIn, zIndex: mergeIn }]}
          >
            {menuRight}
          </Animated.View>
        </WrapRight>
      </MenuBar>
      <Animated.ScrollView
        ref={scrollViewRef}
        style={[tw`w-full h-full`]}
        showsHorizontalScrollIndicator={false}
        snapToOffsets={[0, headerHeight - navHeight, headerHeight]}
        snapToStart={false}
        snapToEnd={false}
        onScroll={Animated.event([{ nativeEvent: { contentOffset: { y: scrollOffset } } }], {
          useNativeDriver: true,
          listener: onScroll,
        })}
      >
        <Animated.View
          style={[
            tw`snap-start`,
            Platform.OS !== 'web'
              ? {
                  transform: [
                    {
                      translateY: scrollOffset.interpolate({
                        inputRange: [-headerHeight, 0],
                        outputRange: [-headerHeight * 0.5, 0],
                        extrapolateRight: 'clamp',
                      }),
                    },
                    {
                      scale: scrollOffset.interpolate({
                        inputRange: [-headerHeight, 0],
                        outputRange: [2, 1],
                        extrapolateRight: 'clamp',
                      }),
                    },
                  ],
                }
              : {},
          ]}
          onLayout={(event) => {
            setHeaderHeight(event.nativeEvent.layout.height);
          }}
        >
          {header}
        </Animated.View>
        <View
          style={[
            tw`snap-start pb-16`,
            {
              paddingLeft: left,
              paddingRight: right,
            },
          ]}
        >
          <Animated.View
            style={{
              opacity: mergeOut,
            }}
          >
            <MenuBar style={tw`items-start`}>
              <WrapLeft hide>{menuLeft}</WrapLeft>
              <View>
                <Inline>{menuCenter}</Inline>
              </View>
              <WrapRight hide>{menuRight}</WrapRight>
            </MenuBar>
          </Animated.View>
          {children}
        </View>
      </Animated.ScrollView>
    </ImageBackground>
  );
};
