import AsyncStorage from '@react-native-async-storage/async-storage';
import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import { IProximusTokenInfo, makeProximusApi } from '~/shared/api/proximus.api';
import config from '~/shared/config';
import { analyticsTracker } from '~/shared/tracking/analytics';
import { openURL } from '~/shared/utils/browser';
import { IMedia, NetworkStatus } from '~/types';
import getProximusOAuthState from './utils/get-proximus-oauth-state';

interface ILoginSource {
  screen: string;
  params?: { id?: string; [key: string]: unknown };
}

interface ILoginOptions {
  prompt?: 'login';
}

interface IProximusState {
  _hasHydrated: boolean;
  tokenInfo?: IProximusTokenInfo & { expires_at: number };
  validation?: {
    networkStatus: NetworkStatus;
    isValid?: boolean;
  };
  validationConfirmationVisible: boolean;
  loginSource?: ILoginSource;
  activeRequest?: { media: IMedia };
}

interface IProximusProducers {
  openLogin(source: ILoginSource, options?: ILoginOptions): void;

  init(tokenInfo?: IProximusTokenInfo): Promise<{ isValid: boolean; loginSource?: ILoginSource }>;
  refreshToken(): Promise<void>;
  reset(): Promise<void>;
  sync(): Promise<void>;

  closeValidationConfirmation(): void;

  // Access Request
  requestMediaItem(media: IMedia): void;
  dismissRequest(): void;
}

type IProximusModel = IProximusState & IProximusProducers;

function makeTokenInfo(
  tokenInfo: IProximusTokenInfo | Exclude<IProximusState['tokenInfo'], undefined>,
): IProximusState['tokenInfo'] {
  if (!tokenInfo.access_token) return undefined;

  if ('expires_at' in tokenInfo) {
    return tokenInfo;
  }

  const expires_at = Date.now() + tokenInfo.expires_in * 1000;
  return {
    ...tokenInfo,
    expires_at,
  };
}

const DEFAULT_AUTH_STATE = {
  loginSource: undefined,
  tokenInfo: undefined,
  validation: undefined,
};

const localState = {
  isRefreshing: false,
};

export const useProximus = create<IProximusModel>()(
  persist(
    (set, get) => ({
      _hasHydrated: false,

      validationConfirmationVisible: false,

      openLogin(source, options) {
        set({ loginSource: source });

        setTimeout(() => {
          const stateIdentifier = getProximusOAuthState(config.proximus);
          const prompt = options?.prompt;
          let loginUrl = config.proximus.authorizeUrl.replace(
            '{{stateIdentifier}}',
            stateIdentifier,
          );
          if (prompt) {
            loginUrl = `${loginUrl}&prompt=${prompt}`;
          }

          openURL(loginUrl);
        }, 100); // Making sure storage has been set
      },

      async init(_tokenInfo) {
        const { tokenInfo: stateTokenInfo, validation: stateValidation, loginSource } = get();
        const tokenInfo = _tokenInfo || stateTokenInfo;

        // If no tokenInfo was passed, simply set the defaults.
        if (!tokenInfo) {
          set(DEFAULT_AUTH_STATE);
          return { isValid: false, loginSource, validationConfirmationVisible: true };
        }

        // Update the tokenInfo and set validation to pending.
        const nextTokenInfo = makeTokenInfo(tokenInfo);
        set(({ validation }) => ({
          loginSource: undefined,
          tokenInfo: nextTokenInfo,
          validation: { ...validation, networkStatus: NetworkStatus.loading },
          validationConfirmationVisible: true,
        }));

        const result = await makeProximusApi(config.proximus).validateToken(tokenInfo);

        // The validation request failed, possibly due to a timeout/..
        // Use the previous validation result and set status to error.
        if (!result) {
          set(({ validation }) => ({
            tokenInfo: undefined,
            validation: { ...validation, networkStatus: NetworkStatus.error },
          }));
          return { isValid: stateValidation?.isValid || false, loginSource };
        }

        // Validation response
        set({
          validation: { isValid: result.isValid, networkStatus: NetworkStatus.ready },
        });

        // Validation was invalid, reset/invalidate tokenInfo
        if (!result.isValid) {
          this.reset();
        }
        return { isValid: result.isValid, loginSource };
      },

      async refreshToken() {
        const { tokenInfo } = get();
        if (!tokenInfo || localState.isRefreshing) return;

        localState.isRefreshing = true;
        try {
          const result = await makeProximusApi(config.proximus).refreshToken(tokenInfo);
          if (!result) {
            set(DEFAULT_AUTH_STATE);
          } else {
            set({ tokenInfo: makeTokenInfo(result) });
          }
        } catch (e) {
        } finally {
          localState.isRefreshing = false;
        }
      },

      async sync() {
        const { tokenInfo, refreshToken } = get();
        if (!tokenInfo) return;
        if (tokenInfo.expires_at > Date.now() + 5 * 60 * 1000) return; // refresh 5 minutes before expiration
        refreshToken();
      },

      async reset() {
        const { tokenInfo } = get();

        set(DEFAULT_AUTH_STATE);

        // Revoke can happen & possibly fail in the background without UX issues.
        if (!tokenInfo) return;
        await makeProximusApi(config.proximus).revokeToken(tokenInfo);
      },

      // Access Request
      activeRequest: undefined,
      requestMediaItem(media) {
        set({ activeRequest: { media } });
        if (media) {
          analyticsTracker.trackContent('view-locked', media);
        }
      },
      dismissRequest() {
        set({ activeRequest: undefined });
      },

      closeValidationConfirmation() {
        set({ validationConfirmationVisible: false });
      },
    }),
    {
      name: 'studio100go-proximus',
      storage: createJSONStorage(() => AsyncStorage),
      onRehydrateStorage: () => () => {
        useProximus.setState({ _hasHydrated: true });
        useProximus.getState().sync();
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      partialize: ({ activeRequest, validationConfirmationVisible, _hasHydrated, ...state }) =>
        state,
    },
  ),
);
