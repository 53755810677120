import { useNetInfo } from '@react-native-community/netinfo';
import React, { FunctionComponent } from 'react';
import { GamePlayer } from '~/shared/ui/game-player';
import { IMediaGame, MediaDownloadStatus } from '~/types';

interface GameMediaDetailProps {
  item: IMediaGame;
  fullScreen?: boolean;
}

export const GameMediaDetail: FunctionComponent<GameMediaDetailProps> = ({
  item,
  fullScreen = false,
}) => {
  const { isConnected } = useNetInfo();
  const isLocal =
    isConnected === false && item.downloadMeta?.status === MediaDownloadStatus.Downloaded;
  const source = isLocal ? `file://${item.gameSource}/index.html` : `${item.gameSource}`;

  return <GamePlayer source={source} isLocal={isLocal} fullScreen={fullScreen} />;
};
