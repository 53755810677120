import React, { FunctionComponent } from 'react';
import { Image, ImageProps } from '../image';

const logos = {
  default: {
    source: require('~/assets/images/logo-studio100go.png'),
    width: 220,
    height: 120,
  },
  pass: {
    source: require('~/assets/images/logo-studio100go-pass.png'),
    width: 270,
    height: 120,
  },
};

export interface LogoProps extends Omit<ImageProps, 'source'> {
  variant?: keyof typeof logos;
}

export const Logo: FunctionComponent<LogoProps> = ({ variant = 'default', style, ...props }) => {
  const logo = logos[variant];
  const { width, height } = logo;
  return <Image {...logo} resizeMode="contain" style={[{ width, height }, style]} {...props} />;
};
