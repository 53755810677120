import * as Types from '~/shared/api/models.main.generated';

import { gql } from '@apollo/client';
import { ListBrandItemFragmentDoc } from '../../../shared/api/fragments/list-brand-item.main.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {"context":{"clientName":"main"}} as const;
export type IGQLGetBrandsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type IGQLGetBrandsQueryData = (
  { __typename: 'Query' }
  & { nl: Array<(
    { __typename: 'Brand' }
    & Pick<Types.IGQLBrand, 'id' | 'slug' | 'name'>
    & { banners: Types.Maybe<(
      { __typename: 'BrandBanners' }
      & { large: Types.Maybe<(
        { __typename: 'Asset' }
        & Pick<Types.IGQLAsset, 'source'>
      )>, medium: Types.Maybe<(
        { __typename: 'Asset' }
        & Pick<Types.IGQLAsset, 'source'>
      )> }
    )>, images: Types.Maybe<(
      { __typename: 'BrandPreviews' }
      & { medium: Types.Maybe<(
        { __typename: 'Asset' }
        & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
      )> }
    )> }
  )>, fr: Array<(
    { __typename: 'Brand' }
    & Pick<Types.IGQLBrand, 'id' | 'slug' | 'name'>
    & { banners: Types.Maybe<(
      { __typename: 'BrandBanners' }
      & { large: Types.Maybe<(
        { __typename: 'Asset' }
        & Pick<Types.IGQLAsset, 'source'>
      )>, medium: Types.Maybe<(
        { __typename: 'Asset' }
        & Pick<Types.IGQLAsset, 'source'>
      )> }
    )>, images: Types.Maybe<(
      { __typename: 'BrandPreviews' }
      & { medium: Types.Maybe<(
        { __typename: 'Asset' }
        & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
      )> }
    )> }
  )> }
);

export type IGQLBrandFragment = (
  { __typename: 'Brand' }
  & Pick<Types.IGQLBrand, 'id' | 'slug' | 'name'>
  & { banners: Types.Maybe<(
    { __typename: 'BrandBanners' }
    & { large: Types.Maybe<(
      { __typename: 'Asset' }
      & Pick<Types.IGQLAsset, 'source'>
    )>, medium: Types.Maybe<(
      { __typename: 'Asset' }
      & Pick<Types.IGQLAsset, 'source'>
    )> }
  )>, images: Types.Maybe<(
    { __typename: 'BrandPreviews' }
    & { medium: Types.Maybe<(
      { __typename: 'Asset' }
      & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
    )> }
  )> }
);

export const BrandFragmentDoc = gql`
    fragment Brand on Brand {
  ...ListBrandItem
  banners {
    large {
      source
    }
    medium {
      source
    }
  }
}
    ${ListBrandItemFragmentDoc}`;
export const GetBrandsOperation = gql`
    query GetBrands {
  nl: brands(language: "nl") {
    ...Brand
  }
  fr: brands(language: "fr") {
    ...Brand
  }
}
    ${BrandFragmentDoc}`;
export function useGetBrandsQuery(baseOptions?: Apollo.QueryHookOptions<IGQLGetBrandsQueryData, IGQLGetBrandsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGQLGetBrandsQueryData, IGQLGetBrandsQueryVariables>(GetBrandsOperation, options);
      }
export function useGetBrandsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGQLGetBrandsQueryData, IGQLGetBrandsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGQLGetBrandsQueryData, IGQLGetBrandsQueryVariables>(GetBrandsOperation, options);
        }
export function useGetBrandsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGQLGetBrandsQueryData, IGQLGetBrandsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGQLGetBrandsQueryData, IGQLGetBrandsQueryVariables>(GetBrandsOperation, options);
        }
export type GetBrandsQueryHookResult = ReturnType<typeof useGetBrandsQuery>;
export type GetBrandsLazyQueryHookResult = ReturnType<typeof useGetBrandsLazyQuery>;
export type GetBrandsSuspenseQueryHookResult = ReturnType<typeof useGetBrandsSuspenseQuery>;
export type GetBrandsQueryResult = Apollo.QueryResult<IGQLGetBrandsQueryData, IGQLGetBrandsQueryVariables>;