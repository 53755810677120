import React, { FunctionComponent } from 'react';
import { StyleProp, Text, TextProps, TextStyle } from 'react-native';
import tw, { config } from '~/shared/theme';

interface TextVariantProps extends TextProps {
  variant?: keyof typeof config.custom.textVariants;
  style?: StyleProp<TextStyle>;
}

export const TextVariant: FunctionComponent<TextVariantProps> = ({
  variant = 'body',
  style,
  children,
  ...props
}) => {
  return (
    <Text style={[tw`${config.custom.textVariants[variant]}`, style]} {...props}>
      {children}
    </Text>
  );
};
