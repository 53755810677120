import { FunctionComponent, useEffect } from 'react';
import { useActiveProfile, useProfiles } from '~/entities/profiles';
import { useProximus } from '~/entities/proximus';
import { useViewer } from '~/entities/viewer';
import appInfo from '~/shared/app-info';
import { analyticsTracker } from '~/shared/tracking/analytics';
import { NetworkStatus } from '~/types';

interface AnalyticsProps {}

function useSetUserValue(name: string, value: unknown): void {
  useEffect(() => {
    if (value) analyticsTracker.setUserProperty(name, value);
  }, [name, value]);
}

function useSubscription(): string | null {
  const { tokenInfo, validation } = useProximus();
  if (!tokenInfo) {
    return 'registered';
  }

  if (validation?.networkStatus === NetworkStatus.loading) {
    return null;
  }

  if (tokenInfo && !validation?.isValid) {
    return 'logged-in';
  }

  return 'paying';
}

export const Analytics: FunctionComponent<AnalyticsProps> = () => {
  const deviceId = useViewer((viewer) => viewer.deviceId);
  const subscription = useSubscription();
  const profiles = useProfiles((state) => state.items);
  const { activeProfile } = useActiveProfile();

  useEffect(() => {
    analyticsTracker.setUserId(deviceId);
  }, [deviceId]);

  useEffect(() => {
    if (!activeProfile) return;
    analyticsTracker.setProfileMeta(activeProfile);
  }, [activeProfile]);

  useSetUserValue('s100_app_profilecount', profiles.length);
  useSetUserValue('s100_user_subscription', subscription);
  useSetUserValue('code_version', appInfo.codeVersion);

  return null;
};
