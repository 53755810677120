import { useAppConfig, useAppConfigModel } from '~/entities/app-config';
import { useBrandsModel, useBrands } from '~/entities/brands/model';
import { useDownloads } from '~/entities/downloads/model';
import { useProfiles } from '~/entities/profiles';
import { useProximus } from '~/entities/proximus';
import { useViewer } from '~/entities/viewer';
import { useMigrateV2toV3 } from './use-migrate-v2-to-v3';

export function useInitApp(): { initialized: boolean } {
  useAppConfig();

  const hydratedAppConfig = useAppConfigModel((state) => state._hasHydrated);
  const hydratedProfiles = useProfiles((state) => state._hasHydrated);
  const hydratedBrands = useBrandsModel((state) => state._hasHydrated);
  const { hydratedViewer, language } = useViewer((state) => ({
    hydratedViewer: state._hasHydrated,
    language: state.language,
  }));
  const hydratedDownloads = useDownloads((state) => state._hasHydrated);
  const hydratedProximus = useProximus((state) => state._hasHydrated);

  const { items: brands } = useBrands({ language });
  const hasBrands = brands.length > 0;

  const hydrated =
    hydratedAppConfig &&
    hydratedProfiles &&
    hydratedBrands &&
    hydratedViewer &&
    hydratedDownloads &&
    hydratedProximus;

  // @todo use zustand persist middleware migrations?
  const migratedV2toV3 = useMigrateV2toV3(hydrated);

  return {
    initialized: hasBrands && hydrated && migratedV2toV3,
  };
}
