import { AppRegistry, Platform } from 'react-native';
import Root from '~/root';
import Storybook from './.storybook'; // Important: Keep this import untouched

/**
 * Enable the native storybook here.
 * For native: add your story imports to /.storybook/stories.ts
 *
 * For web: run `yarn start:storybook`
 * The web storybook will automatically find all stories matching *.stories.tsx
 */
const NATIVE_STORYBOOK_ENABLED = false;

/**
 * Registers the main app
 */
AppRegistry.registerComponent('studio100go', () =>
  NATIVE_STORYBOOK_ENABLED && Platform.OS !== 'web' ? Storybook : Root,
);

/**
 * On web, we hook react-native-web to the #root element on public/index.html
 */
if (Platform.OS === 'web') {
  AppRegistry.runApplication('studio100go', {
    rootTag: document.getElementById('root'),
  });
}
