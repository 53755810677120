import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { Animated, Pressable, StyleProp, View, ViewStyle } from 'react-native';
import avatars, { AvatarName } from '~/assets/avatars';
import tw from '~/shared/theme';
import { Icon } from '../icon';
import { AnimatedImage } from '../image';

export interface AvatarProps {
  /** default avatar1 */
  name?: AvatarName;
  style?: StyleProp<ViewStyle>;
  onPress?: () => void;
  selected?: boolean;
  children?: React.ReactNode;
}

interface InnerAvatarProps extends Omit<AvatarProps, 'onPress'> {
  hovered?: boolean;
  pressed?: boolean;
  animated?: boolean;
}

const animationConfig = {
  outer: {
    inputRange: [-1, 0, 1],
    outputRange: [0.9, 1, 1.05],
  },
  imageScale: {
    inputRange: [-1, 0, 1],
    outputRange: [1, 1, 1.1],
  },
  imageRotation: {
    inputRange: [-1, 0, 1],
    outputRange: ['-5deg', '0deg', '5deg'],
  },
  border: {
    inputRange: [0, 1],
    outputRange: [2, 8],
  },
  borderOffset: {
    inputRange: [0, 1],
    outputRange: [-1, -8],
  },
  borderColor: {
    inputRange: [0, 1],
    outputRange: [tw.color('white'), tw.color('blue')] as string[],
  },
};

const Inner: FunctionComponent<InnerAvatarProps> = ({
  name = 'avatar1',
  selected = false,
  hovered = false,
  pressed = false,
  animated = false,
  style,
  children,
}) => {
  const animation = useMemo(() => {
    return {
      pressable: new Animated.Value(0),
      selected: new Animated.Value(0),
    };
  }, []);

  useEffect(() => {
    if (animated) {
      let toValue = 0;
      if (pressed) {
        toValue = -1;
      } else if (hovered) {
        toValue = 1;
      }
      animation.pressable.stopAnimation();
      Animated.spring(animation.pressable, {
        toValue,
        useNativeDriver: true,
        friction: 4,
        tension: 120,
      }).start();
    }
  }, [animation, animated, hovered, pressed]);
  useEffect(() => {
    const toValue = selected ? 1 : 0;
    if (animated) {
      animation.selected.stopAnimation();
      Animated.timing(animation.selected, {
        toValue,
        useNativeDriver: false,
        duration: 100,
      }).start();
    } else {
      animation.selected.setValue(toValue);
    }
  }, [animation, animated, selected]);

  return (
    <Animated.View
      style={[
        tw`relative items-center`,
        {
          transform: [
            {
              scale: animation.pressable.interpolate(animationConfig.outer),
            },
          ],
        },
      ]}
    >
      {(animated || selected) && (
        <Animated.View
          style={[
            tw`absolute inset-0 border-white rounded-full z-0`,
            selected && tw`shadow-lg`,
            {
              borderWidth: animation.selected.interpolate(animationConfig.border),
              margin: animation.selected.interpolate(animationConfig.borderOffset),
              borderColor: animation.selected.interpolate(animationConfig.borderColor),
            },
            { elevation: 0 },
          ]}
        />
      )}
      <View
        style={[tw`rounded-full overflow-hidden`, tw`w-24 h-24 tablet:w-36 tablet:h-36`, style]}
      >
        <AnimatedImage
          source={avatars[name]}
          width={150}
          height={150}
          resizeMode="contain"
          style={[
            tw`absolute -inset-1/8 w-10/8 h-10/8`,
            {
              transform: [
                {
                  scale: animation.pressable.interpolate(animationConfig.imageScale),
                },
                {
                  rotate: animation.pressable.interpolate(animationConfig.imageRotation),
                },
              ],
            },
            { elevation: 0 },
          ]}
        />
      </View>
      {selected && (
        <View
          style={[
            tw`absolute bg-blue bottom-0 rounded-full items-center justify-center`,
            tw`-mb-4 pt-0.5 w-6 h-6`,
            tw`tablet:-mb-6 tablet:pt-1 tablet:w-12 tablet:h-12`,
            // https://github.com/facebook/react-native/issues/32196
            { elevation: 0 },
          ]}
        >
          <Icon name="heart" color={tw.color('white')} style={tw`text-sm tablet:text-2xl`} />
        </View>
      )}
      {children}
    </Animated.View>
  );
};

export const Avatar: FunctionComponent<AvatarProps> = ({ onPress, ...props }) => {
  if (onPress) {
    return (
      <Pressable onPress={onPress}>
        {({ pressed, hovered }) => (
          <Inner animated pressed={pressed} hovered={hovered} {...props} />
        )}
      </Pressable>
    );
  }

  return <Inner {...props} />;
};
