import React, { FunctionComponent, useEffect, useState } from 'react';
import { Animated } from 'react-native';
import { Inline } from '~/shared/ui/spacing';

interface EnteringMenuWrapperProps {
  mergeProgress: Animated.Value | Animated.AnimatedInterpolation<number>;
  navHeight: number;
  children?: React.ReactNode;
}

const EnteringMenuWrapper: FunctionComponent<EnteringMenuWrapperProps> = ({
  mergeProgress,
  navHeight,
  children,
}) => {
  const [visible, setVisible] = useState<boolean>(false);
  const mergeOut = mergeProgress.interpolate({
    inputRange: [0, 1],
    outputRange: [1, 0],
  });

  useEffect(() => {
    const listenerId = mergeProgress.addListener(({ value }) => {
      setVisible(value > 0);
    });

    return () => mergeProgress.removeListener(listenerId);
  }, [mergeProgress]);

  return (
    <Animated.View
      style={{
        transform: [
          {
            translateY: Animated.multiply(mergeOut, -navHeight),
          },
        ],
        display: visible ? 'flex' : 'none',
      }}
    >
      <Inline>{children}</Inline>
    </Animated.View>
  );
};

export default EnteringMenuWrapper;
