import React, { FunctionComponent, ReactElement } from 'react';
import { useProximus } from '~/entities/proximus';
import { AspectRatioContainerProps } from '~/shared/ui/aspect-ratio-container';
import { Icon } from '~/shared/ui/icon';
import { MediaTile, MediaTileProps } from '~/shared/ui/media-tile/media-tile.component';
import { PressableBounce } from '~/shared/ui/pressables';
import {
  getDefaultMediaTileWidth,
  getMediaHasPlayButton,
  getMediaMeta,
  getMediaThumbnail,
  getMediaTileSubTitle,
  getMediaTileTitle,
} from '~/shared/utils/media';
import { IItemTileType, IMedia, ItemTileType, MediaType } from '~/types';
import { MediaTypeIcon } from './media-type-icon.component';
import { DimensionValue } from 'react-native';

interface MediaSwimlaneItemProps {
  item: IMedia;
  tileProps?: Partial<MediaTileProps>;
  onPress?(): void;
  tileType?: IItemTileType | null;
  icon?: ReactElement<typeof Icon>;
  width?: DimensionValue | number;
}

const MediaSwimlaneItemBase: FunctionComponent<MediaSwimlaneItemProps> = ({
  item,
  icon,
  tileType = ItemTileType.Landscape,
  tileProps,
  onPress,
  width = getDefaultMediaTileWidth(item),
}) => {
  const isProximusSessionValid = useProximus(
    (state) => state.tokenInfo && state.validation?.isValid,
  );

  const book = item.__typename === MediaType.Book;
  const song = item.__typename === MediaType.Song;
  const video = item.__typename === MediaType.Video;
  const contentType =
    (song && item.songContentType) || (video && item.videoContentType) || undefined;
  const hasItemCount =
    item.__typename === MediaType.Playlist || item.__typename === MediaType.Serie;
  return (
    <>
      <PressableBounce
        style={{
          width,
        }}
        onPress={onPress}
        hoveredRotation="3deg"
      >
        <MediaTile
          book={book}
          aspectRatio={tileType?.toLowerCase() as AspectRatioContainerProps['aspectRatio']}
          highlight={item.isFeatured}
          locked={isProximusSessionValid ? false : item.isLocked}
          playButton={getMediaHasPlayButton(item)}
          data={{
            title: getMediaTileTitle(item),
            subtitle: getMediaTileSubTitle(item),
            meta: getMediaMeta(item),
            icon: icon || <MediaTypeIcon type={item.__typename} contentType={contentType} />,
            image: getMediaThumbnail(item, tileType || undefined)?.source as string,
            itemCount: hasItemCount ? item.itemCount : undefined,
          }}
          {...tileProps}
        />
      </PressableBounce>
    </>
  );
};

export const MediaSwimlaneItem = React.memo(MediaSwimlaneItemBase);
