import React, { ComponentProps, ReactElement } from 'react';
import { PressableProps, StyleProp, ViewStyle, TextStyle, Text } from 'react-native';
import tw, { config } from '~/shared/theme';
import { PressableBounce } from '../pressables';
import { GapSpacer, Inline } from '../spacing';

type Variants = keyof typeof config.custom.buttonVariants;
export interface ButtonProps<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ContentWrapper extends React.ComponentType<any>,
  ContentWrapperProps = ComponentProps<ContentWrapper>,
> extends PressableProps {
  children?: React.ReactNode;
  decorationLeft?: ReactElement;
  decorationRight?: ReactElement;
  ContentWrapper?: ContentWrapper;
  contentWrapperProps?: ContentWrapperProps;
  variant?: Variants;
  size?: keyof typeof config.custom.buttonSizes;
  style?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
}

export function isValidButtonVariant(
  variant?: Variants | string | number | symbol | null,
): variant is Variants {
  return !!variant && variant in config.custom.buttonVariants;
}

interface SizeVariantStyle {
  container: string;
  text: string;
  decoration: string;
}
interface VariantStyle {
  container: { default: string; pressed?: string; hovered?: string; disabled?: string };
  text: { default: string; pressed?: string; hovered?: string; disabled?: string };
  decoration: { default: string; pressed?: string; hovered?: string; disabled?: string };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function Button<ContentWrapper extends React.ComponentType<any>>({
  disabled = false,
  children,
  style,
  textStyle,
  variant = 'primary',
  size = 'md',
  decorationRight,
  decorationLeft,
  ContentWrapper: ContentWrapperOverride,
  contentWrapperProps,
  ...props
}: ButtonProps<ContentWrapper>) {
  const ContentWrapper = ContentWrapperOverride || Inline;
  const { container, text, decoration }: VariantStyle = config.custom.buttonVariants[variant];
  const {
    container: containerSize,
    text: textSize,
    decoration: decorationSize,
  }: SizeVariantStyle = config.custom.buttonSizes[size];

  return (
    <PressableBounce
      hoveredRotation="0deg"
      pressedRotation="0deg"
      disabled={disabled || !props.onPress}
      {...props}
    >
      {({ hovered, pressed }) => {
        const decorationStyle = [
          tw.style(decoration.default, decorationSize),
          decoration.hovered ? tw.style(hovered && decoration.hovered) : undefined,
          decoration.pressed ? tw.style(pressed && decoration.pressed) : undefined,
          textStyle,
        ];
        return (
          <ContentWrapper
            {...(contentWrapperProps || {})}
            style={[
              tw`justify-center`,
              { userSelect: 'none' },
              tw.style(container.default, containerSize),
              disabled &&
                (container.disabled
                  ? tw.style(container.disabled)
                  : tw`bg-gray-200 opacity-20 web:cursor-not-allowed`),
              container.hovered ? tw.style(hovered && container.hovered) : undefined,
              container.pressed ? tw.style(pressed && container.pressed) : undefined,
              style,
            ]}
          >
            <GapSpacer spacing="sm" direction="row">
              {decorationLeft &&
                React.cloneElement(decorationLeft, {
                  style: [decorationStyle, decorationLeft.props.style],
                })}
              <Text
                style={[
                  tw.style(text.default, textSize),
                  text.hovered ? tw.style(hovered && text.hovered) : undefined,
                  text.pressed ? tw.style(pressed && text.pressed) : undefined,
                  textStyle,
                ]}
              >
                {children}
              </Text>
              {decorationRight &&
                React.cloneElement(decorationRight, {
                  style: [decorationStyle, decorationRight.props.style],
                })}
            </GapSpacer>
          </ContentWrapper>
        );
      }}
    </PressableBounce>
  );
}
