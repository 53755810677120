import React from 'react';
import { defaultScreenOptions, SettingsRoutes, SettingsStack } from './index';
import { SettingsPage } from '~/pages/settings/settings.page';
import { ProfileOverviewPage } from '~/pages/settings/profile-overview.page';
import { ProfileCreatePage } from '~/pages/settings/profile-create.page';
import { ProfileEditPage } from '~/pages/settings/profile-edit.page';
import { useForceUnboarding } from '~/features/guards/force-onboarding';

const SettingsNavigator: React.FC = () => {
  const allowed = useForceUnboarding();

  if (!allowed) return null;

  return (
    <SettingsStack.Navigator screenOptions={defaultScreenOptions}>
      <SettingsStack.Screen name={SettingsRoutes.Main} component={SettingsPage} />
      <SettingsStack.Screen name={SettingsRoutes.ProfileCreate} component={ProfileCreatePage} />
      <SettingsStack.Screen name={SettingsRoutes.ProfileOverview} component={ProfileOverviewPage} />
      <SettingsStack.Screen name={SettingsRoutes.ProfileEdit} component={ProfileEditPage} />
    </SettingsStack.Navigator>
  );
};

export default SettingsNavigator;
