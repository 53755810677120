import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import { TailwindFn } from 'twrnc';
import tw, { config } from '~/shared/theme';
import { Icon, IconProps } from '../icon';
import { Coin } from './coin.component';

interface Item {
  icon: IconProps['name'];
  coinVariant: keyof typeof config.custom.coinVariants;
  align: ReturnType<TailwindFn>;
}
export interface MultiCoinProps {}

const items: Item[] = [
  {
    icon: 'play',
    coinVariant: 'red',
    align: tw`items-end justify-end`,
  },
  {
    icon: 'music',
    coinVariant: 'orange',
    align: tw`items-start justify-end`,
  },
  {
    icon: 'game',
    coinVariant: 'mint',
    align: tw`items-end justify-start`,
  },
  {
    icon: 'book',
    coinVariant: 'blue',
    align: tw`items-start justify-start`,
  },
];

export const MultiCoin: FunctionComponent<MultiCoinProps> = () => {
  return (
    <Coin coinVariant="transparent">
      <View style={tw`absolute top-0 left-0 w-full h-full flex-row flex-wrap`}>
        {items.map((item: Item) => {
          const { container: containerStyle, icon: iconStyle } =
            config.custom.coinVariants[item.coinVariant];

          return (
            <View
              key={item.icon}
              style={[tw`w-1/2 h-1/2 p-0.5 tablet:p-1`, tw.style(containerStyle), item.align]}
            >
              <Icon name={item.icon} style={[tw`text-3xs tablet:text-2xs`, tw.style(iconStyle)]} />
            </View>
          );
        })}
      </View>
    </Coin>
  );
};
