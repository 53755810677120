import React, { FunctionComponent, useEffect } from 'react';
import { StackScreenProps } from '@react-navigation/stack';
import { EmailSignup } from '~/features/onboarding/email-signup';
import {
  MediaRoutes,
  OnboardingRoutes,
  OnboardingStackParamList,
  RootRoutes,
  RootStackParamList,
} from '~/navigation';
import { CenteredLayout } from '~/widgets/centered-layout';
import { LanguageSelector } from '~/features/onboarding/language-selector';
import { NewsletterSelection } from '~/features/onboarding/newsletter-selection';
import { Button } from '~/shared/ui/button';
import { useTranslation } from 'react-i18next';
import { AccountSelection } from '~/features/onboarding/account-selection';
import tw from '~/shared/theme';
import { Image, ImageSourcePropType } from '~/shared/ui/image';
import { Icon } from '~/shared/ui/icon';
import { PageDecoration } from '~/shared/ui/page-decoration';
import { OnboardingStep, useViewer } from '~/entities/viewer';
import { Text, View } from 'react-native';
import { useProximus } from '~/entities/proximus';
import { CompositeScreenProps } from '@react-navigation/native';

const goPassBackground: Record<string, ImageSourcePropType> = {
  nl: require('~/assets/images/onboarding-go-pass-bg-nl.png'),
  fr: require('~/assets/images/onboarding-go-pass-bg-fr.png'),
};

export const OnboardingPage: FunctionComponent<
  CompositeScreenProps<
    StackScreenProps<OnboardingStackParamList, OnboardingRoutes.Main>,
    StackScreenProps<RootStackParamList, RootRoutes.Onboarding>
  >
> = ({ navigation }) => {
  const { openLogin } = useProximus();
  const { onboardingStep: activeStep, setOnboardingStep } = useViewer();
  const {
    i18n: { language },
    t,
  } = useTranslation();

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (activeStep === OnboardingStep.Profile) {
        navigation.navigate(RootRoutes.Onboarding, { screen: OnboardingRoutes.Profile });
      } else if (activeStep === OnboardingStep.Finished) {
        navigation.navigate(RootRoutes.Media, { screen: MediaRoutes.Overview, params: {} });
      }
    }, 100);

    return () => clearTimeout(timeout);
  }, [activeStep, navigation]);

  if (activeStep === OnboardingStep.Email) {
    return (
      <CenteredLayout
        avoidKeyboard
        decorationTopRight={
          <View style={tw`px-4`}>
            <LanguageSelector />
          </View>
        }
        decorationAfter={
          <>
            {/* the eye must always watch the logo */}
            <PageDecoration name="eye" style={tw`left-16 top-2/3 tablet:left-32 tablet:top-2/5`} />
          </>
        }
      >
        <View style={tw`max-w-lg tablet:max-w-2xl`}>
          <EmailSignup
            onContinue={() => {
              setOnboardingStep(OnboardingStep.Newsletter);
            }}
          />
          <PageDecoration name="book" style={tw`left-1/6 -top-4`} />
          <PageDecoration name="play" style={tw`right-24 -top-12`} />
          <PageDecoration name="game" style={tw`top-8 -right-32`} />
          <PageDecoration name="music" style={tw`right-1/6 -bottom-8`} />
          <PageDecoration name="blocks" style={tw`-bottom-32 left-18`} />
        </View>
      </CenteredLayout>
    );
  }

  if (activeStep === OnboardingStep.Newsletter) {
    return (
      <CenteredLayout
        decorationTopRight={
          <View style={tw`px-4 py-2`}>
            <Button
              variant="background"
              decorationRight={<Icon name="arrow-right" />}
              onPress={() => setOnboardingStep(OnboardingStep.AccountType)}
            >
              {t('skipThisStep')}
            </Button>
          </View>
        }
      >
        <View style={tw`px-5`}>
          <PageDecoration name="game" style={tw`-right-8 top-12`} />
          <PageDecoration name="play" style={tw`bottom-8 left-0`} />
          <NewsletterSelection
            onContinue={() => {
              setOnboardingStep(OnboardingStep.AccountType);
            }}
          />
        </View>
      </CenteredLayout>
    );
  }

  return (
    <CenteredLayout
      decorationBackground={
        <Image
          source={goPassBackground[language] || goPassBackground.nl}
          style={tw`absolute inset-0 w-full h-full bg-yellow`}
          resizeMode="cover"
        />
      }
      decorationAfter={
        <View
          style={[
            tw`absolute top-1/2 left-1/2 rounded-full bg-blue items-center justify-center`,
            tw`-mx-8 w-16 h-16`,
            tw`tablet:-mx-12 tablet:w-24 tablet:h-24`,
          ]}
          pointerEvents="none"
        >
          <Text
            style={tw`text-white font-sans-black text-2xl tablet:text-3xl text-center uppercase`}
          >
            {t('onboardingOr')}
          </Text>
        </View>
      }
    >
      <View style={tw`px-5`}>
        <PageDecoration name="game" style={tw`-right-8 top-12`} />
        <PageDecoration name="play" style={tw`top-1/2 -left-8`} />
        <AccountSelection
          onContinue={({ accountType }) => {
            setOnboardingStep(OnboardingStep.Profile);

            if (accountType === 'PROXIMUS') {
              openLogin({
                screen: OnboardingRoutes.Profile,
                params: {},
              });
            } else {
              navigation.navigate(RootRoutes.Onboarding, { screen: OnboardingRoutes.Profile });
            }
          }}
        />
      </View>
    </CenteredLayout>
  );
};
