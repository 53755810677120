import React, { FunctionComponent, useCallback, useEffect, useRef } from 'react';
import tw from '~/shared/theme';
import { useCanAutoplay } from '~/shared/utils/use-can-autoplay';
import * as THEOplayer from 'theoplayer';
import 'theoplayer/ui.css';
import './theoplayer-extensions/theoplayer.css';

import { VideoPlayerProps } from './types';
import { IPlayerOverlay } from '~/types';
import { AgeClassification, DangerousContentClassification } from './ui/classification.component';
import {
  CLASSIFICATION_DISPLAY_DELAY,
  CLASSIFICATION_DISPLAY_DURATION,
} from './video-player.constants';

const LICENSE_KEY = process.env.REACT_APP_THEOPLAYER_WEB_LICENSE;
let googleCastId = 'CC1AD845';
try {
  googleCastId = window.chrome.cast.media.DEFAULT_MEDIA_RECEIVER_APP_ID;
} catch (er) {}

function useEvent<TType extends THEOplayer.StringKeyOf<THEOplayer.PlayerEventMap>>(
  target: THEOplayer.Player | null,
  event: TType | readonly TType[],
  listener: THEOplayer.EventListener<THEOplayer.PlayerEventMap[TType]>,
) {
  useEffect(() => {
    if (!target || !listener) return;
    target.addEventListener(event, listener);
    () => {
      target.removeEventListener(event, listener);
    };
  }, [target, event, listener]);
}

export const VideoPlayer: FunctionComponent<VideoPlayerProps> = ({
  source,
  coverSource,
  onPlay = () => {
    return;
  },
  onEnded = () => {
    return;
  },
  onError = () => {
    return;
  },
  autoplay = true,
  overlays,
  ageClassification,
  contentClassification,
}) => {
  const containerElRef = useRef<HTMLDivElement>(null);
  const playerElRef = useRef<HTMLDivElement>(null);
  const playerRef = useRef<THEOplayer.Player | null>(null);
  const overlayRef = useRef<HTMLDivElement>(null);
  const classificationRef = useRef<HTMLDivElement>(null);
  const currentOverlayRef = useRef<IPlayerOverlay | undefined>(undefined);
  const canAutoplay = useCanAutoplay();

  const setSize = () => {
    if (!containerElRef.current) return;

    containerElRef.current.style.width = `${containerElRef.current.offsetHeight * (16 / 9)}px`;
  };

  /**
   * Updates overlays through DOM manipulation. Do not set state to prevent heavy rerenders
   */
  const updateClassifications: THEOplayer.EventListener<THEOplayer.TimeUpdateEvent> = useCallback(
    (event) => {
      if (!classificationRef.current) {
        return;
      }
      /**
       * @var {currentTime} Time in seconds
       */
      const currentTime = event.currentTime;
      if (
        currentTime < CLASSIFICATION_DISPLAY_DELAY ||
        currentTime > CLASSIFICATION_DISPLAY_DELAY + CLASSIFICATION_DISPLAY_DURATION
      ) {
        classificationRef.current.style.setProperty('opacity', '0');
      } else {
        classificationRef.current.style.setProperty('opacity', '1');
      }
    },
    [],
  );
  const updateOverlays: THEOplayer.EventListener<THEOplayer.TimeUpdateEvent> = useCallback(
    (event) => {
      if (!overlayRef.current) {
        return;
      }
      /**
       * @var {currentTime} Time in seconds
       */
      const currentTime = event.currentTime;
      const foundOverlay = overlays?.find(
        ({ visibleDuring }) =>
          visibleDuring.start <= currentTime && visibleDuring.end > currentTime,
      );
      if (currentOverlayRef.current !== foundOverlay) {
        if (foundOverlay) {
          overlayRef.current.innerHTML = foundOverlay?.label.content;
          overlayRef.current.style.setProperty('opacity', '1');
        } else {
          overlayRef.current.style.setProperty('opacity', '0');
        }
      }
      currentOverlayRef.current = foundOverlay;
    },
    [overlays],
  );

  useEffect(() => {
    if (!playerElRef.current) {
      return;
    }

    const player = new THEOplayer.Player(playerElRef.current, {
      libraryLocation: `/vendor/theoplayer`,
      license: LICENSE_KEY,
      ui: {
        fluid: true,
      },
      cast: {
        chromecast: {
          appID: googleCastId,
        },
      },
    });

    playerRef.current = player;
    player.source = {
      sources: [
        {
          src: source.uri,
          type: source.type || 'application/x-mpegurl',
        },
      ],
    };
    player.poster = coverSource.uri;

    onEnded && player.addEventListener('ended', onEnded);
    onPlay && player.addEventListener('play', onPlay);
    onError && player.addEventListener('error', onError);

    if (autoplay && canAutoplay) {
      player.play();
    }

    window.addEventListener('resize', setSize);
    setSize();

    return () => {
      player.stop();
      onEnded && player.removeEventListener('ended', onEnded);
      onPlay && player.removeEventListener('play', onPlay);
      onError && player.removeEventListener('error', onError);
      window.removeEventListener('resize', setSize);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!playerRef.current) return;
    const player = playerRef.current;

    player.poster = coverSource.uri;
    const playerSource = Array.isArray(player.source?.sources)
      ? player.source?.sources[0]
      : player.source?.sources;
    const playerSourceString =
      typeof playerSource === 'string'
        ? playerSource
        : (playerSource as THEOplayer.TypedSource)?.src;
    if (playerSourceString !== source.uri) {
      player.source = {
        sources: [
          {
            src: source.uri,
            type: source.type || 'application/x-mpegurl',
          },
        ],
      };

      if (canAutoplay && autoplay) {
        player.play();
      }
    }

    setSize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [source, coverSource]);

  // useEffect(() => {
  //   setCustomTHEOPlayerConfig({ config: { overlays } });
  // }, [overlays]);

  useEvent(playerRef.current, 'ended', onEnded);
  useEvent(playerRef.current, 'play', onPlay);
  useEvent(playerRef.current, 'error', onError);
  useEvent(playerRef.current, 'timeupdate', updateOverlays);
  useEvent(playerRef.current, 'timeupdate', updateClassifications);

  return (
    <div
      ref={containerElRef}
      className="theoplayer-wrapper"
      style={tw`relative w-full h-full max-w-full items-center justify-center flex m-auto rounded-xl overflow-hidden`}
    >
      <div
        ref={playerElRef}
        className="theoplayer-container video-js theoplayer-skin theo-seekbar-inside-controls vjs-16-9 THEOplayer"
      />
      <div ref={overlayRef} className="studio100-overlay" style={{ opacity: 0 }} />
      <div ref={classificationRef} className="studio100-classifications" style={{ opacity: 0 }}>
        {ageClassification ? <AgeClassification classification={ageClassification} /> : null}
        {contentClassification?.map((classification) => (
          <DangerousContentClassification key={classification} classification={classification} />
        ))}
      </div>
    </div>
  );
};
