import appcenter from './appcenter';
import { ILogger } from './logger';
import { logger as rollbar } from './rollbar';

const safe = (cb: (...args: any) => void) => {
  return (...args: any) => {
    try {
      return cb(...args);
    } catch (e) {
      console.error(e);
    }
  };
};

export const logger: ILogger = {
  log: function (...args: any): void {
    safe(rollbar.log)(...args);
  },
  debug: function (...args: any): void {
    safe(rollbar.debug)(...args);
  },
  info: function (...args: any): void {
    safe(rollbar.info)(...args);
  },
  warning: function (...args: any): void {
    safe(rollbar.warning)(...args);
  },
  error: function (...args: any): void {
    safe(rollbar.error)(...args);
    safe(appcenter.error)(...args);
  },
  critical: function (...args: any): void {
    safe(rollbar.critical)(...args);
  },
  setPerson: function (...args: any): void {
    safe(rollbar.setPerson)(...args);
  },
  clearPerson: function (...args: any): void {
    safe(rollbar.clearPerson)(...args);
  },
  setScreenMeta: function (screen: string, params: any): void {
    safe(rollbar.setScreenMeta)(screen, params);
  },
};
