import { useEffect, useRef, useState } from 'react';
import { NewsletterStatus, OnboardingStep, useViewer } from '~/entities/viewer';
import { v4 as uuidv4 } from 'uuid';
import { useProximus } from '~/entities/proximus';
import { IProfile, useProfiles } from '~/entities/profiles';
import { __migration_V2MediaObject } from '../utils/v2-storage.types';
import { logger } from '~/shared/tracking/logs/rollbar';
import {
  __migration_getBrandId,
  __migration_getMediaType,
  __migration_getProximusExpiresAt,
} from '../utils/v2-conversion';
import { __migration_clearV2State, __migration_getV2State } from '../utils/v2-storage';
import { useCookies } from '~/entities/cookies';

async function migrate() {
  const state = await __migration_getV2State();
  if (!state) {
    return null;
  }

  const downloads: __migration_V2MediaObject[] = [];

  //
  // Proximus
  //
  const proximus = {
    tokenInfo: state.auth?.data?.proximus?.tokens
      ? {
          access_token: state.auth?.data?.proximus?.tokens.access_token,
          refresh_token: state.auth?.data?.proximus?.tokens.refresh_token,
          expires_in: state.auth?.data?.proximus?.tokens.expires_in,
          expires_at: __migration_getProximusExpiresAt(
            state.auth?.data?.proximus?.tokens.updatedAt,
            state.auth?.data?.proximus?.tokens.expires_in,
          ),
          token_type: state.auth?.data?.proximus?.tokens.token_type,
          scope: state.auth?.data?.proximus?.tokens.scope,
        }
      : undefined,
  };

  //
  // Settings
  //
  const language = state?.settings?.data?.language === 'fr' ? 'fr' : 'nl';

  //
  // Profiles
  //
  const profiles =
    state.profiles
      ?.map((profile) => {
        if (!profile) return null;
        if (!profile?.avatar) return null;
        if (profile.avatar?.id === 'avatargo') return null;

        profile.downloads?.forEach((download) => {
          if (!download?.id) return;
          const media = state.media?.data?.find((m) => m.id === download.id);
          if (!media) return;

          if (downloads.find((d) => d.id === media.id)) return;
          downloads.push(media);
        });

        return {
          id: uuidv4(),
          language: language,
          name: profile.name,
          age: profile.age,
          avatar: profile.avatar?.id,
          favoriteBrands:
            profile.brands?.map((b) => __migration_getBrandId(language, b)).filter(Boolean) || [],
          favoriteMedia:
            profile.favorites
              ?.map((favorite) => {
                if (!favorite?.id) return null;

                const media = state.media?.data?.find((m) => m.id === favorite.id);
                if (!media) return null;

                const type = __migration_getMediaType(media);
                if (!type) return null;

                return {
                  id: favorite.id,
                  type,
                };
              })
              .filter(Boolean) || [],
          recentlyViewedMedia: [],
        };
      })
      .filter(Boolean) || [];

  //
  // Viewer
  //
  const viewer = {
    email: state?.auth?.data?.user?.email || '',
    deviceId: state?.auth?.data?.device?.id || '',
    newsletterStatus: state?.auth?.data?.newsletter?.subscribed
      ? NewsletterStatus.Subscribed
      : NewsletterStatus.Unsubscribed,
    language: language,
    onboardingStep: profiles.length ? OnboardingStep.Finished : OnboardingStep.Email,
  };

  //
  // App
  //
  const cookies = {
    accepted: state.app?.acceptCookies || false,
  };

  //
  // Store update
  //
  useViewer.setState(viewer);
  useProximus.setState(proximus);
  useCookies.setState(cookies);
  if (profiles.length) {
    useProfiles.setState({
      items: profiles as IProfile[],
      activeProfileId: profiles[0]?.id,
    });
  }

  //
  // Cleanup
  //
  await __migration_clearV2State({ downloads });
}

export function useMigrateV2toV3(hydrated: boolean): boolean {
  const migratingRef = useRef(false);
  const [finished, setFinished] = useState(false);

  useEffect(() => {
    if (!hydrated || migratingRef.current) return;

    migratingRef.current = true;
    migrate()
      .then(() => {
        setFinished(true);
      })
      .catch((e) => {
        logger.error(e, { path: 'Migration::error' });
        setFinished(true);
      });
  }, [hydrated]);

  return finished;
}
