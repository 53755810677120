import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { View, Animated, Pressable, StyleProp, ViewStyle } from 'react-native';
import tw from '~/shared/theme';
import { Icon } from '../icon';
import { AnimatedImage, ImageSourcePropType } from '../image';
import { TextVariant } from '../text-variant';

export interface BrandThumbnailProps {
  variant?: 'tile' | 'coin';
  label?: string;
  source: ImageSourcePropType;
  style?: StyleProp<ViewStyle>;
  onPress?: () => void;
  selected?: boolean;
}

interface InnerBrandProps extends Omit<BrandThumbnailProps, 'onPress'> {
  hovered?: boolean;
  pressed?: boolean;
  animated?: boolean;
}

const animationConfig = {
  outer: {
    inputRange: [-1, 0, 1],
    outputRange: [0.9, 1, 1.05],
  },
  imageScale: {
    inputRange: [-1, 0, 1],
    outputRange: [1, 1, 1.1],
  },
  imageRotation: {
    inputRange: [-1, 0, 1],
    outputRange: ['-5deg', '0deg', '5deg'],
  },
  border: {
    inputRange: [0, 1],
    outputRange: [2, 8],
  },
  borderOffset: {
    inputRange: [0, 1],
    outputRange: [-1, -8],
  },
  borderColor: {
    inputRange: [0, 1],
    outputRange: [tw.color('white'), tw.color('blue')] as string[],
  },
};

const Inner: FunctionComponent<InnerBrandProps> = ({
  source,
  style,
  selected = false,
  hovered = false,
  pressed = false,
  animated = false,
}) => {
  const animation = useMemo(() => {
    return {
      pressable: new Animated.Value(0),
      selected: new Animated.Value(0),
    };
  }, []);

  useEffect(() => {
    if (animated) {
      let toValue = 0;
      if (pressed) {
        toValue = -1;
      } else if (hovered) {
        toValue = 1;
      }
      animation.pressable.stopAnimation();
      Animated.spring(animation.pressable, {
        toValue,
        useNativeDriver: true,
        friction: 4,
        tension: 120,
      }).start();
    }
  }, [animation, animated, hovered, pressed]);
  useEffect(() => {
    const toValue = selected ? 1 : 0;
    if (animated) {
      animation.selected.stopAnimation();
      Animated.timing(animation.selected, {
        toValue,
        useNativeDriver: false,
        duration: 100,
      }).start();
    } else {
      animation.selected.setValue(toValue);
    }
  }, [animation, animated, selected]);

  return (
    <Animated.View
      style={[
        tw`relative self-center`,
        {
          transform: [
            {
              scale: animation.pressable.interpolate(animationConfig.outer),
            },
          ],
        },
      ]}
    >
      {(animated || selected) && (
        <Animated.View
          style={[
            tw`absolute inset-0 border-white rounded-full`,
            selected && tw`shadow-lg`,
            { elevation: 1 },
            {
              borderWidth: animation.selected.interpolate(animationConfig.border),
              margin: animation.selected.interpolate(animationConfig.borderOffset),
              borderColor: animation.selected.interpolate(animationConfig.borderColor),
            },
          ]}
        />
      )}
      <View style={[tw`rounded-full w-24 h-24 tablet:w-36 tablet:h-36`, style]}>
        {/*
        Size calculation:
        wxh: 344 x 361
        l: 40
        r: 34

        t: 51
        b: 40

        inner width: 344 - 40 - 34 = 270
        inner height: 361 - 51 - 40 = 270

        offset left: -(40 / 270) = -0.1481481481
        offset top: -(51 / 270) = -0.1888888889

        outer width: 344 / 270 = 1.2740740741
        outer height: 361 / 270 = 1.337037037
        */}
        <AnimatedImage
          source={source}
          width={344}
          height={361}
          resizeMode="contain"
          style={[
            tw`absolute`,
            { elevation: 2 },
            {
              left: '-14.81%',
              top: '-18.88%',
              width: '127.40%',
              height: '133.70%',
            },
            {
              transform: [
                {
                  scale: animation.pressable.interpolate(animationConfig.imageScale),
                },
                {
                  rotate: animation.pressable.interpolate(animationConfig.imageRotation),
                },
              ],
            },
          ]}
        />
      </View>
      {selected && (
        <View
          style={[
            // tw`absolute bottom-0 left-1/2 -m-6 pt-1 w-12 h-12 bg-blue rounded-full items-center justify-center`,
            tw`absolute bottom-0 left-1/2 bg-blue rounded-full items-center justify-center z-10`,
            tw`-m-3 -mb-4 pt-0.5 w-6 h-6`,
            tw`tablet:-m-6 tablet:pt-1 tablet:w-12 tablet:h-12`,
            { elevation: 3 },
          ]}
        >
          <Icon name="heart" color={tw.color('white')} style={tw`text-sm tablet:text-2xl`} />
        </View>
      )}
    </Animated.View>
  );
};

const VariantWrapper: FunctionComponent<
  Pick<BrandThumbnailProps, 'variant' | 'label'> & { children?: React.ReactNode }
> = ({ children, variant = 'coin', label }) => {
  if (variant === 'coin') {
    return <>{children}</>;
  }

  return (
    <View style={tw`bg-green-400 bg-opacity-80 p-5 pt-9 rounded-xl`}>
      {children}
      {label && (
        <TextVariant variant="h3" style={tw`mt-5 text-center`}>
          {label}
        </TextVariant>
      )}
    </View>
  );
};

export const BrandThumbnail: FunctionComponent<BrandThumbnailProps> = ({ onPress, ...props }) => {
  if (onPress) {
    return (
      <Pressable onPress={onPress}>
        {({ pressed, hovered }) => (
          <VariantWrapper {...props}>
            <Inner animated pressed={pressed} hovered={hovered} {...props} />
          </VariantWrapper>
        )}
      </Pressable>
    );
  }

  return (
    <VariantWrapper {...props}>
      <Inner {...props} />
    </VariantWrapper>
  );
};
