import { useApolloClient } from '@apollo/client';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Dimensions, View } from 'react-native';
import { GetPaginatedFeedItemsOperation } from '~/features/media/list-paginated-feed/api/queries.main.generated';
import { MediaSwimlaneItem } from '~/features/media/swimlane-item';
import tw from '~/shared/theme';
import { AspectRatioContainer } from '~/shared/ui/aspect-ratio-container';
import { BrandThumbnail } from '~/shared/ui/brand-thumbnail';
import { Spinner } from '~/shared/ui/preloader';
import { getGapSpacingValue } from '~/shared/ui/spacing';
import { Swimlane } from '~/shared/ui/swimlane';
import { IFeedContent, MediaType } from '~/types';
import { FeedProps } from './feed';

const getItemWidth = (
  item: IFeedContent,
  laneWidth: number,
  gapWidth: number,
  peek: number,
): number => {
  if (item.__typename === 'Brand') return 220;

  const book = item.__typename === MediaType.Book;
  const count = book ? 6 : 4;
  const maxWidth = book ? 280 : 380;
  const minWidth = book ? 130 : 210;
  const laneContentWidth = laneWidth - 40 - peek - gapWidth * count;

  const targetWidth = Math.ceil(laneContentWidth / count);

  return Math.max(minWidth, Math.min(maxWidth, targetWidth));
};

export const FeedLayoutSwimlane: FunctionComponent<FeedProps> = ({ feed, onItemPress, insets }) => {
  // NOTE: overwriting with width from onLayout event to take scrollbars into account on desktop
  const [laneWidth, setLaneWidth] = useState<number>(() => Dimensions.get('window').width);
  const gapWidth = getGapSpacingValue();
  const itemWidth =
    feed.result && feed.result?.items.length > 0
      ? getItemWidth(feed.result.items[0], laneWidth, gapWidth, insets.left + insets.right + 12)
      : 280;

  const client = useApolloClient();
  const [fetchMoreToken, setFetchMoreToken] = useState(feed.result?.fetchMoreToken || undefined);
  const [fetchedItems, setFetchedItems] = useState<IFeedContent[]>([]);
  const hasFetchMoreToken = !!fetchMoreToken;
  const fetchingRef = useRef<'waiting' | string | undefined>('waiting');
  useEffect(() => {
    // make sure things are properly rendered before attempting `fetchMore`s
    const timeout = setTimeout(() => {
      if (fetchingRef.current === 'waiting') {
        fetchingRef.current = undefined;
      }
    }, 500);
    return () => clearTimeout(timeout);
  }, []);

  const handleEndReached = useCallback(() => {
    if (fetchingRef.current || !fetchMoreToken) {
      return;
    }
    fetchingRef.current = fetchMoreToken;
    client
      .query({
        query: GetPaginatedFeedItemsOperation,
        variables: {
          fetchMoreToken,
        },
      })
      .then(({ data }) => {
        // setNetworkStatus(NetworkStatus.ready);
        // networkStatusRef.current = NetworkStatus.ready;
        if (!data?.feedItems || !data?.feedItems?.items?.length) {
          setFetchMoreToken(undefined);
          return;
        }

        setFetchMoreToken(data.feedItems.fetchMoreToken || undefined);
        setFetchedItems((previousItems) => [...previousItems, ...data.feedItems.items]);
      })
      .catch(() => {
        // setNetworkStatus(NetworkStatus.error);
        // networkStatusRef.current = NetworkStatus.error;
        setFetchMoreToken(undefined);
        fetchingRef.current = undefined;
      })
      .finally(() => {
        fetchingRef.current = undefined;
      });
  }, [client, fetchMoreToken]);

  const data = feed.result?.items ? [...feed.result.items, ...fetchedItems] : [];

  const ListFooterComponent = useMemo(
    () =>
      hasFetchMoreToken
        ? () => (
            <View style={[tw`h-10`, { marginLeft: gapWidth }]}>
              <AspectRatioContainer style={[{ width: itemWidth, aspectRatio: 0.5 }]}>
                <Spinner />
              </AspectRatioContainer>
            </View>
          )
        : null,
    [hasFetchMoreToken, gapWidth, itemWidth],
  );

  return (
    <Swimlane
      style={[tw`-my-5 py-5`]}
      insets={insets}
      data={data}
      snapToInterval={itemWidth + gapWidth}
      onEndReached={handleEndReached}
      onEndReachedThreshold={1}
      contentContainerStyle={tw`items-center`}
      ListFooterComponent={ListFooterComponent}
      onLayout={(event) => {
        if (event.nativeEvent.layout.width !== 0) setLaneWidth(event.nativeEvent.layout.width);
      }}
      renderItem={({ item }) =>
        item.__typename === 'Brand' ? (
          <BrandThumbnail
            variant="tile"
            label={item.name}
            source={{ uri: item.images?.medium?.source }}
            onPress={() => onItemPress(item)}
          />
        ) : (
          <MediaSwimlaneItem
            item={item}
            width={itemWidth}
            onPress={() => onItemPress(item)}
            tileType={feed.itemTileType}
          />
        )
      }
    />
  );
};
