import React, { FunctionComponent } from 'react';
import { StackScreenProps } from '@react-navigation/stack';
import { MediaRoutes, MediaStackParamList } from '~/navigation';
import { View } from 'react-native';
import { BaseLayout } from '~/widgets/base-layout';
import { ScrollingMenuBar } from '~/shared/ui/menu';
import { MainMenu } from '~/widgets/menu/main-menu';
import { Fill } from '~/shared/ui/spacing';
import { UserMenu } from '~/widgets/menu/user-menu';
import { useSearchFeeds } from '~/features/media/search/hooks/use-search-feeds';
import { FeedLayout, FeedLayoutSkeleton } from '~/widgets/feed-layout';
import { useTranslation } from 'react-i18next';
import { NetworkStatus } from '~/types';
import { Preloader } from '~/shared/ui/preloader';
import { TextInput } from '~/shared/ui/input';
import tw from '~/shared/theme';
import { Icon } from '~/shared/ui/icon';
import { isFeedListEmpty } from '~/shared/utils/media';
import { useNetInfo } from '@react-native-community/netinfo';
import { MediaOfflinePage } from './media-offline.page';

export const MediaSearchPage: FunctionComponent<
  StackScreenProps<MediaStackParamList, MediaRoutes.Search>
> = (props) => {
  const { route, navigation } = props;
  const { t } = useTranslation();
  const query = route.params?.q;
  const { feeds, networkStatus } = useSearchFeeds({ query });

  const { isConnected } = useNetInfo();

  if (isConnected === false) {
    return <MediaOfflinePage {...props} />;
  }

  return (
    <BaseLayout
      contentContainerStyle={tw`py-32 tablet:py-48`}
      decorationTop={
        <>
          <ScrollingMenuBar>
            <MainMenu />
            <Fill />
            <UserMenu />
          </ScrollingMenuBar>
          <View style={tw`px-8 tablet:px-28 py-5`}>
            <View>
              <TextInput
                decorationLeft={
                  <Icon
                    name="search"
                    style={tw`text-blue opacity-80 text-lg tablet:text-3xl tablet:ml-2`}
                  />
                }
                defaultValue={query}
                placeholder={t('searchTextDefault')}
                onSubmitEditing={(e) => {
                  navigation.replace(MediaRoutes.Search, { q: e.nativeEvent.text });
                }}
                onBlur={(e) => {
                  navigation.replace(MediaRoutes.Search, { q: e.nativeEvent.text });
                }}
              />
            </View>
          </View>
        </>
      }
    >
      {query && (
        <Preloader
          loading={networkStatus < NetworkStatus.ready}
          error={networkStatus === NetworkStatus.error ? t('generalErrorMessage') : undefined}
          skeleton={<FeedLayoutSkeleton repeatCount={3} />}
          empty={isFeedListEmpty(feeds)}
          emptyMessage={t('searchNoResultsErrorMessage')}
        >
          {() => feeds && <FeedLayout feeds={feeds} />}
        </Preloader>
      )}
    </BaseLayout>
  );
};
