import React, { FunctionComponent, useRef, useState, useEffect } from 'react';
import { Animated, Easing } from 'react-native';
import tw from '~/shared/theme';
import { PageDecoration } from '../page-decoration';
import { IDecorationName, DecorationNames } from '~/assets/images/decorations';

const AnimationTypes = ['left', 'right', 'scale'] as const;
type AnimationType = (typeof AnimationTypes)[number];
interface DecorativeLoaderProps {}

function selectRandomDecoration(exclude?: IDecorationName) {
  const filteredDecoration = exclude
    ? DecorationNames.filter((n) => n !== exclude)
    : DecorationNames;
  return filteredDecoration[Math.floor(Math.random() * filteredDecoration.length)];
}
function selectRandomAnimation() {
  return AnimationTypes[Math.floor(Math.random() * AnimationTypes.length)];
}

export const DecorativeLoader: FunctionComponent<DecorativeLoaderProps> = () => {
  const animated = useRef(new Animated.Value(0)).current;
  const [itemIn, setItemIn] = useState<IDecorationName>(selectRandomDecoration);
  const [itemOut, setItemOut] = useState<IDecorationName>(selectRandomDecoration);
  const [type, setType] = useState<AnimationType>(selectRandomAnimation);

  let translateIn = 0;
  let translateOut = 0;
  let scaleIn = 0.8;
  let scaleOut = 0.8;
  let rotateIn = '25deg';
  let rotateOut = '-25deg';
  if (type === 'left') {
    translateIn = 100;
    translateOut = -100;
  } else if (type === 'right') {
    translateIn = -100;
    translateOut = 100;
    [rotateIn, rotateOut] = [rotateOut, rotateIn];
  } else if (type === 'scale') {
    scaleIn = 0;
    scaleOut = 0;
  }

  useEffect(() => {
    animated.setValue(0);
    Animated.timing(animated, {
      duration: 700,
      toValue: 1,
      easing: Easing.inOut(Easing.back(2)),
      useNativeDriver: true,
      delay: 500,
    }).start(({ finished }) => {
      if (finished) {
        setType(selectRandomAnimation());
        setItemOut(itemIn);
        setItemIn(selectRandomDecoration(itemIn));
        animated.setValue(0);
      }
    });
  }, [animated, itemIn]);

  return (
    <>
      <Animated.View
        style={[
          tw`absolute top-1/2 left-1/2`,
          {
            opacity: animated.interpolate({
              inputRange: [0.5, 1],
              outputRange: [1, 0],
              extrapolate: 'clamp',
            }),
            transform: [
              {
                translateX: animated.interpolate({
                  inputRange: [0, 1],
                  outputRange: [0, translateOut],
                }),
              },
              {
                rotate: animated.interpolate({
                  inputRange: [0, 1],
                  outputRange: ['0deg', rotateOut],
                }),
              },
              {
                scale: animated.interpolate({
                  inputRange: [0, 1],
                  outputRange: [1, scaleOut],
                }),
              },
            ],
          },
        ]}
      >
        <PageDecoration name={itemOut} position="center" />
      </Animated.View>
      <Animated.View
        style={[
          tw`absolute top-1/2 left-1/2`,
          {
            opacity: animated.interpolate({
              inputRange: [0, 0.5],
              outputRange: [0, 1],
              extrapolate: 'clamp',
            }),
            transform: [
              {
                translateX: animated.interpolate({
                  inputRange: [0, 1],
                  outputRange: [translateIn, 0],
                }),
              },
              {
                rotate: animated.interpolate({
                  inputRange: [0, 1],
                  outputRange: [rotateIn, '0deg'],
                }),
              },
              {
                scale: animated.interpolate({
                  inputRange: [0, 1],
                  outputRange: [scaleIn, 1],
                }),
              },
            ],
          },
        ]}
      >
        <PageDecoration name={itemIn} position="center" />
      </Animated.View>
    </>
  );
};
