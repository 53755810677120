import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from '@apollo/client';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { sha256 } from 'react-native-sha256';

import introspection from './possible-types.main.generated';
import hash from 'object-hash';
import appInfo from '../app-info';
import apolloRequestLogger from './utils/apollo-request-logger';
import { setContext } from '@apollo/client/link/context';

const clientHeaders: {
  'x-client-hash'?: string | Buffer;
  'x-client-bundle-id'?: string;
  'x-client-code-version'?: string;
  'x-client-marketing-version'?: string;
  authorization?: string;
  'accept-language'?: string;
} = (() => {
  try {
    return {
      'x-client-hash': hash(appInfo),
      'x-client-bundle-id': appInfo.bundleId,
      'x-client-code-version': appInfo.codeVersion || '',
      'x-client-marketing-version': appInfo.marketingVersion || '',
    };
  } catch (e) {
    return {};
  }
})();

const cache = new InMemoryCache({
  possibleTypes: introspection.possibleTypes,
  typePolicies: {
    Feed: {
      keyFields: false,
    },
  },
});

const client = new ApolloClient({
  link: ApolloLink.from([
    ...apolloRequestLogger,
    setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          ...clientHeaders,
        },
      };
    }),
    createPersistedQueryLink({ useGETForHashedQueries: true, sha256 }),
    createHttpLink({
      uri: process.env.REACT_APP_API_ENDPOINT,
    }),
  ]),
  cache,
});

export function setClientHeader(name: keyof typeof clientHeaders, value?: string): void {
  // const previousValue = clientHeaders[name];
  if (value) {
    clientHeaders[name] = value;
  } else {
    delete clientHeaders[name];
  }

  // if (name === 'authorization' && value && value !== previousValue) {
  //   client.stop();
  //   client.resetStore();
  // }
}

export default client;
