import React, { FunctionComponent } from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import tw from '~/shared/theme';
import { AspectRatioContainer } from '~/shared/ui/aspect-ratio-container';
import { Gap, Inline } from '~/shared/ui/spacing';
import { SwimlaneSkeleton } from '~/shared/ui/swimlane';
import { FeedLayoutType, IFeedLayoutType } from '~/types';

interface FeedLayoutSkeletonProps {
  children?: React.ReactNode;
  style?: StyleProp<ViewStyle>;
  feedLayoutType?: IFeedLayoutType;
  repeatCount?: number;
}

interface SkeletonProps {
  children?: React.ReactNode;
  style?: StyleProp<ViewStyle>;
}

const SwimlaneFeedSkeleton: FunctionComponent<SkeletonProps> = ({ children, style }) => {
  return (
    <>
      <View
        style={[tw`h-7 mt-8 w-1/3 overflow-visible justify-end`, !children ? style : tw`w-full`]}
      >
        <Inline style={[tw`h-12 items-center justify-start`]}>{children}</Inline>
      </View>
      <Gap spacing="sm" />
      <SwimlaneSkeleton containerStyle={tw`mb-8`} style={style} />
      <Gap spacing="xl" />
    </>
  );
};
const GridFeedSkeleton: FunctionComponent<SkeletonProps> = ({ children, style }) => {
  return (
    <>
      <View
        style={[tw`h-7 mt-8 w-1/3 overflow-visible justify-end`, !children ? style : tw`w-full`]}
      >
        <Inline style={[tw`h-12 items-center justify-start`]}>{children}</Inline>
      </View>
      <Gap spacing="sm" />
      <Inline spacing="md">
        <View style={[tw`flex-1`]}>
          <AspectRatioContainer aspectRatio="landscape" style={style} />
        </View>
        <View style={[tw`flex-1`]}>
          <AspectRatioContainer aspectRatio="landscape" style={style} />
        </View>
        <View style={[tw`flex-1`]}>
          <AspectRatioContainer aspectRatio="landscape" style={style} />
        </View>
        <View style={[tw`flex-1`]}>
          <AspectRatioContainer aspectRatio="landscape" style={style} />
        </View>
        <View style={[tw`flex-1`]}>
          <AspectRatioContainer aspectRatio="landscape" style={style} />
        </View>
      </Inline>
      <Gap />
      <Inline spacing="md" style={tw`mb-8`}>
        <View style={[tw`flex-1`]}>
          <AspectRatioContainer aspectRatio="landscape" style={style} />
        </View>
        <View style={[tw`flex-1`]}>
          <AspectRatioContainer aspectRatio="landscape" style={style} />
        </View>
        <View style={[tw`flex-1`]}>
          <AspectRatioContainer aspectRatio="landscape" style={style} />
        </View>
        <View style={[tw`flex-1`]}>
          <AspectRatioContainer aspectRatio="landscape" />
        </View>
        <View style={[tw`flex-1`]}>
          <AspectRatioContainer aspectRatio="landscape" />
        </View>
      </Inline>
      <Gap spacing="xl" />
    </>
  );
};

const skeletonMap: Record<IFeedLayoutType, FunctionComponent<SkeletonProps>> = {
  [FeedLayoutType.Grid]: GridFeedSkeleton,
  [FeedLayoutType.Lane]: SwimlaneFeedSkeleton,
};

export const FeedLayoutSkeleton: FunctionComponent<FeedLayoutSkeletonProps> = ({
  children,
  repeatCount = 1,
  feedLayoutType = FeedLayoutType.Lane,
  ...props
}) => {
  const repetitions = new Array(repeatCount).fill(0).map((_, i) => i);
  const Skeleton = skeletonMap[feedLayoutType];
  return (
    <>
      {repetitions.map((i) => (
        <Skeleton key={i} {...props}>
          {i === 0 && children}
        </Skeleton>
      ))}
    </>
  );
};
