import { useEffect, useState } from 'react';
import {
  FeedLayoutType,
  IFeed,
  IMedia,
  IMediaType,
  ItemTileType,
  MediaType,
  TextType,
} from '~/types';

export function useClientSideFeeds({
  media,
  titles,
}: {
  media?: IMedia[];
  titles: { videosAndSongs?: string; videos: string; songs: string; games: string; books: string };
}): IFeed[] {
  const [feeds, setFeeds] = useState<(IFeed & { allowedTypes: IMediaType[] })[]>([
    {
      id: 'videos',
      title: { content: titles.videos, type: TextType.Raw },
      feedLayoutType: FeedLayoutType.Lane,
      icon: {
        name: 'play',
        color: null,
      },
      itemTileType: ItemTileType.Landscape,
      result: { fetchMoreToken: null, items: [] },
      allowedTypes: [MediaType.Video],
    },
    {
      id: 'songs',
      title: { content: titles.songs, type: TextType.Raw },
      feedLayoutType: FeedLayoutType.Lane,
      icon: {
        name: 'music',
        color: null,
      },
      itemTileType: ItemTileType.Landscape,
      result: { fetchMoreToken: null, items: [] },
      allowedTypes: [MediaType.Song],
    },
    {
      id: 'games',
      title: { content: titles.games, type: TextType.Raw },
      feedLayoutType: FeedLayoutType.Lane,
      icon: {
        name: 'game',
        color: null,
      },
      itemTileType: ItemTileType.Square,
      result: { fetchMoreToken: null, items: [] },
      allowedTypes: [MediaType.Game],
    },
    {
      id: 'books',
      title: { content: titles.books, type: TextType.Raw },
      feedLayoutType: FeedLayoutType.Lane,
      icon: {
        name: 'book',
        color: null,
      },
      itemTileType: ItemTileType.PortraitTall,
      result: { fetchMoreToken: null, items: [] },
      allowedTypes: [MediaType.Book],
    },
  ]);

  useEffect(() => {
    if (!media) return;
    setFeeds((currentFeeds) => {
      return currentFeeds.map((feed) => {
        return {
          ...feed,
          result: {
            fetchMoreToken: null,
            items: media.filter((item) => feed.allowedTypes.includes(item.__typename)),
          },
        };
      });
    });
  }, [media]);

  return feeds;
}
