import * as Types from '~/shared/api/models.main.generated';

import { gql } from '@apollo/client';
import { ListMediaItemFragmentDoc } from '../../../../shared/api/fragments/list-media-item.main.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {"context":{"clientName":"main"}} as const;
export type IGQLGetPaginatedFeedItemsQueryVariables = Types.Exact<{
  fetchMoreToken: Types.Scalars['String']['input'];
}>;


export type IGQLGetPaginatedFeedItemsQueryData = (
  { __typename: 'Query' }
  & { feedItems: (
    { __typename: 'FeedResult' }
    & Pick<Types.IGQLFeedResult, 'fetchMoreToken'>
    & { items: Array<(
      { __typename: 'Book' }
      & Pick<Types.IGQLBook, 'id' | 'title' | 'isFeatured' | 'isLocked'>
      & { tile: (
        { __typename: 'TileProperties' }
        & Pick<Types.IGQLTileProperties, 'title' | 'subTitle'>
      ), brand: (
        { __typename: 'Brand' }
        & Pick<Types.IGQLBrand, 'id' | 'slug' | 'name'>
      ), previews: Types.Maybe<(
        { __typename: 'MediaPreviews' }
        & { medium: Types.Maybe<(
          { __typename: 'Asset' }
          & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
        )> }
      )> }
    ) | { __typename: 'Brand' } | (
      { __typename: 'Game' }
      & Pick<Types.IGQLGame, 'id' | 'title' | 'isFeatured' | 'isLocked'>
      & { tile: (
        { __typename: 'TileProperties' }
        & Pick<Types.IGQLTileProperties, 'title' | 'subTitle'>
      ), brand: (
        { __typename: 'Brand' }
        & Pick<Types.IGQLBrand, 'id' | 'slug' | 'name'>
      ), previews: Types.Maybe<(
        { __typename: 'MediaPreviews' }
        & { medium: Types.Maybe<(
          { __typename: 'Asset' }
          & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
        )> }
      )> }
    ) | (
      { __typename: 'Playlist' }
      & Pick<Types.IGQLPlaylist, 'itemCount' | 'id' | 'title' | 'isFeatured' | 'isLocked'>
      & { tile: (
        { __typename: 'TileProperties' }
        & Pick<Types.IGQLTileProperties, 'title' | 'subTitle'>
      ), brand: (
        { __typename: 'Brand' }
        & Pick<Types.IGQLBrand, 'id' | 'slug' | 'name'>
      ), previews: Types.Maybe<(
        { __typename: 'MediaPreviews' }
        & { medium: Types.Maybe<(
          { __typename: 'Asset' }
          & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
        )> }
      )> }
    ) | (
      { __typename: 'Serie' }
      & Pick<Types.IGQLSerie, 'id' | 'title' | 'isFeatured' | 'isLocked'>
      & { posters: Types.Maybe<(
        { __typename: 'MediaPosters' }
        & { medium: Types.Maybe<(
          { __typename: 'Asset' }
          & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
        )>, small: Types.Maybe<(
          { __typename: 'Asset' }
          & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
        )> }
      )>, tile: (
        { __typename: 'TileProperties' }
        & Pick<Types.IGQLTileProperties, 'title' | 'subTitle'>
      ), brand: (
        { __typename: 'Brand' }
        & Pick<Types.IGQLBrand, 'id' | 'slug' | 'name'>
      ), previews: Types.Maybe<(
        { __typename: 'MediaPreviews' }
        & { medium: Types.Maybe<(
          { __typename: 'Asset' }
          & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
        )> }
      )> }
    ) | (
      { __typename: 'Song' }
      & Pick<Types.IGQLSong, 'duration' | 'id' | 'title' | 'isFeatured' | 'isLocked'>
      & { songContentType: Types.IGQLSong['contentType'] }
      & { tile: (
        { __typename: 'TileProperties' }
        & Pick<Types.IGQLTileProperties, 'title' | 'subTitle'>
      ), brand: (
        { __typename: 'Brand' }
        & Pick<Types.IGQLBrand, 'id' | 'slug' | 'name'>
      ), previews: Types.Maybe<(
        { __typename: 'MediaPreviews' }
        & { medium: Types.Maybe<(
          { __typename: 'Asset' }
          & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
        )> }
      )> }
    ) | (
      { __typename: 'Video' }
      & Pick<Types.IGQLVideo, 'duration' | 'seasonId' | 'serieId' | 'id' | 'title' | 'isFeatured' | 'isLocked'>
      & { videoContentType: Types.IGQLVideo['contentType'] }
      & { posters: Types.Maybe<(
        { __typename: 'MediaPosters' }
        & { medium: Types.Maybe<(
          { __typename: 'Asset' }
          & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
        )>, small: Types.Maybe<(
          { __typename: 'Asset' }
          & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
        )> }
      )>, tile: (
        { __typename: 'TileProperties' }
        & Pick<Types.IGQLTileProperties, 'title' | 'subTitle'>
      ), brand: (
        { __typename: 'Brand' }
        & Pick<Types.IGQLBrand, 'id' | 'slug' | 'name'>
      ), previews: Types.Maybe<(
        { __typename: 'MediaPreviews' }
        & { medium: Types.Maybe<(
          { __typename: 'Asset' }
          & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
        )> }
      )> }
    )> }
  ) }
);


export const GetPaginatedFeedItemsOperation = gql`
    query GetPaginatedFeedItems($fetchMoreToken: String!) {
  feedItems(token: $fetchMoreToken) {
    fetchMoreToken
    items {
      ...ListMediaItem
    }
  }
}
    ${ListMediaItemFragmentDoc}`;
export function useGetPaginatedFeedItemsQuery(baseOptions: Apollo.QueryHookOptions<IGQLGetPaginatedFeedItemsQueryData, IGQLGetPaginatedFeedItemsQueryVariables> & ({ variables: IGQLGetPaginatedFeedItemsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGQLGetPaginatedFeedItemsQueryData, IGQLGetPaginatedFeedItemsQueryVariables>(GetPaginatedFeedItemsOperation, options);
      }
export function useGetPaginatedFeedItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGQLGetPaginatedFeedItemsQueryData, IGQLGetPaginatedFeedItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGQLGetPaginatedFeedItemsQueryData, IGQLGetPaginatedFeedItemsQueryVariables>(GetPaginatedFeedItemsOperation, options);
        }
export function useGetPaginatedFeedItemsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGQLGetPaginatedFeedItemsQueryData, IGQLGetPaginatedFeedItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGQLGetPaginatedFeedItemsQueryData, IGQLGetPaginatedFeedItemsQueryVariables>(GetPaginatedFeedItemsOperation, options);
        }
export type GetPaginatedFeedItemsQueryHookResult = ReturnType<typeof useGetPaginatedFeedItemsQuery>;
export type GetPaginatedFeedItemsLazyQueryHookResult = ReturnType<typeof useGetPaginatedFeedItemsLazyQuery>;
export type GetPaginatedFeedItemsSuspenseQueryHookResult = ReturnType<typeof useGetPaginatedFeedItemsSuspenseQuery>;
export type GetPaginatedFeedItemsQueryResult = Apollo.QueryResult<IGQLGetPaginatedFeedItemsQueryData, IGQLGetPaginatedFeedItemsQueryVariables>;