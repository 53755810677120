import React, { FunctionComponent } from 'react';
import { Image, ImageStyle } from '../image';

import decorations, { IDecorationName } from '~/assets/images/decorations';
import tw from '~/shared/theme';
import { StyleProp } from 'react-native';

export const DecorationPositions = {
  default: {},
  left: tw`left-0 top-1/2`,
  right: tw`right-0 top-1/2`,
  top: tw`top-0 left-1/2`,
  bottom: tw`bottom-0 left-1/2`,
  center: tw`top-1/2 left-1/2`,
};

export interface PageDecorationProps {
  name?: IDecorationName;
  position?: keyof typeof DecorationPositions;
  style?: StyleProp<ImageStyle>;
}

export const PageDecoration: FunctionComponent<PageDecorationProps> = ({
  name = 'play',
  position = 'default',
  style,
}) => {
  return (
    <Image
      style={[
        tw`absolute w-16 h-16 -m-8 tablet:w-32 tablet:h-32 tablet:-m-16`,
        DecorationPositions[position],
        style,
      ]}
      source={decorations[name]}
      width={141}
      height={141}
      pointerEvents="none"
    />
  );
};
