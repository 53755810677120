import { ILogger } from './logger';

export const logger: ILogger = {
  log: (...args) => {
    console.log('log', ...args);
  },
  debug: (...args) => {
    console.log('debug', ...args);
  },
  info: (...args) => {
    console.log('info', ...args);
  },
  warning: (...args) => {
    console.log('warning', ...args);
  },
  error: (...args) => {
    console.log('error', ...args);
  },
  critical: (...args) => {
    console.log('critical', ...args);
  },
  setPerson: (...args) => {
    console.log('setPerson', ...args);
  },
  clearPerson: (...args) => {
    console.log('clearPerson', ...args);
  },

  setScreenMeta: () => {
    //
  },
};
