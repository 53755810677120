import { create } from 'zustand';

interface IParentGateState {
  activeRequest?: { cb: CallableFunction };
  allowed: boolean;
}

interface IParentGateProducers {
  requestVerification(cb: CallableFunction): void;
  dismissVerification(): void;
  approveVerification(): void;
}

type IParentGateModel = IParentGateState & IParentGateProducers;

export const useParentGate = create<IParentGateModel>((set) => ({
  activeRequest: undefined,
  allowed: false,

  requestVerification(cb) {
    set({ activeRequest: { cb } });
  },
  approveVerification() {
    set({ activeRequest: undefined, allowed: true });
  },
  dismissVerification() {
    set({ activeRequest: undefined });
  },
}));
