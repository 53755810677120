import React, { FunctionComponent, useState } from 'react';
import {
  Platform,
  TextInput as TextInputRN,
  TextInputProps as TextInputRNProps,
  View,
} from 'react-native';
import tw from '~/shared/theme';
import { GapSpacer } from '../../spacing';
import { TextVariant } from '../../text-variant';

export interface TextInputProps extends TextInputRNProps {
  label?: string;
  error?: boolean | string;
  decorationLeft?: React.ReactElement;
}

export const TextInput: FunctionComponent<TextInputProps> = ({
  style,
  placeholderTextColor,
  error = false,
  label,
  disableFullscreenUI = true,
  decorationLeft,
  ...props
}) => {
  const [focussed, setFocussed] = useState<boolean>(false);
  return (
    <View>
      <GapSpacer spacing="xs">
        {label && <TextVariant variant="h4">{label}</TextVariant>}
        <TextInputRN
          placeholderTextColor={placeholderTextColor || tw.color('purple-700')}
          disableFullscreenUI={disableFullscreenUI}
          style={[
            tw`px-5 py-2 tablet:py-5 bg-gray-100 rounded-full text-gray-900 border-2 border-gray-200`,
            tw`font-sans text-base tablet:text-lg text-black`,
            decorationLeft ? tw`pl-12 tablet:pl-16` : {},
            Platform.OS === 'web' && { outlineStyle: 'none' },
            focussed && tw`border-yellow`,
            !!error && tw`border-red`,
            style,
          ]}
          onFocus={() => setFocussed(true)}
          onBlur={() => setFocussed(false)}
          {...props}
        />
        {error && typeof error === 'string' && <TextVariant variant="error">{error}</TextVariant>}
      </GapSpacer>
      {decorationLeft && (
        <View
          style={tw`absolute bottom-0 p-2 w-11 h-11 tablet:w-16 tablet:h-18 items-center justify-center z-10`}
          pointerEvents="box-none"
        >
          {decorationLeft}
        </View>
      )}
    </View>
  );
};
