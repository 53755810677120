import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import { Slider } from '@miblanchard/react-native-slider';
import { Thumb } from './thumb.component';
import tw from '~/shared/theme';
import { Tick } from './tick.component';

export interface AgeSliderProps {
  name?: string;
  min?: number;
  max?: number;
  value: number;
  onChange: (value: number) => void;
}

export const AgeSlider: FunctionComponent<AgeSliderProps> = ({
  value,
  onChange,
  min = 1,
  max = 15,
}) => {
  const marks = Array(max - min + 1)
    .fill(0)
    .map((_, i) => i + min);
  const formatValue = (tickValue: number) => (tickValue === max ? `${max - 1}+` : `${tickValue}`);
  return (
    <View>
      <Slider
        containerStyle={tw`mb-4 h-16 mx-4 tablet:h-32 tablet:mx-8`}
        step={1}
        minimumValue={min}
        maximumValue={max}
        value={value}
        onValueChange={(newValue) => onChange(Array.isArray(newValue) ? newValue[0] : newValue)}
        renderThumbComponent={() => <Thumb label={formatValue(value)} />}
        trackMarks={marks}
        renderTrackMarkComponent={(index) => {
          return (
            <Tick
              index={index}
              label={formatValue(index + min)}
              showLine={index > 0 && index < marks.length - 1}
            />
          );
        }}
        trackStyle={tw`bg-blue border-2 border-black h-4 rounded-full`}
        minimumTrackTintColor="transparent"
      />
    </View>
  );
};
