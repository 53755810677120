import { ApolloLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { logger } from '~/shared/tracking/logs';

const ProductionErrorLogLink = onError(({ graphQLErrors, operation, forward }) => {
  try {
    graphQLErrors?.forEach((error) => {
      logger.warning(`Apollo error::${operation.operationName}:: ${error.message}`, {
        error,
        operation,
      });
    });
  } catch (e) {}

  return forward(operation);
});

const ErrorLogLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  console.groupCollapsed(`%c ⚠ GraphQL Request: ${operation.operationName}`, 'color: #f33;');
  console.log('Query', operation.query?.loc?.source);
  console.log('Variables', operation.variables);
  console.log('Headers', operation.getContext().headers);
  if (graphQLErrors) {
    graphQLErrors.forEach((error) => {
      console.group(`Error ${error.message}`);
      console.log(error);
      console.groupEnd();
    });
  }
  if (networkError) {
    console.group(`Network error ${networkError.message}`);
    console.log(networkError);
    console.groupEnd();
  }
  console.groupEnd();
  return forward(operation);
});

const ResponseLogLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    console.groupCollapsed(`%c GraphQL Request: ${operation.operationName}`, 'color: #f0f;');
    console.log('Query', operation.query?.loc?.source);
    console.log('Variables', operation.variables);
    console.log('Headers', operation.getContext().headers);
    console.log('Response', response);
    console.log('All', { all: { operation, response } });
    console.groupEnd();
    return response;
  });
});

const links: ApolloLink[] = [];
if (process.env.NODE_ENV !== 'production') {
  links.push(ErrorLogLink, ResponseLogLink);
} else if (process.env.REACT_APP_LOG_APOLLO_ERRORS) {
  links.push(ProductionErrorLogLink);
}

export default links;
