import { useIsFocused } from '@react-navigation/core';
import { useTranslation } from 'react-i18next';
import { IBrand, NetworkStatus } from '~/types';
import { useGetRelatedBrandsQuery } from '../api/queries.main.generated';

export function useRelatedBrands({
  relatedToBrandIds,
  limit = 10,
  shouldIncludeBaseBrands = false,
}: {
  relatedToBrandIds: string[] | undefined;
  limit?: number;
  shouldIncludeBaseBrands?: boolean;
}): {
  brands?: IBrand[];
  networkStatus: NetworkStatus;
} {
  const { i18n } = useTranslation();
  const inFocus = useIsFocused();
  const { data, networkStatus } = useGetRelatedBrandsQuery({
    variables: {
      relatedToBrandIds,
      language: i18n.language,
      limit,
      shouldIncludeBaseBrands: shouldIncludeBaseBrands,
    },
    skip: !inFocus || !relatedToBrandIds,
  });

  return { brands: data?.brands, networkStatus };
}
