import { NavigatorScreenParams } from '@react-navigation/core';
import { createStackNavigator, StackNavigationOptions } from '@react-navigation/stack';
import { Platform } from 'react-native';
import tw from '~/shared/theme';
import { MediaMedium } from '~/types';

import { IProximusTokenInfo } from '~/shared/api/proximus.api';

export const defaultScreenOptions: StackNavigationOptions = {
  headerShown: false,
  animationEnabled: false,
  gestureEnabled: false,
  cardStyle: tw`h-full`,
  detachPreviousScreen: Platform.OS === 'web',
};

export const fadeScreenOptions: StackNavigationOptions = {
  animationEnabled: true,
  cardStyleInterpolator: ({ current }) => ({
    cardStyle: {
      opacity: current.progress,
    },
  }),
};

export enum OnboardingRoutes {
  Main = 'Onboarding:Main',
  Profile = 'Onboarding:Profile',
}

export type OnboardingStackParamList = {
  [OnboardingRoutes.Main]: undefined;
  [OnboardingRoutes.Profile]: undefined;
};

export const OnboardingStack = createStackNavigator<OnboardingStackParamList>();

export enum MediaRoutes {
  Overview = 'Media:Overview',
  SerieOverview = 'Media:SerieOverview',
  MediaDetail = 'Media:Detail',
  Brands = 'Media:Brands',
  Favorites = 'Media:Favorites',
  Downloads = 'Media:Downloads',
  Search = 'Media:Search',
}

export type MediaStackParamList = {
  [MediaRoutes.Overview]: { brandSlug?: string; medium?: MediaMedium };
  [MediaRoutes.SerieOverview]: { id: string };
  [MediaRoutes.MediaDetail]: { id: string };
  [MediaRoutes.Brands]: undefined;
  [MediaRoutes.Favorites]: undefined;
  [MediaRoutes.Downloads]: undefined;
  [MediaRoutes.Search]: { q?: string };
};

export const MediaStack = createStackNavigator<MediaStackParamList>();

export enum SettingsRoutes {
  Main = 'Settings:Main',
  ProfileOverview = 'Settings:Profiles:Overview',
  ProfileCreate = 'Settings:Profiles:Create',
  ProfileEdit = 'Settings:Profiles:Edit',
}

export type SettingsStackParamList = {
  [SettingsRoutes.Main]: undefined;
  [SettingsRoutes.ProfileOverview]: undefined;
  [SettingsRoutes.ProfileCreate]: undefined;
  [SettingsRoutes.ProfileEdit]: { id: string };
};

export const SettingsStack = createStackNavigator<SettingsStackParamList>();

export const enum RootRoutes {
  Onboarding = 'Onboarding',
  Media = 'Media',
  Settings = 'Settings',
  OAuth = 'OAuth',
}
export type RootStackParamList = {
  [RootRoutes.Onboarding]: NavigatorScreenParams<OnboardingStackParamList>;
  [RootRoutes.Media]: NavigatorScreenParams<MediaStackParamList>;
  [RootRoutes.Settings]: NavigatorScreenParams<SettingsStackParamList>;
  [RootRoutes.OAuth]: { status?: string; message?: string } & Partial<IProximusTokenInfo>;
};

export const RootStack = createStackNavigator<RootStackParamList>();
