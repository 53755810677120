import * as Types from '~/shared/api/models.main.generated';

import { gql } from '@apollo/client';
import { ListMediaItemFragmentDoc } from '../../../../shared/api/fragments/list-media-item.main.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {"context":{"clientName":"main"}} as const;
export type IGQLGetFavoriteMediaQueryVariables = Types.Exact<{
  ids: Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input'];
}>;


export type IGQLGetFavoriteMediaQueryData = (
  { __typename: 'Query' }
  & { media: (
    { __typename: 'FeedResult' }
    & Pick<Types.IGQLFeedResult, 'fetchMoreToken'>
    & { items: Array<(
      { __typename: 'Book' }
      & Pick<Types.IGQLBook, 'id' | 'title' | 'isFeatured' | 'isLocked'>
      & { tile: (
        { __typename: 'TileProperties' }
        & Pick<Types.IGQLTileProperties, 'title' | 'subTitle'>
      ), brand: (
        { __typename: 'Brand' }
        & Pick<Types.IGQLBrand, 'id' | 'slug' | 'name'>
      ), previews: Types.Maybe<(
        { __typename: 'MediaPreviews' }
        & { medium: Types.Maybe<(
          { __typename: 'Asset' }
          & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
        )> }
      )> }
    ) | { __typename: 'Brand' } | (
      { __typename: 'Game' }
      & Pick<Types.IGQLGame, 'id' | 'title' | 'isFeatured' | 'isLocked'>
      & { tile: (
        { __typename: 'TileProperties' }
        & Pick<Types.IGQLTileProperties, 'title' | 'subTitle'>
      ), brand: (
        { __typename: 'Brand' }
        & Pick<Types.IGQLBrand, 'id' | 'slug' | 'name'>
      ), previews: Types.Maybe<(
        { __typename: 'MediaPreviews' }
        & { medium: Types.Maybe<(
          { __typename: 'Asset' }
          & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
        )> }
      )> }
    ) | (
      { __typename: 'Playlist' }
      & Pick<Types.IGQLPlaylist, 'itemCount' | 'id' | 'title' | 'isFeatured' | 'isLocked'>
      & { tile: (
        { __typename: 'TileProperties' }
        & Pick<Types.IGQLTileProperties, 'title' | 'subTitle'>
      ), brand: (
        { __typename: 'Brand' }
        & Pick<Types.IGQLBrand, 'id' | 'slug' | 'name'>
      ), previews: Types.Maybe<(
        { __typename: 'MediaPreviews' }
        & { medium: Types.Maybe<(
          { __typename: 'Asset' }
          & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
        )> }
      )> }
    ) | (
      { __typename: 'Serie' }
      & Pick<Types.IGQLSerie, 'id' | 'title' | 'isFeatured' | 'isLocked'>
      & { posters: Types.Maybe<(
        { __typename: 'MediaPosters' }
        & { medium: Types.Maybe<(
          { __typename: 'Asset' }
          & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
        )>, small: Types.Maybe<(
          { __typename: 'Asset' }
          & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
        )> }
      )>, tile: (
        { __typename: 'TileProperties' }
        & Pick<Types.IGQLTileProperties, 'title' | 'subTitle'>
      ), brand: (
        { __typename: 'Brand' }
        & Pick<Types.IGQLBrand, 'id' | 'slug' | 'name'>
      ), previews: Types.Maybe<(
        { __typename: 'MediaPreviews' }
        & { medium: Types.Maybe<(
          { __typename: 'Asset' }
          & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
        )> }
      )> }
    ) | (
      { __typename: 'Song' }
      & Pick<Types.IGQLSong, 'duration' | 'id' | 'title' | 'isFeatured' | 'isLocked'>
      & { songContentType: Types.IGQLSong['contentType'] }
      & { tile: (
        { __typename: 'TileProperties' }
        & Pick<Types.IGQLTileProperties, 'title' | 'subTitle'>
      ), brand: (
        { __typename: 'Brand' }
        & Pick<Types.IGQLBrand, 'id' | 'slug' | 'name'>
      ), previews: Types.Maybe<(
        { __typename: 'MediaPreviews' }
        & { medium: Types.Maybe<(
          { __typename: 'Asset' }
          & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
        )> }
      )> }
    ) | (
      { __typename: 'Video' }
      & Pick<Types.IGQLVideo, 'duration' | 'seasonId' | 'serieId' | 'id' | 'title' | 'isFeatured' | 'isLocked'>
      & { videoContentType: Types.IGQLVideo['contentType'] }
      & { posters: Types.Maybe<(
        { __typename: 'MediaPosters' }
        & { medium: Types.Maybe<(
          { __typename: 'Asset' }
          & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
        )>, small: Types.Maybe<(
          { __typename: 'Asset' }
          & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
        )> }
      )>, tile: (
        { __typename: 'TileProperties' }
        & Pick<Types.IGQLTileProperties, 'title' | 'subTitle'>
      ), brand: (
        { __typename: 'Brand' }
        & Pick<Types.IGQLBrand, 'id' | 'slug' | 'name'>
      ), previews: Types.Maybe<(
        { __typename: 'MediaPreviews' }
        & { medium: Types.Maybe<(
          { __typename: 'Asset' }
          & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
        )> }
      )> }
    )> }
  ) }
);


export const GetFavoriteMediaOperation = gql`
    query GetFavoriteMedia($ids: [ID!]!) {
  media(ids: $ids) {
    fetchMoreToken
    items {
      ...ListMediaItem
    }
  }
}
    ${ListMediaItemFragmentDoc}`;
export function useGetFavoriteMediaQuery(baseOptions: Apollo.QueryHookOptions<IGQLGetFavoriteMediaQueryData, IGQLGetFavoriteMediaQueryVariables> & ({ variables: IGQLGetFavoriteMediaQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGQLGetFavoriteMediaQueryData, IGQLGetFavoriteMediaQueryVariables>(GetFavoriteMediaOperation, options);
      }
export function useGetFavoriteMediaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGQLGetFavoriteMediaQueryData, IGQLGetFavoriteMediaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGQLGetFavoriteMediaQueryData, IGQLGetFavoriteMediaQueryVariables>(GetFavoriteMediaOperation, options);
        }
export function useGetFavoriteMediaSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGQLGetFavoriteMediaQueryData, IGQLGetFavoriteMediaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGQLGetFavoriteMediaQueryData, IGQLGetFavoriteMediaQueryVariables>(GetFavoriteMediaOperation, options);
        }
export type GetFavoriteMediaQueryHookResult = ReturnType<typeof useGetFavoriteMediaQuery>;
export type GetFavoriteMediaLazyQueryHookResult = ReturnType<typeof useGetFavoriteMediaLazyQuery>;
export type GetFavoriteMediaSuspenseQueryHookResult = ReturnType<typeof useGetFavoriteMediaSuspenseQuery>;
export type GetFavoriteMediaQueryResult = Apollo.QueryResult<IGQLGetFavoriteMediaQueryData, IGQLGetFavoriteMediaQueryVariables>;