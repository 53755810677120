import { useNetInfo } from '@react-native-community/netinfo';
import { NavigationProp, useNavigation, useRoute } from '@react-navigation/core';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, Pressable, View } from 'react-native';
import { useParentGate } from '~/entities/parent-gate';
import { useActiveProfile, useProfiles } from '~/entities/profiles';
import { MediaRoutes, RootRoutes, RootStackParamList, SettingsRoutes } from '~/navigation';
import tw from '~/shared/theme';
import { Avatar } from '~/shared/ui/avatar';
import { Icon } from '~/shared/ui/icon';
import { Coin, MenuItem } from '~/shared/ui/menu';
import { Gap, GapSpacer } from '~/shared/ui/spacing';
import { TextVariant } from '~/shared/ui/text-variant';
import { useIsPhone } from '~/shared/utils/use-is-phone';

interface UserMenuProps {}

const profileRoutes = [
  SettingsRoutes.ProfileOverview,
  SettingsRoutes.ProfileCreate,
  SettingsRoutes.ProfileEdit,
];

export const UserMenu: FunctionComponent<UserMenuProps> = () => {
  const navigation = useNavigation<NavigationProp<RootStackParamList>>();
  const { t, i18n } = useTranslation();
  const isPhone = useIsPhone();
  const { requestVerification } = useParentGate();
  const { name: activeRoute } = useRoute();
  const { profiles, setActiveProfile } = useProfiles((state) => ({
    profiles: state.items,
    setActiveProfile: state.setActiveProfile,
  }));
  const profilesForLanguage = profiles.filter((p) => p.language === i18n.language);
  const { activeProfile } = useActiveProfile();

  const { isConnected } = useNetInfo();

  const [showProfiles, setShowProfiles] = useState(false);

  const onOpenSettings = () => {
    requestVerification(() => {
      navigation.navigate(RootRoutes.Settings, { screen: SettingsRoutes.Main });
    });
  };
  const onOpenProfileOverview = () => {
    setShowProfiles(false);
    navigation.navigate(RootRoutes.Settings, { screen: SettingsRoutes.ProfileOverview });
  };

  return (
    <>
      <MenuItem
        label={t('submenuItemSearch')}
        active={activeRoute === MediaRoutes.Search}
        onPress={() => {
          navigation.navigate(RootRoutes.Media, { screen: MediaRoutes.Search, params: {} });
        }}
      >
        <Coin icon="search" />
      </MenuItem>
      <MenuItem
        label={t('submenuItemParents')}
        active={activeRoute === SettingsRoutes.Main}
        onPress={onOpenSettings}
      >
        <Coin icon="lock" />
      </MenuItem>
      <MenuItem
        label={activeProfile?.name || t('menuItemProfile')}
        active={showProfiles || profileRoutes.includes(activeRoute as SettingsRoutes)}
        onPress={() => {
          // android does not allow pressing things that overflow scroll views
          if (isPhone || Platform.OS === 'android') {
            onOpenProfileOverview();
          } else {
            setShowProfiles(!showProfiles);
          }
        }}
        contentAfter={
          showProfiles && (
            <>
              <Gap />
              <GapSpacer>
                {profilesForLanguage.map(
                  (profile, index) =>
                    profile !== activeProfile && (
                      <Pressable
                        key={`${profile.name}-${index}`}
                        style={tw`items-center`}
                        onPress={() => {
                          setShowProfiles(false);
                          setActiveProfile(profile);
                        }}
                      >
                        <Avatar name={profile.avatar} style={tw`w-8 h-8 tablet:w-12 tablet:h-12`} />
                        <Gap spacing="xs" />
                        <TextVariant variant="h5" style={tw`text-center`}>
                          {profile.name}
                        </TextVariant>
                      </Pressable>
                    ),
                )}
                {isConnected && (
                  <Pressable onPress={onOpenProfileOverview} style={tw`items-center z-10`}>
                    <Coin icon="edit" coinVariant="yellow" />
                    <Gap spacing="xs" />
                    <TextVariant variant="h5" style={tw`text-center`}>
                      {t('profilesTitle')}
                    </TextVariant>
                  </Pressable>
                )}
              </GapSpacer>
            </>
          )
        }
      >
        {activeProfile?.avatar ? (
          <Avatar name={activeProfile.avatar} style={tw`w-8 h-8 tablet:w-12 tablet:h-12`} />
        ) : (
          <Coin coinVariant="yellow">
            <View style={tw`absolute -bottom-2 w-4 h-4 tablet:w-6 bg-blue rounded-full`} />
            <Icon name="avatar" style={tw`text-2xl tablet:text-4xl mt-1 tablet:mt-2 text-blue`} />
          </Coin>
        )}
      </MenuItem>
    </>
  );
};
