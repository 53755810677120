import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from 'react-native';
import { useParentGate } from '~/entities/parent-gate';
import tw from '~/shared/theme';
import { Card } from '~/shared/ui/card';
import { TextInput } from '~/shared/ui/input';
import { Modal } from '~/shared/ui/modal';
import { Gap, Inline } from '~/shared/ui/spacing';
import { TextVariant } from '~/shared/ui/text-variant';
import { getParentChallenge } from '../utils/challenge';

interface ParentCheckModalProps {}

export const ParentCheckModal: FunctionComponent<ParentCheckModalProps> = () => {
  const { t } = useTranslation();
  const { activeRequest, dismissVerification, approveVerification } = useParentGate();

  const [challenge, setChallenge] = useState(getParentChallenge());
  const [input, setInput] = useState('');

  useEffect(() => {
    setChallenge(getParentChallenge());
  }, [activeRequest]);

  const valid = input === challenge.verificationResult.toString();
  const invalid = input.length === 2 && !valid;

  useEffect(() => {
    if (valid) {
      const timeout = setTimeout(() => {
        const cb = activeRequest!.cb;
        approveVerification();
        setInput('');
        cb();
      }, 500);

      return () => clearTimeout(timeout);
    }
  }, [valid, approveVerification, activeRequest]);

  return (
    <Modal visible={!!activeRequest} onRequestClose={dismissVerification} avoidKeyboard>
      <Card style={tw`items-center`}>
        <TextVariant variant="modal-title" style={tw`text-center`}>
          {t('parentalGateTitle')}
        </TextVariant>

        <TextVariant variant="body" style={tw`text-center`}>
          {t('parentalGateDescription')}
        </TextVariant>

        <Gap />

        <Inline spacing="sm">
          <Text style={tw`font-sans-bold text-blue text-3xl`}>{challenge.numberOne}</Text>
          <Text style={tw`font-sans-bold text-purple-400 text-2xl`}>{challenge.operator}</Text>
          <Text style={tw`font-sans-bold text-blue text-3xl`}>{challenge.numberTwo}</Text>
          <Text style={tw`font-sans-bold text-purple-400 text-2xl`}>=</Text>

          <TextInput
            style={[
              tw`text-2xl w-30 text-center`,
              invalid && tw`bg-rose-300`,
              valid && tw`bg-green-200`,
            ]}
            placeholder="＿ ＿"
            keyboardType="number-pad"
            editable={!valid}
            value={input}
            maxLength={2}
            textContentType="oneTimeCode"
            autoComplete="off"
            onChangeText={(text) => {
              setInput(text);
            }}
          />
        </Inline>
      </Card>
    </Modal>
  );
};
