import React, { FunctionComponent } from 'react';
import { Text, View } from 'react-native';
import tw from '~/shared/theme';
import { Image } from '../../image';

interface ThumbProps {
  label?: string | number;
}

export const Thumb: FunctionComponent<ThumbProps> = ({ label: value }) => {
  return (
    <View
      style={tw`relative w-18 h-18 -m-8 tablet:w-36 tablet:h-36 tablet:-m-16 items-center justify-center`}
    >
      <Image
        style={[
          tw`absolute top-1/2 left-1/2`,
          tw`w-[68px] h-[65px] -ml-[33px] -mt-[40px]`,
          tw`tablet:w-[135px] tablet:h-[130px] tablet:-ml-[65px] tablet:-mt-[80px]`,
        ]}
        source={require('./assets/crown.png')}
        width={135}
        height={130}
        resizeMode="contain"
      />
      {value !== undefined && value !== '' && (
        <Text style={tw`font-sans-black relative text-blue text-xl tablet:text-4xl`}>{value}</Text>
      )}
    </View>
  );
};
