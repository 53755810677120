import { useState } from 'react';
import { useViewer } from '~/entities/viewer';
import { NetworkStatus } from '~/types';

export function useRequestAccountSettings(): {
  email: string;
  networkStatus: NetworkStatus;
  handleSubmit: () => Promise<void>;
  error: string | null;
} {
  const { email, requestAccountSettings } = useViewer((state) => ({
    email: state.email,
    requestAccountSettings: state.requestAccountSettings,
  }));
  const [networkStatus, setNetworkStatus] = useState(NetworkStatus.default);
  const [error, setError] = useState<string | null>(null);

  return {
    email,
    networkStatus,
    handleSubmit: async () => {
      if (networkStatus === NetworkStatus.loading) return;

      setError(null);
      setNetworkStatus(NetworkStatus.loading);
      try {
        await requestAccountSettings();
        setNetworkStatus(NetworkStatus.ready);
      } catch (e) {
        setError((e as Error).message);
        setNetworkStatus(NetworkStatus.error);
      }
    },
    error,
  };
}
