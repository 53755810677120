import React, { FunctionComponent, useContext } from 'react';
import { StyleProp, TextStyle } from 'react-native';
import tw from '~/shared/theme';

interface MenuItemTextColorValue {
  activeTextStyle: StyleProp<TextStyle>;
  inactiveTextStyle: StyleProp<TextStyle>;
  children?: React.ReactNode;
}
const defaultValue: MenuItemTextColorValue = {
  activeTextStyle: tw`text-blue`,
  inactiveTextStyle: tw`text-green-200`,
};
const MenuItemTextColorContext = React.createContext<MenuItemTextColorValue>(defaultValue);

export function useMenuItemTextColor(): MenuItemTextColorValue {
  return useContext(MenuItemTextColorContext);
}

export const MenuItemTextColorContextProvider: FunctionComponent<
  Partial<MenuItemTextColorValue>
> = ({ children, ...props }) => (
  <MenuItemTextColorContext.Provider value={{ ...defaultValue, ...props }}>
    {children}
  </MenuItemTextColorContext.Provider>
);
