import React, { FunctionComponent } from 'react';
import tw from '~/shared/theme';
import { AspectRatioContainer } from '~/shared/ui/aspect-ratio-container';
import { Button } from '~/shared/ui/button';
import { Carousel } from '~/shared/ui/carousel';
import { Icon, IconName } from '~/shared/ui/icon';
import { ImageBackground } from '~/shared/ui/image';
import { GapSpacer, Inline } from '~/shared/ui/spacing';
import { TextVariant } from '~/shared/ui/text-variant';
import { getCarouselPoster } from '~/shared/utils/media';
import { IBannerSlide } from '~/types';

import { useDynamicLinkTo } from '~/navigation/use-dynamic-link-to';
import { useGetImageOrPlaceholder } from '~/shared/utils/use-get-image-or-placeholder';
import { ImageOverlay } from '~/shared/ui/image-overlay';
import { isValidButtonVariant } from '~/shared/ui/button/button.component';

export interface MediaCarouselProps {
  slides: IBannerSlide[];
}

export const MediaCarousel: FunctionComponent<MediaCarouselProps> = ({ slides: items }) => {
  const linkTo = useDynamicLinkTo();
  const imageOrPlaceholder = useGetImageOrPlaceholder({
    placeholder: require('~/assets/images/placeholders/carousel.jpg'),
    getImageFunction: getCarouselPoster,
  });

  return (
    <Carousel autoRotate={5000}>
      {items.map((item) => (
        <AspectRatioContainer key={item.id} aspectRatio={3}>
          <ImageBackground
            style={[tw`w-full h-full justify-end`]}
            source={imageOrPlaceholder(item)}
          >
            <ImageOverlay>
              <GapSpacer>
                <TextVariant variant="page-title" style={tw`text-white`}>
                  {item.title.content}
                </TextVariant>
                <Inline spacing="md" style={tw`w-full`}>
                  {item.actions?.map(
                    (button) =>
                      button && (
                        <Button
                          key={button.title}
                          variant={
                            isValidButtonVariant(button.variant?.name)
                              ? button.variant?.name
                              : undefined
                          }
                          decorationLeft={
                            button.icon ? (
                              <Icon
                                name={button.icon.name as IconName}
                                color={button.icon.color || undefined}
                              />
                            ) : undefined
                          }
                          onPress={() => {
                            linkTo(button.link);
                          }}
                        >
                          {button.title}
                        </Button>
                      ),
                  )}
                </Inline>
              </GapSpacer>
            </ImageOverlay>
          </ImageBackground>
        </AspectRatioContainer>
      ))}
    </Carousel>
  );
};
