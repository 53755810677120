import { useState, useEffect, Dispatch, SetStateAction } from 'react';

type MaybeCallablePromise<T> = (() => Promise<T>) | (() => T);

async function execute<T>(fn: MaybeCallablePromise<T>, cb: (t: T) => void) {
  const result = await fn();
  cb(result);
}

export default function useLazyState<T>(
  promise: MaybeCallablePromise<T>,
): [T | undefined, Dispatch<SetStateAction<T | undefined>>] {
  const [value, setValue] = useState<T>();

  useEffect(() => {
    execute<T>(promise, setValue);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [value, setValue];
}
