import React, { FunctionComponent } from 'react';
import { Pressable, View } from 'react-native';
import tw from '~/shared/theme';
import { GapSpacer } from '../spacing';
import { TextVariant } from '../text-variant';
import { Coin } from './coin.component';

export interface MenuBackProps {
  label?: string;
  onPress?: () => void;
}

export const MenuBack: FunctionComponent<MenuBackProps> = ({ label, onPress }) => {
  return (
    <Pressable style={tw`flex-row items-center self-center`} onPress={onPress}>
      {({ hovered, pressed }) => (
        <GapSpacer spacing="sm" direction="row">
          <View
            style={[
              hovered && {
                transform: [
                  {
                    rotate: '6deg',
                  },
                  {
                    scale: 1.1,
                  },
                ],
              },
              pressed && {
                transform: [
                  {
                    rotate: '-4deg',
                  },
                  {
                    scale: 0.95,
                  },
                ],
              },
            ]}
          >
            <Coin icon="back" coinVariant="white" />
          </View>
          {label && <TextVariant variant="h3">{label}</TextVariant>}
        </GapSpacer>
      )}
    </Pressable>
  );
};
