import { EdgeInsets, useSafeAreaInsets } from 'react-native-safe-area-context';

/**
 * Because edge insets on native already have some padding applied to them it is too much on mobile, but sticks to the edges on web, sad.
 * @returns edge insets with a minimum horizontal padding
 */
export function useInsetsOrPadding(): EdgeInsets {
  const insets = useSafeAreaInsets();
  const left = Math.max(20, insets.left);
  const right = Math.max(20, insets.right);

  return {
    ...insets,
    left,
    right,
  };
}
