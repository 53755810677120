import { useEffect, useState } from 'react';
import { Image as RNImage, Platform } from 'react-native';

export interface IImageDimensions {
  width: number;
  height: number;
}

function measureImageWeb(source: string): Promise<IImageDimensions> {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = (e) => {
      const loadedImage = e.target as unknown as IImageDimensions;

      resolve({ width: loadedImage.width, height: loadedImage.height });
    };
    image.onerror = () => {
      reject();
    };

    image.src = source;
  });
}

function measureImageNative(source: string): Promise<IImageDimensions> {
  return new Promise((resolve, reject) => {
    RNImage.getSize(
      source,
      (width, height) => {
        resolve({ width, height });
      },
      reject,
    );
  });
}

export const measureImage = Platform.OS === 'web' ? measureImageWeb : measureImageNative;

export function useImageDimensions(source: string): IImageDimensions | undefined {
  const [dimensions, setDimensions] = useState<IImageDimensions>();

  useEffect(() => {
    setDimensions(undefined);

    measureImage(source).then(setDimensions);
  }, [source]);

  return dimensions;
}
