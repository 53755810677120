import { IProfileMeta } from '../types';

export interface ITracker {
  setProfileMeta(meta: IProfileMeta): void;
  logEvent(name: string, params: Record<string, unknown>): void;
  logCurrentScreen(name: string): void;
  logRevenue(productIdentifier: string, quantity: number, amount: number): void;
  setUserId(userId: string): void;
  setUserProperty(property: string, value: unknown): void;
  setUserPropertyOnce(property: string, value: unknown): void;
  addToUserProperty(property: string, amount: number): void;
}

export interface ITrackerAdapter extends ITracker {
  displayName: string;
  getSafePropertyValue(value: unknown): unknown;
}

export default class BaseTrackerAdapter implements ITrackerAdapter {
  static methods = [
    'setProfileMeta',
    'logEvent',
    'logCurrentScreen',
    'logRevenue',
    'setUserId',
    'setUserProperty',
    'setUserPropertyOnce',
    'addToUserProperty',
  ];
  displayName: string;
  debug: boolean;

  constructor(name: string) {
    this.displayName = name;
    this.debug = false;
  }

  getSafePropertyValue(value: unknown): unknown {
    if (typeof value === 'boolean') {
      return value ? '1' : '0';
    }
    return value;
  }

  profileMeta?: IProfileMeta;

  setProfileMeta(meta: IProfileMeta): void {
    this.profileMeta = meta;
  }

  augmentEventParams(params: Record<string, unknown>): Record<string, unknown> {
    if (!this.profileMeta) return params;
    return {
      profile_age: this.profileMeta?.age,
      profile_language: this.profileMeta?.language,
      ...params,
    };
  }

  logEvent(name: string, params: Record<string, unknown>): void {
    if (this.debug)
      console.info(
        `TRACKER::${this.displayName}::LOG_EVENT::${name}`,
        this.augmentEventParams(params),
      );
  }

  logCurrentScreen(name: string): void {
    if (this.debug) console.info(`TRACKER::${this.displayName}::LOG_CURRENT_SCREEN::${name}`);
  }

  logRevenue(productIdentifier: string, quantity: number, amount: number): void {
    if (this.debug)
      console.info(`TRACKER::${this.displayName}::LOG_REVENUE`, {
        productIdentifier,
        quantity,
        amount,
      });
  }

  setUserId(userId: string): void {
    if (this.debug) console.info(`TRACKER::${this.displayName}::SET_USER_ID::${userId}`);
  }

  setUserProperty(property: string, value: unknown): void {
    if (this.debug)
      console.info(`TRACKER::${this.displayName}::SET_USER_PROPERTY::${property}:${value}`);
  }

  setUserPropertyOnce(property: string, value: unknown): void {
    if (this.debug)
      console.info(`TRACKER::${this.displayName}::SET_USER_PROPERTY_ONCE::${property}:${value}`);
  }

  addToUserProperty(property: string, amount: number): void {
    if (this.debug)
      console.info(`TRACKER::${this.displayName}::ADD_TO_USER_PROPERTY::${property}:${amount}`);
  }
}
