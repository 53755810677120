import { useNetInfo } from '@react-native-community/netinfo';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Animated, View } from 'react-native';

import tw from '~/shared/theme';
import { Icon } from '~/shared/ui/icon';
import { ImageBackground } from '~/shared/ui/image';
import { Spinner } from '~/shared/ui/preloader';
import { Inline } from '~/shared/ui/spacing';
import { TextVariant } from '~/shared/ui/text-variant';

interface SplashProps {
  animated?: boolean;
  loading?: boolean;
  onAnimationEnd?(): void;
}

export const Splash: FunctionComponent<SplashProps> = ({
  animated = true,
  loading,
  onAnimationEnd,
}) => {
  const { t } = useTranslation();
  const { isConnected } = useNetInfo();
  const [animationFinished, setAnimationFinished] = useState(false);
  const animation = useRef(new Animated.Value(animated ? 0 : 1)).current;

  useEffect(() => {
    if (animated) {
      Animated.spring(animation, {
        delay: 200,
        toValue: 1,
        bounciness: 12,
        useNativeDriver: true,
      }).start(() => {
        setTimeout(() => {
          setAnimationFinished(true);
          onAnimationEnd?.();
        }, 300);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animated, animation]);

  return (
    <View style={tw`absolute top-0 left-0 w-full h-full bg-green-splash`}>
      <Animated.View
        style={[
          tw`absolute top-0 left-0 w-full h-full`,
          {
            opacity: animation,
            transform: [
              {
                scale: animation.interpolate({
                  inputRange: [0, 1],
                  outputRange: [0, 1],
                }),
              },
            ],
          },
        ]}
      >
        <ImageBackground
          style={tw`absolute top-0 left-0 w-full h-full items-center`}
          source={require('~/assets/splash/splash.jpg')}
          resizeMode="contain"
        >
          {loading && animationFinished && (
            <View style={tw`top-[75%]`}>
              {isConnected ? (
                <Inline>
                  <Spinner />
                  <TextVariant variant="h2">{t('loading')}</TextVariant>
                </Inline>
              ) : (
                <TextVariant variant="h2">
                  <Icon name="error" style={tw`mr-2`} /> {t('noInternetConnectionErrorMessage')}
                </TextVariant>
              )}
            </View>
          )}
        </ImageBackground>
      </Animated.View>
    </View>
  );
};
