import React, { FunctionComponent } from 'react';
import { Gap } from '../spacing';
import { TextVariant } from '../text-variant';
import { SettingsRowProps } from './settings-row.component';

export interface SettingsGroupProps {
  title?: string;
  children: React.ReactNode;
}

export const SettingsGroup: FunctionComponent<SettingsGroupProps> = ({ title, children }) => {
  let isFirst = true;
  const validChildIndices: (number | null)[] =
    React.Children.map(children, (child, index) => (React.isValidElement(child) ? index : null)) ||
    [];
  const lastValidIndex = validChildIndices.reduceRight((validIndex, index) => {
    if (validIndex === null && index !== null) {
      return index;
    }
    return validIndex;
  }, null);

  return (
    <>
      {title && <TextVariant variant="h1">{title}</TextVariant>}
      {title && <Gap spacing="sm" />}
      {React.Children.map(children, (child, index) => {
        if (!React.isValidElement(child)) {
          return child;
        }

        let positionVariant: SettingsRowProps['positionVariant'] = 'center';
        if (isFirst) {
          isFirst = false;
          if (index === lastValidIndex) {
            positionVariant = 'single';
          } else {
            positionVariant = 'top';
          }
        } else if (index === lastValidIndex) {
          positionVariant = 'bottom';
        }

        return (
          React.isValidElement<{ positionVariant: SettingsRowProps['positionVariant'] }>(child) &&
          React.cloneElement(child, {
            positionVariant,
          })
        );
      })}
    </>
  );
};
