import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalProps, View } from 'react-native';
import { useProfiles } from '~/entities/profiles';
import { useProximus } from '~/entities/proximus';
import { useViewer } from '~/entities/viewer';
import tw from '~/shared/theme';
import { Button } from '~/shared/ui/button';
import { Card } from '~/shared/ui/card';
import { Modal } from '~/shared/ui/modal';
import { GapSpacer, Inline } from '~/shared/ui/spacing';
import { TextVariant } from '~/shared/ui/text-variant';

interface LogoutConfirmationModalProps extends ModalProps {}

export const LogoutConfirmationModal: FunctionComponent<LogoutConfirmationModalProps> = ({
  visible,
  onRequestClose,
}) => {
  const { t } = useTranslation();

  const resetProfiles = useProfiles((state) => state.reset);
  const resetViewer = useViewer((state) => state.reset);
  const resetProximus = useProximus((state) => state.reset);

  const handleLogout = () => {
    resetProximus();
    resetViewer();
    resetProfiles();
  };

  return (
    <Modal visible={visible} onRequestClose={onRequestClose}>
      <Card>
        <GapSpacer>
          <View>
            <TextVariant variant="modal-title" style={tw`text-center`}>
              {t('logDeviceOffOverlayTitle')}
            </TextVariant>

            <TextVariant variant="body" style={tw`text-center mt-2`}>
              {t('logDeviceOffOverlayDescription')}
            </TextVariant>
          </View>

          <Inline spacing="md" style={tw`justify-center`}>
            <Button variant="danger" onPress={handleLogout}>
              {t('logDeviceOffOverlaySubmitButtonLabel')}
            </Button>
            <Button variant="secondary" onPress={onRequestClose}>
              {t('logDeviceOffOverlayCancelButtonLabel')}
            </Button>
          </Inline>
        </GapSpacer>
      </Card>
    </Modal>
  );
};
