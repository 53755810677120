import React, { FunctionComponent } from 'react';
import { View, ViewProps } from 'react-native';
import tw from '~/shared/theme';
import { GapSpacer, GapSpacerProps } from './gap.component';

interface InlineProps extends ViewProps {
  direction?: 'row' | 'col';
  spacing?: GapSpacerProps['spacing'];
}

export const Inline: FunctionComponent<InlineProps> = ({
  direction = 'row',
  spacing,
  style,
  children,
  ...props
}) => {
  return (
    <View
      style={[tw`flex items-center ${direction === 'row' ? 'flex-row' : 'flex-col'}`, style]}
      {...props}
    >
      {spacing ? (
        <GapSpacer spacing={spacing} direction={direction}>
          {children}
        </GapSpacer>
      ) : (
        children
      )}
    </View>
  );
};
