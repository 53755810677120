import Axios from 'axios';
import { Platform } from 'react-native';

interface AccountApi {
  register(args: { deviceId: string; email: string; language: string }): Promise<void>;
  changeNewsletterStatus(args: { deviceId: string; status: boolean }): Promise<void>;
  requestAccountSettings(args: { deviceId: string }): Promise<void>;
}

export function makeAccountApi(config: { apiUrl: string; apiKey: string }): AccountApi {
  const client = Axios.create({
    timeout: 5000,
    baseURL: config.apiUrl,
    headers: {
      'x-api-key': config.apiKey,
    },
  });

  return {
    async register({ deviceId, email, language }) {
      const response = await client.post('device', {
        account: { language, email, newsletter: false },
        device: {
          id: deviceId,
          name: '',
          type: Platform.OS,
        },
      });

      if (response.status !== 201) {
        throw new Error('Registration failed');
      }
    },

    async changeNewsletterStatus({ deviceId, status }) {
      const response = await client.post('device/newsletter', {
        id: deviceId,
        status,
      });

      if (response.status !== 201) {
        throw new Error('Newsletter subscription failed');
      }
    },

    async requestAccountSettings({ deviceId }) {
      await client.post('/account/send/request-account', {
        id: deviceId,
      });
    },
  };
}
