import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { BookPlayerProps } from './types';

import PageFlip from 'react-pageflip';
import { useDimensions } from './utils';

export const BookPlayer: FunctionComponent<BookPlayerProps> = ({ pages, fullScreen }) => {
  const [activePageIndex, setActivePageIndex] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const { dimensions, setLayout } = useDimensions({ pages, fullScreen });

  useEffect(() => {
    if (!containerRef.current) return;
    const doLayout = () => {
      setLayout({
        width: containerRef.current!.offsetWidth,
        height: containerRef.current!.offsetHeight,
      });
    };
    doLayout();

    window.addEventListener('resize', doLayout);
    return () => {
      window.removeEventListener('resize', doLayout);
    };
  }, [setLayout, containerRef, fullScreen]);

  return (
    <div
      style={{ width: '100%', height: '100%', justifyContent: 'center', display: 'flex' }}
      ref={containerRef}
    >
      {dimensions.width ? (
        <PageFlip
          key={dimensions.height}
          width={dimensions.width}
          height={dimensions.height}
          size="stretch"
          className={''}
          style={{}}
          startPage={activePageIndex}
          minWidth={dimensions.width}
          maxWidth={dimensions.width}
          minHeight={dimensions.height}
          maxHeight={dimensions.height}
          drawShadow={true}
          flippingTime={500}
          usePortrait={false}
          startZIndex={0}
          autoSize={true}
          maxShadowOpacity={1}
          showCover={false}
          mobileScrollSupport={true}
          clickEventForward={false}
          useMouseEvents={true}
          swipeDistance={0}
          showPageCorners={true}
          disableFlipByClick={false}
          onFlip={({ data }: { data: number }) => {
            setActivePageIndex(data);
          }}
        >
          <div>
            <div style={{ width: '100%', height: '100%', backgroundColor: '#357bc2' }} />
          </div>
          {pages.map((page, i) => (
            <img src={page} key={i} />
          ))}
        </PageFlip>
      ) : null}
    </div>
  );
};
