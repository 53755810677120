import { createNavigationContainerRef } from '@react-navigation/native';
import { useCallback } from 'react';
import { linkingMap } from '~/navigation/linking';
import { RootRoutes, RootStackParamList } from './index';

export const navigationRef = createNavigationContainerRef<RootStackParamList>();

type Params =
  | undefined
  | {
      [key: string]: unknown;
    };

function getPathToRoute(
  screenName: string,
  params: Params,
): { screenName: RootRoutes; params: Params } | null {
  if (!linkingMap[screenName]) return null;

  const { parent, screen } = linkingMap[screenName];

  if (parent) {
    return { screenName: parent, params: { screen, params } };
  }

  return { screenName: screen, params };
}

type LinkToOptions = { screen: keyof typeof linkingMap | string; params?: Params };

export function useNavigationRef() {
  return navigationRef;
}

export function useDynamicLinkTo(): (options: LinkToOptions) => boolean {
  return useCallback(({ screen, params }) => {
    if (!navigationRef.isReady() || !navigationRef.current) return false;

    try {
      const path = getPathToRoute(screen, params);
      if (!path) {
        return false;
      }
      // @todo fix any type
      navigationRef.current.navigate(path.screenName, path.params as any);
      return true;
    } catch (e) {
      return false;
    }
  }, []);
}
