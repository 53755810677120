import React, { FunctionComponent, useMemo, useRef } from 'react';
import {
  Pressable,
  Animated,
  GestureResponderEvent,
  PressableStateCallbackType,
  StyleProp,
  ViewStyle,
} from 'react-native';
import { PressablesBaseProps } from './pressable';

type AnimationWrapper = FunctionComponent<{
  children: PressablesBaseProps['children'];
  pressableProps: PressableStateCallbackType;
  style?: StyleProp<ViewStyle> | ((e: PressableStateCallbackType) => StyleProp<ViewStyle>);
}>;

type SharedProps = {
  pressedScale?: number;
  hoveredScale?: number;
  hoveredRotation?: string;
  pressedRotation?: string;

  onPressDelay?: number;
};
type PressablesBounceProps =
  | (PressablesBaseProps &
      SharedProps & {
        /**
         * When provided, the AnimationWrapper is passed to the children fn.
         * You are responsible to handle the composition
         */
        complexViewStructure?: false;
      })
  | (Omit<PressablesBaseProps, 'children'> &
      SharedProps & {
        /**
         * When provided, the AnimationWrapper is passed to the children fn.
         * You are responsible to handle the composition
         */
        complexViewStructure: true;
        children?:
          | React.ReactNode
          | ((
              state: PressableStateCallbackType & {
                AnimationWrapper: AnimationWrapper;
              },
            ) => React.ReactNode)
          | undefined;
      });

export const PressableBounce: FunctionComponent<PressablesBounceProps> = ({
  children,
  pressedScale = 0.6,
  hoveredScale = 0.95,
  hoveredRotation = '6deg',
  pressedRotation = '-4deg',
  onPressDelay = 250,
  onPress,
  style,
  complexViewStructure,
  ...props
}) => {
  const pressableAnimationRef = useRef<Animated.CompositeAnimation>();
  const value = useMemo(() => new Animated.Value(-1), []);

  const handlePress = (e: GestureResponderEvent) => {
    if (pressableAnimationRef.current) {
      const previousAnimation = pressableAnimationRef.current;
      pressableAnimationRef.current = undefined;
      previousAnimation.reset();
    }
    const animation = Animated.spring(value, {
      toValue: 1,
      useNativeDriver: true,
    });
    pressableAnimationRef.current = animation;
    animation.start(() => {
      if (pressableAnimationRef.current !== animation) return;
      pressableAnimationRef.current = undefined;
      value.setValue(-1);
    });

    // Spring animations have a bit too much "blank" space before triggering the end callback
    // A consistent delay of 300ms seems to feel better.
    setTimeout(() => {
      if (pressableAnimationRef?.current !== animation) return;
      onPress?.(e);
    }, onPressDelay);
  };

  const handleHover = (toValue: number) => () => {
    if (pressableAnimationRef.current) return;

    Animated.spring(value, {
      toValue,
      useNativeDriver: true,
    }).start();
  };

  const animatedInner: AnimationWrapper = ({
    children: innerChildren,
    pressableProps,
    style: innerStyle,
  }) => (
    <Animated.View
      style={[
        {
          transform: [
            {
              rotate: value.interpolate({
                inputRange: [-2, -1, 0, 1],
                outputRange: [hoveredRotation, '0deg', pressedRotation, '0deg'],
              }),
            },
            {
              scale: value.interpolate({
                inputRange: [-2, -1, 0, 1],
                outputRange: [hoveredScale, 1, pressedScale, 1],
              }),
            },
          ],
        },
        typeof innerStyle === 'function' ? innerStyle(pressableProps) : innerStyle,
      ]}
    >
      {typeof innerChildren === 'function' ? innerChildren(pressableProps) : innerChildren}
    </Animated.View>
  );

  return (
    <Pressable
      onPress={handlePress}
      onHoverIn={handleHover(-2)}
      onHoverOut={handleHover(-1)}
      style={style}
      {...props}
    >
      {(pressableProps) =>
        !complexViewStructure ? (
          animatedInner({ pressableProps, children })
        ) : (
          <>
            {typeof children === 'function'
              ? children({ ...pressableProps, AnimationWrapper: animatedInner })
              : children}
          </>
        )
      }
    </Pressable>
  );
};
