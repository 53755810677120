import React, { FunctionComponent, useState } from 'react';
import { View } from 'react-native';
import tw from '~/shared/theme';
import { Icon } from '../../icon';
import { GapSpacer, Inline } from '../../spacing';
import { TextVariant } from '../../text-variant';
import { CheckboxStyles } from './checkbox.style';

interface CheckboxProps {
  value: boolean;
  onChange: (value: boolean) => void;
  label?: string | React.ReactNode;
  error?: boolean | string;
}

export const Checkbox: FunctionComponent<CheckboxProps> = ({ value, error, onChange, label }) => {
  const [focussed, setFocussed] = useState<boolean>(false);
  return (
    <label>
      <GapSpacer spacing="xs">
        <Inline spacing="sm">
          <View
            style={[
              CheckboxStyles.box.default,
              !!error && CheckboxStyles.box.error,
              focussed && CheckboxStyles.box.focussed,
            ]}
          >
            <input
              style={tw`absolute top-0 left-0 w-full h-full m-0 opacity-0`}
              type="checkbox"
              checked={value}
              onChange={(event) => onChange(event.target.checked)}
              onFocus={() => setFocussed(true)}
              onBlur={() => setFocussed(false)}
            />
            {value && <Icon name="check" style={CheckboxStyles.check} />}
          </View>
          {label && <TextVariant variant="small">{label}</TextVariant>}
        </Inline>
        {error && typeof error === 'string' && <TextVariant variant="error">{error}</TextVariant>}
      </GapSpacer>
    </label>
  );
};
