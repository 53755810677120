import { Platform } from 'react-native';
import { create, plugin, TwConfig } from 'twrnc';

import config from '../../tailwind.config.js';

const webPlugins =
  Platform.OS === 'web'
    ? [
        // https://github.com/tailwindlabs/tailwindcss/blob/master/src/corePlugins.js#L684
        plugin(({ addUtilities }) => {
          addUtilities({
            'snap-start': {
              scrollSnapAlign: 'start',
            },
            'snap-end': {
              scrollSnapAlign: 'end',
            },
            'snap-x-mandatory': {
              scrollSnapType: 'x mandatory',
            },
            'snap-x-proximity': {
              scrollSnapType: 'x proximity',
            },
            'snap-y-madatory': {
              scrollSnapType: 'y mandatory',
            },
            'snap-y-proximity': {
              scrollSnapType: 'y proximity',
            },
          });
        }),
        // mimics https://github.com/tailwindlabs/tailwindcss/blob/master/src/corePlugins.js#L744
        plugin(({ addUtilities }) => {
          // scroll padding, available in tailwind core, but not twrnc
          addUtilities({
            'scroll-p-5': {
              scrollPadding: '20px',
            },
          });
        }),
        plugin(({ addUtilities }) => {
          // animations
          addUtilities({
            spin: {
              animation: 'spin 1s linear infinite',
            },
          });
        }),
      ]
    : [
        // prevent console warnings
        plugin(({ addUtilities }) => {
          addUtilities({
            'snap-start': {},
            'snap-end': {},
            'snap-x-mandatory': {},
            'snap-x-proximity': {},
            'snap-y-madatory': {},
            'snap-y-proximity': {},
          });
        }),
      ];

const tw = create({
  theme: {
    ...config.theme,
    extend: {},
  },
  plugins: [...webPlugins],
} as unknown as TwConfig);

export { config };
export default tw;
