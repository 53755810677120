import { useIsFocused } from '@react-navigation/core';
import { useMemoizedActiveProfilePreferences } from '~/entities/profiles';
import { NetworkStatus } from '~/types';
import {
  IGQLGetMediaForSearchQueryData,
  useGetMediaForSearchQuery,
} from '../api/queries.main.generated';

export function useSearchFeeds({ query }: { query?: string }): {
  feeds?: Exclude<IGQLGetMediaForSearchQueryData['search'], null>['items'];
  networkStatus: NetworkStatus;
} {
  const inFocus = useIsFocused();
  const profilePreferences = useMemoizedActiveProfilePreferences(20);
  const { data, networkStatus } = useGetMediaForSearchQuery({
    variables: {
      query: query as string,
      ...profilePreferences,
    },
    skip: !query || !inFocus,
  });

  return {
    feeds: data?.search.items,
    networkStatus,
  };
}
