import { RootRoutes, MediaRoutes, OnboardingRoutes, SettingsRoutes } from './index';

export const linkingConfig = {
  initialRouteName: RootRoutes.Media,
  screens: {
    [RootRoutes.OAuth]: 'oauth',
    [RootRoutes.Media]: {
      initialRouteName: MediaRoutes.Overview,
      path: '',
      screens: {
        [MediaRoutes.Brands]: 'brands',
        [MediaRoutes.MediaDetail]: 'content/:id',
        [MediaRoutes.Search]: 'search',
        [MediaRoutes.Downloads]: 'downloads',
        [MediaRoutes.Favorites]: 'favorites',
        [MediaRoutes.SerieOverview]: 'serie/:id',
        [MediaRoutes.Overview]: ':medium?/:brandSlug?',
      },
    },
    [RootRoutes.Onboarding]: {
      initialRouteName: OnboardingRoutes.Main,
      path: 'onboarding',
      screens: {
        [OnboardingRoutes.Main]: '',
        [OnboardingRoutes.Profile]: 'profile',
      },
    },
    [RootRoutes.Settings]: {
      initialRouteName: SettingsRoutes.Main,
      path: 'settings',
      screens: {
        [SettingsRoutes.Main]: '',
        [SettingsRoutes.ProfileOverview]: 'profiles',
        [SettingsRoutes.ProfileCreate]: 'profiles/create',
        [SettingsRoutes.ProfileEdit]: 'profiles/:id/edit',
      },
    },
  },
};

export const linkingMap: Record<
  string,
  ({ parent: RootRoutes; screen: string } | { parent?: undefined; screen: RootRoutes }) & {
    path: string;
  }
> = {};
(Object.keys(linkingConfig.screens) as Array<keyof typeof linkingConfig.screens>).forEach((key) => {
  const screen = linkingConfig.screens[key];
  if (typeof screen === 'string') {
    linkingMap[key] = { screen: key, path: screen };
    return;
  }

  (Object.keys(screen.screens) as Array<keyof typeof screen.screens>).forEach((subKey) => {
    linkingMap[subKey] = {
      parent: key,
      screen: subKey,
      path: `${screen.path}/${screen.screens[subKey]}`,
    };
  });
});
