import React, { FunctionComponent } from 'react';
import { NavigationProp, useNavigation, useRoute } from '@react-navigation/core';
import { View } from 'react-native';
import { MediaRoutes, MediaStackParamList } from '~/navigation';
import tw from '~/shared/theme';
import { Icon, IconName } from '~/shared/ui/icon';
import { Gap, GapSpacer } from '~/shared/ui/spacing';
import { TextVariant } from '~/shared/ui/text-variant';
import { IFeed, IFeedContent, MediaType } from '~/types';
import { BackgroundEndCap, BackgroundStartCap } from '~/shared/ui/background';
import { FeedLayoutGrid } from './components/feed-grid.component';
import { FeedLayoutSwimlane } from './components/feed-swimlane.component';
import { useProximus } from '~/entities/proximus';
import { useTranslation } from 'react-i18next';
import { Button } from '~/shared/ui/button';
import { useInsetsOrPadding } from '~/shared/utils/use-insets-or-padding';
import { useDynamicLinkTo } from '~/navigation/use-dynamic-link-to';
import { analyticsTracker, NavigationEventSourceType } from '~/shared/tracking/analytics';

interface FeedLayoutProps {
  feeds: IFeed[];
  onItemPress?(item: IFeedContent): void;
}

const FeedLayoutBase: FunctionComponent<FeedLayoutProps> = ({ feeds, onItemPress }) => {
  const { t } = useTranslation();
  const route = useRoute();
  const linkTo = useDynamicLinkTo();
  const requestMediaItem = useProximus((state) => state.requestMediaItem);
  const navigation = useNavigation<NavigationProp<MediaStackParamList>>();
  const insets = useInsetsOrPadding();
  const { left, right } = insets;

  const handleItemPress = (item: IFeedContent) => {
    if (onItemPress) {
      return onItemPress(item);
    }
    if (item.__typename === MediaType.Serie) {
      navigation.navigate(MediaRoutes.SerieOverview, { id: item.id });
    } else if (item.__typename === 'Brand') {
      navigation.navigate(MediaRoutes.Overview, {
        brandSlug: item.slug,
      });
    } else {
      navigation.navigate(MediaRoutes.MediaDetail, {
        id: item.id,
      });
    }
  };

  const feedsWithItems = feeds.filter((feed) => feed.result?.items.length);

  if (feedsWithItems.length === 0) {
    // @todo nice state
    return (
      <View>
        <TextVariant>{t('notFoundTitle')}</TextVariant>
      </View>
    );
  }

  return (
    <View style={tw`pb-5`}>
      <GapSpacer spacing="xl">
        {feedsWithItems.map((feed, index) => {
          const Feed =
            feed.feedLayoutType === 'GRID' || feedsWithItems.length === 1
              ? FeedLayoutGrid
              : FeedLayoutSwimlane;
          return (
            <View
              key={feed.id}
              style={[
                tw`py-8`,
                index % 2 === 1 ? tw`bg-green-450` : {},
                {
                  marginLeft: -left,
                  marginRight: -right,
                  paddingLeft: left,
                  paddingRight: right,
                },
              ]}
            >
              {index % 2 === 1 && <BackgroundStartCap />}
              {index % 2 === 1 && <BackgroundEndCap />}
              <View style={tw`flex justify-between flex-row mb-3`}>
                <TextVariant variant="swimlane-title">
                  {feed.icon && (
                    <Icon
                      name={feed.icon.name as IconName}
                      color={feed.icon.color || undefined}
                      style={tw`mr-2`}
                    />
                  )}{' '}
                  {feed.title.content}
                </TextVariant>
                {feed.button && (
                  <Button
                    variant="transparent"
                    size="box-md"
                    decorationRight={<Icon name="arrow-right" style={tw`text-white`} />}
                    onPress={() => {
                      if (feed.button?.link) {
                        analyticsTracker.setNavigationEventMeta({
                          id: feed.id,
                          type: NavigationEventSourceType.FeedButton,
                          route,
                        });
                        linkTo(feed.button?.link);
                      }
                    }}
                  >
                    {feed.button.title}
                  </Button>
                )}
              </View>
              <Gap spacing="sm" />
              <Feed
                feed={feed}
                insets={insets}
                onItemPress={(item) => {
                  analyticsTracker.setNavigationEventMeta({
                    id: feed.id,
                    type: NavigationEventSourceType.Feed,
                    route,
                  });
                  if (item.__typename !== 'Brand' && item.isLocked) {
                    requestMediaItem(item);
                  } else {
                    handleItemPress(item);
                  }
                }}
              />
            </View>
          );
        })}
      </GapSpacer>
    </View>
  );
};

export const FeedLayout = React.memo(FeedLayoutBase);
