import Axios from 'axios';
import { IConfig } from '../config';

export interface IProximusTokenInfo {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  scope: string;
  token_type: string;
}

interface IProximusApi {
  refreshToken(tokenInfo: IProximusTokenInfo): Promise<IProximusTokenInfo | null>;
  validateToken(tokenInfo: IProximusTokenInfo): Promise<{ isValid: boolean } | null>;
  revokeToken(tokenInfo: IProximusTokenInfo): Promise<void>;
}

export function makeProximusApi(config: IConfig['proximus']): IProximusApi {
  const client = Axios.create({
    timeout: 5000,
  });

  return {
    async refreshToken(tokenInfo) {
      try {
        const response = await client.post(config.refreshTokenUrl, {
          refreshToken: tokenInfo.refresh_token,
        });

        return response.data as IProximusTokenInfo;
      } catch (e) {
        return null;
      }
    },

    async revokeToken(tokenInfo) {
      try {
        await client.post(config.revokeTokenUrl, {
          accessToken: tokenInfo.access_token,
        });
      } catch (e) {}
    },

    async validateToken(tokenInfo) {
      try {
        const response = await client.get(config.validateTokenUrl, {
          headers: {
            authorization: `${tokenInfo.token_type || 'Bearer'} ${tokenInfo.access_token}`,
          },
        });

        return response.data as { isValid: boolean };
      } catch (e) {
        if (Axios.isAxiosError(e)) {
          return { isValid: false };
        }
        return null;
      }
    },
  };
}
