import AsyncStorage from '@react-native-async-storage/async-storage';
import { useNetInfo } from '@react-native-community/netinfo';
import { useEffect } from 'react';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import client from '~/shared/api/main.api';
import { NetworkStatus } from '~/types';
import {
  GetBrandsOperation,
  IGQLBrandFragment,
  IGQLGetBrandsQueryData,
} from './api/queries.main.generated';

interface IBrandsState {
  _hasHydrated: boolean;
  networkStatus: NetworkStatus;
  items: {
    nl: IGQLBrandFragment[];
    fr: IGQLBrandFragment[];
  };
}

interface IBrandsProducers {
  init(): Promise<void>;
}

type IBrandsModel = IBrandsState & IBrandsProducers;

export const useBrandsModel = create<IBrandsModel>()(
  persist(
    (set) => ({
      _hasHydrated: false,
      networkStatus: NetworkStatus.default,

      items: {
        nl: [],
        fr: [],
      },

      async init() {
        try {
          set({ networkStatus: NetworkStatus.loading });
          const response = await client.query<IGQLGetBrandsQueryData>({
            query: GetBrandsOperation,
          });

          set({
            networkStatus: NetworkStatus.ready,
            items: response.data,
          });
        } catch (e) {
          console.log(e);
          set({ networkStatus: NetworkStatus.error });
        }
      },
    }),
    {
      name: 'studio100go-brands',
      storage: createJSONStorage(() => AsyncStorage),
      onRehydrateStorage: () => () => {
        useBrandsModel.setState({ _hasHydrated: true });
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      partialize: ({ networkStatus, _hasHydrated, ...state }) => state,
    },
  ),
);

export const useBrands = (args: {
  language: string;
}): { networkStatus: NetworkStatus; items: IGQLBrandFragment[] } => {
  const { isConnected } = useNetInfo();
  const { init, networkStatus, items } = useBrandsModel();

  const resultItems = items[args.language as 'nl' | 'fr'];

  useEffect(() => {
    if (!isConnected || networkStatus !== NetworkStatus.default) return;

    init();
  }, [networkStatus, init, resultItems.length, isConnected]);

  return { networkStatus, items: resultItems };
};
