import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useWindowDimensions, View } from 'react-native';
import { useActiveProfile } from '~/entities/profiles';
import tw from '~/shared/theme';
import { BrandSpacer, BrandThumbnail } from '~/shared/ui/brand-thumbnail';
import { Preloader } from '~/shared/ui/preloader';
import { Inline } from '~/shared/ui/spacing';
import { useRetryQuery } from '~/shared/utils/use-retry-query';
import { NetworkStatus } from '~/types';
import { useGetBrandsListQuery } from '../api/queries.main.generated';
import { ListBrandsSkeleton } from './list-brands.skeleton';

interface ListBrandsProps {
  onBrandPress?(slug: string): void;
}

export const ListBrands: FunctionComponent<ListBrandsProps> = ({ onBrandPress }) => {
  const { t, i18n } = useTranslation();
  const { activeProfile } = useActiveProfile();
  const { data, ...query } = useGetBrandsListQuery({
    variables: {
      language: i18n.language,
      relatedToBrandIds: activeProfile?.favoriteBrands,
    },
  });
  const brands = data?.brands;
  const { networkStatus, ...retryQuery } = useRetryQuery(query);

  const estimatedItemsPerLine = Math.floor(useWindowDimensions().width / 220);
  return (
    <Preloader
      loading={networkStatus < NetworkStatus.ready}
      error={networkStatus === NetworkStatus.error ? t('generalErrorMessage') : undefined}
      errorRetryButton={retryQuery}
      empty={!brands || brands.length === 0}
      skeleton={<ListBrandsSkeleton />}
    >
      {() =>
        brands && (
          <Inline style={tw`flex-wrap justify-center -m-4`} spacing="sm">
            {brands.map(({ id, images, name, slug }) => (
              <View key={id} style={tw`m-4 tablet:mb-10`}>
                <BrandThumbnail
                  variant="tile"
                  label={name}
                  source={{ uri: images?.medium?.source }}
                  onPress={() => onBrandPress?.(slug)}
                />
              </View>
            ))}
            {Array(estimatedItemsPerLine)
              .fill(0)
              .map((_, i) => (
                <BrandSpacer key={i} />
              ))}
          </Inline>
        )
      }
    </Preloader>
  );
};
