import React, { FunctionComponent } from 'react';
import { GuardWebOnMobileProps } from './guard-web-on-mobile.types';

function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

export const GuardWebOnMobile: FunctionComponent<GuardWebOnMobileProps> = ({
  children,
  fallback,
}) => {
  if (isMobile()) {
    return fallback();
  }

  return <>{children}</>;
};
