import React, { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import tw from '~/shared/theme';
import { Card } from '~/shared/ui/card';
import { TextInput } from '~/shared/ui/input';
import { TextVariant } from '~/shared/ui/text-variant';
import { ProfileUpsertStepProps } from '../../types';
import { PageDecoration } from '~/shared/ui/page-decoration';

interface ProfileNameStepProps extends ProfileUpsertStepProps {}

export const ProfileNameStep: FunctionComponent<ProfileNameStepProps> = ({
  state,
  onChange,
  decorated,
}) => {
  const { t } = useTranslation();

  const isValid = (value: string) => value.length > 2;

  useEffect(() => {
    onChange(null, { isValid: isValid(state.name) });
    // Only do this on mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {decorated && <PageDecoration name="eye" style={tw`bottom-1/3 left-16`} />}
      <Card>
        {decorated && <PageDecoration name="stripes" style={tw`-right-32 -top-16`} />}
        <TextVariant variant="page-title" style={tw`text-center mb-4`}>
          {t('onboardingStep1Title')}
        </TextVariant>
        <TextInput
          spellCheck={false}
          value={state.name}
          onChangeText={(value) =>
            onChange((s) => ({ ...s, name: value }), { isValid: isValid(value) })
          }
          placeholder={t('onboardingStep1TextDefault')}
        />
      </Card>
    </>
  );
};
