import React, { FunctionComponent, ReactElement } from 'react';
import { View } from 'react-native';
import tw from '~/shared/theme';
import { TextVariant } from '../text-variant';
import { AspectRatioContainer, AspectRatioContainerProps } from '../aspect-ratio-container';
import { Icon, IconProps } from '../icon';
import { Inline, Fill, GapSpacer } from '../spacing';
import { Button } from '../button';
import { Image, ImageSourcePropType } from '../image';
import { IAspectRatio } from '../aspect-ratio-container/aspect-ratio-container.component';

interface MediaTileData {
  title: string;
  subtitle?: string;
  image: string;
  itemCount?: number;
  meta: string;
  icon?: ReactElement<typeof Icon>;
}

interface MediaTileHighlight {
  borderColor?: `border-${string}`;
  icon?: {
    name: IconProps['name'];
    backgroundColor?: `bg-${string}`;
    color?: `text-${string}`;
  };
}

export interface MediaTileProps {
  aspectRatio?: AspectRatioContainerProps['aspectRatio'];
  data: MediaTileData;
  locked?: boolean;
  playButton?: boolean;
  book?: boolean;
  highlight?: MediaTileHighlight | boolean;
}

const placeholders: Record<IAspectRatio, ImageSourcePropType> = {
  square: require('~/assets/images/placeholders/media-square.jpg'),
  portrait: require('~/assets/images/placeholders/media-portrait.jpg'),
  portrait_tall: require('~/assets/images/placeholders/media-portrait-tall.jpg'),
  landscape: require('~/assets/images/placeholders/media-landscape.jpg'),
};

const getPlaceholder = (
  aspectRatio: AspectRatioContainerProps['aspectRatio'],
): ImageSourcePropType => {
  if (!aspectRatio || typeof aspectRatio === 'number') {
    return placeholders.landscape;
  }
  return placeholders[aspectRatio];
};

export const MediaTile: FunctionComponent<MediaTileProps> = ({
  data,
  highlight: highlightProp,
  aspectRatio,
  locked = false,
  playButton = false,
  book = false,
}) => {
  const { itemCount = 1, image, title, subtitle, meta, icon } = data;
  const imageSource = image ? { uri: image } : getPlaceholder(aspectRatio);
  const isMultiple = itemCount > 1;
  const highlight: MediaTileHighlight | undefined = highlightProp
    ? {
        borderColor: 'border-yellow',
        icon: {
          name: 'star',
          backgroundColor: 'bg-yellow',
          color: 'text-blue',
        },
        ...(typeof highlightProp === 'object' ? highlightProp : {}),
      }
    : undefined;

  return (
    <View style={tw`w-full`}>
      {isMultiple && (
        <>
          <View
            style={[
              tw`rounded-xl bg-green-710 opacity-30 w-full h-full absolute top-0 left-0`,
              { transform: [{ rotate: '-5deg' }] },
            ]}
          />
          <View
            style={[
              tw`rounded-xl bg-green-700 opacity-50 w-full h-full absolute top-0 left-0`,
              { transform: [{ rotate: '5deg' }] },
            ]}
          />
        </>
      )}
      {book && (
        <>
          <View
            style={[
              tw`bg-white border-2 border-green-600 absolute w-full h-full top-0 -right-2 rounded-xl`,
            ]}
          />
          <View
            style={[
              tw`border border-purple-400 opacity-60 absolute w-full top-0 bottom-0 -right-1 rounded-xl`,
            ]}
          />
          <View
            style={[
              tw`border border-purple-400 opacity-60 absolute w-full top-0 bottom-0 -right-0.5 rounded-xl`,
            ]}
          />
        </>
      )}
      <AspectRatioContainer
        aspectRatio={aspectRatio}
        style={[
          tw`rounded-xl bg-white overflow-hidden`,
          tw.style(highlight && `border-2 ${highlight.borderColor}`),
        ]}
      >
        <Image style={tw`absolute w-full h-full`} source={imageSource} />
        <View style={tw`flex flex-col-reverse w-full h-full`}>
          {/* @todo text overflows */}
          <View style={tw`bg-white px-4 py-2 w-full`}>
            <Inline>
              <GapSpacer direction="row" spacing="xs">
                {icon && <TextVariant variant="h5">{icon}</TextVariant>}
                <TextVariant variant="h5">{title}</TextVariant>
              </GapSpacer>
              <Fill />
              <TextVariant variant="meta">{meta}</TextVariant>
            </Inline>
            {subtitle && (
              <TextVariant variant="xs" numberOfLines={1}>
                {subtitle}
              </TextVariant>
            )}
          </View>
          <Fill>
            <Inline style={tw`h-full`}>
              <Fill style={tw`items-center justify-center`}>
                {playButton && (
                  <Button variant="secondary" style={tw`p-0`}>
                    <Icon name="play" />
                  </Button>
                )}
              </Fill>
              {isMultiple && (
                <View
                  style={tw`h-full min-w-[90px] justify-center items-center bg-blue opacity-60`}
                >
                  <TextVariant style={tw`text-white font-sans-bold z-10 text-3xl`}>
                    {itemCount}
                  </TextVariant>
                  <Icon name="playlist" color="white" size={28} />
                </View>
              )}
            </Inline>
          </Fill>
          {highlight?.icon && (
            <View
              style={[
                tw`rounded-br-xl absolute top-0 left-0 h-11 w-11 bg-yellow items-center justify-center`,
                tw.style(highlight.icon.backgroundColor),
              ]}
            >
              <Icon
                name={highlight.icon.name}
                style={tw.style(highlight.icon.color || 'text-blue')}
              />
            </View>
          )}
        </View>
        {locked && (
          <View
            style={tw`absolute top-0 left-0 w-full h-full bg-blue bg-opacity-50 items-center justify-center`}
          >
            <Button style={tw`p-0`} size="lg">
              <Icon name="lock" size={32} style={tw`leading-8`} />
            </Button>
          </View>
        )}
      </AspectRatioContainer>
    </View>
  );
};
