function pad(n: string, length = 2, character = '0'): string {
  const arr = n.split('');
  if (arr.length >= length) {
    return n;
  }

  return [...new Array(length - arr.length).fill(character), ...arr].join('');
}

export function formatDuration(duration: number | null | undefined): string | null {
  if (duration === null || duration === undefined) {
    return null;
  }

  const minutes = Math.floor(duration / 60);
  const seconds = duration % 60;

  return `${pad(minutes.toString())}:${pad(seconds.toString())}`;
}
