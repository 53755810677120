import { useEffect, useState } from 'react';

const globalState: { canAutoplay: boolean; listeners: (() => void)[] } = {
  canAutoplay: false,
  listeners: [],
};

const events = ['click', 'mousedown', 'mouseup'];
const handleInteraction = () => {
  globalState.canAutoplay = true;
  globalState.listeners.forEach((l) => l());
};
events.forEach((event) => {
  window.addEventListener(event, handleInteraction);
});

export function useCanAutoplay(): boolean {
  const [canAutoplay, setCanAutoplay] = useState(globalState.canAutoplay);

  useEffect(() => {
    const handler = () => {
      setCanAutoplay(globalState.canAutoplay);
    };

    globalState.listeners.push(handler);
    return () => {
      globalState.listeners.splice(globalState.listeners.indexOf(handler), 1);
    };
  });

  return canAutoplay;
}
