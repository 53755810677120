import React from 'react';
import { defaultScreenOptions, fadeScreenOptions, MediaRoutes, MediaStack } from './index';
import { MediaOverviewPage } from '~/pages/media/overview.page';
import { MediaSearchPage } from '~/pages/media/search.page';
import { MediaDetailPage } from '~/pages/media/detail.page';
import { BrandsOverviewPage } from '~/pages/media/brands.page';
import { DownloadsPage } from '~/pages/media/downloads.page';
import { FavoritesPage } from '~/pages/media/favorites.page';
import { useForceUnboarding } from '~/features/guards/force-onboarding';
import { OverviewSeriePage } from '~/pages/media/overview-serie.page';

const MediaNavigator: React.FC = () => {
  const allowed = useForceUnboarding();

  if (!allowed) return null;

  return (
    <MediaStack.Navigator screenOptions={defaultScreenOptions}>
      <MediaStack.Screen name={MediaRoutes.Overview} component={MediaOverviewPage} />
      <MediaStack.Screen name={MediaRoutes.SerieOverview} component={OverviewSeriePage} />
      <MediaStack.Screen name={MediaRoutes.Brands} component={BrandsOverviewPage} />
      <MediaStack.Screen name={MediaRoutes.Favorites} component={FavoritesPage} />
      <MediaStack.Screen name={MediaRoutes.Downloads} component={DownloadsPage} />
      <MediaStack.Screen
        name={MediaRoutes.MediaDetail}
        component={MediaDetailPage}
        options={fadeScreenOptions}
      />
      <MediaStack.Screen name={MediaRoutes.Search} component={MediaSearchPage} />
    </MediaStack.Navigator>
  );
};

export default MediaNavigator;
