const { tint } = require('polished');
const colors = require('tailwindcss/colors');

// dummy template literal to enable tooling
const tw = (n) => n;

module.exports = {
  custom: {
    buttonSizes: {
      sm: {
        container: tw`rounded-full py-1 px-3 h-8 min-w-8 justify-center items-center`,
        text: tw`text-sm font-sans`,
        decoration: tw`text-xs`,
      },
      md: {
        container: tw`rounded-full py-1 px-3 h-8 min-w-8 tablet:py-2 tablet:px-6 tablet:h-12 tablet:min-w-12 justify-center items-center`,
        text: tw`text-sm tablet:text-lg`,
        decoration: tw`text-xs tablet:text-md`,
      },
      lg: {
        container: tw`rounded-full py-2 px-6 h-12 min-w-12 tablet:py-2 tablet:px-8 tablet:h-16 tablet:min-w-16 justify-center items-center`,
        text: tw`text-xl`,
        decoration: tw`text-lg`,
      },
      'box-md': {
        container: tw`justify-center items-center`,
        text: tw`text-sm tablet:text-lg`,
        decoration: tw`text-xs tablet:text-md`,
      },
    },
    buttonVariants: {
      primary: {
        container: {
          default: tw`bg-white`,
          hovered: tw`bg-white bg-opacity-80`,
          pressed: tw`bg-white bg-opacity-90`,
        },
        text: {
          default: tw`font-sans-bold text-blue`,
        },
        decoration: {
          default: tw`font-sans-bold text-blue`,
        },
      },
      submit: {
        container: {
          default: tw`bg-yellow bg-opacity-100`,
          hovered: tw`bg-yellow bg-opacity-80`,
          pressed: tw`bg-yellow bg-opacity-90`,
        },
        text: {
          default: tw`font-sans-bold text-blue`,
        },
        decoration: {
          default: tw`font-sans-bold text-blue`,
        },
      },
      transparent: {
        container: {
          default: tw``,
          hovered: tw`bg-white bg-opacity-10`,
          pressed: tw`bg-white bg-opacity-20`,
        },
        text: {
          default: tw`font-sans text-white`,
        },
        decoration: {
          default: tw`font-sans-bold text-blue`,
        },
      },
      textLink: {
        container: {
          default: tw`border-b-2 border-white`,
          hovered: tw`bg-white bg-opacity-10`,
          pressed: tw`bg-white bg-opacity-20`,
        },
        text: {
          default: tw`font-sans text-white`,
        },
        decoration: {
          default: tw`font-sans-bold text-white`,
        },
      },
      background: {
        container: {
          default: tw`bg-green bg-opacity-90`,
          hovered: tw`bg-white bg-opacity-10`,
          pressed: tw`bg-green-400`,
        },
        text: {
          default: tw`font-sans text-white`,
        },
        decoration: {
          default: tw`font-sans-bold text-blue`,
        },
      },
      secondary: {
        container: {
          default: tw`bg-blue bg-opacity-60`,
          hovered: tw`bg-blue bg-opacity-80`,
          pressed: tw`bg-blue bg-opacity-90`,
        },
        text: {
          default: tw`font-sans-bold text-white`,
        },
        decoration: {
          default: tw`font-sans-bold text-white`,
        },
      },
      danger: {
        container: {
          default: tw`bg-red bg-opacity-60`,
          hovered: tw`bg-red bg-opacity-80`,
          pressed: tw`bg-red bg-opacity-90`,
        },
        text: {
          default: tw`font-sans-bold text-white`,
        },
        decoration: {
          default: tw`font-sans-bold text-white`,
        },
      },
    },

    coinVariants: {
      default: {
        container: tw`bg-blue`,
        icon: tw`text-white`,
      },
      yellow: {
        container: tw`bg-yellow`,
        icon: tw`text-blue`,
      },
      green: {
        container: tw`bg-green`,
        icon: tw`text-blue`,
      },
      red: {
        container: tw`bg-red`,
        icon: tw`text-blue`,
      },
      mint: {
        container: tw`bg-mint`,
        icon: tw`text-blue`,
      },
      blue: {
        container: tw`bg-blue-600`,
        icon: tw`text-blue`,
      },
      orange: {
        container: tw`bg-orange`,
        icon: tw`text-blue`,
      },
      pink: {
        container: tw`bg-pink`,
        icon: tw`text-blue`,
      },
      white: {
        container: tw`bg-white`,
        icon: tw`text-blue`,
      },
      transparent: {
        container: tw``,
        icon: tw`text-blue`,
      },
    },

    aspectRatio: {
      landscape: 265 / 185,
      square: 1,
      portrait: 265 / 380,
      portrait_tall: 170 / 265,
    },

    textVariants: {
      'modal-title': tw`font-sans-black text-4xl tablet:text-4xl text-blue pt-1`,
      'page-title': tw`font-sans-black text-4xl tablet:text-6xl text-blue pt-1`,
      'swimlane-title': tw`font-sans-bold text-xl text-white`,
      h1: tw`font-sans-black text-2xl tablet:text-4xl text-white`,
      h2: tw`font-sans-black text-xl tablet:text-2xl text-white`,
      h3: tw`font-sans-black text-md text-white`,
      h4: tw`font-sans-black text-sm text-blue`,
      h5: tw`font-sans-black text-xs text-blue`,
      large: tw`font-sans text-xl text-blue`,
      list: tw`font-sans text-lg tablet:text-xl text-blue`,
      settings: tw`font-sans text-lg text-blue`,
      body: tw`font-sans text-lg text-blue`,
      small: tw`font-sans text-sm text-blue`,
      xs: tw`font-sans text-xs text-blue`,
      meta: tw`font-sans text-xs text-purple opacity-80`,
      error: tw`font-sans text-xs text-red opacity-80`,
    },

    gradients: {
      proximus: {
        colors: ['proximus-secondary', 'proximus-primary'],
      },
      'fade-to-white': {
        colors: ['white-transparent', 'white'],
      },
      'fade-to-black': {
        colors: ['[rgba(0,0,0,0)]', 'black-999'],
      },
    },
  },

  theme: {
    colors: {
      ...colors,

      black: {
        DEFAULT: '#2A0229', // aka secondary/black
        999: '#000',
      },
      purple: {
        DEFAULT: '#6C58DF', // aka branding/purple
        400: '#A7A1CE',
        600: '#B7B1D5', // aka secondary/purple
        700: tint(0.7, '#6C58DF'), // #d2ccf5
        800: tint(0.8, '#6C58DF'), // #e1ddf8
        900: tint(0.9, '#6C58DF'), //#f0eefb
      },
      proximus: {
        DEFAULT: '#5e328b',
        primary: '#5e328b',
        secondary: '#4770b2',
      },
      blue: {
        DEFAULT: '#362C81', // aka branding/blue
        600: '#0AAAE2', // aka secondary/blue
      },
      green: {
        DEFAULT: '#85C642', // aka branding/green
        200: '#C0E097',
        400: '#7EB93D',
        450: '#70AC31',
        600: '#5F8B31',
        700: '#4B7321',
        710: '#416719',
        splash: '#82c523',
      },
      yellow: {
        DEFAULT: '#EFDF00', // aka branding/yellow
      },
      darkorange: {
        DEFAULT: '#FF501A', // aka secondary/darkorange
      },
      mint: {
        DEFAULT: '#08BAB1', // aka secondary/mint
      },
      orange: {
        DEFAULT: '#F4761E', // aka secondary/orange
      },
      pink: {
        DEFAULT: '#EF4F99', // aka secondary/pink
      },
      red: {
        DEFAULT: '#EE364E', // aka secondary/red
      },
      gray: {
        100: '#f4f4f4',
        200: '#e6e6ec',
        500: '#676767',
        700: '#343434',
      },
      white: {
        DEFAULT: '#FFFFFF', // aka secondary/white
        transparent: 'rgba(255,255,255,0)',
      },
    },

    fontFamily: {
      sans: 'MikadoRegular',
      'sans-italic': 'MikadoRegular-Italic',
      'sans-bold': 'MikadoBold',
      'sans-black': 'MikadoBlack',
    },

    fontSize: {
      '3xs': ['0.5625rem', { lineHeight: '0.6875rem' }],
      '2xs': ['0.6875rem', { lineHeight: '0.75rem' }],
      xs: ['0.75rem', { lineHeight: '1rem' }],
      sm: ['0.875rem', { lineHeight: '1.25rem' }],
      md: ['1rem', { lineHeight: '1.5rem' }],
      base: ['1rem', { lineHeight: '1.5rem' }],
      lg: ['1.125rem', { lineHeight: '1.75rem' }],
      xl: ['1.25rem', { lineHeight: '1.75rem' }],
      '2xl': ['1.5rem', { lineHeight: '2rem' }],
      '3xl': ['1.875rem', { lineHeight: '2.25rem' }],
      '4xl': ['2.25rem', { lineHeight: '2.5rem' }],
      '5xl': ['3rem', { lineHeight: '3.25rem' }],
      '6xl': ['3.75rem', { lineHeight: '4rem' }],
      '7xl': ['4.5rem', { lineHeight: '5rem' }],
      '8xl': ['6rem', { lineHeight: '6.25rem' }],
      '9xl': ['8rem', { lineHeight: '8.25rem' }],
    },

    screens: {
      sm: '380px',
      md: '420px',
      lg: '680px',

      tablet: '1000px',
      wide: '1400px',
    },

    extend: {
      // Only for intellisense
      screens: {
        web: { raw: 'web' },
        ios: { raw: 'ios' },
        android: { raw: 'android' },
        landscape: { raw: 'landscape' },
        portrait: { raw: 'portrait' },
      },

      gap: {
        xs: 5,
        sm: 10,
        md: 20,
        lg: 25,
        xl: 30,
      },
    },
  },
};
