import React from 'react';
import { createIconSetFromIcoMoon } from 'react-native-vector-icons';
import type { IconProps as VectorIconProps } from 'react-native-vector-icons/Icon';

import icoMoonConfig from './selection';

export type IconName = (typeof icoMoonConfig.icons)[number]['properties']['name'];
// @todo missing icons
type MissingIcons = 'exit-fullscreen';

export interface IconProps extends VectorIconProps {
  name: IconName | MissingIcons;
  /**
   * The size of the icon, inherits font-size from parent text, 14px if no text parent exists.
   * @default undefined
   */
  size?: number;
}

const IcomoonIcon = createIconSetFromIcoMoon(
  icoMoonConfig,
  'symbols-studio100go',
  'symbols-studio100go.ttf',
) as unknown as React.ComponentType<VectorIconProps>;

export const Icon: React.FC<IconProps> = ({ name, size, style: providedStyles, ...props }) => {
  // icon size has priority over font-size if present
  const style = size
    ? [providedStyles, { fontSize: size }]
    : [{ fontSize: undefined }, providedStyles];

  return <IcomoonIcon name={name} size={size} style={style} {...props} />;
};
