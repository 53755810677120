import React, { FunctionComponent } from 'react';
import { View, Modal as RNModal, StyleProp, ViewStyle, ModalBaseProps } from 'react-native';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import tw from '~/shared/theme';
import { CenteredLayout } from '~/widgets/centered-layout';
import { Coin } from '../menu';
import { PressableBounce } from '../pressables';

interface ModalProps {
  onRequestClose?: ModalBaseProps['onRequestClose'];
  visible?: boolean;
  contentContainerStyle?: StyleProp<ViewStyle>;
  avoidKeyboard?: boolean;
  children?: React.ReactNode;
}

export const Modal: FunctionComponent<ModalProps> = ({
  visible = true,
  onRequestClose,
  children,
  avoidKeyboard,
  contentContainerStyle = tw`max-w-2xl w-full`,
}) => {
  return (
    <RNModal
      statusBarTranslucent
      transparent
      visible={visible}
      onRequestClose={onRequestClose}
      supportedOrientations={['landscape']}
    >
      <SafeAreaProvider style={tw`w-full h-full flex`}>
        <View style={tw`bg-blue opacity-90 w-full h-full absolute top-0 left-0`} />
        <CenteredLayout
          avoidKeyboard={avoidKeyboard}
          contentContainerStyle={contentContainerStyle}
          contentOffset={{ x: 0, y: 0 }}
          decorationTopLeft={<></>} // remove if you want the logo
        >
          {/*
          NOTE: close button goes first because react-native-web attempts to focus the first interactive element,
          which can cause the modal to scroll down when you do not want it to.
          */}
          {onRequestClose && (
            <PressableBounce
              style={[
                tw`absolute rounded-full -right-4 -top-4 tablet:-right-6 tablet:-top-6 z-10`,
                // because z-index does not seem to work on android
                // https://github.com/facebook/react-native/issues/32196
                { elevation: 3 },
              ]}
              onPress={onRequestClose}
            >
              <Coin coinVariant={'red'} icon="close" />
            </PressableBounce>
          )}
          {children}
        </CenteredLayout>
      </SafeAreaProvider>
    </RNModal>
  );
};
