import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { MediaSwimlaneItem } from '~/features/media/swimlane-item';
import tw from '~/shared/theme';
import { Gap, GapSpacer, Inline } from '~/shared/ui/spacing';
import { FeedProps } from './feed';
import { Dimensions, Platform, View } from 'react-native';
import { chunkArray } from '~/shared/utils/chunk-array';
import { IFeedContent } from '~/types';
import { BrandThumbnail } from '~/shared/ui/brand-thumbnail';

const Filler: FunctionComponent<{ count: number }> = ({ count }) => {
  if (count === 0) {
    return null;
  }
  const items = Array(count)
    .fill(0)
    .map((_, index) => index);

  return (
    <>
      {items.map((item, index) => (
        <React.Fragment key={`filler-${item}`}>
          {index !== items.length - 1 && <Gap direction="row" />}
          <View style={tw`flex-1`} />
        </React.Fragment>
      ))}
    </>
  );
};

export const FeedLayoutGrid: FunctionComponent<FeedProps> = ({ feed, onItemPress }) => {
  const calculateColumnCount = () =>
    Math.max(3, Math.floor((Dimensions.get('window').width - 40) / 300));

  const [columnCount, setColumnCount] = useState(calculateColumnCount);

  const columns = useMemo((): IFeedContent[][] => {
    return chunkArray(feed.result?.items || [], columnCount);
  }, [feed, columnCount]);

  useEffect(() => {
    if (Platform.OS !== 'web') return;

    const handleResize = () => setColumnCount(calculateColumnCount);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <View>
      <GapSpacer>
        {columns.map((column, columnIndex) => {
          return (
            <Inline spacing="md" key={columnIndex}>
              {column.map((item, itemIndex) => {
                return (
                  <View style={tw`flex-1`} key={`${item.id}-${itemIndex}`}>
                    {item.__typename === 'Brand' ? (
                      <BrandThumbnail
                        variant="tile"
                        label={item.name}
                        source={{ uri: item.images?.medium?.source }}
                        onPress={() => onItemPress(item)}
                      />
                    ) : (
                      <MediaSwimlaneItem
                        item={item}
                        width={'100%'}
                        onPress={() => onItemPress(item)}
                        tileType={feed.itemTileType}
                      />
                    )}
                  </View>
                );
              })}
              {columnIndex === columns.length - 1 && columnCount - column.length > 0 && (
                <Filler count={columnCount - column.length} />
              )}
            </Inline>
          );
        })}
      </GapSpacer>
    </View>
  );
};
