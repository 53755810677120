import { StackScreenProps } from '@react-navigation/stack';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { MediaRoutes, MediaStackParamList } from '~/navigation';
import tw from '~/shared/theme';
import { Button } from '~/shared/ui/button';
import { Icon } from '~/shared/ui/icon';
import { ScrollingMenuBar } from '~/shared/ui/menu';
import { Fill } from '~/shared/ui/spacing';
import { TextVariant } from '~/shared/ui/text-variant';
import { BaseLayout } from '~/widgets/base-layout';
import { MainMenu } from '~/widgets/menu/main-menu';
import { UserMenu } from '~/widgets/menu/user-menu';

export const MediaOfflinePage: FunctionComponent<
  StackScreenProps<
    MediaStackParamList,
    MediaRoutes.Favorites | MediaRoutes.Overview | MediaRoutes.Brands | MediaRoutes.Search
  >
> = ({ navigation }) => {
  const { t } = useTranslation();

  return (
    <BaseLayout
      decorationTop={
        <ScrollingMenuBar>
          <MainMenu />
          <Fill />
          <UserMenu />
        </ScrollingMenuBar>
      }
    >
      <View style={tw`mt-6`}>
        <View style={tw`bg-black bg-opacity-5 self-center flex items-center p-20 rounded-xl`}>
          <Icon name="no-connection" style={tw`text-8xl text-white `} />
          <TextVariant variant="h1" style={tw`text-center mt-10`}>
            {t('noInternetConnectionErrorMessage')}
          </TextVariant>
          <Button
            decorationLeft={<Icon name="downloads" />}
            decorationRight={<Icon name="downloads" />}
            style={tw`self-center mt-5`}
            variant="submit"
            onPress={() => {
              navigation.navigate(MediaRoutes.Downloads);
            }}
          >
            {t('noInternetConnectionGotoDownloadsButtonLabel')}
          </Button>
        </View>
      </View>
    </BaseLayout>
  );
};
