import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import tw from '~/shared/theme';
import { Button } from '../button';
import { Icon, IconName } from '../icon';
import { TextVariant } from '../text-variant';

export interface PageErrorProps {
  icon?: IconName;
  title?: string;
  message?: string;
  retryButton?: { onPress(): void; disabled?: boolean; label?: string };
  children?: React.ReactNode;
}

export const PageError: FunctionComponent<PageErrorProps> = (props) => {
  const { t } = useTranslation();
  const {
    icon,
    title = t('generalErrorTitle'),
    message = t('generalErrorMessage'),
    children,
    retryButton,
  } = props;
  return (
    <View style={tw`flex-grow self-center p-5 tablet:p-10`}>
      <TextVariant variant="page-title" style={tw`text-center max-w-2xl`}>
        {icon && <Icon name={icon} />}
        {title}
      </TextVariant>
      <TextVariant variant="h1" style={tw`text-center max-w-2xl font-sans-bold`}>
        {message}
      </TextVariant>
      {retryButton && (
        <Button
          onPress={retryButton.onPress}
          disabled={retryButton.disabled}
          style={tw`self-center mt-5`}
        >
          {retryButton.label || t('generalRetryButtonLabel')}
        </Button>
      )}
      {children}
    </View>
  );
};
