import { FunctionComponent, useState } from 'react';
import { ProfileFinalState } from '../index';
import { ProfileEditingState, ProfileUpsertStepProps } from '../types';

export function useProfileUpsert({
  steps,
  defaultState,
  onFinish,
  onPrevious,
}: {
  steps: FunctionComponent<ProfileUpsertStepProps>[];
  defaultState?: ProfileEditingState;
  onFinish(state: ProfileFinalState): void;
  onPrevious?(): void;
}): {
  activeStep: FunctionComponent<ProfileUpsertStepProps>;
  activeStepIndex: number;
  state: ProfileEditingState;
  stepProps: ProfileUpsertStepProps;
  previousStepEnabled: boolean;
  onPreviousStep(): void;
  nextStepEnabled: boolean;
  onNextStep(force?: boolean): void;
  finalNextStep: boolean;
} {
  const [state, setState] = useState<ProfileEditingState>({
    name: defaultState?.name || '',
    age: defaultState?.age || 8,
    avatar: defaultState?.avatar || null,
    favoriteBrands: defaultState?.favoriteBrands || [],
  });

  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [isStepValid, setIsStepValid] = useState(false);

  const onNextStep = (force?: boolean) => {
    if (!force && !isStepValid) return;
    if (finalNextStep) {
      onFinish(state as ProfileFinalState);
      return;
    }

    setIsStepValid(false);
    setActiveStepIndex(activeStepIndex + 1);
  };

  const finalNextStep = activeStepIndex === steps.length - 1;

  return {
    activeStep: steps[activeStepIndex],
    activeStepIndex,
    state,
    stepProps: {
      state,
      onChange(fn, { isValid, shouldContinue }) {
        if (fn) {
          setState(fn);
        }
        if (shouldContinue) {
          onNextStep(true);
          return;
        }
        setIsStepValid(isValid);
      },
    },
    previousStepEnabled: !!onPrevious || activeStepIndex > 0,
    onPreviousStep: () => {
      if (activeStepIndex === 0) {
        onPrevious?.();
        return;
      }

      setIsStepValid(false);
      setActiveStepIndex(activeStepIndex - 1);
    },
    nextStepEnabled: isStepValid,
    onNextStep,
    finalNextStep,
  };
}
