import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NetworkStatus } from '~/types';

export interface IRetryQuery {
  disabled: boolean;
  onPress(): void;
  label: string;
}

export function useRetryQuery<Refetch extends () => unknown | Promise<unknown>>({
  networkStatus,
  refetch,
  fakeLoadingDuration = 3000,
}: {
  fakeLoadingDuration?: number;
  networkStatus: NetworkStatus;
  refetch: Refetch;
}): {
  networkStatus: NetworkStatus;
} & IRetryQuery {
  const { t } = useTranslation();
  const [fakeLoading, setFakeLoading] = useState(false);
  const fakeNextRef = useRef(false);
  const fakeLoadingTimeout = useRef<NodeJS.Timeout | undefined>();

  const disabled = networkStatus < NetworkStatus.error;

  useEffect(() => {
    return () => clearTimeout(fakeLoadingTimeout.current);
  }, []);

  return {
    networkStatus: fakeLoading ? NetworkStatus.loading : networkStatus,
    disabled,
    onPress: () => {
      if (fakeLoading) return;

      if (!fakeNextRef.current) {
        fakeNextRef.current = true;
        return refetch();
      }

      clearTimeout(fakeLoadingTimeout.current);
      setFakeLoading(true);
      fakeNextRef.current = false;
      fakeLoadingTimeout.current = setTimeout(() => {
        setFakeLoading(false);
      }, fakeLoadingDuration);

      refetch();
    },
    label: disabled ? t('loading') : t('generalRetryButtonLabel'),
  };
}
