import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { View, Animated } from 'react-native';
import { AnimatedImage, ImageSourcePropType } from '../image';
import tw, { config } from '~/shared/theme';

interface AnimatedCoinProps {
  imageSources: ImageSourcePropType[];
  transitionDelay?: number;
}

export const AnimatedCoin: FunctionComponent<AnimatedCoinProps> = ({
  imageSources,
  transitionDelay = 3000,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const activeAnimState = useRef(-1);
  const transitionAnim = useRef(new Animated.Value(-1)).current;

  const activeImage = imageSources[activeIndex];
  const nextImage = imageSources[(activeIndex + 1) % imageSources.length];

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    function next() {
      if (activeAnimState.current === 1) {
        transitionAnim.setValue(-1);
      }
      const nextIndex = activeAnimState.current === 0 ? 1 : 0;
      Animated.spring(transitionAnim, { toValue: nextIndex, useNativeDriver: true }).start(() => {
        setActiveIndex((prevIndex) => (prevIndex + 1) % imageSources.length);
        transitionAnim.setValue(-1);
        activeAnimState.current = -1;
        timeout = setTimeout(() => next(), transitionDelay);
      });
      activeAnimState.current = nextIndex;
    }

    next();

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [transitionAnim, imageSources.length, transitionDelay]);

  return (
    <View style={[tw`w-10 h-10 tablet:w-15 tablet:h-15 -m-1 tablet:-my-1.5 rounded-full relative`]}>
      <AnimatedImage
        style={[
          tw`w-full h-full absolute`,
          {
            shadowColor: config.theme.colors.gray[500],
            shadowOffset: {
              width: 0,
              height: 2,
            },
            shadowOpacity: 0.3,
            shadowRadius: 1,
            elevation: 1,
            opacity: transitionAnim.interpolate({
              inputRange: [-1, 0, 1],
              outputRange: [0, 1, 0],
            }),
            transform: [
              {
                scale: transitionAnim.interpolate({
                  inputRange: [-1, 0, 1],
                  outputRange: [0, 1, 0],
                }),
              },
              {
                rotate: transitionAnim.interpolate({
                  inputRange: [-1, 0, 1],
                  outputRange: ['-90deg', '0deg', '-90deg'],
                }),
              },
            ],
          },
        ]}
        source={nextImage}
      />
      <AnimatedImage
        style={[
          tw`w-full h-full absolute`,
          {
            shadowColor: config.theme.colors.gray[500],
            shadowOffset: {
              width: 0,
              height: 2,
            },
            shadowOpacity: 0.3,
            shadowRadius: 1,
            elevation: 1,
            opacity: transitionAnim.interpolate({
              inputRange: [-1, 0, 1],
              outputRange: [1, 0, 1],
            }),
            transform: [
              {
                scale: transitionAnim.interpolate({
                  inputRange: [-1, 0, 1],
                  outputRange: [1, 0, 1],
                }),
              },
            ],
          },
        ]}
        source={activeImage}
      />
    </View>
  );
};
