import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { useProximus } from '~/entities/proximus';
import { MediaRoutes } from '~/navigation';
import tw from '~/shared/theme';
import { AdvantageList } from '~/shared/ui/advantage-list';
import { ProximusButton } from '~/shared/ui/button';
import { Card, CardHeader } from '~/shared/ui/card';
import { Gradient } from '~/shared/ui/gradient';
import { ImageBackground, ImageSourcePropType } from '~/shared/ui/image';
import { Logo } from '~/shared/ui/logo';
import { Modal } from '~/shared/ui/modal';
import { Gap, GapSpacer } from '~/shared/ui/spacing';
import { TextVariant } from '~/shared/ui/text-variant';
import { MediaType } from '~/types';

const goPassBackground: Record<string, ImageSourcePropType> = {
  nl: require('~/assets/images/onboarding-go-pass-bg-nl.png'),
  fr: require('~/assets/images/onboarding-go-pass-bg-fr.png'),
};

interface ProximusGateModalProps {}

export const ProximusGateModal: FunctionComponent<ProximusGateModalProps> = () => {
  const { t, i18n } = useTranslation();
  const { activeRequest, dismissRequest, openLogin } = useProximus();
  const onPressLoginButton = () => {
    dismissRequest();
    openLogin({
      screen:
        activeRequest?.media.__typename === MediaType.Serie
          ? MediaRoutes.SerieOverview
          : MediaRoutes.MediaDetail,
      params: { id: activeRequest?.media.id },
    });
  };

  return (
    <Modal
      visible={!!activeRequest}
      onRequestClose={dismissRequest}
      contentContainerStyle={tw`pb-0 pt-8 wide:px-12 wide:pb-8 max-w-6xl items-stretch`}
    >
      <ImageBackground
        source={goPassBackground[i18n.language]}
        style={tw`w-full p-8 tablet:p-12 rounded-tl-xl rounded-tr-xl tablet:rounded-xl overflow-hidden`}
      >
        <TextVariant variant="page-title" style={tw`text-center`}>
          {t('proximusOverlayLockedContentTitle')}
        </TextVariant>
        <Gap spacing="sm" />
        <TextVariant variant="body" style={tw`text-white text-center`}>
          {t('proximusOverlayLockedContentDescription')}
        </TextVariant>
        <Gap />

        <View style={tw`items-stretch justify-center tablet:flex-row`}>
          <GapSpacer direction="both">
            <Card style={tw`tablet:flex-1 wide:max-w-xl`}>
              <CardHeader style={tw`bg-proximus`}>
                <>
                  <Gradient
                    variant="proximus"
                    absoluteFill
                    style={tw`rounded-tl-md rounded-tr-md`}
                  />
                  <Logo variant="pass" style={tw`h-20 tablet:h-32`} />
                </>
                <TextVariant variant="h3" style={tw`text-center z-10`}>
                  {t('goPassForProximusCustomers')}
                </TextVariant>
              </CardHeader>

              <AdvantageList
                advantages={t('proximusOverlayAdvantages', { returnObjects: true }) as string[]}
              />
            </Card>
            <Card style={tw`p-4 items-center justify-center tablet:max-w-2/5`}>
              <TextVariant variant="modal-title" style={tw`text-center`}>
                {t('goPassAlreadySubscribedTitle')}
              </TextVariant>
              <Gap spacing="sm" />
              <TextVariant variant="body" style={tw`text-center`}>
                {t('goPassAlreadySubscribedText')}
              </TextVariant>
              <Gap />
              <ProximusButton onPress={onPressLoginButton} size="lg">
                {t('goPassLoginWithProximus')}
              </ProximusButton>
            </Card>
          </GapSpacer>
        </View>
      </ImageBackground>
    </Modal>
  );
};
