import React, { FunctionComponent } from 'react';
import { View, ViewProps } from 'react-native';
import tw, { config } from '~/shared/theme';
import { AspectRatioContainerProps } from './aspect-ratio-container.component';

export const AspectRatioContainer: FunctionComponent<AspectRatioContainerProps & ViewProps> = ({
  aspectRatio = 'landscape',
  containerStyle,
  style,
  ...props
}) => {
  const selectedRatio =
    typeof aspectRatio === 'number' ? aspectRatio : config.custom.aspectRatio[aspectRatio] || 1;

  return (
    <View style={[tw`relative`, containerStyle]}>
      <div
        style={{
          paddingTop: `${((1 / selectedRatio) * 100).toFixed(1)}%`,
        }}
      >
        <View style={[tw`absolute inset-0`, style]} {...props} />
      </div>
    </View>
  );
};
