import React, { FunctionComponent } from 'react';
import { StyleProp, useWindowDimensions, ViewStyle } from 'react-native';
import tw from '~/shared/theme';
import { BrandSpacer } from '~/shared/ui/brand-thumbnail';
import { Inline } from '~/shared/ui/spacing';

interface ListBrandsSkeletonProps {
  style?: StyleProp<ViewStyle>;
  children?: React.ReactNode;
}

export const ListBrandsSkeleton: FunctionComponent<ListBrandsSkeletonProps> = ({
  children,
  style,
}) => {
  const itemCount = Math.floor((useWindowDimensions().width / 220) * 3);
  return (
    <Inline spacing="sm" style={tw`flex-wrap justify-center`}>
      {Array(itemCount)
        .fill(0)
        .map((_, i) => (
          <BrandSpacer
            key={i}
            showIndent={i < itemCount * 0.7}
            style={[style, tw`bg-green-400 bg-opacity-80`]}
          >
            {i === 0 && children}
          </BrandSpacer>
        ))}
    </Inline>
  );
};
