import { StackScreenProps } from '@react-navigation/stack';
import React, { FunctionComponent, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, View } from 'react-native';
import { useBrands } from '~/entities/brands/model';
import { useBrandFeeds } from '~/features/media/list-brand-feeds';
import {
  GridPaginationLoader,
  usePaginateFinalGridFeed,
} from '~/features/media/list-paginated-feed';
import { ListRelatedBrands } from '~/features/media/list-related-brands/ui/list-related-brands.component';
import { MediaRoutes, MediaStackParamList } from '~/navigation';
import tw from '~/shared/theme';
import { ImageBackground } from '~/shared/ui/image';
import { ImageOverlay } from '~/shared/ui/image-overlay';
import { MenuBack } from '~/shared/ui/menu';
import { PageError } from '~/shared/ui/page-error';
import { Preloader } from '~/shared/ui/preloader';
import { TextVariant } from '~/shared/ui/text-variant';
import { getBrandBanner } from '~/shared/utils/brands';
import { isFeedListEmpty } from '~/shared/utils/media';
import { useGetImageOrPlaceholder } from '~/shared/utils/use-get-image-or-placeholder';
import { MediaMedium, NetworkStatus } from '~/types';
import { FeedLayout, FeedLayoutSkeleton } from '~/widgets/feed-layout';
import { BrandMenu } from '~/widgets/menu/brand-menu';
import { UserMenu } from '~/widgets/menu/user-menu';
import { MergingMenuLayout } from '~/widgets/merging-menu-layout';

export const OverviewBrandPage: FunctionComponent<
  StackScreenProps<MediaStackParamList, MediaRoutes.Overview>
> = ({ route, navigation }) => {
  const scrollViewRef = useRef<React.ElementRef<typeof ScrollView>>(null);
  const { t, i18n } = useTranslation();
  const { medium, brandSlug } = route.params;
  const brands = useBrands({ language: i18n.language });
  const brand = brands.items.find((b) => b.slug === brandSlug);
  const {
    feeds: initialFeeds,
    networkStatus,
    retryQuery,
  } = useBrandFeeds({ brandId: brand?.id, medium });
  const {
    feeds,
    scrollableProps,
    networkStatus: paginationNetworkStatus,
  } = usePaginateFinalGridFeed({ feeds: initialFeeds });
  const showRelatedBrands = brand && (!medium || medium === MediaMedium.All);

  const imageOrPlaceholder = useGetImageOrPlaceholder({
    placeholder: require('~/assets/images/placeholders/header.jpg'),
    getImageFunction: getBrandBanner,
  });

  const relatedBrands = (
    <ListRelatedBrands
      swimlaneProps={medium === MediaMedium.All ? undefined : { arrowStyle: tw`opacity-0` }}
      relatedTo={brand}
      onBrandPress={(relatedBrandSlug) => {
        navigation.navigate(MediaRoutes.Overview, {
          medium: medium,
          brandSlug: relatedBrandSlug,
        });
      }}
    />
  );

  useEffect(() => {
    scrollViewRef.current?.scrollTo({
      x: 0,
      y: 0,
      animated: false,
    });
  }, [brandSlug]);

  return (
    <MergingMenuLayout
      scrollViewRef={scrollViewRef}
      header={
        <ImageBackground
          source={imageOrPlaceholder(brand)}
          style={{ height: 400, width: '100%' }}
          resizeMode="cover"
        >
          <ImageOverlay>
            <TextVariant variant="page-title" style={tw`text-white text-center`}>
              {brand?.name}
            </TextVariant>
          </ImageOverlay>
        </ImageBackground>
      }
      menuLeft={
        <MenuBack
          label={t('menuItemAllBrands')}
          onPress={() => navigation.navigate(MediaRoutes.Brands)}
        />
      }
      menuCenter={<BrandMenu medium={medium || MediaMedium.All} brandSlug={brandSlug as string} />}
      menuRight={<UserMenu />}
      {...scrollableProps}
    >
      <Preloader
        loading={networkStatus < NetworkStatus.ready}
        error={networkStatus === NetworkStatus.error ? t('generalErrorMessage') : undefined}
        errorRetryButton={retryQuery}
        empty={isFeedListEmpty(feeds)}
        shouldRenderSkeletonWhenEmpty={showRelatedBrands}
        renderEmpty={() => (
          <>
            <View>
              <PageError
                message={t('noResultsFoundErrorMessage', {
                  brand: brand?.name,
                  type: t([`${medium}NothingFoundLabel`, 'allNothingFoundLabel']),
                })}
              />
            </View>
            {!showRelatedBrands && <View style={tw`mx-auto`}>{relatedBrands}</View>}
          </>
        )}
        skeleton={<FeedLayoutSkeleton />}
      >
        {() => feeds && <FeedLayout feeds={feeds} />}
      </Preloader>

      {paginationNetworkStatus === NetworkStatus.loading && <GridPaginationLoader />}

      {showRelatedBrands && relatedBrands}
    </MergingMenuLayout>
  );
};
