import React, { FunctionComponent } from 'react';
import { ButtonProps } from './button.component';
import tw from '~/shared/theme';
import { Button } from './button.component';
import { Gradient } from '../gradient';

interface ProximusButtonProps extends ButtonProps<typeof Gradient> {}

export const ProximusButton: FunctionComponent<ProximusButtonProps> = (props) => {
  return (
    <Button
      variant="secondary"
      style={tw`bg-proximus`}
      ContentWrapper={Gradient}
      contentWrapperProps={{
        variant: 'proximus',
        style: tw`flex items-center flex-row`,
      }}
      {...props}
    />
  );
};
