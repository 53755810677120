import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import tw from '~/shared/theme';
import { BrandThumbnail } from '~/shared/ui/brand-thumbnail';
import { Icon } from '~/shared/ui/icon';
import { Preloader } from '~/shared/ui/preloader';
import { Gap } from '~/shared/ui/spacing';
import { Swimlane } from '~/shared/ui/swimlane';
import { SwimlaneProps } from '~/shared/ui/swimlane/swimlane.component';
import { TextVariant } from '~/shared/ui/text-variant';
import { IBrand, NetworkStatus } from '~/types';
import { useRelatedBrands } from '../index';

interface ListRelatedBrandsProps {
  relatedTo?: IBrand | string[];
  swimlaneProps?: Pick<SwimlaneProps<unknown>, 'arrowStyle'>;
  onBrandPress?(slug: string): void;
}

export const ListRelatedBrands: FunctionComponent<ListRelatedBrandsProps> = ({
  relatedTo,
  onBrandPress,
  swimlaneProps,
}) => {
  const { t } = useTranslation();
  const { brands, networkStatus } = useRelatedBrands({
    relatedToBrandIds: relatedTo
      ? Array.isArray(relatedTo)
        ? relatedTo
        : [relatedTo.id]
      : undefined,
    limit: 6,
  });
  return (
    <Preloader
      loading={networkStatus < NetworkStatus.ready}
      error={networkStatus === NetworkStatus.error ? t('generalErrorMessage') : undefined}
      renderError={() => <></>}
      empty={!brands || brands.length === 0}
      renderEmpty={() => <></>}
    >
      {() => (
        <View style={tw`py-8`}>
          <Gap spacing="xl" />
          <TextVariant variant="swimlane-title">
            <Icon name="heart" style={tw`mr-2`} />{' '}
            {Array.isArray(relatedTo)
              ? t('relatedBrandsTitle')
              : t('relatedBrandsToOneTitle', { brand: relatedTo?.name || '' })}
          </TextVariant>
          <Gap spacing="md" />
          <Swimlane
            arrowStyle={[tw`bg-green-450`, swimlaneProps?.arrowStyle]}
            data={brands}
            renderItem={({ item: { id, images, name, slug } }) => (
              <BrandThumbnail
                key={id}
                variant="tile"
                label={name}
                source={{ uri: images?.medium?.source }}
                onPress={() => onBrandPress?.(slug)}
              />
            )}
          />
        </View>
      )}
    </Preloader>
  );
};
