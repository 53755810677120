import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useActiveProfile } from '~/entities/profiles';
import { Inline } from '~/shared/ui/spacing';
import { LanguageSelectorToggleOption } from './language-selector-option.component';
import { LanguageSelectorTabOption } from './language-selector-tab.component';

const OptionComponents = {
  tabs: LanguageSelectorTabOption,
  toggle: LanguageSelectorToggleOption,
};

interface LanguageSelectorProps {
  variant?: keyof typeof OptionComponents;
}

export const LanguageSelector: FunctionComponent<LanguageSelectorProps> = ({
  variant = 'tabs',
}) => {
  const { i18n } = useTranslation();
  const { setLanguage } = useActiveProfile();
  const Option = OptionComponents[variant];
  const onSelectLanguage = (language: string) => {
    setLanguage?.(language);
    i18n.changeLanguage(language);
  };

  return (
    <Inline>
      <Option locale="nl" onSelect={onSelectLanguage} currentLanguage={i18n.language} isFirst />
      <Option locale="fr" onSelect={onSelectLanguage} currentLanguage={i18n.language} isLast />
    </Inline>
  );
};
