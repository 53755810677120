import React, { FunctionComponent } from 'react';
import { VideoPlayer } from '~/shared/ui/video-player';
import { getMediaPreview, getMediaVideoResource } from '~/shared/utils/media';
import { useMemoVideoSource } from '~/shared/utils/use-memo-video-source';
import { IMediaVideo } from '~/types';

interface VideoMediaDetailProps {
  item: IMediaVideo;
  autoplay?: boolean;
  onEnded?(): void;
  autoFullscreen?: boolean;
  autoFullScreenAfter?: number;
  isFullScreen?: boolean;
  setIsFullScreen?: React.Dispatch<React.SetStateAction<boolean | undefined>>;
}

export const VideoMediaDetail: FunctionComponent<VideoMediaDetailProps> = React.memo(
  ({
    item,
    autoplay,
    autoFullscreen,
    autoFullScreenAfter = 3000,
    onEnded,
    isFullScreen,
    setIsFullScreen,
  }) => {
    // Memoize source objects to prevent quick rerenders of video-player
    // which may cause the custom init script to get lost somewhere
    const coverSourceObject = useMemoVideoSource(getMediaPreview(item)?.source as string);
    const sourceObject = useMemoVideoSource(getMediaVideoResource(item)?.source as string);

    return (
      <VideoPlayer
        autoFullScreen={autoFullscreen}
        autoFullScreenAfter={autoFullScreenAfter}
        onEnded={onEnded}
        autoplay={autoplay}
        coverSource={coverSourceObject}
        source={sourceObject}
        overlays={item.playerOverlays || undefined}
        isFullScreen={isFullScreen}
        setIsFullScreen={setIsFullScreen}
        ageClassification={item.ageClassification}
        contentClassification={item.dangerousContentClassification}
      />
    );
  },
);
