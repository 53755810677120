import { ScaledSize } from 'react-native';
import mimeTypes from 'mime-types';
import {
  IAsset,
  IItemTileType,
  IMedia,
  IBannerSlide,
  IMediaSerie,
  IMediaVideo,
  ItemTileType,
  MediaType,
  VideoContentType,
  IMediaSong,
  IFeed,
} from '~/types';
import { formatDuration } from './formatters/time';

export function prefixLocalUri(uri: string): string {
  return `file://${uri}`;
}

export function getCarouselPoster(
  media?: IBannerSlide,
  highestConversion: 'large' | 'medium' | 'small' = 'large',
): IAsset | null {
  if (highestConversion === 'large') {
    return (
      media?.backgrounds.large || media?.backgrounds.medium || media?.backgrounds.small || null
    );
  }
  if (highestConversion === 'medium') {
    return (
      media?.backgrounds.medium || media?.backgrounds.small || media?.backgrounds.large || null
    );
  }

  return media?.backgrounds.small || media?.backgrounds.medium || media?.backgrounds.large || null;
}

export function isFeedListEmpty(feeds?: IFeed[]): boolean {
  return !feeds || !feeds.find((feed) => (feed.result?.items.length || 0) > 0);
}

export function getMediaPreview(
  media?: IMedia,
  highestConversionPreference: 'large' | 'medium' | 'small' = 'large',
): IAsset | null {
  if (media?.previews?.local) {
    return {
      ...media.previews.local,
      source: prefixLocalUri(media.previews.local.source),
    };
  }

  if (highestConversionPreference === 'large') {
    return media?.previews?.large || media?.previews?.medium || media?.previews?.small || null;
  }
  if (highestConversionPreference === 'medium') {
    return media?.previews?.medium || media?.previews?.small || media?.previews?.large || null;
  }

  return media?.previews?.small || media?.previews?.medium || media?.previews?.large || null;
}

export function getMediaSerieBanner(
  media?: Partial<IMediaSerie>,
  highestConversionPreference: 'large' | 'medium' | 'small' = 'large',
): IAsset | null {
  if (media?.banners?.local) {
    return {
      ...media.banners.local,
      source: prefixLocalUri(media.banners.local.source),
    };
  }

  if (highestConversionPreference === 'large') {
    return media?.banners?.large || media?.banners?.medium || media?.banners?.small || null;
  }
  if (highestConversionPreference === 'medium') {
    return media?.banners?.medium || media?.banners?.small || media?.banners?.large || null;
  }

  return media?.banners?.small || media?.banners?.medium || media?.banners?.large || null;
}

export function getSourceMimeType({ uri, type }: { uri: string; type?: string }): string {
  // if already defined, just return that one
  if (type) {
    return type;
  }
  // remove query and hash from url
  const filename = uri.split(/\?|#/g)[0] as string;
  // lookup mime type based on filename and ultimately fallback to 'application/x-mpegurl'
  return mimeTypes.lookup(filename) || 'application/x-mpegurl';
}

export function getMediaVideoResource(
  media?: IMediaVideo | IMediaSong,
  highestConversionPreference: 'stream' | 'large' | 'medium' | 'small' = 'stream',
): IAsset | null {
  if (media?.resources?.local) {
    return {
      ...media.resources.local,
      source: prefixLocalUri(media.resources.local.source),
    };
  }

  if (highestConversionPreference === 'stream') {
    return (
      media?.resources?.stream ||
      media?.resources?.large ||
      media?.resources?.medium ||
      media?.resources?.small ||
      null
    );
  }
  if (highestConversionPreference === 'large') {
    return media?.resources?.large || media?.resources?.medium || media?.resources?.small || null;
  }
  if (highestConversionPreference === 'medium') {
    return media?.resources?.medium || media?.resources?.small || media?.resources?.large || null;
  }

  return media?.resources?.small || media?.resources?.medium || media?.resources?.large || null;
}

export function getMediaPoster(
  media?: IMediaVideo | IMediaSerie,
  highestConversion: 'large' | 'medium' | 'small' = 'large',
): IAsset | null {
  // @TODO switch based on device?
  if (highestConversion === 'large') {
    return media?.posters?.large || media?.posters?.medium || media?.posters?.small || null;
  }
  if (highestConversion === 'medium') {
    return media?.posters?.medium || media?.posters?.small || media?.posters?.large || null;
  }

  return media?.posters?.small || media?.posters?.medium || media?.posters?.large || null;
}

export function getMediaThumbnail(
  media?: IMedia,
  tileType: IItemTileType = ItemTileType.Landscape,
): IAsset | null {
  if (
    tileType === ItemTileType.Portrait &&
    ((media?.__typename === MediaType.Video && media.videoContentType === VideoContentType.Movie) ||
      media?.__typename === MediaType.Serie)
  ) {
    return getMediaPoster(media, 'medium') || getMediaPreview(media, 'medium');
  }

  return getMediaPreview(media, 'medium');
}

export function getMediaTileTitle(media: IMedia): string {
  return media.tile?.title || '';
}

export function getMediaTileSubTitle(media: IMedia): string | undefined {
  return media.tile?.subTitle || undefined;
}

export function getMediaHasPlayButton(media: IMedia): boolean {
  return media.__typename === MediaType.Video || media.__typename === MediaType.Song;
}

export function getRelatedMediaTileWidth(_media: IMedia, windowDimensions: ScaledSize): number {
  // never take up more than 1/4 of screen height, NEVER!
  const maxHeight = windowDimensions.height / 4;
  const estimatedMaxWidth = maxHeight * 1.4;
  return Math.min(Math.floor(estimatedMaxWidth), 280);
}

export function getDefaultMediaTileWidth(_media: IMedia): number {
  return 280;
}

export function getMediaMeta(media: IMedia): string {
  if ('duration' in media) {
    return formatDuration(media.duration) || '';
  }

  return '';
}
