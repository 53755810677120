import { useEffect, useState } from 'react';
import { IImageDimensions, measureImage } from '~/shared/utils/measure-image';

export async function calculateDimensions(
  pageSource: string,
  maxWidth: number,
  maxHeight: number,
): Promise<{ width: number; height: number }> {
  const { width: pageWidth, height: pageHeight } = await measureImage(pageSource);

  const totalWidth = pageWidth * 2;
  let offset = maxWidth / totalWidth;
  let width = pageWidth * offset;
  let height = pageHeight * offset;

  if (height > maxHeight) {
    height = maxHeight;
    offset = height / pageHeight;
    width = pageWidth * offset;
  }
  return { width: width, height };
}

export function useDimensions({ pages, fullScreen }: { pages: string[]; fullScreen?: boolean }): {
  dimensions: IImageDimensions;
  setLayout(layout: IImageDimensions): void;
} {
  const [layout, setLayout] = useState<IImageDimensions>({ width: 0, height: 0 });
  const [dimensions, setDimensions] = useState<IImageDimensions>({ width: 0, height: 0 });
  const firstPage = pages[0];

  useEffect(() => {
    if (!firstPage) return;
    calculateDimensions(firstPage, layout.width, layout.height).then(setDimensions);
  }, [firstPage, fullScreen, layout]);

  return { dimensions, setLayout };
}
