import React, { FunctionComponent, useEffect, useRef } from 'react';
import { Animated } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import tw from '~/shared/theme';
import { TextVariant } from '~/shared/ui/text-variant';
import { LanguageSelectorOptionProps } from './language-selector.types';

export const LanguageSelectorTabOption: FunctionComponent<LanguageSelectorOptionProps> = ({
  locale,
  currentLanguage,
  onSelect,
}) => {
  const offset = useRef(new Animated.Value(-100)).current;
  const isActive = locale === currentLanguage;

  useEffect(() => {
    Animated.timing(offset, {
      useNativeDriver: true,
      toValue: isActive ? 0 : -100,
      duration: 200,
    }).start();

    return () => offset.stopAnimation();
  }, [offset, isActive]);

  return (
    <TouchableOpacity
      onPress={() => {
        onSelect(locale);
      }}
      style={[tw`pt-4 pb-2 px-3 ml-1 bg-green bg-opacity-50 rounded-bl-full rounded-br-full`]}
    >
      <Animated.View
        style={[
          tw`absolute inset-0 -top-4 bg-white rounded-bl-full rounded-br-full`,
          {
            transform: [
              {
                translateY: offset,
              },
            ],
            shadowOffset: {
              width: 0,
              height: 2,
            },
            shadowRadius: 4,
            shadowColor: tw.color('gray-700'),
            shadowOpacity: 0.3,
            elevation: 4,
          },
        ]}
      />
      <TextVariant
        variant="h4"
        style={[tw`relative`, isActive ? tw`text-blue` : tw`text-white`, { elevation: 5 }]}
      >
        {locale.toUpperCase()}
      </TextVariant>
    </TouchableOpacity>
  );
};
