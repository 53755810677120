export const __migration_brandsMap = {
  nl: [
    {
      id: '026d8ba2-1484-513a-8e22-58eb79f454e6',
      slug: 'maya-nl',
    },
    {
      id: '8730248b-6d0c-52f7-b8dd-7308968c6b3e',
      slug: 'bumba-nl',
    },
    {
      id: '28c05c7b-bcf1-55e1-903f-db9c0318fb96',
      slug: 'wickie-nl',
    },
    {
      id: 'b5dc21ad-bec7-56a0-958a-fad89b85df98',
      slug: 'samson_en_gert-nl',
    },
    {
      id: 'aeecc148-42b4-5e20-bbd8-45b74aa09197',
      slug: 'plop-nl',
    },
    {
      id: '406df96a-209e-5770-aa33-dd3b0ae0b1a8',
      slug: 'heidi-nl',
    },
    {
      id: 'ba79d377-1d6d-55d9-a370-5b1d3650bcbd',
      slug: 'mega_mindy-nl',
    },
    {
      id: '3127ca44-20cb-5302-b45a-dec0fc5a923d',
      slug: 'nachtwacht-nl',
    },
    {
      id: 'c8647669-1a23-57af-80a1-4dbe00da0cc2',
      slug: 'piet_piraat-nl',
    },
    {
      id: '7007941b-56db-422c-9034-c8f99977a5ef',
      slug: 'studio_100_:_elaba-nl',
    },
    {
      id: 'd905bf89-9078-5a00-bb73-9e32b71d5c9c',
      slug: 'k3-nl',
    },
    {
      id: '2badb49e-3bdd-5617-a7cf-3f5c4f6ebfca',
      slug: 'ghost_rockers-nl',
    },
    {
      id: 'efc7a13f-29f1-56a6-9319-153407a0c0a1',
      slug: 'blinky_bill-nl',
    },
    {
      id: '16ca21a4-b06e-5934-a18b-bf10c489627a',
      slug: 'lolly_lolbroek-nl',
    },
    {
      id: '39d253a1-86e4-5de0-9c0a-1c642eee7fdd',
      slug: 'flin_&_flo-nl',
    },
    {
      id: '2cb2d3d8-8dd5-5834-b1af-8652bcb545c9',
      slug: 'abrakodabra-nl',
    },
    {
      id: '65dae602-f5c7-54bd-b8ec-0be5dd0a0f32',
      slug: 'bol_&_smik-nl',
    },
    {
      id: 'd97d7ec8-68ea-5478-bbb3-94b00b688725',
      slug: 'nils_holgersson-nl',
    },
    {
      id: 'e872753b-dd85-58ca-baa9-eca8caf74455',
      slug: 'rox-nl',
    },
    {
      id: '9690f988-ae3e-556f-ab3b-9ed070204013',
      slug: 'wizzy_&_woppy-nl',
    },
    {
      id: '4b1122c0-97ae-5948-b719-d410593d0a9e',
      slug: 'twinipop-nl',
    },
    {
      id: '08704634-5a5d-58eb-821b-811cbd553048',
      slug: 'galaxy_park-nl',
    },
    {
      id: 'dcdca97b-57c9-587b-99cf-7bd0c180dc81',
      slug: 'jabaloe-nl',
    },
    {
      id: '219f5e9b-8f7c-400a-aca0-733e2ecc1afb',
      slug: 'pippi_langkous-nl',
    },
    {
      id: 'f7223793-7271-4883-9ea8-b60d1c1dd76f',
      slug: 'de-sterrenstudio-nl',
    },
    {
      id: '7d57d4e9-8c41-58f8-9bcf-141c567e17bc',
      slug: 'amika-nl',
    },
    {
      id: 'a9355e86-ad68-5a16-bcba-27c60b12e520',
      slug: 'spring-nl',
    },
    {
      id: '67c915e1-5ca8-45a1-8c48-3d230eab2849',
      slug: 'de_liedjestuin-nl',
    },
    {
      id: 'cb134efa-fba0-5b61-944e-d6102c5cc198',
      slug: 'prinsessia-nl',
    },
    {
      id: 'bedb5d13-7c5f-5767-b113-63c2da7659c0',
      slug: 'zigby-nl',
    },
    {
      id: '8e89fae8-ca38-4e15-9629-fd7b981a50bb',
      slug: 'het_huis_anubis-nl',
    },
    {
      id: 'bc65f130-4457-5820-af85-73b70fab4e82',
      slug: 'kosmoo-nl',
    },
    {
      id: 'c27f63db-4b87-49fd-8534-29cc84c554a8',
      slug: 'wissper-nl',
    },
    {
      id: 'fd4d5362-0ed7-433e-8eb0-4f7536037d9a',
      slug: 'campus_12-nl',
    },
    {
      id: '5ca046e8-025b-5a6f-8802-cd8fc757bf58',
      slug: 'big_&_betsy-nl',
    },
    {
      id: '97946c18-fdb0-47cf-8771-04686b3b84fe',
      slug: 'tip_de_muis-nl',
    },
    {
      id: '838a6e31-138c-4056-9b57-ae9fb2d8448c',
      slug: '100%_wolf-nl',
    },
    {
      id: '5f5bb36a-1646-4f8e-98e8-a5cf7f7a48d2',
      slug: 'mia_&_me-nl',
    },
    {
      id: 'a1c35765-4608-5442-8859-f3beddf76541',
      slug: 'dobus-nl',
    },
    {
      id: '46226250-5e4b-495b-be4c-517e780db17f',
      slug: 'nijntje-nl',
    },
    {
      id: '8fdbcd2b-41f7-46f7-ad7b-81f0921f0ffe',
      slug: 'gamekeepers-nl',
    },
    {
      id: 'd961e622-99f6-5bda-a957-9269d7b8321c',
      slug: 'studio_100_tv-nl',
    },
    {
      id: '57770f3c-f454-5676-999b-d43dcdf7f5cc',
      slug: 'musicals-nl',
    },
    {
      id: '45bc1ccc-f625-49dc-93c0-1c79c19638a4',
      slug: 'sinterklaas-nl',
    },
  ],
  fr: [
    {
      id: '7b45db6c-9394-5c5c-a7dc-79cd29fa1a1a',
      slug: 'maya-fr',
    },
    {
      id: 'f1f141fe-6345-58e6-b852-7dd0b45ba01a',
      slug: 'bumba-fr',
    },
    {
      id: '3ab4ab4b-7473-5414-ae05-78cd8cdee097',
      slug: 'vic-fr',
    },
    {
      id: '8dabfc5d-c179-5fb3-aab8-bcd3b1d72db1',
      slug: 'fred_et_samson-fr',
    },
    {
      id: '838636b2-2050-5342-886d-a032c692a2f8',
      slug: 'plop-fr',
    },
    {
      id: '1c3c54a7-3888-5033-b2d4-0b1452599ca5',
      slug: 'heidi-fr',
    },
    {
      id: '2ad5c57b-147c-5ca1-b00a-9aa5d8423806',
      slug: 'mega_mindy-fr',
    },
    {
      id: 'c061c889-e784-582c-a1b8-4a8e8ddbfaff',
      slug: 'garde_de_nuit-fr',
    },
    {
      id: 'a49e6f07-4a69-5d6d-801a-a541d0424e0a',
      slug: 'pat_le_pirate-fr',
    },
    {
      id: '7a9241c4-efa0-5866-b3a9-b66ca883c87c',
      slug: 'rox-fr',
    },
    {
      id: '486273fa-34b9-5132-9937-0ec54fc213fb',
      slug: 'k3-fr',
    },
    {
      id: '78c436b0-d7a9-4088-905f-69a7ca9a688f',
      slug: 'super_stars_studio-fr',
    },
    {
      id: '2d59692c-ed06-5dbb-8f04-45c854444012',
      slug: 'blinky_bill-fr',
    },
    {
      id: '7a49e35a-800c-5b73-968f-a8996e16d5af',
      slug: 'lolly_loustic-fr',
    },
    {
      id: '733d53d7-04a9-5f88-89de-d347fe5df583',
      slug: 'flin_&_flo-fr',
    },
    {
      id: 'b1cac1be-25fd-5706-be1a-cce49847a3b1',
      slug: 'arc-en-ciel-fr',
    },
    {
      id: '03da8abc-00f3-5cfd-8f82-7eac04231b91',
      slug: 'studio_100-fr',
    },
    {
      id: 'dfb71ef5-55dc-5eec-90d1-e40749770d17',
      slug: 'petit_et_costaud-fr',
    },
    {
      id: 'c3c8584e-e95f-5832-afb4-17cc1c82032c',
      slug: 'nils_holgersson-fr',
    },
    {
      id: '944db3de-e68d-41f7-8202-3c5cd49835bd',
      slug: 'le_jardin_des_refrains-fr',
    },
    {
      id: '0b045d14-b547-5a7e-93d9-02b84ca45c32',
      slug: 'wizzy_&_woppy-fr',
    },
    {
      id: '7cfa39f7-bf67-5a6b-aeb2-0684ec0da5f8',
      slug: 'twinipop-fr',
    },
    {
      id: '2be2ce0b-2daf-413f-b06f-e1b710f2a467',
      slug: 'fifi_brindacier-fr',
    },
    {
      id: 'aa7806eb-f176-5b86-9f77-7648735812f5',
      slug: 'zigby-fr',
    },
    {
      id: 'd970f94b-17f7-5435-84bd-3dd415000939',
      slug: 'ftpd-fr',
    },
    {
      id: '82ab7107-9350-44b8-97b4-8970ced5b18f',
      slug: 'wissper-fr',
    },
    {
      id: 'c18cad96-179a-5bc8-a45d-50b501f4d742',
      slug: 'studio_100_tv-fr',
    },
    {
      id: '611f6df8-56c6-4235-8621-06c867982584',
      slug: '100%_loup-fr',
    },
    {
      id: 'c3ee9861-8ef7-4edc-bfbd-6846b3056a59',
      slug: 'Le_monde_selon_Zack-fr',
    },
    {
      id: 'c589e02d-e2ba-4a58-9229-b3ac8b73fd8b',
      slug: 'mia_et_moi-fr',
    },
    {
      id: '4737e026-8176-4109-bf6d-498a0cb10385',
      slug: 'lapitch-fr',
    },
    {
      id: '90a4d414-87d2-5154-b876-18ccc4d4cf61',
      slug: 'flipper-fr',
    },
    {
      id: '2beaa9f8-9972-4efc-b208-1a3e1c4a1c62',
      slug: 'miffy-fr',
    },
    {
      id: '851dbfc5-91f1-4708-820f-406e7e6e26d9',
      slug: 'les_petites_patates-fr',
    },
    {
      id: 'ba31682c-8df6-5e2d-8b40-6ff24628776a',
      slug: 'musicals-fr',
    },
    {
      id: '34fc2d50-16b1-4eaa-bc92-9b882a62c34b',
      slug: 'saint_nicolas-fr',
    },
  ],
};
