import React, { ReactElement, useEffect, useRef, useState } from 'react';
import {
  Dimensions,
  FlatListProps,
  ListRenderItem,
  NativeScrollEvent,
  NativeSyntheticEvent,
  Platform,
  Pressable,
  StyleProp,
  View,
  ViewStyle,
} from 'react-native';
import { FlatList } from 'react-native-gesture-handler';
import tw from '~/shared/theme';
import { Icon } from '../icon';
import { Gap } from '../spacing';

export interface SwimlaneProps<ItemT> extends FlatListProps<ItemT> {
  renderItem: ListRenderItem<ItemT>;
  activeIndex?: number;
  insets?: {
    left: number;
    right: number;
  };
  arrowStyle?: StyleProp<ViewStyle>;
}

const DefaultItemSeparator = () => <Gap direction="row" />;

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
export const Swimlane = <ItemT extends unknown>({
  onScroll,
  renderItem: renderItemProp,
  style,
  insets = { left: 20, right: 20 },
  arrowStyle: arrowStyleProp,
  activeIndex,
  ...props
}: SwimlaneProps<ItemT>): ReactElement => {
  // --- State
  const [atStart, setAtStart] = useState<boolean>(true);
  const [atEnd, setAtEnd] = useState<boolean>(false);
  const [scrollState, setScrollState] = useState<NativeScrollEvent>();
  const scrollViewRef = useRef<FlatList>(null);

  // --- Derived state
  const isSnapping = !!props.snapToInterval || !!props.snapToOffsets || !!props.pagingEnabled;

  // --- Event handlers
  const handleScroll = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
    const { contentOffset, layoutMeasurement, contentSize } = event.nativeEvent;
    setScrollState(event.nativeEvent);
    setAtStart(contentOffset.x <= 0);
    setAtEnd(contentOffset.x + layoutMeasurement.width >= contentSize.width);

    onScroll?.(event);
  };

  // --- Helpers
  const renderItem: ListRenderItem<ItemT> = (itemProps) => {
    return <View style={[isSnapping && tw`snap-start`]}>{renderItemProp(itemProps)}</View>;
  };

  const scrollLeft = () => {
    if (!atStart && scrollViewRef.current && scrollState) {
      const x = scrollState?.contentOffset.x;
      const width = scrollState?.layoutMeasurement.width;
      scrollViewRef.current.scrollToOffset({
        offset: Math.max(0, x - width),
      });
    }
  };
  const scrollRight = () => {
    if (!atEnd && scrollViewRef.current) {
      const x = scrollState?.contentOffset.x || 0;
      const width = scrollState?.layoutMeasurement.width || Dimensions.get('window').width;
      const maxScrollWidth = scrollState?.contentSize
        ? scrollState.contentSize.width - width
        : x + width;
      scrollViewRef.current.scrollToOffset({
        offset: Math.min(x + width, maxScrollWidth),
      });
    }
  };

  // --- Side-effects
  useEffect(() => {
    if (activeIndex !== undefined && scrollViewRef.current) {
      if (!scrollViewRef.current.props.getItemLayout) {
        console.warn(
          'Will not scroll to active index without getItemLayout as this may crash the app.',
        );
        return;
      }
      scrollViewRef.current.scrollToIndex({
        index: activeIndex,
        animated: true,
        viewPosition: 0.5,
      });
    }
  }, [activeIndex]);

  // Note: using background color as a the viewability observer is kind of laggy on web. And not dimming the items makes the arrows super hard to spot.
  const arrowStyle = tw`absolute h-full px-5 items-center justify-center bg-green-450 bg-opacity-50`;

  return (
    <View>
      <FlatList
        ref={scrollViewRef}
        horizontal
        ItemSeparatorComponent={DefaultItemSeparator}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        onScroll={handleScroll}
        scrollEventThrottle={200}
        renderItem={renderItem}
        style={[
          style,
          isSnapping && tw`snap-x-mandatory`,
          {
            marginLeft: -insets.left,
            marginRight: -insets.right,
            paddingLeft: insets.left,
            paddingRight: insets.right,
          },
          Platform.OS === 'web'
            ? {
                scrollPaddingLeft: insets.left,
                scrollPaddingRight: insets.right,
              }
            : {},
        ]}
        contentInset={
          Platform.OS !== 'web' ? { right: insets.right, left: 0, top: 0, bottom: 0 } : undefined
        }
        contentContainerStyle={
          Platform.OS !== 'web'
            ? {
                paddingRight: insets.right,
              }
            : {}
        }
        decelerationRate="fast"
        {...props}
      />
      {/* buttons are not focussable because they only make sense for touch and mouse */}
      {!atStart && (
        <Pressable
          style={[arrowStyle, tw`-left-20 pl-24 rounded-tr-xl rounded-br-xl`, arrowStyleProp]}
          onPress={scrollLeft}
          focusable={false}
        >
          <Icon name="arrow-left" color="white" />
        </Pressable>
      )}
      {!atEnd && (
        <Pressable
          style={[arrowStyle, tw`-right-20 pr-24 rounded-tl-xl rounded-bl-xl`, arrowStyleProp]}
          onPress={scrollRight}
          focusable={false}
        >
          <Icon name="arrow-right" color="white" />
        </Pressable>
      )}
    </View>
  );
};
