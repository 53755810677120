import React, { FunctionComponent } from 'react';
import { StackScreenProps } from '@react-navigation/stack';
import { MediaRoutes, MediaStackParamList } from '~/navigation';
import { BaseLayout } from '~/widgets/base-layout';
import { ScrollingMenuBar } from '~/shared/ui/menu';
import { MainMenu } from '~/widgets/menu/main-menu';
import { Fill } from '~/shared/ui/spacing';
import { UserMenu } from '~/widgets/menu/user-menu';
import { ListBrands } from '~/features/media/list-brands';
import { MediaMedium } from '~/types';
import { useNetInfo } from '@react-native-community/netinfo';
import { MediaOfflinePage } from './media-offline.page';

export const BrandsOverviewPage: FunctionComponent<
  StackScreenProps<MediaStackParamList, MediaRoutes.Brands>
> = (props) => {
  const { isConnected } = useNetInfo();

  if (isConnected === false) {
    return <MediaOfflinePage {...props} />;
  }

  return (
    <BaseLayout
      decorationTop={
        <ScrollingMenuBar>
          <MainMenu />
          <Fill />
          <UserMenu />
        </ScrollingMenuBar>
      }
    >
      <ListBrands
        onBrandPress={(brandSlug) => {
          props.navigation.navigate(MediaRoutes.Overview, {
            medium: MediaMedium.All,
            brandSlug,
          });
        }}
      />
    </BaseLayout>
  );
};
