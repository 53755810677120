const avatars = {
  avatar1: require('./avatar1.png'),
  avatar2: require('./avatar2.png'),
  avatar3: require('./avatar3.png'),
  avatar4: require('./avatar4.png'),
  avatar5: require('./avatar5.png'),
  avatar6: require('./avatar6.png'),
  avatar7: require('./avatar7.png'),
  avatar8: require('./avatar8.png'),
  avatar9: require('./avatar9.png'),
  avatar10: require('./avatar10.png'),
  avatar11: require('./avatar11.png'),
  avatar12: require('./avatar12.png'),
  avatar13: require('./avatar13.png'),
  avatar14: require('./avatar14.png'),
  avatar15: require('./avatar15.png'),
  avatar16: require('./avatar16.png'),
  avatar17: require('./avatar17.png'),
  avatar18: require('./avatar18.png'),
  avatar19: require('./avatar19.png'),
  avatar20: require('./avatar20.png'),
  avatargo: require('./avatargo.png'),
};
export type AvatarName = keyof typeof avatars;
export const avatarNames = Object.keys(avatars) as AvatarName[];
export default avatars;
