import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useActiveProfile } from '~/entities/profiles';
import client from '~/shared/api/main.api';
import { chunkArray } from '~/shared/utils/chunk-array';
import { useClientSideFeeds } from '~/shared/utils/use-clientside-feeds';
import { IFeed, IMedia, NetworkStatus } from '~/types';
import {
  GetFavoriteMediaOperation,
  IGQLGetFavoriteMediaQueryData,
  IGQLGetFavoriteMediaQueryVariables,
} from '../api/queries.main.generated';

async function fetchAllMedia(ids: string[]): Promise<IMedia[]> {
  const media = [];

  const chunks = chunkArray(ids, 30);
  for (const chunk of chunks) {
    const result = await client.query<
      IGQLGetFavoriteMediaQueryData,
      IGQLGetFavoriteMediaQueryVariables
    >({
      query: GetFavoriteMediaOperation,
      variables: { ids: chunk },
    });

    media.push(
      ...(result.data.media.items?.filter((item) => item.__typename !== 'Brand') as IMedia[]),
    );
  }

  return media;
}

export function useFavoritesFeeds(): { feeds: IFeed[]; networkStatus: NetworkStatus } {
  const { t } = useTranslation();

  const [networkStatus, setNetworkStatus] = useState<NetworkStatus>(NetworkStatus.loading);
  const [fetchedMedia, setFetchedMedia] = useState<IMedia[]>([]);
  const feeds = useClientSideFeeds({
    media: fetchedMedia,
    titles: {
      videos: t('favoriteVideos'),
      songs: t('favoriteSongs'),
      games: t('favoriteGames'),
      books: t('favoriteBooks'),
    },
  });

  const { activeProfile, setFavoriteMedia } = useActiveProfile();

  useEffect(() => {
    const ids = activeProfile?.favoriteMedia.map((media) => media.id);
    if (!ids?.length || ids.length === fetchedMedia.length) {
      setNetworkStatus(NetworkStatus.ready);
      return;
    }

    fetchAllMedia(ids).then((media) => {
      setFetchedMedia(media);
      if (ids.length !== media.length) {
        setFavoriteMedia?.(media);
      }
      setNetworkStatus(NetworkStatus.ready);
    });
  }, [activeProfile?.favoriteMedia, setFavoriteMedia, fetchedMedia.length]);

  if (!activeProfile) {
    return { feeds: [], networkStatus: NetworkStatus.ready };
  }

  return { feeds, networkStatus };
}
