import React, { FunctionComponent, useState } from 'react';
import { StackScreenProps } from '@react-navigation/stack';
import { SettingsRoutes, SettingsStackParamList } from '~/navigation';
import { ScrollingMenuBar } from '~/shared/ui/menu';
import { MainMenu } from '~/widgets/menu/main-menu';
import { Fill, Inline } from '~/shared/ui/spacing';
import { UserMenu } from '~/widgets/menu/user-menu';
import { SettingsLayout } from '~/widgets/settings-layout';
import { TextVariant } from '~/shared/ui/text-variant';
import { SettingsGroup, SettingsRow } from '~/shared/ui/settings';
import { useTranslation } from 'react-i18next';
import { LanguageSelector } from '~/features/onboarding/language-selector';
import { Button } from '~/shared/ui/button';
import { Icon } from '~/shared/ui/icon';
import { useRequestAccountSettings } from '~/features/viewer/request-account-settings';
import { NetworkStatus } from '~/types';
import tw from '~/shared/theme';
import { useAppConfig } from '~/entities/app-config';
import { useProximus } from '~/entities/proximus';
import { LogoutConfirmationModal } from '~/features/viewer/logout';
import { AppInfo } from '~/shared/ui/app-info';
import { openURL } from '~/shared/utils/browser/index.web';

export const SettingsPage: FunctionComponent<
  StackScreenProps<SettingsStackParamList, SettingsRoutes.Main>
> = ({ route }) => {
  const [logoutConfirmationVisible, setLogoutConfirmationVisible] = useState(false);
  const config = useAppConfig();
  const { reset: resetProximus, openLogin, tokenInfo, validation } = useProximus();
  const {
    email,
    handleSubmit: handleAccountSettings,
    error: accountSettingsError,
    networkStatus: accountSettingsNetworkStatus,
  } = useRequestAccountSettings();
  const { t, i18n } = useTranslation();

  return (
    <SettingsLayout
      decorationTop={
        <ScrollingMenuBar>
          <MainMenu />
          <Fill />
          <UserMenu />
        </ScrollingMenuBar>
      }
    >
      <SettingsGroup title={t('generalSettingsGroupLabel')}>
        {tokenInfo && validation?.isValid ? (
          <SettingsRow decorationLeft="pass" onPress={resetProximus}>
            {t('logoutToStudio100goLabel')}
          </SettingsRow>
        ) : (
          <SettingsRow
            decorationLeft="pass"
            onPress={() => {
              openLogin({
                screen: SettingsRoutes.Main,
                params: route.params,
              });
            }}
          >
            {t('loginToStudio100goLabel')}
          </SettingsRow>
        )}
        <SettingsRow
          decorationLeft="language"
          decorationRight={<LanguageSelector variant="toggle" />}
        >
          {t('languageSettingLabel')}
          <TextVariant variant="meta">{t('activeLanguage')}</TextVariant>
        </SettingsRow>
      </SettingsGroup>

      <SettingsGroup title={t('accountSettingsGroupLabel')}>
        <SettingsRow
          decorationLeft="settings"
          decorationRight={
            <Button
              decorationRight={<Icon name="arrow-right" />}
              variant="secondary"
              size="sm"
              onPress={handleAccountSettings}
              disabled={accountSettingsNetworkStatus === NetworkStatus.loading}
            >
              {accountSettingsNetworkStatus !== NetworkStatus.loading
                ? t('sendEmailLabel')
                : t('loading')}
            </Button>
          }
        >
          {t('accountSettingLabel')}
          <TextVariant variant="small">{email}</TextVariant>
          <TextVariant variant="meta">{t('accountSettingDescription')}</TextVariant>
          {accountSettingsNetworkStatus === NetworkStatus.ready && (
            <TextVariant variant="meta" style={tw`mt-2`}>
              <Icon name="check" color={tw.color('green')} /> {t('accountSettingFeedback')}
            </TextVariant>
          )}
          {accountSettingsError && (
            <Inline>
              <Icon name="error" color={tw.color('red')} size={12} style={tw`mb-1 mr-1`} />
              <TextVariant variant="error">{t('accountChangeEmailError')}</TextVariant>
            </Inline>
          )}
        </SettingsRow>

        <LogoutConfirmationModal
          visible={logoutConfirmationVisible}
          onRequestClose={() => setLogoutConfirmationVisible(false)}
        />
        <SettingsRow decorationLeft="logout" onPress={() => setLogoutConfirmationVisible(true)}>
          {t('logDeviceOffLabel')}
        </SettingsRow>
      </SettingsGroup>

      <SettingsGroup title={t('appInfoSettingsGroupLabel')}>
        <SettingsRow
          decorationLeft="info"
          onPress={() => {
            try {
              openURL(config?.faqUrl[i18n.language as keyof typeof config.faqUrl] as string, {
                webTarget: '_blank',
              });
            } catch (e) {}
          }}
        >
          {t('faqPageLinkLabel')}
        </SettingsRow>
        <SettingsRow
          decorationLeft="info"
          onPress={() => {
            try {
              openURL(
                config?.privacyPolicyUrl[
                  i18n.language as keyof typeof config.privacyPolicyUrl
                ] as string,
                { webTarget: '_blank' },
              );
            } catch (e) {}
          }}
        >
          {t('legalPageLinkLabel')}
        </SettingsRow>
      </SettingsGroup>
      <AppInfo />
    </SettingsLayout>
  );
};
