import React, { FunctionComponent } from 'react';
import { View, Pressable, PressableStateCallbackType, ColorValue } from 'react-native';
import tw from '~/shared/theme';
import { Icon, IconProps } from '../icon';
import { Fill, GapSpacer, Inline } from '../spacing';
import { TextVariant } from '../text-variant';

const positionVariants = {
  single: tw`rounded-md`,
  top: tw`rounded-t-md border-b border-gray-200`,
  bottom: tw`rounded-b-md border-t border-gray-200 -mt-px`,
  center: tw`border-t border-b border-gray-200 -mt-px`,
};

type DecorationProps = { color?: ColorValue; size?: number };

/**
 * The default size used for the icon on the left, use this if you wish to match the size with a custom rendered icon which does not take a `size` prop.
 */
export const DEFAULT_ICON_SIZE = 28;

export interface SettingsRowProps {
  positionVariant?: keyof typeof positionVariants;
  decorationLeft?: IconProps['name'] | React.ReactElement;
  decorationRight?: React.ReactElement;
  onPress?: () => void;
  children?: React.ReactNode;
}

export const SettingsRow: FunctionComponent<SettingsRowProps> = ({
  children,
  positionVariant = 'single',
  decorationLeft,
  decorationRight,
  onPress,
}) => {
  const render = (
    { hovered, pressed }: PressableStateCallbackType = { pressed: false, hovered: false },
  ) => (
    <Inline
      style={[
        tw`bg-white px-4 py-2 min-h-14 items-center`,
        positionVariants[positionVariant],
        hovered && tw`bg-purple-900`,
        pressed && tw`bg-purple-700`,
      ]}
    >
      <GapSpacer direction="row" spacing="sm">
        {decorationLeft && (
          <View>
            {React.isValidElement<DecorationProps>(decorationLeft) ? (
              React.cloneElement(decorationLeft, {
                color: tw.color('purple-400') as ColorValue,
                size: DEFAULT_ICON_SIZE,
                ...(decorationLeft.props || {}),
              })
            ) : (
              <Icon
                name={decorationLeft as IconProps['name']}
                color={tw.color('purple-400')}
                size={DEFAULT_ICON_SIZE}
              />
            )}
          </View>
        )}
        <Fill>
          {React.Children.map(children, (child) => {
            if (typeof child === 'string') {
              return <TextVariant variant="settings">{child}</TextVariant>;
            }
            return child;
          })}
        </Fill>
        {decorationRight}
      </GapSpacer>
    </Inline>
  );

  if (onPress) {
    return <Pressable onPress={onPress}>{render}</Pressable>;
  }

  return render();
};
