import * as Types from '~/shared/api/models.main.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {"context":{"clientName":"main"}} as const;
export type IGQLAssetFragment = (
  { __typename: 'Asset' }
  & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
);

export type IGQLButtonFragment = (
  { __typename: 'Button' }
  & Pick<Types.IGQLButton, 'title'>
  & { icon: Types.Maybe<(
    { __typename: 'Icon' }
    & Pick<Types.IGQLIcon, 'name' | 'color'>
  )>, variant: Types.Maybe<(
    { __typename: 'Variant' }
    & Pick<Types.IGQLVariant, 'name'>
  )>, link: (
    { __typename: 'Link' }
    & Pick<Types.IGQLLink, 'screen' | 'params'>
  ) }
);

export type IGQLBannerFragment = (
  { __typename: 'PageBanner' }
  & { slides: Array<(
    { __typename: 'BannerSlide' }
    & Pick<Types.IGQLBannerSlide, 'id'>
    & { title: (
      { __typename: 'Text' }
      & Pick<Types.IGQLText, 'type' | 'content'>
    ), backgrounds: (
      { __typename: 'BannerBackground' }
      & { small: Types.Maybe<(
        { __typename: 'Asset' }
        & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
      )>, medium: Types.Maybe<(
        { __typename: 'Asset' }
        & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
      )>, large: Types.Maybe<(
        { __typename: 'Asset' }
        & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
      )> }
    ), actions: Types.Maybe<Array<Types.Maybe<(
      { __typename: 'Button' }
      & Pick<Types.IGQLButton, 'title'>
      & { icon: Types.Maybe<(
        { __typename: 'Icon' }
        & Pick<Types.IGQLIcon, 'name' | 'color'>
      )>, variant: Types.Maybe<(
        { __typename: 'Variant' }
        & Pick<Types.IGQLVariant, 'name'>
      )>, link: (
        { __typename: 'Link' }
        & Pick<Types.IGQLLink, 'screen' | 'params'>
      ) }
    )>>> }
  )> }
);

export type IGQLGetPageBannerQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type IGQLGetPageBannerQueryData = (
  { __typename: 'Query' }
  & { page: Types.Maybe<(
    { __typename: 'Page' }
    & Pick<Types.IGQLPage, 'id'>
    & { banner: Types.Maybe<(
      { __typename: 'PageBanner' }
      & { slides: Array<(
        { __typename: 'BannerSlide' }
        & Pick<Types.IGQLBannerSlide, 'id'>
        & { title: (
          { __typename: 'Text' }
          & Pick<Types.IGQLText, 'type' | 'content'>
        ), backgrounds: (
          { __typename: 'BannerBackground' }
          & { small: Types.Maybe<(
            { __typename: 'Asset' }
            & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
          )>, medium: Types.Maybe<(
            { __typename: 'Asset' }
            & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
          )>, large: Types.Maybe<(
            { __typename: 'Asset' }
            & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
          )> }
        ), actions: Types.Maybe<Array<Types.Maybe<(
          { __typename: 'Button' }
          & Pick<Types.IGQLButton, 'title'>
          & { icon: Types.Maybe<(
            { __typename: 'Icon' }
            & Pick<Types.IGQLIcon, 'name' | 'color'>
          )>, variant: Types.Maybe<(
            { __typename: 'Variant' }
            & Pick<Types.IGQLVariant, 'name'>
          )>, link: (
            { __typename: 'Link' }
            & Pick<Types.IGQLLink, 'screen' | 'params'>
          ) }
        )>>> }
      )> }
    )> }
  )> }
);

export const AssetFragmentDoc = gql`
    fragment asset on Asset {
  source
  width
  height
}
    `;
export const ButtonFragmentDoc = gql`
    fragment button on Button {
  title
  icon {
    name
    color
  }
  variant {
    name
  }
  link {
    screen
    params
  }
}
    `;
export const BannerFragmentDoc = gql`
    fragment banner on PageBanner {
  slides {
    id
    title {
      type
      content
    }
    backgrounds {
      small {
        ...asset
      }
      medium {
        ...asset
      }
      large {
        ...asset
      }
    }
    actions {
      __typename
      ...button
    }
  }
}
    ${AssetFragmentDoc}
${ButtonFragmentDoc}`;
export const GetPageBannerOperation = gql`
    query GetPageBanner($id: ID!) {
  page(id: $id) {
    id
    banner {
      ...banner
    }
  }
}
    ${BannerFragmentDoc}`;
export function useGetPageBannerQuery(baseOptions: Apollo.QueryHookOptions<IGQLGetPageBannerQueryData, IGQLGetPageBannerQueryVariables> & ({ variables: IGQLGetPageBannerQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGQLGetPageBannerQueryData, IGQLGetPageBannerQueryVariables>(GetPageBannerOperation, options);
      }
export function useGetPageBannerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGQLGetPageBannerQueryData, IGQLGetPageBannerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGQLGetPageBannerQueryData, IGQLGetPageBannerQueryVariables>(GetPageBannerOperation, options);
        }
export function useGetPageBannerSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGQLGetPageBannerQueryData, IGQLGetPageBannerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGQLGetPageBannerQueryData, IGQLGetPageBannerQueryVariables>(GetPageBannerOperation, options);
        }
export type GetPageBannerQueryHookResult = ReturnType<typeof useGetPageBannerQuery>;
export type GetPageBannerLazyQueryHookResult = ReturnType<typeof useGetPageBannerLazyQuery>;
export type GetPageBannerSuspenseQueryHookResult = ReturnType<typeof useGetPageBannerSuspenseQuery>;
export type GetPageBannerQueryResult = Apollo.QueryResult<IGQLGetPageBannerQueryData, IGQLGetPageBannerQueryVariables>;