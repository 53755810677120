
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "Action": [
      "Button"
    ],
    "ContentConfig": [
      "BrandContentConfig",
      "MediaContentConfig"
    ],
    "FeedItem": [
      "Book",
      "Brand",
      "Game",
      "Playlist",
      "Serie",
      "Song",
      "Video"
    ],
    "Filter": [
      "GenericFilter",
      "IdFilter"
    ],
    "Media": [
      "Book",
      "Game",
      "Playlist",
      "Serie",
      "Song",
      "Video"
    ],
    "RawMediaProperties": [
      "RawBookProperties",
      "RawGameProperties",
      "RawPlaylistProperties",
      "RawSongProperties",
      "RawVideoProperties"
    ]
  }
};
      export default result;
    