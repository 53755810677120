import { IDownloadableMedia, MediaDownloadStatus } from '~/types';

export function updateDownloadProgress(
  item: IDownloadableMedia,
  increment = 1,
): IDownloadableMedia['downloadMeta'] {
  const { progress, videoProgress } = item.downloadMeta!;
  const nextProgress = [progress[0] + increment, progress[1]];
  return {
    progress: nextProgress,
    status:
      nextProgress[0] >= nextProgress[1]
        ? MediaDownloadStatus.Downloaded
        : MediaDownloadStatus.Downloading,
    videoProgress,
  };
}
