import AsyncStorage from '@react-native-async-storage/async-storage';
import { useEffect, useMemo, useRef, useState } from 'react';
import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import { v4 as uuidv4 } from 'uuid';
import { AvatarName } from '~/assets/avatars';
import { IMedia } from '~/types';
import { useIsFocused } from '@react-navigation/core';
import I18N from '~/assets/locale/i18n';
import { analyticsTracker } from '~/shared/tracking/analytics';
import { useTranslation } from 'react-i18next';

const MAX_RECENTLY_VIEWED_ITEMS = 30;

export interface IProfile {
  id: string;
  language: string;
  name: string;
  age: number;
  avatar: AvatarName;
  favoriteBrands: string[];
  favoriteMedia: { id: string; type: string }[];
  recentlyViewedMedia: string[];
}

interface IProfilesState {
  _hasHydrated: boolean;

  items: IProfile[];
  activeProfileId: string | undefined;
  deleteRequest?: { profile: IProfile };
}

interface IProfilesProducers {
  registerProfile(
    profile: Omit<IProfile, 'id' | 'favoriteMedia' | 'recentlyViewedMedia' | 'language'>,
  ): IProfile;
  updateProfile(profile: IProfile): IProfile;
  setActiveProfile(profile: IProfile): void;
  requestDeleteProfile(profiile: IProfile | undefined): void;
  deleteProfile(profiile: IProfile): void;
  reset(): void;
}

type IProfilesModel = IProfilesState & IProfilesProducers;

export const useProfiles = create<IProfilesModel>()(
  persist(
    (set, get) => ({
      _hasHydrated: false,

      activeProfileId: undefined,
      items: [],
      deleteRequest: undefined,

      registerProfile(profile) {
        const profileWithId = {
          id: uuidv4(),
          ...profile,
          language: I18N.language,
          favoriteMedia: [],
          recentlyViewedMedia: [],
        };

        set(({ items }) => {
          return {
            items: [...items, profileWithId],
            activeProfileId: profileWithId.id,
          };
        });

        analyticsTracker.trackProfile('profile-add', profileWithId);

        return profileWithId;
      },

      updateProfile(profile) {
        set(({ items }) => {
          return {
            items: items.map((item) => {
              if (item.id === profile.id) {
                return profile;
              }
              return item;
            }),
            activeProfileId: profile.id,
          };
        });

        analyticsTracker.trackProfile('profile-update', profile);

        return profile;
      },

      setActiveProfile(profile) {
        set({ activeProfileId: profile.id });
      },

      reset() {
        set({
          items: [],
          activeProfileId: undefined,
        });
      },

      requestDeleteProfile(profile: IProfile | undefined) {
        set({
          deleteRequest: profile ? { profile } : undefined,
        });
      },

      deleteProfile(profile: IProfile) {
        const { activeProfileId, items } = get();

        set({
          items: items.filter((item) => item.id !== profile.id),
          activeProfileId:
            activeProfileId === profile.id
              ? items.filter((item) => item.language === profile.language)[0]?.id
              : undefined,
          deleteRequest: undefined,
        });

        analyticsTracker.trackProfile('profile-remove', profile);
      },
    }),
    {
      name: 'studio100go-profiles',
      storage: createJSONStorage(() => AsyncStorage),
      onRehydrateStorage: () => () => {
        useProfiles.setState({ _hasHydrated: true });
      },
      partialize: ({ _hasHydrated, ...state }) => state,
    },
  ),
);

export const useActiveProfile = (): {
  activeProfile?: IProfile;
  setLanguage?(language: string): void;
  setFavoriteMedia?(media: IMedia[]): void;
  toggleFavoriteMedia?(mediaItem: IMedia): boolean;
  markMediaAsRecentlyViewed?(mediaItem: IMedia): void;
} => {
  const { items, activeProfileId, updateProfile } = useProfiles();

  if (!activeProfileId) return {};

  const activeProfile = items.find((item) => item.id === activeProfileId);

  return {
    activeProfile,
    setLanguage:
      activeProfile &&
      ((language: string) => {
        updateProfile({
          ...activeProfile,
          language,
        });
      }),
    setFavoriteMedia:
      activeProfile &&
      ((media) => {
        updateProfile({
          ...activeProfile,
          favoriteMedia: media.map((item) => ({ type: item.__typename, id: item.id })),
        });
      }),
    toggleFavoriteMedia:
      activeProfile &&
      ((mediaItem) => {
        const { id, __typename } = mediaItem;
        const found = activeProfile.favoriteMedia.find((m) => m.id === id);
        updateProfile({
          ...activeProfile,
          favoriteMedia: found
            ? activeProfile.favoriteMedia.filter((m) => m.id !== id)
            : [...activeProfile.favoriteMedia, { id, type: __typename }],
        });

        analyticsTracker.trackContent(found ? 'favorite-remove' : 'favorite-add', mediaItem);

        return !found;
      }),
    markMediaAsRecentlyViewed:
      activeProfile &&
      ((mediaItem) => {
        const foundIndex = activeProfile.recentlyViewedMedia.indexOf(mediaItem.id);
        if (foundIndex === 0) return;

        const nextRecentlyViewedMedia =
          foundIndex > -1
            ? [
                mediaItem.id,
                ...activeProfile.recentlyViewedMedia.filter((id) => id !== mediaItem.id),
              ]
            : [mediaItem.id, ...activeProfile.recentlyViewedMedia];
        updateProfile({
          ...activeProfile,
          recentlyViewedMedia: nextRecentlyViewedMedia.slice(0, MAX_RECENTLY_VIEWED_ITEMS),
        });
      }),
  };
};

export function useActiveProfilePreferences(): {
  favoriteBrands: string[];
  favoriteMedia: string[];
  recentlyViewed: string[];
  language: string;
} {
  const { i18n } = useTranslation();
  const { activeProfile } = useActiveProfile();

  return useMemo(
    () => ({
      favoriteBrands: activeProfile?.favoriteBrands || [],
      favoriteMedia: activeProfile?.favoriteMedia.map((media) => media.id) || [],
      recentlyViewed: activeProfile?.recentlyViewedMedia || [],
      language: activeProfile?.language || i18n.language,
    }),
    [activeProfile, i18n.language],
  );
}

export function useMemoizedActiveProfilePreferences(
  updateAfterActions = 0,
): ReturnType<typeof useActiveProfilePreferences> {
  const activeProfileId = useProfiles((state) => state.activeProfileId);
  const updateCounterRef = useRef(0);
  const [update, setUpdate] = useState(0);
  const preferences = useActiveProfilePreferences();
  const isFocused = useIsFocused();

  useEffect(() => {
    setUpdate((u) => u + 1);
    updateCounterRef.current = 0;
  }, [activeProfileId]);

  useEffect(() => {
    if (updateAfterActions === 0) return;

    if (updateCounterRef.current + 1 >= updateAfterActions && isFocused) {
      setUpdate((u) => u + 1);
      updateCounterRef.current = 0;
    }
    updateCounterRef.current += 1;
  }, [preferences, updateAfterActions, isFocused]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => preferences, [update, preferences.language]);
}
