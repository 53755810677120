import React, { FunctionComponent, useEffect, useRef } from 'react';
import {
  View,
  KeyboardAvoidingView,
  Animated,
  Platform,
  KeyboardAvoidingViewProps,
} from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import tw from '~/shared/theme';
import { Button } from '../button';
import { ExpandingDotPager } from '../expanding-dot-pager';
import { Icon } from '../icon';
import { Fill } from '../spacing';

export interface FormWizardPagerProps {
  page: number;
  pages: unknown[];

  previousDisabled?: boolean;
  previousLabel: string;
  onPressPrevious: () => void;

  nextDisabled?: boolean;
  nextLabel: string;
  completeLabel: string;
  onPressNext: () => void;

  isFinalStep?: boolean;
}

export const FormWizardPager: FunctionComponent<FormWizardPagerProps> = ({
  page,
  pages,

  previousDisabled = false,
  previousLabel,
  onPressPrevious,

  nextDisabled = false,
  nextLabel,
  completeLabel,
  onPressNext,

  isFinalStep = page === pages.length - 1,
}) => {
  const animatedPage = useRef(new Animated.Value(page)).current;
  const insets = useSafeAreaInsets();
  const Wrapper = Platform.OS === 'android' ? View : KeyboardAvoidingView;
  const wrapperProps: KeyboardAvoidingViewProps =
    Platform.OS === 'android'
      ? {}
      : {
          behavior: 'padding',
          keyboardVerticalOffset: -10,
        };

  useEffect(() => {
    Animated.timing(animatedPage, {
      toValue: page,
      useNativeDriver: false,
      duration: 200,
    }).start();
  }, [animatedPage, page]);

  return (
    <Wrapper {...wrapperProps} style={tw`bg-white bottom-0 left-0 w-full`}>
      <View
        style={[
          tw`w-full items-center py-2 tablet:py-4`,
          { paddingLeft: insets.left, paddingRight: insets.right },
        ]}
      >
        <View
          style={tw`flex flex-row justify-center items-center px-2 tablet:px-4 w-full max-w-4xl`}
        >
          <Button
            variant="secondary"
            disabled={previousDisabled}
            onPress={onPressPrevious}
            decorationLeft={<Icon name="arrow-left" />}
          >
            {previousLabel}
          </Button>
          <Fill
          // in case you want to nudge the dots upwards, you could do this, but seems okay as it is now
          // style={[tw`-my-4`, { paddingBottom: insets.bottom }]}
          >
            <ExpandingDotPager
              page={animatedPage}
              pages={pages}
              dot={{
                size: 8,
                activeColor: tw.color('purple'),
                inactiveColor: tw.color('green'),
                inactiveOpacity: 1,
              }}
            />
          </Fill>
          <Button
            variant="submit"
            disabled={nextDisabled}
            onPress={onPressNext}
            decorationRight={<Icon name={isFinalStep ? 'check' : 'arrow-right'} />}
          >
            {!isFinalStep ? nextLabel : completeLabel}
          </Button>
        </View>
      </View>
    </Wrapper>
  );
};
