import { Linking } from 'react-native';
import type { Options } from './index.d';

export async function openURL(
  url: string,
  options: Options = { webTarget: '_self' },
): Promise<void> {
  if (options.webTarget === '_self') {
    window.location.href = url;
    return;
  }

  if (options.webTarget === '_blank') {
    return Linking.openURL(url);
  }

  return;
}
