import { CompositeScreenProps } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { useProfiles } from '~/entities/profiles';
import { OnboardingStep, useViewer } from '~/entities/viewer';
import { useForceUnboarding } from '~/features/guards/force-onboarding';
import {
  useProfileUpsert,
  ProfileIntroStep,
  ProfileNameStep,
  ProfileAgeStep,
  ProfileAvatarStep,
  ProfileBrandsStep,
  ProfileFinalState,
} from '~/features/profiles/upsert-profile';
import {
  MediaRoutes,
  OnboardingRoutes,
  OnboardingStackParamList,
  RootRoutes,
  RootStackParamList,
} from '~/navigation';
import tw from '~/shared/theme';
import { Button } from '~/shared/ui/button';
import { FormWizardPager } from '~/shared/ui/form-wizard-pager';
import { Icon } from '~/shared/ui/icon';
import { CenteredLayout } from '~/widgets/centered-layout';

const steps = [
  ProfileIntroStep,
  ProfileNameStep,
  ProfileAgeStep,
  ProfileAvatarStep,
  ProfileBrandsStep,
];
const pages = steps.slice(1);

export const OnboardingProfilePage: FunctionComponent<
  CompositeScreenProps<
    StackScreenProps<OnboardingStackParamList, OnboardingRoutes.Profile>,
    StackScreenProps<RootStackParamList, RootRoutes.Onboarding>
  >
> = ({ navigation }) => {
  useForceUnboarding();

  const setOnboardingStep = useViewer((state) => state.setOnboardingStep);
  const registerProfile = useProfiles((state) => state.registerProfile);
  const { t } = useTranslation();
  const onFinish = (state?: ProfileFinalState) => {
    if (state) {
      registerProfile(state);
    }

    setOnboardingStep(OnboardingStep.Finished);
    navigation.navigate(RootRoutes.Media, { screen: MediaRoutes.Overview, params: {} });
    return;
  };

  const {
    activeStep: ActiveStep,
    activeStepIndex,
    nextStepEnabled,
    previousStepEnabled,
    finalNextStep,
    onNextStep,
    onPreviousStep,
    stepProps,
  } = useProfileUpsert({
    steps,
    onFinish,
  });

  return (
    <CenteredLayout
      decorationTopRight={
        ActiveStep === ProfileIntroStep ? (
          <View style={tw`px-4 py-2`}>
            <Button
              onPress={() => onFinish()}
              decorationRight={<Icon name="arrow-right" />}
              variant="background"
            >
              {t('startOnboardingNoProfileLink')}
            </Button>
          </View>
        ) : undefined
      }
      decorationAfter={
        ActiveStep !== ProfileIntroStep ? (
          <FormWizardPager
            page={activeStepIndex - 1}
            pages={pages}
            previousDisabled={!previousStepEnabled}
            previousLabel={t('onboardingPreviousButtonLabel')}
            onPressPrevious={onPreviousStep}
            nextDisabled={!nextStepEnabled}
            nextLabel={t('onboardingNextButtonLabel')}
            completeLabel={t('onboardingFinalButtonLabel')}
            onPressNext={onNextStep}
            isFinalStep={finalNextStep}
          />
        ) : undefined
      }
    >
      <ActiveStep {...stepProps} decorated />
    </CenteredLayout>
  );
};
