import { Route } from '@react-navigation/routers';

export enum NavigationEventSourceType {
  Minihub = 'minihub',
  Feed = 'feed',
  CarouselButton = 'carousel-button',
  FeedButton = 'feed-button',
}

export interface INavigationEventMeta {
  type: NavigationEventSourceType;
  id: string;
  route: Route<string>;
}

export interface IProfileMeta {
  age: number;
  language: string;
}
