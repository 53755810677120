import { NavigationProp, RouteProp, useNavigation, useRoute } from '@react-navigation/core';
import React, { FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, Text, View } from 'react-native';
import { useAppConfig } from '~/entities/app-config';
import { useActiveProfile } from '~/entities/profiles';
import { MediaRoutes, MediaStackParamList, RootRoutes, RootStackParamList } from '~/navigation';
import config from '~/shared/config';
import tw from '~/shared/theme';
import { Icon } from '~/shared/ui/icon';
import { AnimatedCoin, Coin, MenuItem, MultiCoin } from '~/shared/ui/menu';
import { MediaMedium, MEDIA_ALL_BRANDS } from '~/types';

interface MainMenuProps {}

export const MainMenu: FunctionComponent<MainMenuProps> = () => {
  const navigation = useNavigation<NavigationProp<RootStackParamList>>();
  const { params, name: activeRoute } =
    useRoute<RouteProp<MediaStackParamList, keyof MediaStackParamList>>();
  const { t } = useTranslation();
  const { activeProfile } = useActiveProfile();
  const appConfig = useAppConfig();
  const medium = params && 'medium' in params ? params.medium : undefined;

  const shuffledBrandMenuCoinImages = useMemo(() => {
    const brandMenuCoinImages = appConfig?.brandMenuCoinImages || config.brandMenuCoinImages;
    if (!brandMenuCoinImages) {
      return [];
    }

    return [...brandMenuCoinImages[(activeProfile?.language || 'nl') as 'fr' | 'nl']].sort(
      () => Math.random() - 0.5,
    );
  }, [appConfig?.brandMenuCoinImages, activeProfile]);

  return (
    <>
      <MenuItem
        label={t('menuItemHome')}
        active={activeRoute === MediaRoutes.Overview && (!medium || medium === MediaMedium.All)}
        onPress={() =>
          navigation.navigate(RootRoutes.Media, {
            screen: MediaRoutes.Overview,
            params: {
              medium: MediaMedium.All,
              brandSlug: MEDIA_ALL_BRANDS,
            },
          })
        }
      >
        <MultiCoin />
      </MenuItem>
      <MenuItem
        label={t('menuItemBrands')}
        active={activeRoute === MediaRoutes.Brands}
        onPress={() => navigation.navigate(RootRoutes.Media, { screen: MediaRoutes.Brands })}
      >
        {shuffledBrandMenuCoinImages?.length ? (
          <AnimatedCoin imageSources={shuffledBrandMenuCoinImages.map((uri) => ({ uri }))} />
        ) : (
          <Coin coinVariant="green">
            <View style={tw`absolute -bottom-2 w-4 h-4 tablet:w-6 bg-blue rounded-full`} />
            <Icon name="avatar" style={tw`text-2xl tablet:text-4xl mt-1 tablet:mt-2 text-blue`} />
          </Coin>
        )}
      </MenuItem>
      <MenuItem
        label={t('menuItemWatch')}
        active={activeRoute === MediaRoutes.Overview && medium === MediaMedium.Watch}
        onPress={() =>
          navigation.navigate(RootRoutes.Media, {
            screen: MediaRoutes.Overview,
            params: {
              medium: MediaMedium.Watch,
              brandSlug: MEDIA_ALL_BRANDS,
            },
          })
        }
      >
        <Coin coinVariant="red">
          <Icon name="play" style={tw`ml-0.25 text-md tablet:ml-0.5 tablet:text-xl text-blue`} />
        </Coin>
      </MenuItem>
      <MenuItem
        label={t('menuItemPlay')}
        active={activeRoute === MediaRoutes.Overview && medium === MediaMedium.Play}
        onPress={() =>
          navigation.navigate(RootRoutes.Media, {
            screen: MediaRoutes.Overview,
            params: {
              medium: MediaMedium.Play,
              brandSlug: MEDIA_ALL_BRANDS,
            },
          })
        }
      >
        <Coin coinVariant="mint">
          <Icon name="game" style={tw`text-xl tablet:text-3xl text-blue`} />
        </Coin>
      </MenuItem>
      <MenuItem
        label={t('menuItemRead')}
        active={activeRoute === MediaRoutes.Overview && medium === MediaMedium.Read}
        onPress={() =>
          navigation.navigate(RootRoutes.Media, {
            screen: MediaRoutes.Overview,
            params: {
              medium: MediaMedium.Read,
              brandSlug: MEDIA_ALL_BRANDS,
            },
          })
        }
      >
        <Coin coinVariant="blue">
          <Icon name="book" style={tw`mt-0.5 text-lg tablet:mt-1 tablet:text-2xl text-blue`} />
        </Coin>
      </MenuItem>
      <MenuItem
        label={t('menuItemListen')}
        active={activeRoute === MediaRoutes.Overview && medium === MediaMedium.Listen}
        onPress={() =>
          navigation.navigate(RootRoutes.Media, {
            screen: MediaRoutes.Overview,
            params: {
              medium: MediaMedium.Listen,
              brandSlug: MEDIA_ALL_BRANDS,
            },
          })
        }
      >
        <Coin coinVariant="orange">
          <Icon
            name="music"
            style={tw`-ml-0.5 text-lg tablet:-ml-1 tablet:mt-0.5 tablet:text-2xl text-blue`}
          />
        </Coin>
      </MenuItem>
      {activeProfile && (
        <MenuItem
          label={t('menuItemFavorites')}
          active={activeRoute === MediaRoutes.Favorites}
          onPress={() =>
            navigation.navigate(RootRoutes.Media, {
              screen: MediaRoutes.Favorites,
            })
          }
        >
          <Coin coinVariant="pink">
            <Icon name="heart" style={tw`mt-1 text-xl tablet:mt-1 tablet:text-3xl text-blue`} />
            <Text
              style={tw`absolute font-sans tablet:font-sans-bold text-3xs tablet:text-sm top-2/5 tablet:top-1/3 text-pink`}
            >
              {activeProfile.favoriteMedia.length > 99
                ? '99+'
                : activeProfile.favoriteMedia.length || ''}
            </Text>
          </Coin>
        </MenuItem>
      )}
      {Platform.OS !== 'web' && (
        <MenuItem
          label={t('menuItemDownloads')}
          active={activeRoute === MediaRoutes.Downloads}
          onPress={() =>
            navigation.navigate(RootRoutes.Media, {
              screen: MediaRoutes.Downloads,
            })
          }
        >
          <Coin coinVariant="green">
            <Icon name="downloads" style={tw`text-blue text-xl tablet:text-2xl`} />
          </Coin>
        </MenuItem>
      )}
    </>
  );
};
