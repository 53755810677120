import React, { FunctionComponent } from 'react';
import { GamePlayerProps } from './types';

export const GamePlayer: FunctionComponent<GamePlayerProps> = ({ source, fullScreen = false }) => {
  return (
    <iframe
      src={source}
      style={{
        border: 0,
        width: '100%',
        height: '100%',
        ...(fullScreen && {
          position: 'fixed',
          zIndex: 5,
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
        }),
      }}
    />
  );
};
