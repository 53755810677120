import BaseTrackerAdapter from './base';

const DIMENSION_INDEX_MAP = {
  s100_user_age: 1,
  s100_content_category: 2,
  s100_user_language: 3,
  s100_user_subscription: 4,
  s100_content_brand_id: 5,
  s100_app_profilecount: 6,
  s100_content_brand_name: 7,
  s100_content_age: 8,
  s100_content_target: 9,
  s100_content_id: 10,
  s100_content_title: 11,
};

function getDimensionFields(params: Record<string, unknown>) {
  try {
    const fields: Record<string, any> = {};
    Object.keys(params).forEach((key) => {
      if (!(key in DIMENSION_INDEX_MAP)) {
        return null;
      }
      const keyIndex = DIMENSION_INDEX_MAP[key as keyof typeof DIMENSION_INDEX_MAP];
      if (!isNaN(keyIndex)) {
        const value = params[key] as any;
        const isEmpty = value === undefined || value === null;
        if (!isEmpty && value?.toString) {
          fields[`dimension${keyIndex}`] = value.toString();
        }
      }
    });
    return fields;
  } catch (e) {
    console.log(e);
    return {};
  }
}

export default class GoogleAnalyticsTracker extends BaseTrackerAdapter {
  userDimensions: Record<string, string>;

  constructor(key: string) {
    super('google-analytics');

    if (key === 'test') {
      window.ga = (...args) => {
        console.log('GA::test', ...args);
      };
    }
    window.ga('create', key, 'auto');

    this.userDimensions = {};
  }

  setUserId(userId: string): void {
    window.ga('set', '&uid', userId);
    super.setUserId(userId);
  }

  logCurrentScreen(name: string): void {
    super.logCurrentScreen(name);
  }

  logEvent(event: string, params: Record<string, unknown>): void {
    const fields = getDimensionFields({ ...this.userDimensions, ...params });
    if (event === 'pageview' || event === 'view_dimensions') {
      if (params.path) {
        window.ga('set', 'page', params.path);
      }
      // Avoid double tracking the content view
      if (!(event === 'pageview' && params.view_name === 'Media:Detail')) {
        window.ga('send', 'pageview', fields);
      }
    } else {
      window.ga('send', 'event', {
        eventCategory: event,
        ...fields,
      });
    }

    super.logEvent(event, params);
  }

  setUserProperty(property: string, value: string): void {
    const safeValue = this.getSafePropertyValue(value) as string;
    this.userDimensions[property] = value;
    super.setUserProperty(property, safeValue);
  }

  setUserPropertyOnce(property: string, value: string): void {
    const safeValue = this.getSafePropertyValue(value) as string;
    this.userDimensions[property] = value;
    super.setUserPropertyOnce(property, safeValue);
  }
}
