import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { StackScreenProps } from '@react-navigation/stack';
import { MediaRoutes, MediaStackParamList } from '~/navigation';
import { FlatListProps, Platform, ScaledSize, useWindowDimensions, View } from 'react-native';
import { useMediaDetail } from '~/features/media/list-media-detail';
import { Coin, MenuBack } from '~/shared/ui/menu';
import { TextVariant } from '~/shared/ui/text-variant';
import { Swimlane, SwimlaneSkeleton } from '~/shared/ui/swimlane';
import { MediaSwimlaneItem } from '~/features/media/swimlane-item';
import {
  BookMediaDetail,
  GameMediaDetail,
  SongMediaDetail,
  VideoMediaDetail,
} from '~/features/media/detail';
import tw from '~/shared/theme';
import { getGapSpacingValue, Inline } from '~/shared/ui/spacing';
import { useActiveProfile } from '~/entities/profiles';
import { IMedia, MediaType, NetworkStatus } from '~/types';
import { FavoriteButton } from '~/features/media/change-favorite-status';
import { DownloadButton } from '~/features/media/change-download-status';
import { useProximus } from '~/entities/proximus';
import { KeepAwake } from '~/shared/ui/keep-awake';
import { PressableGrow } from '~/shared/ui/pressables';
import { useInsetsOrPadding } from '~/shared/utils/use-insets-or-padding';
import { getMediaPreview, getRelatedMediaTileWidth } from '~/shared/utils/media';
import { ImageBackground, ImageSourcePropType } from '~/shared/ui/image';

import background from '~/assets/images/default-bg.png';
import { DecorativeLoader, Preloader } from '~/shared/ui/preloader';
import { useTranslation } from 'react-i18next';
import { Icon } from '~/shared/ui/icon';
import { ProximusButton } from '~/shared/ui/button';

import lockedPlaceholder from '~/assets/images/placeholders/media-square.jpg';
import { analyticsTracker, NavigationEventSourceType } from '~/shared/tracking/analytics';

const getItemLayoutFactory: (
  data: IMedia[] | undefined,
  windowDimensions: ScaledSize,
) => FlatListProps<IMedia>['getItemLayout'] = (data, windowDimensions) => {
  let nextOffset = 0;
  const separatorWidth = getGapSpacingValue();

  // pre-calculate item layouts
  const itemLayouts =
    data?.map((item, index) => {
      // current item layout
      const length = getRelatedMediaTileWidth(item, windowDimensions);
      const offset = nextOffset;

      // set the offset for the next item
      nextOffset = offset + length + separatorWidth;

      return {
        length,
        offset,
        index,
      };
    }) || [];

  // return layout from pre-calculated array, possibly called a single time when using scrollToIndex
  return (_data, index) => itemLayouts[index] || { index: 0, length: 0, offset: 0 };
};

export const MediaDetailPage: FunctionComponent<
  StackScreenProps<MediaStackParamList, MediaRoutes.MediaDetail>
> = ({ route, navigation }) => {
  const { t } = useTranslation();
  const insets = useInsetsOrPadding();
  const windowDimensions = useWindowDimensions();
  const preventAutoplay = useProximus((state) => state.validationConfirmationVisible);
  const requestMediaItem = useProximus((state) => state.requestMediaItem);
  const {
    item,
    activeHubItem,
    activeHubItemIndex,
    hubItems,
    networkStatus,
    changeActiveHubItem,
    setNextHubItemActive,
  } = useMediaDetail({
    id: route.params.id,
  });
  // Only show full screen button for content types that do not have a built-in UI
  const isLocked = activeHubItem?.isLocked;
  const showFullScreenButton =
    !isLocked &&
    (activeHubItem?.__typename === MediaType.Game || activeHubItem?.__typename === MediaType.Book);
  const bookHasNoPages =
    activeHubItem?.__typename === MediaType.Book && activeHubItem?.bookPages?.length === 0;

  const [fullScreen, setFullScreen] = useState<boolean>();
  const { markMediaAsRecentlyViewed } = useActiveProfile();

  const getItemLayout = useMemo(() => {
    return getItemLayoutFactory(hubItems, windowDimensions);
  }, [hubItems, windowDimensions]);

  // auto-fullscreen
  useEffect(() => {
    if (fullScreen === undefined && item) {
      setFullScreen(
        // only on devices
        Platform.OS !== 'web' &&
          // not when autoplay is prevented
          !preventAutoplay &&
          // not on locked items
          !item.isLocked &&
          // not when a book has no pages
          !bookHasNoPages &&
          // not on playlists or videos or songs
          !(
            item.__typename === MediaType.Playlist ||
            item.__typename === MediaType.Video ||
            item.__typename === MediaType.Song
          ),
      );
    }
  }, [preventAutoplay, fullScreen, item, showFullScreenButton, bookHasNoPages]);

  useEffect(() => {
    // sometimes (video ended in playlist) you could end up on a locked item in fullscreen state, auto close that
    if (fullScreen && isLocked) {
      setFullScreen(false);
    }
  }, [fullScreen, isLocked]);

  // Local view tracking
  useEffect(() => {
    if (activeHubItem && item && markMediaAsRecentlyViewed) {
      markMediaAsRecentlyViewed(item.__typename === MediaType.Playlist ? item : activeHubItem);
    }
  }, [markMediaAsRecentlyViewed, item, activeHubItem]);

  // Anyltics tracking
  useEffect(() => {
    if (!item || item.__typename !== MediaType.Playlist) return;
    analyticsTracker.trackContent('view', item);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item?.id]);
  useEffect(() => {
    if (!activeHubItem || activeHubItem.__typename === MediaType.Playlist) return;
    analyticsTracker.trackContent('view', activeHubItem);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeHubItem?.id]);

  const previewOrPlaceholder = (): ImageSourcePropType => {
    const preview = getMediaPreview(activeHubItem);
    if (preview) {
      return {
        uri: preview.source,
      };
    }
    return lockedPlaceholder as ImageSourcePropType;
  };

  const handleContentEnded = setNextHubItemActive;

  return (
    <ImageBackground
      source={background as ImageSourcePropType}
      resizeMode="cover"
      style={tw`flex justify-between h-full`}
    >
      <KeepAwake />
      {!fullScreen && (
        <View
          style={[
            tw`py-2 flex-row justify-between items-start`,
            { paddingLeft: insets.left, paddingRight: insets.right },
          ]}
        >
          <Inline spacing="md" style={tw`items-center flex-1`}>
            <MenuBack onPress={() => navigation.goBack()} />
            <View>
              {item?.__typename === MediaType.Playlist && (
                <TextVariant variant="h3">{item?.title || ''}</TextVariant>
              )}
              <TextVariant
                variant="h1"
                maxFontSizeMultiplier={1}
                minimumFontScale={0.5}
                adjustsFontSizeToFit
                style={Platform.select({ native: { width: '90%' } })}
                numberOfLines={1}
              >
                {activeHubItem?.title || ''}
              </TextVariant>
            </View>
          </Inline>
          {!isLocked && (
            <Inline spacing="md">
              <FavoriteButton media={activeHubItem} />
              <DownloadButton media={activeHubItem} />
              {showFullScreenButton && (
                <PressableGrow
                  onPress={() => {
                    setFullScreen(true);
                  }}
                  style={tw`rounded-full`}
                >
                  <Coin icon="fullscreen" />
                </PressableGrow>
              )}
            </Inline>
          )}
        </View>
      )}

      {isLocked ? (
        <View style={tw`flex-1 justify-center items-center flex-row`}>
          <ImageBackground source={previewOrPlaceholder()} style={tw`rounded-xl overflow-hidden`}>
            <View style={tw`bg-white bg-opacity-50 rounded-xl`}>
              <Inline
                direction="col"
                spacing="md"
                style={tw`bg-blue p-8 tablet:p-16 rounded-xl bg-opacity-50`}
              >
                <Icon name="lock" style={tw`text-3xl tablet:text-6xl text-white`} />
                <TextVariant style={tw`text-white`}>{t('goPassForProximusCustomers')}</TextVariant>
                <ProximusButton
                  onPress={() => {
                    requestMediaItem(activeHubItem);
                  }}
                >
                  {t('cookiesOverlayDescriptionLink')}
                </ProximusButton>
              </Inline>
            </View>
          </ImageBackground>
        </View>
      ) : (
        <View
          style={[
            tw`flex-1`,
            !fullScreen ? tw`py-2 tablet:py-4` : {},
            !fullScreen ? { paddingLeft: insets.left, paddingRight: insets.right } : {},
          ]}
        >
          <Preloader
            loading={networkStatus < NetworkStatus.ready}
            error={networkStatus === NetworkStatus.error ? t('generalErrorMessage') : undefined}
            renderSpinner={() => <DecorativeLoader />}
            skeleton={<View style={tw`w-full h-full`} />}
            empty={!item || bookHasNoPages}
            emptyMessage={t('notFoundDescription')}
          >
            {() => (
              <>
                {activeHubItem?.__typename === MediaType.Video && (
                  <VideoMediaDetail
                    item={activeHubItem}
                    autoFullscreen
                    autoplay={!preventAutoplay}
                    onEnded={handleContentEnded}
                    isFullScreen={fullScreen}
                    setIsFullScreen={setFullScreen}
                  />
                )}
                {activeHubItem?.__typename === MediaType.Game && (
                  <GameMediaDetail item={activeHubItem} fullScreen={fullScreen} />
                )}
                {activeHubItem?.__typename === MediaType.Book && (
                  <BookMediaDetail item={activeHubItem} fullScreen={fullScreen} />
                )}
                {activeHubItem?.__typename === MediaType.Song && (
                  <SongMediaDetail
                    item={activeHubItem}
                    autoFullscreen
                    autoplay={!preventAutoplay}
                    onEnded={handleContentEnded}
                    isFullScreen={fullScreen}
                    setIsFullScreen={setFullScreen}
                  />
                )}
              </>
            )}
          </Preloader>
        </View>
      )}

      {fullScreen && showFullScreenButton && (
        <View style={[tw`absolute top-2 right-3 z-10 opacity-50`, { right: insets.right }]}>
          <PressableGrow
            onPress={() => {
              setFullScreen(false);
            }}
            style={tw`rounded-full`}
          >
            <Coin icon="exit-fullscreen" />
          </PressableGrow>
        </View>
      )}

      {!fullScreen && (
        <View
          style={[
            tw`pt-2 pb-4 tablet:py-5 bg-green-450 bg-opacity-50`,
            {
              paddingLeft: insets.left,
              paddingRight: insets.right,
            },
          ]}
        >
          <Preloader loading={networkStatus < NetworkStatus.ready} skeleton={<SwimlaneSkeleton />}>
            {() =>
              hubItems && (
                <Swimlane
                  style={[tw`-mt-2 pt-2 -mb-4 pb-4`, tw`tablet:-my-5 tablet:py-5`]}
                  insets={insets}
                  data={hubItems}
                  activeIndex={activeHubItemIndex}
                  getItemLayout={getItemLayout}
                  renderItem={({ item: hubItem }) => (
                    <MediaSwimlaneItem
                      item={hubItem}
                      width={getRelatedMediaTileWidth(hubItem, windowDimensions)}
                      tileProps={{
                        highlight:
                          activeHubItem === hubItem
                            ? { borderColor: 'border-orange', icon: undefined }
                            : false,
                      }}
                      onPress={() => {
                        if (item && activeHubItem) {
                          analyticsTracker.setNavigationEventMeta({
                            id:
                              item.__typename === MediaType.Playlist ? item.id : activeHubItem?.id,
                            type: NavigationEventSourceType.Minihub,
                            route,
                          });
                        }
                        if (hubItem.isLocked) {
                          requestMediaItem(hubItem);
                        } else {
                          changeActiveHubItem(hubItem.id);
                        }
                      }}
                    />
                  )}
                />
              )
            }
          </Preloader>
        </View>
      )}
    </ImageBackground>
  );
};
