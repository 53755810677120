import * as Types from '~/shared/api/models.main.generated';

import { gql } from '@apollo/client';
import { ListMediaItemFragmentDoc } from './list-media-item.main.generated';
import { ListBrandItemFragmentDoc } from './list-brand-item.main.generated';
export type IGQLListFeedFragment = (
  { __typename: 'Feed' }
  & Pick<Types.IGQLFeed, 'id' | 'itemTileType' | 'feedLayoutType'>
  & { title: (
    { __typename: 'Text' }
    & Pick<Types.IGQLText, 'type' | 'content'>
  ), icon: Types.Maybe<(
    { __typename: 'Icon' }
    & Pick<Types.IGQLIcon, 'name' | 'color'>
  )>, button: Types.Maybe<(
    { __typename: 'Button' }
    & Pick<Types.IGQLButton, 'title'>
    & { link: (
      { __typename: 'Link' }
      & Pick<Types.IGQLLink, 'screen' | 'params'>
    ) }
  )>, result: Types.Maybe<(
    { __typename: 'FeedResult' }
    & Pick<Types.IGQLFeedResult, 'fetchMoreToken'>
    & { items: Array<(
      { __typename: 'Book' }
      & Pick<Types.IGQLBook, 'id' | 'title' | 'isFeatured' | 'isLocked'>
      & { tile: (
        { __typename: 'TileProperties' }
        & Pick<Types.IGQLTileProperties, 'title' | 'subTitle'>
      ), brand: (
        { __typename: 'Brand' }
        & Pick<Types.IGQLBrand, 'id' | 'slug' | 'name'>
      ), previews: Types.Maybe<(
        { __typename: 'MediaPreviews' }
        & { medium: Types.Maybe<(
          { __typename: 'Asset' }
          & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
        )> }
      )> }
    ) | (
      { __typename: 'Brand' }
      & Pick<Types.IGQLBrand, 'id' | 'slug' | 'name'>
      & { images: Types.Maybe<(
        { __typename: 'BrandPreviews' }
        & { medium: Types.Maybe<(
          { __typename: 'Asset' }
          & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
        )> }
      )> }
    ) | (
      { __typename: 'Game' }
      & Pick<Types.IGQLGame, 'id' | 'title' | 'isFeatured' | 'isLocked'>
      & { tile: (
        { __typename: 'TileProperties' }
        & Pick<Types.IGQLTileProperties, 'title' | 'subTitle'>
      ), brand: (
        { __typename: 'Brand' }
        & Pick<Types.IGQLBrand, 'id' | 'slug' | 'name'>
      ), previews: Types.Maybe<(
        { __typename: 'MediaPreviews' }
        & { medium: Types.Maybe<(
          { __typename: 'Asset' }
          & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
        )> }
      )> }
    ) | (
      { __typename: 'Playlist' }
      & Pick<Types.IGQLPlaylist, 'itemCount' | 'id' | 'title' | 'isFeatured' | 'isLocked'>
      & { tile: (
        { __typename: 'TileProperties' }
        & Pick<Types.IGQLTileProperties, 'title' | 'subTitle'>
      ), brand: (
        { __typename: 'Brand' }
        & Pick<Types.IGQLBrand, 'id' | 'slug' | 'name'>
      ), previews: Types.Maybe<(
        { __typename: 'MediaPreviews' }
        & { medium: Types.Maybe<(
          { __typename: 'Asset' }
          & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
        )> }
      )> }
    ) | (
      { __typename: 'Serie' }
      & Pick<Types.IGQLSerie, 'id' | 'title' | 'isFeatured' | 'isLocked'>
      & { posters: Types.Maybe<(
        { __typename: 'MediaPosters' }
        & { medium: Types.Maybe<(
          { __typename: 'Asset' }
          & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
        )>, small: Types.Maybe<(
          { __typename: 'Asset' }
          & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
        )> }
      )>, tile: (
        { __typename: 'TileProperties' }
        & Pick<Types.IGQLTileProperties, 'title' | 'subTitle'>
      ), brand: (
        { __typename: 'Brand' }
        & Pick<Types.IGQLBrand, 'id' | 'slug' | 'name'>
      ), previews: Types.Maybe<(
        { __typename: 'MediaPreviews' }
        & { medium: Types.Maybe<(
          { __typename: 'Asset' }
          & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
        )> }
      )> }
    ) | (
      { __typename: 'Song' }
      & Pick<Types.IGQLSong, 'duration' | 'id' | 'title' | 'isFeatured' | 'isLocked'>
      & { songContentType: Types.IGQLSong['contentType'] }
      & { tile: (
        { __typename: 'TileProperties' }
        & Pick<Types.IGQLTileProperties, 'title' | 'subTitle'>
      ), brand: (
        { __typename: 'Brand' }
        & Pick<Types.IGQLBrand, 'id' | 'slug' | 'name'>
      ), previews: Types.Maybe<(
        { __typename: 'MediaPreviews' }
        & { medium: Types.Maybe<(
          { __typename: 'Asset' }
          & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
        )> }
      )> }
    ) | (
      { __typename: 'Video' }
      & Pick<Types.IGQLVideo, 'duration' | 'seasonId' | 'serieId' | 'id' | 'title' | 'isFeatured' | 'isLocked'>
      & { videoContentType: Types.IGQLVideo['contentType'] }
      & { posters: Types.Maybe<(
        { __typename: 'MediaPosters' }
        & { medium: Types.Maybe<(
          { __typename: 'Asset' }
          & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
        )>, small: Types.Maybe<(
          { __typename: 'Asset' }
          & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
        )> }
      )>, tile: (
        { __typename: 'TileProperties' }
        & Pick<Types.IGQLTileProperties, 'title' | 'subTitle'>
      ), brand: (
        { __typename: 'Brand' }
        & Pick<Types.IGQLBrand, 'id' | 'slug' | 'name'>
      ), previews: Types.Maybe<(
        { __typename: 'MediaPreviews' }
        & { medium: Types.Maybe<(
          { __typename: 'Asset' }
          & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
        )> }
      )> }
    )> }
  )> }
);

export const ListFeedFragmentDoc = gql`
    fragment ListFeed on Feed {
  id
  title {
    type
    content
  }
  icon {
    name
    color
  }
  button {
    title
    link {
      screen
      params
    }
  }
  itemTileType
  feedLayoutType
  result {
    fetchMoreToken
    items {
      ...ListMediaItem
      ...ListBrandItem
    }
  }
}
    ${ListMediaItemFragmentDoc}
${ListBrandItemFragmentDoc}`;