import tw from '~/shared/theme';

export const CheckboxStyles = {
  box: {
    default: tw`bg-white border border-gray-200 w-6 h-6 rounded-sm items-center justify-center`,
    error: tw`border-red`,
    focussed: tw`border-yellow`,
  },
  check: tw`text-green`,
};
