import { useEffect } from 'react';
import { setClientHeader } from '~/shared/api/main.api';
import { useProximus } from '../model';

export function useProximusEffect(): { sync: () => Promise<void> } {
  const { tokenInfo, sync, hasHydrated } = useProximus((state) => ({
    tokenInfo: state.tokenInfo,
    sync: state.sync,
    hasHydrated: state._hasHydrated,
  }));

  useEffect(() => {
    if (!hasHydrated) return;

    if (tokenInfo?.access_token) {
      setClientHeader(
        'authorization',
        `${tokenInfo.token_type || 'Bearer'} ${tokenInfo.access_token}`,
      );
    } else {
      setClientHeader('authorization', undefined);
    }
  }, [tokenInfo, hasHydrated]);

  return { sync };
}
