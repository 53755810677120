import DeviceInfo from 'react-native-device-info';
import useLazyState from './utils/use-lazy-state';

export default {
  bundleId: 'web',
  otaCodeVersion: process.env.REACT_APP_BUILD_NUMBER,
  nativeCodeVersion: process.env.REACT_APP_BUILD_NUMBER,
  codeVersion: process.env.REACT_APP_BUILD_NUMBER,
  marketingVersion: process.env.REACT_APP_MARKETING_VERSION || '',
};

export function useSystemIdentifier(): string {
  const [userAgent] = useLazyState(DeviceInfo.getUserAgent);

  return `${userAgent || ''}`;
}
