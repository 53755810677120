import React, { FunctionComponent, useState } from 'react';
import { useViewer } from '~/entities/viewer';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable } from 'react-native';
import { isValidEmail } from '~/shared/utils/validators/email';
import tw from '~/shared/theme';
import { TextVariant } from '~/shared/ui/text-variant';
import { Card } from '~/shared/ui/card';
import { Inline, GapSpacer, Fill } from '~/shared/ui/spacing';
import { Button } from '~/shared/ui/button';
import { Icon } from '~/shared/ui/icon';
import { Checkbox, TextInput } from '~/shared/ui/input';
import { logger } from '~/shared/tracking/logs';
import { useAppConfig } from '~/entities/app-config';
import { openURL } from '~/shared/utils/browser';

interface OnboardingSignupProps {
  onContinue?(): void;
}

export const EmailSignup: FunctionComponent<OnboardingSignupProps> = ({ onContinue }) => {
  const { t, i18n } = useTranslation();
  const config = useAppConfig();
  const setEmail = useViewer((state) => state.setEmail);

  const [emailValue, setEmailValue] = useState('');
  const emailValid = useMemo(() => isValidEmail(emailValue), [emailValue]);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [errors, setErrors] = useState<{ email?: string; submit?: unknown }>({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async () => {
    if (!acceptedTerms) return;
    if (isSubmitting) return;

    if (!emailValid) {
      setErrors((previousErrors) => ({ ...previousErrors, email: t('loginEmailErrorMessage') }));
      return;
    }

    setErrors((previousErrors) => ({ ...previousErrors, email: undefined }));
    setIsSubmitting(true);
    try {
      await setEmail(emailValue);
      onContinue?.();
    } catch (e) {
      logger.error(e);
      setIsSubmitting(false);
      setErrors((previousErrors) => ({
        ...previousErrors,
        email: t('registerEmailErrorMessage'),
        submit: e,
      }));
    }
  };

  return (
    <Card>
      <GapSpacer spacing="md">
        <TextVariant variant="page-title" style={tw`text-center mb-2`}>
          {t('loginTitle')}
        </TextVariant>
        <TextVariant style={tw`text-center mb-2`}>{t('loginDescription')}</TextVariant>
        <TextInput
          textContentType="emailAddress"
          autoComplete="email"
          keyboardType="email-address"
          autoCapitalize="none"
          spellCheck={false}
          placeholder={t('loginEmailTextDefault')}
          value={emailValue}
          // trim email address during input, spaces in e-mail addresses are invalid anyway
          onChangeText={(value) => setEmailValue(value.trim())}
          error={errors.email}
          onSubmitEditing={handleSubmit}
        />
        <Inline>
          <Checkbox
            value={acceptedTerms}
            onChange={setAcceptedTerms}
            label={
              <Inline style={tw`ios:-mb-1.5`}>
                <TextVariant variant="small">{t('acceptPrivacyPolicyLabel')}</TextVariant>
                <Pressable
                  style={[tw`web:cursor-pointer`]}
                  onPress={(event) => {
                    event.preventDefault();
                    try {
                      openURL(
                        config?.privacyPolicyUrl[
                          i18n.language as keyof typeof config.privacyPolicyUrl
                        ] as string,
                        { webTarget: '_blank' },
                      );
                    } catch (e) {}
                  }}
                >
                  <TextVariant variant="small" style={tw`font-sans-bold`}>
                    {t('privacyPolicyLinkLabel')}
                  </TextVariant>
                </Pressable>
              </Inline>
            }
          />
          <Fill />

          <Button
            disabled={isSubmitting || !acceptedTerms || !isValidEmail}
            variant="submit"
            decorationRight={<Icon name="arrow-right" />}
            onPress={handleSubmit}
            style={tw`self-end`}
          >
            {!isSubmitting ? t('loginEmailButtonLabel') : t('loading')}
          </Button>
        </Inline>
      </GapSpacer>
    </Card>
  );
};
