import React, { FunctionComponent } from 'react';
import { useActiveProfile } from '~/entities/profiles';
import tw from '~/shared/theme';
import { Icon } from '~/shared/ui/icon';
import { Coin } from '~/shared/ui/menu';
import { PressableGrow } from '~/shared/ui/pressables';
import { IMedia } from '~/types';

interface FavoriteButtonProps {
  media?: IMedia;
}

export const FavoriteButton: FunctionComponent<FavoriteButtonProps> = ({ media }) => {
  const { activeProfile, toggleFavoriteMedia } = useActiveProfile();

  if (!media || !activeProfile) return null;

  const isFavorite = !!activeProfile.favoriteMedia.find((m) => m.id === media.id);

  return (
    <PressableGrow onPress={() => toggleFavoriteMedia?.(media)} style={tw`rounded-full`}>
      <Coin>
        <Icon
          name="heart"
          style={tw`mt-1 text-xl tablet:mt-1 tablet:text-2xl`}
          color={tw.color(isFavorite ? 'pink' : 'white')}
        />
      </Coin>
    </PressableGrow>
  );
};
