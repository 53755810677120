import React, { FunctionComponent } from 'react';
import { Animated } from 'react-native';
import tw from '~/shared/theme';
import { Button } from '../button';
import { Icon } from '../icon';
import { ArrowButtonProps } from './arrow-button.base';

const styles = {
  left: {
    container: tw`left-0`,
    button: tw`p-0 rounded-tl-0 rounded-bl-0`,
  },
  right: {
    container: tw`right-0`,
    button: tw`p-0 rounded-tr-0 rounded-br-0`,
  },
};

export const ArrowButton: FunctionComponent<ArrowButtonProps> = ({
  direction,
  page,
  pages,
  onPress,
}) => {
  const { offset, opacity, inputRange } = {
    left: {
      inputRange: [0, 1],
      offset: [-100, 0],
      opacity: [0, 1],
    },
    right: {
      inputRange: [pages.length - 2, pages.length - 1],
      offset: [0, 100],
      opacity: [1, 0],
    },
  }[direction];
  return (
    <Animated.View
      style={[
        tw`absolute h-full justify-center`,
        styles[direction].container,
        {
          opacity: page.interpolate({
            inputRange: inputRange,
            outputRange: opacity,
            extrapolate: 'clamp',
          }),
          transform: [
            {
              translateX: page.interpolate({
                inputRange: inputRange,
                outputRange: offset,
                extrapolate: 'clamp',
              }),
            },
          ],
        },
      ]}
    >
      <Button
        onPress={onPress}
        children={<Icon name={`arrow-${direction}`} />}
        style={styles[direction].button}
      />
    </Animated.View>
  );
};
