import * as Types from '~/shared/api/models.main.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {"context":{"clientName":"main"}} as const;
export type IGQLGetAppConfigQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type IGQLGetAppConfigQueryData = (
  { __typename: 'Query' }
  & { appConfig: Types.Maybe<(
    { __typename: 'AppConfig' }
    & { proximus: (
      { __typename: 'ProximusConfig' }
      & Pick<Types.IGQLProximusConfig, 'authorizeUrl' | 'logoutUrl' | 'refreshTokenUrl' | 'validateTokenUrl' | 'revokeTokenUrl' | 'environmentIdentifier'>
    ), wieni: (
      { __typename: 'WieniConfig' }
      & Pick<Types.IGQLWieniConfig, 'apiUrl' | 'apiKey'>
    ), privacyPolicyUrl: (
      { __typename: 'PrivacyPolicyUrlConfig' }
      & Pick<Types.IGQLPrivacyPolicyUrlConfig, 'nl' | 'fr'>
    ), faqUrl: (
      { __typename: 'FaqUrlConfig' }
      & Pick<Types.IGQLFaqUrlConfig, 'nl' | 'fr'>
    ), brandMenuCoinImages: Types.Maybe<(
      { __typename: 'BrandMenuCoinImages' }
      & Pick<Types.IGQLBrandMenuCoinImages, 'nl' | 'fr'>
    )> }
  )> }
);


export const GetAppConfigOperation = gql`
    query GetAppConfig {
  appConfig {
    proximus {
      authorizeUrl
      logoutUrl
      refreshTokenUrl
      validateTokenUrl
      revokeTokenUrl
      environmentIdentifier
    }
    wieni {
      apiUrl
      apiKey
    }
    privacyPolicyUrl {
      nl
      fr
    }
    faqUrl {
      nl
      fr
    }
    brandMenuCoinImages {
      nl
      fr
    }
  }
}
    `;
export function useGetAppConfigQuery(baseOptions?: Apollo.QueryHookOptions<IGQLGetAppConfigQueryData, IGQLGetAppConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGQLGetAppConfigQueryData, IGQLGetAppConfigQueryVariables>(GetAppConfigOperation, options);
      }
export function useGetAppConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGQLGetAppConfigQueryData, IGQLGetAppConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGQLGetAppConfigQueryData, IGQLGetAppConfigQueryVariables>(GetAppConfigOperation, options);
        }
export function useGetAppConfigSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGQLGetAppConfigQueryData, IGQLGetAppConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGQLGetAppConfigQueryData, IGQLGetAppConfigQueryVariables>(GetAppConfigOperation, options);
        }
export type GetAppConfigQueryHookResult = ReturnType<typeof useGetAppConfigQuery>;
export type GetAppConfigLazyQueryHookResult = ReturnType<typeof useGetAppConfigLazyQuery>;
export type GetAppConfigSuspenseQueryHookResult = ReturnType<typeof useGetAppConfigSuspenseQuery>;
export type GetAppConfigQueryResult = Apollo.QueryResult<IGQLGetAppConfigQueryData, IGQLGetAppConfigQueryVariables>;