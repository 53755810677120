import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import tw from '~/shared/theme';
import { Icon, IconProps } from '../icon';

interface SpinnerProps {
  size?: IconProps['size'];
  style?: IconProps['style'];
}

export const Spinner: FunctionComponent<SpinnerProps> = ({ size = 24, style }) => {
  // @topolish icon slightly bobbing up and down, check if applicable on mobile as well
  return (
    <View style={[tw`spin`, tw`items-center justify-center w-12 h-12`]}>
      <Icon name="spinner" size={size} style={[tw`text-white leading-none m-0`, style]} />
    </View>
  );
};
