import * as Types from '~/shared/api/models.main.generated';

import { gql } from '@apollo/client';
import { ListFeedFragmentDoc } from '../../../../shared/api/fragments/list-feed.main.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {"context":{"clientName":"main"}} as const;
export type IGQLGetBrandFeedsQueryVariables = Types.Exact<{
  language: Types.Scalars['String']['input'];
  brandId: Types.Scalars['ID']['input'];
  medium?: Types.InputMaybe<Types.IGQLMediaMedium>;
  favoriteBrands: Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input'];
  favoriteMedia: Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input'];
  recentlyViewed: Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input'];
}>;


export type IGQLGetBrandFeedsQueryData = (
  { __typename: 'Query' }
  & { brandFeeds: Types.Maybe<(
    { __typename: 'Feeds' }
    & { items: Array<(
      { __typename: 'Feed' }
      & Pick<Types.IGQLFeed, 'id' | 'itemTileType' | 'feedLayoutType'>
      & { title: (
        { __typename: 'Text' }
        & Pick<Types.IGQLText, 'type' | 'content'>
      ), icon: Types.Maybe<(
        { __typename: 'Icon' }
        & Pick<Types.IGQLIcon, 'name' | 'color'>
      )>, button: Types.Maybe<(
        { __typename: 'Button' }
        & Pick<Types.IGQLButton, 'title'>
        & { link: (
          { __typename: 'Link' }
          & Pick<Types.IGQLLink, 'screen' | 'params'>
        ) }
      )>, result: Types.Maybe<(
        { __typename: 'FeedResult' }
        & Pick<Types.IGQLFeedResult, 'fetchMoreToken'>
        & { items: Array<(
          { __typename: 'Book' }
          & Pick<Types.IGQLBook, 'id' | 'title' | 'isFeatured' | 'isLocked'>
          & { tile: (
            { __typename: 'TileProperties' }
            & Pick<Types.IGQLTileProperties, 'title' | 'subTitle'>
          ), brand: (
            { __typename: 'Brand' }
            & Pick<Types.IGQLBrand, 'id' | 'slug' | 'name'>
          ), previews: Types.Maybe<(
            { __typename: 'MediaPreviews' }
            & { medium: Types.Maybe<(
              { __typename: 'Asset' }
              & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
            )> }
          )> }
        ) | (
          { __typename: 'Brand' }
          & Pick<Types.IGQLBrand, 'id' | 'slug' | 'name'>
          & { images: Types.Maybe<(
            { __typename: 'BrandPreviews' }
            & { medium: Types.Maybe<(
              { __typename: 'Asset' }
              & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
            )> }
          )> }
        ) | (
          { __typename: 'Game' }
          & Pick<Types.IGQLGame, 'id' | 'title' | 'isFeatured' | 'isLocked'>
          & { tile: (
            { __typename: 'TileProperties' }
            & Pick<Types.IGQLTileProperties, 'title' | 'subTitle'>
          ), brand: (
            { __typename: 'Brand' }
            & Pick<Types.IGQLBrand, 'id' | 'slug' | 'name'>
          ), previews: Types.Maybe<(
            { __typename: 'MediaPreviews' }
            & { medium: Types.Maybe<(
              { __typename: 'Asset' }
              & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
            )> }
          )> }
        ) | (
          { __typename: 'Playlist' }
          & Pick<Types.IGQLPlaylist, 'itemCount' | 'id' | 'title' | 'isFeatured' | 'isLocked'>
          & { tile: (
            { __typename: 'TileProperties' }
            & Pick<Types.IGQLTileProperties, 'title' | 'subTitle'>
          ), brand: (
            { __typename: 'Brand' }
            & Pick<Types.IGQLBrand, 'id' | 'slug' | 'name'>
          ), previews: Types.Maybe<(
            { __typename: 'MediaPreviews' }
            & { medium: Types.Maybe<(
              { __typename: 'Asset' }
              & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
            )> }
          )> }
        ) | (
          { __typename: 'Serie' }
          & Pick<Types.IGQLSerie, 'id' | 'title' | 'isFeatured' | 'isLocked'>
          & { posters: Types.Maybe<(
            { __typename: 'MediaPosters' }
            & { medium: Types.Maybe<(
              { __typename: 'Asset' }
              & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
            )>, small: Types.Maybe<(
              { __typename: 'Asset' }
              & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
            )> }
          )>, tile: (
            { __typename: 'TileProperties' }
            & Pick<Types.IGQLTileProperties, 'title' | 'subTitle'>
          ), brand: (
            { __typename: 'Brand' }
            & Pick<Types.IGQLBrand, 'id' | 'slug' | 'name'>
          ), previews: Types.Maybe<(
            { __typename: 'MediaPreviews' }
            & { medium: Types.Maybe<(
              { __typename: 'Asset' }
              & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
            )> }
          )> }
        ) | (
          { __typename: 'Song' }
          & Pick<Types.IGQLSong, 'duration' | 'id' | 'title' | 'isFeatured' | 'isLocked'>
          & { songContentType: Types.IGQLSong['contentType'] }
          & { tile: (
            { __typename: 'TileProperties' }
            & Pick<Types.IGQLTileProperties, 'title' | 'subTitle'>
          ), brand: (
            { __typename: 'Brand' }
            & Pick<Types.IGQLBrand, 'id' | 'slug' | 'name'>
          ), previews: Types.Maybe<(
            { __typename: 'MediaPreviews' }
            & { medium: Types.Maybe<(
              { __typename: 'Asset' }
              & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
            )> }
          )> }
        ) | (
          { __typename: 'Video' }
          & Pick<Types.IGQLVideo, 'duration' | 'seasonId' | 'serieId' | 'id' | 'title' | 'isFeatured' | 'isLocked'>
          & { videoContentType: Types.IGQLVideo['contentType'] }
          & { posters: Types.Maybe<(
            { __typename: 'MediaPosters' }
            & { medium: Types.Maybe<(
              { __typename: 'Asset' }
              & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
            )>, small: Types.Maybe<(
              { __typename: 'Asset' }
              & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
            )> }
          )>, tile: (
            { __typename: 'TileProperties' }
            & Pick<Types.IGQLTileProperties, 'title' | 'subTitle'>
          ), brand: (
            { __typename: 'Brand' }
            & Pick<Types.IGQLBrand, 'id' | 'slug' | 'name'>
          ), previews: Types.Maybe<(
            { __typename: 'MediaPreviews' }
            & { medium: Types.Maybe<(
              { __typename: 'Asset' }
              & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
            )> }
          )> }
        )> }
      )> }
    )> }
  )> }
);


export const GetBrandFeedsOperation = gql`
    query GetBrandFeeds($language: String!, $brandId: ID!, $medium: MediaMedium, $favoriteBrands: [ID!]!, $favoriteMedia: [ID!]!, $recentlyViewed: [ID!]!) {
  brandFeeds(
    brand: $brandId
    medium: $medium
    profilePreferences: {language: $language, favoriteBrands: $favoriteBrands, favoriteMedia: $favoriteMedia, recentlyViewed: $recentlyViewed}
  ) {
    items {
      ...ListFeed
    }
  }
}
    ${ListFeedFragmentDoc}`;
export function useGetBrandFeedsQuery(baseOptions: Apollo.QueryHookOptions<IGQLGetBrandFeedsQueryData, IGQLGetBrandFeedsQueryVariables> & ({ variables: IGQLGetBrandFeedsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGQLGetBrandFeedsQueryData, IGQLGetBrandFeedsQueryVariables>(GetBrandFeedsOperation, options);
      }
export function useGetBrandFeedsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGQLGetBrandFeedsQueryData, IGQLGetBrandFeedsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGQLGetBrandFeedsQueryData, IGQLGetBrandFeedsQueryVariables>(GetBrandFeedsOperation, options);
        }
export function useGetBrandFeedsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGQLGetBrandFeedsQueryData, IGQLGetBrandFeedsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGQLGetBrandFeedsQueryData, IGQLGetBrandFeedsQueryVariables>(GetBrandFeedsOperation, options);
        }
export type GetBrandFeedsQueryHookResult = ReturnType<typeof useGetBrandFeedsQuery>;
export type GetBrandFeedsLazyQueryHookResult = ReturnType<typeof useGetBrandFeedsLazyQuery>;
export type GetBrandFeedsSuspenseQueryHookResult = ReturnType<typeof useGetBrandFeedsSuspenseQuery>;
export type GetBrandFeedsQueryResult = Apollo.QueryResult<IGQLGetBrandFeedsQueryData, IGQLGetBrandFeedsQueryVariables>;