import React from 'react';
import { defaultScreenOptions, OnboardingRoutes, OnboardingStack } from './index';
import { OnboardingPage } from '~/pages/onboarding/onboarding.page';
import { OnboardingProfilePage } from '~/pages/onboarding/onboarding-profile.page';
import { OnboardingStep, useViewer } from '~/entities/viewer';

const OnboardingNavigator: React.FC = () => {
  const onboardingStep = useViewer((state) => state.onboardingStep);

  return (
    <OnboardingStack.Navigator
      screenOptions={defaultScreenOptions}
      initialRouteName={
        onboardingStep === OnboardingStep.Profile ? OnboardingRoutes.Profile : OnboardingRoutes.Main
      }
    >
      <OnboardingStack.Screen name={OnboardingRoutes.Main} component={OnboardingPage} />
      <OnboardingStack.Screen name={OnboardingRoutes.Profile} component={OnboardingProfilePage} />
    </OnboardingStack.Navigator>
  );
};

export default OnboardingNavigator;
