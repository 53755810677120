import { IAsset, IBrand } from '~/types';
import { prefixLocalUri } from './media';

export function getBrandBanner(
  brand?: Partial<IBrand>,
  highestConversionPreference: 'large' | 'medium' | 'small' = 'large',
): IAsset | null {
  if (brand?.banners?.local) {
    return {
      ...brand.banners.local,
      source: prefixLocalUri(brand.banners.local.source),
    };
  }

  if (highestConversionPreference === 'large') {
    return brand?.banners?.large || brand?.banners?.medium || brand?.banners?.small || null;
  }
  if (highestConversionPreference === 'medium') {
    return brand?.banners?.medium || brand?.banners?.small || brand?.banners?.large || null;
  }

  return brand?.banners?.small || brand?.banners?.medium || brand?.banners?.large || null;
}
