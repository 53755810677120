import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { useProfiles } from '~/entities/profiles';
import tw from '~/shared/theme';
import { Button } from '~/shared/ui/button';
import { Card } from '~/shared/ui/card';
import { Modal } from '~/shared/ui/modal';
import { GapSpacer, Inline } from '~/shared/ui/spacing';
import { TextVariant } from '~/shared/ui/text-variant';

interface ProfileDeleteRequestModalProps {}

export const ProfileDeleteRequestModal: FunctionComponent<ProfileDeleteRequestModalProps> = () => {
  const { t } = useTranslation();
  const { deleteProfile, requestDeleteProfile, deleteRequest } = useProfiles((state) => ({
    deleteProfile: state.deleteProfile,
    requestDeleteProfile: state.requestDeleteProfile,
    deleteRequest: state.deleteRequest,
  }));

  return (
    <Modal visible={!!deleteRequest}>
      <Card>
        <GapSpacer>
          <View>
            <TextVariant variant="modal-title" style={tw`text-center`}>
              {t('deleteConfirmationTitle')}
            </TextVariant>

            <TextVariant variant="body" style={tw`text-center`}>
              {t('deleteProfileConfirmationText', { name: deleteRequest?.profile.name || '' })}
            </TextVariant>
          </View>

          <Inline spacing="md" style={tw`justify-center`}>
            <Button
              variant="danger"
              onPress={() => {
                deleteProfile(deleteRequest!.profile);
              }}
            >
              {t('deleteConfirmationYes')}
            </Button>
            <Button
              variant="secondary"
              onPress={() => {
                requestDeleteProfile(undefined);
              }}
            >
              {t('deleteConfirmationNo')}
            </Button>
          </Inline>
        </GapSpacer>
      </Card>
    </Modal>
  );
};
