import React, { FunctionComponent } from 'react';
import { BookPlayer } from '~/shared/ui/book-player';
import { prefixLocalUri } from '~/shared/utils/media';
import { IMediaBook } from '~/types';

interface BookMediaDetailProps {
  item: IMediaBook;
  fullScreen?: boolean;
}

export const BookMediaDetail: FunctionComponent<BookMediaDetailProps> = ({
  item,
  fullScreen = false,
}) => {
  return (
    <BookPlayer
      pages={item.resources?.map((page) => prefixLocalUri(page)) || item.bookPages!}
      fullScreen={fullScreen}
    />
  );
};
