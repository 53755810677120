import React, { FunctionComponent } from 'react';
import { View, StyleProp, ViewStyle } from 'react-native';
import tw from '~/shared/theme';
import { TextVariant } from '../text-variant';

interface BrandSpacerProps {
  style?: StyleProp<ViewStyle>;
  showIndent?: boolean;
  label?: string;
  children?: React.ReactNode;
}

export const BrandSpacer: FunctionComponent<BrandSpacerProps> = ({
  children,
  style,
  label,
  showIndent: indentThumbnail = false,
}) => (
  <View
    style={[
      tw`m-4 mb-10 bg-green-400 bg-opacity-40 px-5 rounded-xl self-stretch max-w-34 tablet:max-w-46`,
      style,
    ]}
  >
    <View
      style={[
        tw`w-24 tablet:w-36`,
        indentThumbnail
          ? tw`justify-self-stretch h-24 tablet:h-36 mt-9 rounded-full items-center justify-center border border-green-450 bg-green-400 z-10`
          : undefined,
      ]}
    >
      {children}
    </View>
    {indentThumbnail && (
      <View style={tw`h-5 mb-4 mt-5 bg-green-400 self-stretch rounded-full justify-end z-0`}>
        {label && (
          <TextVariant
            variant="small"
            style={[
              tw`justify-self-end px-2 py-1`,
              tw`bg-green-400 border border-green-450 rounded-lg`,
              tw`text-white`,
            ]}
          >
            {label}
          </TextVariant>
        )}
      </View>
    )}
  </View>
);
