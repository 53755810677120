const decorations = {
  blocks: require('./decoration-blocks.png'),
  book: require('./decoration-book.png'),
  eye: require('./decoration-eye.png'),
  game: require('./decoration-game.png'),
  music: require('./decoration-music.png'),
  play: require('./decoration-play.png'),
  stripes: require('./decoration-stripes.png'),
};
export type IDecorationName = keyof typeof decorations;
export const DecorationNames = Object.keys(decorations) as IDecorationName[];
export default decorations;
