import React, { Fragment, FunctionComponent } from 'react';
import { View } from 'react-native';
import { config } from '~/shared/theme';

type Spacing = keyof typeof config.theme.extend.gap | number;
export interface GapSpacerProps {
  spacing?: Spacing;
  direction?: 'row' | 'col' | 'both';
  children?: React.ReactNode;
}

export const getGapSpacingValue = (spacing: Spacing = 'md'): number => {
  return typeof spacing === 'number' ? spacing : config.theme.extend.gap[spacing] || 0;
};

export const Gap: FunctionComponent<GapSpacerProps> = ({ spacing = 'md', direction = 'col' }) => {
  const spacingValue = getGapSpacingValue(spacing);

  return (
    <View
      style={[
        direction === 'row' || direction === 'both' ? { width: spacingValue } : {},
        direction === 'col' || direction === 'both' ? { height: spacingValue } : {},
      ]}
    />
  );
};

export const GapSpacer: FunctionComponent<GapSpacerProps> = ({ children, ...gapProps }) => {
  let validElementCounter = 0;

  return (
    <>
      {React.Children.map(children, (child, i) => {
        if (!React.isValidElement(child)) {
          return child;
        }

        validElementCounter += 1;
        if (validElementCounter === 1) {
          return child;
        }

        return (
          <Fragment key={i}>
            <Gap {...gapProps} />
            {child}
          </Fragment>
        );
      })}
    </>
  );
};
