import React, { FunctionComponent, ReactElement } from 'react';
import {
  KeyboardAvoidingView,
  KeyboardAvoidingViewProps,
  Platform,
  ScrollViewProps,
  StyleProp,
  View,
  ViewStyle,
} from 'react-native';
import { ImageBackground } from '~/shared/ui/image';
import { ScrollView } from 'react-native-gesture-handler';
import tw from '~/shared/theme';
import { GapSpacer } from '~/shared/ui/spacing';
import { useInsetsOrPadding } from '~/shared/utils/use-insets-or-padding';

interface SettingsLayoutProps extends ScrollViewProps {
  avoidKeyboard?: boolean;
  contentContainerStyle?: StyleProp<ViewStyle>;
  decorationTop?: ReactElement;
  decorationAfter?: ReactElement;
}

export const SettingsLayout: FunctionComponent<SettingsLayoutProps> = ({
  children,
  avoidKeyboard = false,
  contentContainerStyle,
  decorationTop,
  decorationAfter,
  ...props
}) => {
  // android doesn't seem to need this
  const shouldAvoidKeyboard = avoidKeyboard && Platform.OS === 'ios';
  const Wrapper = shouldAvoidKeyboard ? KeyboardAvoidingView : React.Fragment;
  const wrapperProps: KeyboardAvoidingViewProps = shouldAvoidKeyboard
    ? { behavior: 'height', style: tw`flex-1` }
    : {};
  const insets = useInsetsOrPadding();
  const decoration = tw`absolute z-10`;
  const { left, right, top } = insets;
  return (
    <>
      {decorationTop && <View style={[decoration, { left, top, right }]}>{decorationTop}</View>}

      <Wrapper {...wrapperProps}>
        <ImageBackground
          source={require('~/assets/images/settings-bg.png')}
          resizeMode="cover"
          style={tw`flex-1`}
        >
          <ScrollView
            style={tw`flex-1`}
            showsHorizontalScrollIndicator={false}
            contentContainerStyle={[
              tw`p-5 w-full max-w-2xl py-28 tablet:py-32 mx-auto`,
              contentContainerStyle,
            ]}
            {...props}
          >
            <GapSpacer>{children}</GapSpacer>
          </ScrollView>
        </ImageBackground>
      </Wrapper>

      {decorationAfter}
    </>
  );
};
