import { useMemoizedActiveProfilePreferences } from '~/entities/profiles';
import { IRetryQuery, useRetryQuery } from '~/shared/utils/use-retry-query';
import { NetworkStatus, MediaMediumMapper, SpecificMediaMedium } from '~/types';
import { IGQLGetMediumFeedsQueryData, useGetMediumFeedsQuery } from '../api/queries.main.generated';

export function useMediumFeeds({ medium }: { medium: SpecificMediaMedium }): {
  feeds?: Exclude<IGQLGetMediumFeedsQueryData['mediumFeeds'], null>['items'];
  networkStatus: NetworkStatus;
  retryQuery: IRetryQuery;
} {
  const preferences = useMemoizedActiveProfilePreferences(20);
  const { data, ...query } = useGetMediumFeedsQuery({
    variables: {
      medium: MediaMediumMapper[medium],
      ...preferences,
    },
  });

  const { networkStatus, ...retryQuery } = useRetryQuery(query);

  return { feeds: data?.mediumFeeds?.items, networkStatus, retryQuery };
}
