import * as Types from '~/shared/api/models.main.generated';

import { gql } from '@apollo/client';
export type IGQLListMediaItem_Book_Fragment = (
  { __typename: 'Book' }
  & Pick<Types.IGQLBook, 'id' | 'title' | 'isFeatured' | 'isLocked'>
  & { tile: (
    { __typename: 'TileProperties' }
    & Pick<Types.IGQLTileProperties, 'title' | 'subTitle'>
  ), brand: (
    { __typename: 'Brand' }
    & Pick<Types.IGQLBrand, 'id' | 'slug' | 'name'>
  ), previews: Types.Maybe<(
    { __typename: 'MediaPreviews' }
    & { medium: Types.Maybe<(
      { __typename: 'Asset' }
      & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
    )> }
  )> }
);

export type IGQLListMediaItem_Game_Fragment = (
  { __typename: 'Game' }
  & Pick<Types.IGQLGame, 'id' | 'title' | 'isFeatured' | 'isLocked'>
  & { tile: (
    { __typename: 'TileProperties' }
    & Pick<Types.IGQLTileProperties, 'title' | 'subTitle'>
  ), brand: (
    { __typename: 'Brand' }
    & Pick<Types.IGQLBrand, 'id' | 'slug' | 'name'>
  ), previews: Types.Maybe<(
    { __typename: 'MediaPreviews' }
    & { medium: Types.Maybe<(
      { __typename: 'Asset' }
      & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
    )> }
  )> }
);

export type IGQLListMediaItem_Playlist_Fragment = (
  { __typename: 'Playlist' }
  & Pick<Types.IGQLPlaylist, 'itemCount' | 'id' | 'title' | 'isFeatured' | 'isLocked'>
  & { tile: (
    { __typename: 'TileProperties' }
    & Pick<Types.IGQLTileProperties, 'title' | 'subTitle'>
  ), brand: (
    { __typename: 'Brand' }
    & Pick<Types.IGQLBrand, 'id' | 'slug' | 'name'>
  ), previews: Types.Maybe<(
    { __typename: 'MediaPreviews' }
    & { medium: Types.Maybe<(
      { __typename: 'Asset' }
      & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
    )> }
  )> }
);

export type IGQLListMediaItem_Serie_Fragment = (
  { __typename: 'Serie' }
  & Pick<Types.IGQLSerie, 'id' | 'title' | 'isFeatured' | 'isLocked'>
  & { posters: Types.Maybe<(
    { __typename: 'MediaPosters' }
    & { medium: Types.Maybe<(
      { __typename: 'Asset' }
      & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
    )>, small: Types.Maybe<(
      { __typename: 'Asset' }
      & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
    )> }
  )>, tile: (
    { __typename: 'TileProperties' }
    & Pick<Types.IGQLTileProperties, 'title' | 'subTitle'>
  ), brand: (
    { __typename: 'Brand' }
    & Pick<Types.IGQLBrand, 'id' | 'slug' | 'name'>
  ), previews: Types.Maybe<(
    { __typename: 'MediaPreviews' }
    & { medium: Types.Maybe<(
      { __typename: 'Asset' }
      & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
    )> }
  )> }
);

export type IGQLListMediaItem_Song_Fragment = (
  { __typename: 'Song' }
  & Pick<Types.IGQLSong, 'duration' | 'id' | 'title' | 'isFeatured' | 'isLocked'>
  & { songContentType: Types.IGQLSong['contentType'] }
  & { tile: (
    { __typename: 'TileProperties' }
    & Pick<Types.IGQLTileProperties, 'title' | 'subTitle'>
  ), brand: (
    { __typename: 'Brand' }
    & Pick<Types.IGQLBrand, 'id' | 'slug' | 'name'>
  ), previews: Types.Maybe<(
    { __typename: 'MediaPreviews' }
    & { medium: Types.Maybe<(
      { __typename: 'Asset' }
      & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
    )> }
  )> }
);

export type IGQLListMediaItem_Video_Fragment = (
  { __typename: 'Video' }
  & Pick<Types.IGQLVideo, 'duration' | 'seasonId' | 'serieId' | 'id' | 'title' | 'isFeatured' | 'isLocked'>
  & { videoContentType: Types.IGQLVideo['contentType'] }
  & { posters: Types.Maybe<(
    { __typename: 'MediaPosters' }
    & { medium: Types.Maybe<(
      { __typename: 'Asset' }
      & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
    )>, small: Types.Maybe<(
      { __typename: 'Asset' }
      & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
    )> }
  )>, tile: (
    { __typename: 'TileProperties' }
    & Pick<Types.IGQLTileProperties, 'title' | 'subTitle'>
  ), brand: (
    { __typename: 'Brand' }
    & Pick<Types.IGQLBrand, 'id' | 'slug' | 'name'>
  ), previews: Types.Maybe<(
    { __typename: 'MediaPreviews' }
    & { medium: Types.Maybe<(
      { __typename: 'Asset' }
      & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
    )> }
  )> }
);

export type IGQLListMediaItemFragment = IGQLListMediaItem_Book_Fragment | IGQLListMediaItem_Game_Fragment | IGQLListMediaItem_Playlist_Fragment | IGQLListMediaItem_Serie_Fragment | IGQLListMediaItem_Song_Fragment | IGQLListMediaItem_Video_Fragment;

export const ListMediaItemFragmentDoc = gql`
    fragment ListMediaItem on Media {
  __typename
  id
  title
  tile {
    title
    subTitle
  }
  brand {
    id
    slug
    name
  }
  isFeatured
  isLocked
  previews {
    medium {
      source
      width
      height
    }
  }
  ... on Playlist {
    itemCount
  }
  ... on Serie {
    posters {
      medium {
        source
        width
        height
      }
      small {
        source
        width
        height
      }
    }
  }
  ... on Video {
    duration
    posters {
      medium {
        source
        width
        height
      }
      small {
        source
        width
        height
      }
    }
    seasonId
    serieId
    videoContentType: contentType
  }
  ... on Song {
    duration
    songContentType: contentType
  }
}
    `;