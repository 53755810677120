import React, { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { useBrands } from '~/entities/brands/model';
import tw from '~/shared/theme';
import { BrandThumbnail } from '~/shared/ui/brand-thumbnail';
import { Inline } from '~/shared/ui/spacing';
import { TextVariant } from '~/shared/ui/text-variant';
import { ProfileUpsertStepProps } from '../../types';

interface ProfileBrandsStepProps extends ProfileUpsertStepProps {}

const SELECTION_SIZE = 3;
const lastLine = [1, 2, 3, 4, 5, 6, 7, 8];

export const ProfileBrandsStep: FunctionComponent<ProfileBrandsStepProps> = ({
  state,
  onChange,
}) => {
  const { t, i18n } = useTranslation();
  const { items } = useBrands({ language: i18n.language });

  const { favoriteBrands: brands } = state;
  const toggleBrand = (id: string) => {
    let nextBrands = brands;
    if (brands.includes(id)) {
      nextBrands = brands.filter((brand) => brand !== id);
    } else if (brands.length < SELECTION_SIZE) {
      nextBrands = [...brands, id];
    }
    onChange(
      (prev) => ({
        ...prev,
        favoriteBrands: nextBrands,
      }),
      {
        isValid: nextBrands.length === 3,
      },
    );
  };

  useEffect(() => {
    if (items.filter(({ id }) => brands.includes(id)).length === SELECTION_SIZE) {
      onChange(null, { isValid: true });
    }
    // Only do this on mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const brandSize = tw`w-24 h-24 tablet:w-36 tablet:h-36`;

  return (
    <View>
      <TextVariant variant="page-title" style={tw`text-center mb-4`}>
        {t('onboardingStep4Title')}
      </TextVariant>
      <Inline style={tw`flex-wrap items-center justify-center`}>
        {items.map(({ id, images }) => (
          <View key={id} style={tw`m-4 my-8`}>
            <BrandThumbnail
              source={{ uri: images?.medium?.source }}
              onPress={() => toggleBrand(id)}
              selected={brands.includes(id)}
              style={brandSize}
            />
          </View>
        ))}
        {lastLine.map((dummy) => (
          <View key={dummy} style={[tw`mx-4`, brandSize, tw`h-0`]} />
        ))}
      </Inline>
    </View>
  );
};
