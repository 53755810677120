import { useWindowDimensions } from 'react-native';
import { IAsset } from '~/types';
import { ImageSourcePropType } from '../ui/image';

type Conversions = 'small' | 'medium' | 'large';
// type Variant = 'header' | 'thumbnail';

interface UseGetImageOrPlaceholderOptions<ItemT> {
  placeholder: ImageSourcePropType;
  // variant?: Variant;
  getImageFunction: (item: ItemT, conversion: Conversions) => IAsset | null;
}

type GetImageOrPlaceholder<ItemT> = (item?: ItemT) => ImageSourcePropType;

export function useGetImageOrPlaceholder<ItemType>({
  placeholder,
  // variant = 'header',
  getImageFunction,
}: UseGetImageOrPlaceholderOptions<ItemType>): GetImageOrPlaceholder<ItemType> {
  const dimensions = useWindowDimensions();
  let imageConversion: Conversions = 'small';

  if (dimensions.width > 1000) {
    imageConversion = 'large';
  } else if (dimensions.width > 600) {
    imageConversion = 'medium';
  }
  return (item) => {
    const imageAsset = item && getImageFunction(item, imageConversion);
    if (imageAsset) {
      return {
        uri: imageAsset.source as string,
      };
    }
    return placeholder as ImageSourcePropType;
  };
}
