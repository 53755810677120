import { Maybe, IAsset, IImageSet } from './shared';
import { IBrand } from './brands';
import {
  IGQLAgeClassification,
  IGQLDangerousContentClassification,
} from '~/shared/api/models.main.generated';

// @maybe move this to constants?
export const MEDIA_ALL_BRANDS = 'all';

export const enum MediaDownloadStatus {
  Remote = 'remote',
  Downloading = 'downloading',
  Downloaded = 'downloaded',
  Failed = 'failed',
}

export const enum MediaMedium {
  All = 'all',
  Watch = 'videos',
  Listen = 'music',
  Play = 'games',
  Read = 'books',
}

export const AgeClassification = {
  Six: 'SIX',
  Sixteen: 'SIXTEEN',
  Ten: 'TEN',
  Twelve: 'TWELVE',
} as const;

export type IAgeClassification = (typeof AgeClassification)[keyof typeof AgeClassification];

export const DangerousContentClassification = {
  Discrimination: 'DISCRIMINATION',
  DrugsOrAddiction: 'DRUGS_OR_ADDICTION',
  Fear: 'FEAR',
  FoulLanguage: 'FOUL_LANGUAGE',
  Sex: 'SEX',
  Violence: 'VIOLENCE',
} as const;

export type IDangerousContentClassification =
  (typeof DangerousContentClassification)[keyof typeof DangerousContentClassification];

export type SpecificMediaMedium = Exclude<MediaMedium, MediaMedium.All>;

export const MediaMediumMapper = {
  [MediaMedium.All]: null,
  [MediaMedium.Watch]: 'WATCH',
  [MediaMedium.Listen]: 'LISTEN',
  [MediaMedium.Play]: 'PLAY',
  [MediaMedium.Read]: 'READ',
} as const;

export type ExtractValuesAsUnion<T> = T[keyof T];

export const TextType = {
  Raw: 'RAW',
  Html: 'HTML',
} as const;
export type ITextType = ExtractValuesAsUnion<typeof TextType>;

export interface IText {
  type: ITextType;
  content: string;
}

export interface IPlayerOverlay {
  label: IText;
  visibleDuring: {
    /**
     * Start time in seconds
     */
    start: number;
    /**
     * End time in seconds
     */
    end: number;
  };
}

export interface IIcon {
  name: string;
  color: Maybe<string>;
}

export const ItemTileType = {
  Square: 'SQUARE',
  Landscape: 'LANDSCAPE',
  Portrait: 'PORTRAIT',
  PortraitTall: 'PORTRAIT_TALL',
} as const;
export type IItemTileType = ExtractValuesAsUnion<typeof ItemTileType>;

export const MediaType = {
  Video: 'Video',
  Song: 'Song',
  Book: 'Book',
  Game: 'Game',
  Playlist: 'Playlist',
  Serie: 'Serie',
} as const;
export type IMediaType = ExtractValuesAsUnion<typeof MediaType>;

export const VideoContentType = {
  Extra: 'EXTRA',
  Movie: 'MOVIE',
  Serie: 'SERIE',
  Playlist: 'PLAYLIST',
} as const;
export type IVideoContentType = ExtractValuesAsUnion<typeof VideoContentType>;

export const SongContentType = {
  Clip: 'CLIP',
  Karaoke: 'KARAOKE',
} as const;
export type ISongContentType = ExtractValuesAsUnion<typeof SongContentType>;

export const FeedLayoutType = {
  Lane: 'LANE',
  Grid: 'GRID',
} as const;
export type IFeedLayoutType = ExtractValuesAsUnion<typeof FeedLayoutType>;

interface IVideoSet {
  small?: Maybe<IAsset>;
  medium?: Maybe<IAsset>;
  large?: Maybe<IAsset>;
  stream?: Maybe<IAsset>;
  local?: Maybe<IAsset>;
}

export interface ISeason {
  id: string;
  title: string;
}

interface IMediaMeta {
  category?: Maybe<string>;
  subCategory?: Maybe<string>;
}

export interface IMediaBase {
  __typename: string;
  id: string;
  title: string;
  tile: {
    title: string;
    subTitle: Maybe<string>;
  };
  brand?: Maybe<IBrand>;
  isFeatured: boolean;
  isLocked: boolean;
  previews: Maybe<IImageSet>;

  meta?: Maybe<IMediaMeta>;

  downloadMeta?: {
    progress: number[];
    videoProgress?: number;
    status: MediaDownloadStatus;
  };
}

export interface IMediaPlaylist extends IMediaBase {
  __typename: typeof MediaType.Playlist;
  itemCount?: number;
  items?: Partial<IMedia>[];
}

export interface IMediaSerie extends IMediaBase {
  __typename: typeof MediaType.Serie;
  itemCount?: number;
  items?: Partial<IMedia>[];
  posters?: Maybe<IImageSet>;
  banners?: Maybe<IImageSet>;
}

export interface IMediaVideo extends IMediaBase {
  __typename: typeof MediaType.Video;
  resources?: Maybe<IVideoSet>;
  duration?: Maybe<number>;
  posters?: Maybe<IImageSet>;
  videoContentType?: Maybe<IVideoContentType>;
  season?: Maybe<ISeason>;
  seasonId?: Maybe<number>;
  serieId?: Maybe<number>;
  playerOverlays?: Maybe<IPlayerOverlay[]>;
  ageClassification?: Maybe<IAgeClassification>;
  dangerousContentClassification?: Maybe<Array<IDangerousContentClassification>>;
}

export interface IMediaSong extends IMediaBase {
  __typename: typeof MediaType.Song;
  resources?: Maybe<IVideoSet>;
  duration?: Maybe<number>;
  playerOverlays?: Maybe<IPlayerOverlay[]>;
  songContentType?: Maybe<ISongContentType>;
  ageClassification?: Maybe<IAgeClassification>;
  dangerousContentClassification?: Maybe<Array<IDangerousContentClassification>>;
}

export interface IMediaBook extends IMediaBase {
  __typename: typeof MediaType.Book;
  bookPages?: Maybe<string[]>;
  resources?: Maybe<string[]>;
}

export interface IMediaGame extends IMediaBase {
  __typename: typeof MediaType.Game;
  gameSource?: Maybe<string>;
  gameDownloadSource?: Maybe<string>;
}

export type IMedia =
  | IMediaPlaylist
  | IMediaVideo
  | IMediaBook
  | IMediaGame
  | IMediaSong
  | IMediaSerie;

export type IDownloadableMedia = IMediaVideo | IMediaGame | IMediaSong | IMediaBook;

export type IFeedContent = IMedia | IBrand;

export interface IFeed {
  id: string;
  title: IText;
  icon: Maybe<IIcon>;
  button?: Maybe<IButton>;
  feedLayoutType: Maybe<IFeedLayoutType>;
  itemTileType: Maybe<IItemTileType>;
  result: Maybe<{
    items: IFeedContent[];
    fetchMoreToken?: Maybe<string>;
  }>;
}

interface IButton {
  __typename: 'Button';
  title?: Maybe<string>;
  icon?: Maybe<IIcon>;
  variant?: Maybe<{
    name?: Maybe<string>;
  }>;
  link: {
    screen: string;
    params: {
      id: string;
    };
  };
}

type IAction = IButton;

export interface IBannerSlide {
  id: string;
  title: IText;
  actions: Maybe<Maybe<IAction>[]>;
  backgrounds: IImageSet;
}

export interface IPageBanner {
  id: string;
  slides: IBannerSlide[];
}
