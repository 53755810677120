import { useTranslation } from 'react-i18next';
import { useDownloadedMedia } from '~/entities/downloads';
import { useClientSideFeeds } from '~/shared/utils/use-clientside-feeds';
import { IFeed } from '~/types';

export function useDownloadFeeds(): { feeds: IFeed[] } {
  const { t } = useTranslation();

  const items = useDownloadedMedia();
  const feeds = useClientSideFeeds({
    media: items,
    titles: {
      videos: t('downloadedVideos'),
      songs: t('downloadedSongs'),
      games: t('downloadedGames'),
      books: t('downloadedBooks'),
    },
  });

  return { feeds };
}
