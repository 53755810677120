import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { View, Text } from 'react-native';
import tw from '~/shared/theme';
import { Button } from '~/shared/ui/button';
import { Icon } from '~/shared/ui/icon';
import { PageDecoration } from '~/shared/ui/page-decoration';
import { GapSpacer } from '~/shared/ui/spacing';
import { TextVariant } from '~/shared/ui/text-variant';
import { ProfileUpsertStepProps } from '../../types';

interface ProfileIntroStepProps extends ProfileUpsertStepProps {}

export const ProfileIntroStep: FunctionComponent<ProfileIntroStepProps> = ({
  onChange,
  decorated,
}) => {
  const { t } = useTranslation();

  return (
    <View style={tw`items-center`}>
      {decorated && (
        <>
          <PageDecoration name="game" style={tw`-right-8 -top-16`} />
          <PageDecoration name="play" style={tw`bottom-12 tablet:bottom-0 left-0`} />
        </>
      )}
      <GapSpacer spacing="lg">
        <TextVariant variant="page-title" style={tw`text-center`}>
          {t('startOnboardingTitle')}
        </TextVariant>
        <TextVariant variant="large" style={tw`text-white text-center max-w-xl`}>
          {t('startOnboardingDescription')}
        </TextVariant>
        <Button
          variant="submit"
          size="lg"
          decorationRight={<Icon name="arrow-right" />}
          onPress={() => onChange(null, { isValid: true, shouldContinue: true })}
        >
          {t('startOnboardingButtonLabel')}
        </Button>
        <TextVariant variant="large" style={tw`text-white text-center`}>
          <Text style={tw`font-sans-bold text-blue`}>{t('startOnboardingTip')}</Text>{' '}
          {t('startOnboardingTipDescription')}
        </TextVariant>
      </GapSpacer>
    </View>
  );
};
