import React, { FunctionComponent } from 'react';
import { StackScreenProps } from '@react-navigation/stack';
import { MediaRoutes, MediaStackParamList } from '~/navigation';
import { BaseLayout } from '~/widgets/base-layout';
import { ScrollingMenuBar } from '~/shared/ui/menu';
import { MainMenu } from '~/widgets/menu/main-menu';
import { Fill } from '~/shared/ui/spacing';
import { UserMenu } from '~/widgets/menu/user-menu';
import { useFavoritesFeeds } from '~/features/media/list-favorites/hooks/use-favorite-feeds';
import { FeedLayout, FeedLayoutSkeleton } from '~/widgets/feed-layout';
import { Preloader } from '~/shared/ui/preloader';
import { useTranslation } from 'react-i18next';
import { isFeedListEmpty } from '~/shared/utils/media';
import { NetworkStatus } from '~/types';
import { useNetInfo } from '@react-native-community/netinfo';
import { MediaOfflinePage } from './media-offline.page';

export const FavoritesPage: FunctionComponent<
  StackScreenProps<MediaStackParamList, MediaRoutes.Favorites>
> = (props) => {
  const { isConnected } = useNetInfo();
  const { feeds, networkStatus } = useFavoritesFeeds();
  const { t } = useTranslation();

  if (isConnected === false) {
    return <MediaOfflinePage {...props} />;
  }

  return (
    <BaseLayout
      decorationTop={
        <ScrollingMenuBar>
          <MainMenu />
          <Fill />
          <UserMenu />
        </ScrollingMenuBar>
      }
    >
      <Preloader
        loading={networkStatus < NetworkStatus.ready}
        error={networkStatus === NetworkStatus.error ? t('generalErrorMessage') : undefined}
        skeleton={<FeedLayoutSkeleton repeatCount={3} />}
        empty={isFeedListEmpty(feeds)}
        emptyMessage={t('favoritesNoResultsErrorMessage')}
      >
        {() => feeds && <FeedLayout feeds={feeds} />}
      </Preloader>
    </BaseLayout>
  );
};
