import React, { FunctionComponent } from 'react';
import { ScrollView } from 'react-native-gesture-handler';
import tw from '~/shared/theme';
import { useInsetsOrPadding } from '~/shared/utils/use-insets-or-padding';
import { useIsPhone } from '~/shared/utils/use-is-phone';
import { MenuBarProps } from './menu-bar.component.base';

import { MenuBar } from './menu-bar.component.base';
export { MenuBar } from './menu-bar.component.base';

export const ScrollingMenuBar: FunctionComponent<MenuBarProps> = ({ style, ...props }) => {
  const insets = useInsetsOrPadding();
  const isPhone = useIsPhone();

  if (!isPhone) {
    return <MenuBar {...props} />;
  }

  return (
    <ScrollView
      horizontal
      showsHorizontalScrollIndicator={false}
      showsVerticalScrollIndicator={false}
      bounces={false}
      style={[
        tw`-mb-12`,
        {
          marginLeft: -insets.left,
          marginRight: -insets.right,
        },
      ]}
      contentContainerStyle={[
        tw`flex-grow pb-12`,
        {
          paddingLeft: insets.left,
          paddingRight: insets.right,
        },
      ]}
    >
      <MenuBar {...props} style={[tw`flex-grow`, style]} />
    </ScrollView>
  );
};
