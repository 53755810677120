import React from 'react';
import { Animated, Pressable, StyleProp, ViewStyle } from 'react-native';
import tw from '~/shared/theme';
import { Inline } from '../spacing';

// Inspired by https://github.com/weahforsage/react-native-animated-pagination-dots/blob/main/src/dots/ExpandingDot.tsx

interface DotConfig {
  /** default white */
  activeColor: string;
  /** default white */
  inactiveColor: string;
  /** default 0.4 */
  inactiveOpacity: number;
  /** default 5 */
  size: number;
  /** defaults to size * 2 */
  activeWidth: number;
}
interface ExpandingDotPagerProps {
  pages: unknown[];
  page: Animated.Value | Animated.AnimatedInterpolation<number>;
  onDotPress?: (index: number) => void;
  dot?: Partial<DotConfig>;
  style?: StyleProp<ViewStyle>;
}

const DEFAULT_DOT: DotConfig = {
  activeColor: tw.color('white') as string,
  inactiveColor: tw.color('white') as string,
  inactiveOpacity: 0.4,
  size: 5,
  activeWidth: 10,
};

export const ExpandingDotPager: React.FC<ExpandingDotPagerProps> = ({
  pages,
  page,
  onDotPress,
  dot,
  style,
}) => {
  const dotProps = {
    ...DEFAULT_DOT,
    activeWidth: dot?.activeWidth || (dot?.size && dot?.size * 2) || DEFAULT_DOT.activeWidth,
    ...dot,
  };

  return (
    <Inline style={[tw`items-center justify-center m-2`, style]}>
      {pages.map((_, index) => {
        const inputRange = [index - 1, index, index + 1];

        const color = page.interpolate({
          inputRange,
          outputRange: [dotProps.inactiveColor, dotProps.activeColor, dotProps.inactiveColor],
          extrapolate: 'clamp',
        });
        const opacity = page.interpolate({
          inputRange,
          outputRange: [dotProps.inactiveOpacity, 1, dotProps.inactiveOpacity],
          extrapolate: 'clamp',
        });
        const expand = page.interpolate({
          inputRange,
          outputRange: [dotProps.size, dotProps.activeWidth, dotProps.size],
          extrapolate: 'clamp',
        });

        return (
          <Pressable key={`dot-${index}`} onPress={() => onDotPress?.(index)} focusable={false}>
            <Animated.View
              style={[
                {
                  borderRadius: dotProps.size,
                  margin: 2,
                  height: dotProps.size,
                  width: expand,
                  opacity,
                  backgroundColor: color,
                },
              ]}
            />
          </Pressable>
        );
      })}
    </Inline>
  );
};
