import { useEffect, useMemo, useRef, useState } from 'react';
import { NativeScrollEvent, NativeSyntheticEvent } from 'react-native';
import client from '~/shared/api/main.api';
import { FeedLayoutType, IFeed, IFeedLayoutType, IMedia, NetworkStatus } from '~/types';
import { GetPaginatedFeedItemsOperation } from '../api/queries.main.generated';

export function usePaginateFinalGridFeed({
  feeds,
  onlyOnFeedType = FeedLayoutType.Grid,
  activationDistanceFromBottom = 20,
}: {
  onlyOnFeedType?: IFeedLayoutType;
  feeds?: IFeed[];
  activationDistanceFromBottom?: number;
}): {
  feeds?: IFeed[];
  networkStatus: NetworkStatus;
  scrollableProps: {
    scrollEventThrottle: number;
    onScroll(event: NativeSyntheticEvent<NativeScrollEvent>): void;
  };
} {
  const fetchMoreTokenRef = useRef<string>();
  const networkStatusRef = useRef(NetworkStatus.default);
  const [networkStatus, setNetworkStatus] = useState<NetworkStatus>(NetworkStatus.default);
  const [fetchedItems, setFetchedItems] = useState<IMedia[]>([]);
  const [fetchMoreToken, setFetchMoreToken] = useState<string>();

  // Initial token set
  useEffect(() => {
    const lastFeed = feeds?.[feeds?.length - 1];

    setFetchedItems([]);
    networkStatusRef.current = NetworkStatus.default;
    setNetworkStatus(NetworkStatus.default);
    setFetchMoreToken(undefined);

    if (lastFeed && (!onlyOnFeedType || lastFeed.feedLayoutType === onlyOnFeedType)) {
      setFetchMoreToken(
        lastFeed.result?.items.length ? lastFeed.result.fetchMoreToken || undefined : undefined,
      );
    }
  }, [feeds, onlyOnFeedType]);

  // Update feeds with fetched items
  const feedsWithExtraItems = useMemo(() => {
    if (!feeds) return undefined;

    const lastFeed = feeds[feeds.length - 1];

    if (!lastFeed) {
      return [];
    }

    return [
      ...feeds.slice(0, -1),
      {
        ...lastFeed,
        result: {
          ...lastFeed?.result,
          items: [...(lastFeed?.result?.items || []), ...fetchedItems],
        },
      },
    ];
  }, [feeds, fetchedItems]);

  // Expose the handler that does the work
  const handleScroll = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
    const { layoutMeasurement, contentOffset, contentSize } = event.nativeEvent;
    const isCloseToBottom =
      layoutMeasurement.height + contentOffset.y >=
      contentSize.height - activationDistanceFromBottom;

    if (
      fetchMoreToken &&
      networkStatusRef.current !== NetworkStatus.loading &&
      networkStatusRef.current !== NetworkStatus.error &&
      isCloseToBottom
    ) {
      fetchMoreTokenRef.current = fetchMoreToken;
      setNetworkStatus(NetworkStatus.loading);
      networkStatusRef.current = NetworkStatus.loading;
      client
        .query({
          query: GetPaginatedFeedItemsOperation,
          variables: {
            fetchMoreToken,
          },
        })
        .then(({ data }) => {
          setNetworkStatus(NetworkStatus.ready);
          networkStatusRef.current = NetworkStatus.ready;
          if (!data?.feedItems || !data?.feedItems?.items?.length) {
            setFetchMoreToken(undefined);
            return;
          }

          setFetchMoreToken(data.feedItems.fetchMoreToken || undefined);
          setFetchedItems((previousItems) => [...previousItems, ...data.feedItems.items]);
        })
        .catch(() => {
          setNetworkStatus(NetworkStatus.error);
          networkStatusRef.current = NetworkStatus.error;
        });
    }
  };

  return {
    feeds: feedsWithExtraItems,
    networkStatus: networkStatus,
    scrollableProps: { onScroll: handleScroll, scrollEventThrottle: 400 },
  };
}
