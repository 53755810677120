import { FunctionComponent } from 'react';
import { IMedia } from '~/types';

interface DownloadButtonProps {
  media?: IMedia;
}

export const DownloadButton: FunctionComponent<DownloadButtonProps> = () => {
  return null;
};
