import React from 'react';
import { OnboardingStep, useViewer } from '~/entities/viewer';
import { OAuthPage } from '~/pages/oauth.page';
import { defaultScreenOptions, RootRoutes, RootStack } from './index';
import MediaNavigator from './media.navigator';
import OnboardingNavigator from './onboarding.navigator';
import SettingsNavigator from './settings.navigator';

const RootNavigator: React.FC = () => {
  const onboardingStep = useViewer((state) => state.onboardingStep);

  return (
    <RootStack.Navigator
      screenOptions={defaultScreenOptions}
      initialRouteName={
        onboardingStep === OnboardingStep.Finished ? RootRoutes.Media : RootRoutes.Onboarding
      }
    >
      <RootStack.Screen name={RootRoutes.Onboarding} component={OnboardingNavigator} />
      <RootStack.Screen name={RootRoutes.Media} component={MediaNavigator} />
      <RootStack.Screen name={RootRoutes.Settings} component={SettingsNavigator} />
      <RootStack.Screen name={RootRoutes.OAuth} component={OAuthPage} />
    </RootStack.Navigator>
  );
};

export default RootNavigator;
