import AsyncStorage from '@react-native-async-storage/async-storage';
import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import i18n from '~/assets/locale/i18n';
import { v4 as uuidv4 } from 'uuid';
import { makeAccountApi } from '~/shared/api/account.api';
import config from '~/shared/config';

export enum OnboardingStep {
  Email = 'email',
  Newsletter = 'newsletter',
  AccountType = 'accounttype',
  Profile = 'profile',
  Finished = 'finished',
}

export enum NewsletterStatus {
  Subscribed = 'subscribed',
  Unsubscribed = 'unsubscribed',
}

interface IViewerState {
  _hasHydrated: boolean;
  deviceId: string;
  email: string;
  newsletterStatus: NewsletterStatus;
  onboardingStep: OnboardingStep;
  language: string;
}

interface IViewerProducers {
  setLanguage(language: string): void;
  setOnboardingStep(step: OnboardingStep): void;
  setEmail(email: string): Promise<void>;
  setNewsletterStatus(status: NewsletterStatus): Promise<void>;
  requestAccountSettings(): Promise<void>;
  reset(): void;
}

type IViewerModel = IViewerState & IViewerProducers;

const defaultState = {
  deviceId: '',
  email: '',
  newsletterStatus: NewsletterStatus.Unsubscribed,
  onboardingStep: OnboardingStep.Email,
};

export const useViewer = create<IViewerModel>()(
  persist(
    (set, get) => ({
      _hasHydrated: false,
      ...defaultState,

      language: 'nl',

      setOnboardingStep(step) {
        set({ onboardingStep: step });
      },

      async setEmail(email) {
        const deviceId = uuidv4();
        await makeAccountApi(config.wieni).register({
          deviceId,
          email,
          language: i18n.language,
        });
        set({ email, deviceId });
      },

      async setNewsletterStatus(status) {
        await makeAccountApi(config.wieni).changeNewsletterStatus({
          deviceId: get().deviceId,
          status: status === NewsletterStatus.Subscribed,
        });
        set({ newsletterStatus: status });
      },

      async requestAccountSettings() {
        await makeAccountApi(config.wieni).requestAccountSettings({
          deviceId: get().deviceId,
        });
      },

      setLanguage(language: string) {
        set({ language });
      },

      reset() {
        set(defaultState);
      },
    }),
    {
      name: 'studio100go-viewer',
      storage: createJSONStorage(() => AsyncStorage),
      onRehydrateStorage: () => (state) => {
        useViewer.setState({ _hasHydrated: true });
        if (state?.language) {
          i18n.changeLanguage(state?.language);
        }
      },
      partialize: ({ _hasHydrated, ...state }) => state,
    },
  ),
);
