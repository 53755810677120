import { useIsFocused } from '@react-navigation/native';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { NetworkStatus } from '~/types';
import { IGQLBannerFragment, useGetPageBannerQuery } from '../api/queries.main.generated';

export function useGetHomePageBanner(): {
  banner?: IGQLBannerFragment;
  networkStatus: NetworkStatus;
} {
  const { i18n } = useTranslation();
  const isFocused = useIsFocused();
  const { data, networkStatus, refetch } = useGetPageBannerQuery({
    notifyOnNetworkStatusChange: false,
    variables: {
      id: 'home',
    },
  });

  useEffect(() => {
    if (isFocused && networkStatus === NetworkStatus.ready) {
      refetch();
    }
    // do not include networkstatus, because it may trigger an immediate refetch
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch, isFocused, i18n.language]);

  return { banner: data?.page?.banner || undefined, networkStatus };
}
