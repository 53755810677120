import { IMediaType, MediaType } from '~/types';
import { __migration_brandsMap } from './v2-brand-data';

export function __migration_getProximusExpiresAt(updatedAt?: string, expiresIn?: number): number {
  if (!updatedAt || !expiresIn) {
    return 0;
  }

  const value = new Date(updatedAt).getTime() + expiresIn * 1000;
  if (Number.isNaN(value)) {
    return 0;
  }
  return value;
}

export function __migration_getMediaType(media?: {
  category: string;
  subCategory: string;
}): IMediaType | null {
  if (!media) return null;

  const { category, subCategory } = media;
  if (category === 'video') {
    if (subCategory === 'series') {
      return MediaType.Serie;
    }
    return MediaType.Video;
  }
  if (category === 'music') {
    return MediaType.Song;
  }
  if (category === 'books') {
    return MediaType.Book;
  }
  if (category === 'games') {
    return MediaType.Game;
  }
  return null;
}

export function __migration_getBrandId(
  language: 'nl' | 'fr',
  brand?: { name: string },
): string | null {
  if (!brand) return null;

  const found = __migration_brandsMap[language]?.find((b) => b.slug === brand.name);
  if (!found) return null;
  return found.id;
}
