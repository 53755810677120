import * as Types from '~/shared/api/models.main.generated';

import { gql } from '@apollo/client';
import { ListBrandItemFragmentDoc } from '../../../../shared/api/fragments/list-brand-item.main.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {"context":{"clientName":"main"}} as const;
export type IGQLGetBrandsListQueryVariables = Types.Exact<{
  relatedToBrandIds?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
  language: Types.Scalars['String']['input'];
}>;


export type IGQLGetBrandsListQueryData = (
  { __typename: 'Query' }
  & { brands: Array<(
    { __typename: 'Brand' }
    & Pick<Types.IGQLBrand, 'id' | 'slug' | 'name'>
    & { images: Types.Maybe<(
      { __typename: 'BrandPreviews' }
      & { medium: Types.Maybe<(
        { __typename: 'Asset' }
        & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
      )> }
    )> }
  )> }
);


export const GetBrandsListOperation = gql`
    query GetBrandsList($relatedToBrandIds: [ID!], $language: String!) {
  brands(orderBaseBrands: $relatedToBrandIds, language: $language) {
    ...ListBrandItem
  }
}
    ${ListBrandItemFragmentDoc}`;
export function useGetBrandsListQuery(baseOptions: Apollo.QueryHookOptions<IGQLGetBrandsListQueryData, IGQLGetBrandsListQueryVariables> & ({ variables: IGQLGetBrandsListQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGQLGetBrandsListQueryData, IGQLGetBrandsListQueryVariables>(GetBrandsListOperation, options);
      }
export function useGetBrandsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGQLGetBrandsListQueryData, IGQLGetBrandsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGQLGetBrandsListQueryData, IGQLGetBrandsListQueryVariables>(GetBrandsListOperation, options);
        }
export function useGetBrandsListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGQLGetBrandsListQueryData, IGQLGetBrandsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGQLGetBrandsListQueryData, IGQLGetBrandsListQueryVariables>(GetBrandsListOperation, options);
        }
export type GetBrandsListQueryHookResult = ReturnType<typeof useGetBrandsListQuery>;
export type GetBrandsListLazyQueryHookResult = ReturnType<typeof useGetBrandsListLazyQuery>;
export type GetBrandsListSuspenseQueryHookResult = ReturnType<typeof useGetBrandsListSuspenseQuery>;
export type GetBrandsListQueryResult = Apollo.QueryResult<IGQLGetBrandsListQueryData, IGQLGetBrandsListQueryVariables>;