import React, { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import tw from '~/shared/theme';
import { Card } from '~/shared/ui/card';
import { AgeSlider } from '~/shared/ui/input';
import { PageDecoration } from '~/shared/ui/page-decoration';
import { TextVariant } from '~/shared/ui/text-variant';
import { ProfileUpsertStepProps } from '../../types';

interface ProfileAgeStepProps extends ProfileUpsertStepProps {}

export const ProfileAgeStep: FunctionComponent<ProfileAgeStepProps> = ({
  state,
  onChange,
  decorated,
}) => {
  const { t } = useTranslation();
  const { age } = state;
  const setAge = (newAge: number) => {
    onChange((prev) => ({ ...prev, age: newAge }), { isValid: true });
  };

  useEffect(() => {
    onChange(null, { isValid: true });
    // Only do this on mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card>
      {decorated && (
        <>
          <PageDecoration name="music" style={tw`left-1/12 -bottom-8`} />
          <PageDecoration name="book" style={tw`-right-32 -top-16`} />
        </>
      )}
      <TextVariant variant="page-title" style={tw`text-center tablet:mb-4`}>
        {t('onboardingStep2Title')}
      </TextVariant>
      <AgeSlider value={age} onChange={setAge} />
    </Card>
  );
};
