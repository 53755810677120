import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { useProximus } from '~/entities/proximus';
import { useNavigationRef } from '~/navigation/use-dynamic-link-to';
import tw from '~/shared/theme';
import { Button } from '~/shared/ui/button';
import { ImageBackground, ImageSourcePropType } from '~/shared/ui/image';
import { Modal } from '~/shared/ui/modal';
import { Spinner } from '~/shared/ui/preloader';
import { TextVariant } from '~/shared/ui/text-variant';
import { NetworkStatus } from '~/types';

const goPassBackground: Record<string, ImageSourcePropType> = {
  nl: require('~/assets/images/onboarding-go-pass-bg-nl.png'),
  fr: require('~/assets/images/onboarding-go-pass-bg-fr.png'),
};

interface ProximusValidationModalProps {}

export const ProximusValidationModal: FunctionComponent<ProximusValidationModalProps> = () => {
  const { t, i18n } = useTranslation();
  const { openLogin, validation, closeValidationConfirmation, validationConfirmationVisible } =
    useProximus();
  const navigationRef = useNavigationRef();

  const onRetryPress = () => {
    closeValidationConfirmation();
    const currentRoute = navigationRef.current?.getCurrentRoute();
    if (currentRoute) {
      openLogin(
        {
          screen: currentRoute.name,
          params: { ...currentRoute.params },
        },
        { prompt: 'login' },
      );
    }
  };

  const isError = validation?.networkStatus === NetworkStatus.error;
  const isLoading = validation?.networkStatus === NetworkStatus.loading;
  const isReady = validation?.networkStatus === NetworkStatus.ready;
  const isValid = validation?.isValid === true;

  return (
    <Modal
      visible={validationConfirmationVisible}
      onRequestClose={isLoading ? undefined : closeValidationConfirmation}
      contentContainerStyle={tw`pb-0 pt-8 wide:px-12 wide:pb-8`}
    >
      <ImageBackground
        source={goPassBackground[i18n.language]}
        style={tw`p-8 tablet:p-12 rounded-tl-xl rounded-tr-xl tablet:rounded-xl overflow-hidden`}
      >
        {isLoading ? (
          <View style={tw`p-20`}>
            <Spinner />
          </View>
        ) : (
          <>
            <TextVariant variant="page-title" style={tw`text-center mb-20`}>
              {isError && t('proximusOverlayErrorMessage')}
              {isReady && isValid && t('proximusOverlaySuccessMessage')}
              {isReady && !isValid && t('proximusOverlayInvalidGrants')}
            </TextVariant>
            <View style={tw`flex flex-row justify-center`}>
              {isReady && isValid && (
                <Button variant="submit" size="lg" onPress={closeValidationConfirmation}>
                  {t('proximusOverlaySuccessMessageButtonLabel')}
                </Button>
              )}
              {(isError || (isReady && !isValid)) && (
                <Button variant="submit" size="lg" onPress={onRetryPress}>
                  {t('proximusOverlayErrorMessageButtonLabel')}
                </Button>
              )}
            </View>
          </>
        )}
      </ImageBackground>
    </Modal>
  );
};
