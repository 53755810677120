import React, { FunctionComponent } from 'react';
import { StyleProp, View, ViewProps, ViewStyle } from 'react-native';
import tw from '~/shared/theme';
import { GapSpacer } from '../spacing';

type CardProps = ViewProps;
interface CardHeaderProps {
  style?: StyleProp<ViewStyle>;
  children?: React.ReactNode;
}

export const CardHeader: FunctionComponent<CardHeaderProps> = ({ children, style }) => (
  <View
    style={[
      tw`bg-green -mx-4 -mt-4 mb-4 tablet:-mx-14 tablet:-mt-14 tablet:mb-14 rounded-tl-lg rounded-tr-lg p-8 border-2 border-white items-center`,
      style,
    ]}
  >
    <GapSpacer>{children}</GapSpacer>
  </View>
);

export const Card: FunctionComponent<CardProps> = ({ children, style, ...props }) => {
  return (
    <View style={[tw`bg-white shadow-md p-4 tablet:p-14 rounded-lg`, style]} {...props}>
      {children}
    </View>
  );
};
