import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { NewsletterStatus, useViewer } from '~/entities/viewer';
import tw from '~/shared/theme';
import { AdvantageList } from '~/shared/ui/advantage-list';
import { Button } from '~/shared/ui/button';
import { Card } from '~/shared/ui/card';
import { Icon } from '~/shared/ui/icon';
import { GapSpacer, Inline } from '~/shared/ui/spacing';
import { TextVariant } from '~/shared/ui/text-variant';

interface NewsletterSelectionProps {
  onContinue?(): void;
}

export const NewsletterSelection: FunctionComponent<NewsletterSelectionProps> = ({
  onContinue,
}) => {
  const setNewsletterStatus = useViewer((state) => state.setNewsletterStatus);
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubscribe = async () => {
    try {
      setIsSubmitting(true);
      await setNewsletterStatus(NewsletterStatus.Subscribed);
      onContinue?.();
    } catch (e) {
      setIsSubmitting(false);
    }
  };

  return (
    <Inline spacing="md" style={tw`justify-center`}>
      <View style={tw`items-center w-1/2 max-w-sm`}>
        <GapSpacer>
          <TextVariant variant="page-title" style={tw`text-center`}>
            {t('stayUpdatedQuestion')}
          </TextVariant>
          <TextVariant variant="large" style={tw`text-center text-white`}>
            {t('stayUpdatedDescription')}
          </TextVariant>
          <Button
            variant="submit"
            decorationRight={<Icon name="arrow-right" />}
            onPress={handleSubscribe}
            disabled={isSubmitting}
          >
            {!isSubmitting ? t('yesPlease') : t('loading')}
          </Button>
        </GapSpacer>
      </View>
      <Card style={tw`w-1/2`}>
        <GapSpacer>
          <AdvantageList
            advantages={t('newsletterAdvantageList', { returnObjects: true }) as string[]}
            style={tw`pt-4`}
          />
          <TextVariant style={tw`text-center`} variant="large">
            {t('newsletterAdvantageFooter')}
          </TextVariant>
        </GapSpacer>
      </Card>
    </Inline>
  );
};
