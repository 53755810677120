import AsyncStorage from '@react-native-async-storage/async-storage';
import { useNetInfo } from '@react-native-community/netinfo';
import { useEffect } from 'react';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import client from '~/shared/api/main.api';
import config, { DEFAULT_CONFIG, IConfig } from '~/shared/config';
import { NetworkStatus } from '~/types';
import { GetAppConfigOperation, IGQLGetAppConfigQueryData } from './api/queries.main.generated';

interface IConfigState {
  _hasHydrated: boolean;
  networkStatus: NetworkStatus;

  config?: IConfig;
}

interface IConfigProducers {
  init: () => void;
}

type IConfigModel = IConfigState & IConfigProducers;

export const useAppConfigModel = create<IConfigModel>()(
  persist(
    (set) => ({
      _hasHydrated: false,
      networkStatus: NetworkStatus.default,

      config: DEFAULT_CONFIG,

      async init() {
        try {
          set({ networkStatus: NetworkStatus.loading });
          const response = await client.query<IGQLGetAppConfigQueryData>({
            query: GetAppConfigOperation,
          });
          const nextConfig = response.data.appConfig;
          if (response.data.appConfig) {
            set({
              networkStatus: NetworkStatus.ready,
              config: nextConfig as IConfig,
            });
            config.updateConfig(nextConfig as IConfig);
          } else {
            set({ networkStatus: NetworkStatus.error });
          }
        } catch (e) {
          set({ networkStatus: NetworkStatus.error });
        }
      },
    }),
    {
      name: 'studio100go-appconfig',
      storage: createJSONStorage(() => AsyncStorage),
      onRehydrateStorage: () => () => {
        useAppConfigModel.setState({ _hasHydrated: true });
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      partialize: ({ networkStatus, _hasHydrated, ...state }) => state,
    },
  ),
);

export const useAppConfig = (): IConfigState['config'] => {
  const { isConnected } = useNetInfo();
  const {
    init,
    networkStatus,
    config: stateConfig,
  } = useAppConfigModel((state) => ({
    init: state.init,
    networkStatus: state.networkStatus,
    config: state.config,
  }));

  useEffect(() => {
    if (!isConnected || networkStatus !== NetworkStatus.default) return;

    init();
  }, [networkStatus, init, isConnected]);

  return stateConfig;
};
