import React, { FunctionComponent } from 'react';
import tw from '~/shared/theme';
import Svg, { Path } from 'react-native-svg';

export interface BackgroundCapProps {
  color?: string;
}

export const BackgroundStartCap: FunctionComponent<BackgroundCapProps> = ({
  color = tw.color('green-450'),
}) => {
  return (
    <Svg
      viewBox="0 0 1200 16"
      style={tw`absolute h-2 tablet:h-4 min-w-full left-0 right-0 -top-2 tablet:-top-4`}
      preserveAspectRatio="none"
      focusable={false}
    >
      <Path
        fill={color}
        d="M169.992 0L0 1.38768V16H1200V1.38768L914.334 9.61578L628.667 3.96707L363.118 7.1986L169.992 0Z"
      />
    </Svg>
  );
};

export const BackgroundEndCap: FunctionComponent<BackgroundCapProps> = ({
  color = tw.color('green-450'),
}) => {
  return (
    <Svg
      viewBox="0 0 1200 16"
      style={tw`absolute h-2 tablet:h-4 min-w-full left-0 right-0 -bottom-2 tablet:-bottom-4`}
      preserveAspectRatio="none"
      focusable={false}
    >
      <Path
        fill={color}
        d="M0 0V16L123.722 11.837L264.543 15.157L527.075 11.231L764.459 15.688L982.23 11.681L1200 16V0H0Z"
      />
    </Svg>
  );
};
