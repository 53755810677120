import i18n from 'i18next';
import defaultNl from './nl.json';
import defaultFr from './fr.json';
import metadataNl from './metadata/nl.json';
import metadataFr from './metadata/fr.json';

i18n.init({
  fallbackLng: 'nl',
  debug: true,

  supportedLngs: ['nl', 'fr'],

  interpolation: {
    escapeValue: false,
  },

  resources: {
    nl: {
      translation: {
        metadata: metadataNl,
        ...defaultNl,
      },
    },
    fr: {
      translation: {
        metadata: metadataFr,
        ...defaultFr,
      },
    },
  },
});

export default i18n;
