import { StackScreenProps } from '@react-navigation/stack';
import React, { FunctionComponent, useMemo } from 'react';
import { View } from 'react-native';
import { useHomeFeeds } from '~/features/media/list-home-feeds';
import { MediaRoutes, MediaStackParamList } from '~/navigation';
import tw from '~/shared/theme';
import { ScrollingMenuBar } from '~/shared/ui/menu';
import { Fill } from '~/shared/ui/spacing';
import { NetworkStatus } from '~/types';
import { BaseLayout } from '~/widgets/base-layout';
import { FeedLayout, FeedLayoutSkeleton } from '~/widgets/feed-layout';
import { MainMenu } from '~/widgets/menu/main-menu';
import { UserMenu } from '~/widgets/menu/user-menu';
import { MediaCarousel } from '~/features/media/carousel';
import { useGetHomePageBanner } from '~/features/media/carousel/hooks/use-get-home-page-banner';
import { DecorativeLoader, Preloader } from '~/shared/ui/preloader';
import { AspectRatioContainer } from '~/shared/ui/aspect-ratio-container';
import { useTranslation } from 'react-i18next';
import { isFeedListEmpty } from '~/shared/utils/media';
import { useIsFocused } from '@react-navigation/native';

export const OverviewHomePage: FunctionComponent<
  StackScreenProps<MediaStackParamList, MediaRoutes.Overview>
> = () => {
  const { feeds, networkStatus, retryQuery } = useHomeFeeds();
  const { banner, networkStatus: bannerNetworkStatus } = useGetHomePageBanner();
  const { t } = useTranslation();

  const isFocused = useIsFocused();

  // Shuffle banner slides every time we change focus
  const shuffledBannerSlides = useMemo(() => {
    if (!banner?.slides?.length) return [];

    return [...banner.slides].sort(() => Math.random() - 0.5);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [banner, isFocused]);

  return (
    <BaseLayout
      decorationTop={
        <ScrollingMenuBar>
          <MainMenu />
          <Fill />
          <UserMenu />
        </ScrollingMenuBar>
      }
    >
      <View style={tw`-mt-5`}>
        {/* NOTE: this one ignores errors, because, well, it is less crucial as content */}
        <Preloader
          loading={bannerNetworkStatus === NetworkStatus.loading}
          skeleton={<AspectRatioContainer aspectRatio={3} containerStyle={tw`mb-[27px]`} />}
          renderSpinner={() => <DecorativeLoader />}
        >
          {() =>
            shuffledBannerSlides.length > 0 && (
              <MediaCarousel
                // Reset carousel index on shuffle change
                key={shuffledBannerSlides.map((slide) => slide.id).join(',')}
                slides={shuffledBannerSlides}
              />
            )
          }
        </Preloader>
      </View>

      <Preloader
        loading={networkStatus < NetworkStatus.refetch}
        error={networkStatus === NetworkStatus.error ? t('generalErrorMessage') : undefined}
        errorRetryButton={retryQuery}
        empty={isFeedListEmpty(feeds)}
        skeleton={<FeedLayoutSkeleton />}
      >
        {() => feeds && <FeedLayout feeds={feeds} />}
      </Preloader>
    </BaseLayout>
  );
};
