import { NavigationProp, useNavigation } from '@react-navigation/core';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { MediaRoutes, MediaStackParamList } from '~/navigation';
import { Coin, MenuItem, MultiCoin } from '~/shared/ui/menu';
import { MediaMedium } from '~/types';

interface BrandMenuProps {
  medium: MediaMedium;
  brandSlug: string;
}

export const BrandMenu: FunctionComponent<BrandMenuProps> = ({ medium, brandSlug }) => {
  const { t } = useTranslation();
  const navigation = useNavigation<NavigationProp<MediaStackParamList>>();

  return (
    <>
      <MenuItem
        label={t('menuItemAll')}
        active={medium === MediaMedium.All}
        onPress={() =>
          navigation.navigate(MediaRoutes.Overview, { brandSlug, medium: MediaMedium.All })
        }
      >
        <MultiCoin />
      </MenuItem>
      <MenuItem
        label={t('menuItemWatch')}
        active={medium === MediaMedium.Watch}
        onPress={() =>
          navigation.navigate(MediaRoutes.Overview, { brandSlug, medium: MediaMedium.Watch })
        }
      >
        <Coin icon="play" coinVariant="red" />
      </MenuItem>
      <MenuItem
        label={t('menuItemRead')}
        active={medium === MediaMedium.Read}
        onPress={() =>
          navigation.navigate(MediaRoutes.Overview, { brandSlug, medium: MediaMedium.Read })
        }
      >
        <Coin icon="book" coinVariant="blue" />
      </MenuItem>
      <MenuItem
        label={t('menuItemListen')}
        active={medium === MediaMedium.Listen}
        onPress={() =>
          navigation.navigate(MediaRoutes.Overview, { brandSlug, medium: MediaMedium.Listen })
        }
      >
        <Coin icon="music" coinVariant="orange" />
      </MenuItem>
    </>
  );
};
