import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import tw from '~/shared/theme';
import { AdvantageList } from '~/shared/ui/advantage-list';
import { Button, ProximusButton } from '~/shared/ui/button';
import { Card, CardHeader } from '~/shared/ui/card';
import { Gradient } from '~/shared/ui/gradient';
import { Logo } from '~/shared/ui/logo';
import { Fill, Inline } from '~/shared/ui/spacing';
import { TextVariant } from '~/shared/ui/text-variant';

interface AccountSelectionProps {
  onContinue?(args: { accountType: 'FREE' | 'PROXIMUS' }): void;
}

export const AccountSelection: FunctionComponent<AccountSelectionProps> = ({ onContinue }) => {
  const { t } = useTranslation();

  return (
    <>
      <TextVariant variant="page-title" style={tw`text-center mb-4`}>
        {t('onboardingGoPassTitle')}
      </TextVariant>
      <Inline style={tw`w-full tablet:max-w-4xl`} spacing="lg">
        <Card style={tw`flex-1 h-full`}>
          <CardHeader>
            <Logo style={tw`h-20 tablet:h-32`} />
            <TextVariant variant="h3">{t('onboardingGoPassEveryBody')}</TextVariant>
          </CardHeader>
          <AdvantageList
            style={tw`mb-8`}
            advantages={t('onboardingGoPassDefaultAdvantages', { returnObjects: true }) as string[]}
          />
          <Fill />
          <Button
            style={tw`mx-8`}
            size="lg"
            variant="submit"
            onPress={() => {
              onContinue?.({ accountType: 'FREE' });
            }}
          >
            {t('onboardingContinueFree')}
          </Button>
        </Card>
        <Card style={tw`flex-1 h-full`}>
          <CardHeader style={tw`bg-proximus`}>
            <>
              <Gradient variant="proximus" absoluteFill style={tw`rounded-tl-md rounded-tr-md`} />
              <Logo variant="pass" style={tw`h-20 tablet:h-32`} />
            </>
            <TextVariant variant="h3" style={tw`text-center z-10`}>
              {t('goPassForProximusCustomers')}
            </TextVariant>
          </CardHeader>

          <AdvantageList
            style={tw`mb-8`}
            advantages={t('onboardingGoPassAdvantages', { returnObjects: true }) as string[]}
          />
          <Fill />
          <ProximusButton
            size="lg"
            onPress={() => {
              onContinue?.({ accountType: 'PROXIMUS' });
            }}
          >
            {t('goPassLoginWithProximus')}
          </ProximusButton>
        </Card>
      </Inline>
    </>
  );
};
