import { useNetInfo } from '@react-native-community/netinfo';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDownloadedMedia } from '~/entities/downloads';
import { useMemoizedActiveProfilePreferences } from '~/entities/profiles';
import { IRetryQuery, useRetryQuery } from '~/shared/utils/use-retry-query';
import { IMedia, MediaType, NetworkStatus } from '~/types';
import { useGetMediaQuery, useGetRelatedMediaQuery } from '../api/queries.main.generated';

export function useMediaDetail({ id }: { id?: string }): {
  networkStatus: NetworkStatus;
  item?: IMedia;
  hubItems?: IMedia[];
  activeHubItemIndex?: number;
  activeHubItem?: IMedia;
  changeActiveHubItem(nextItemId: string): void;
  setNextHubItemActive(): void;
  retryQuery: IRetryQuery;
} {
  const preferences = useMemoizedActiveProfilePreferences();
  const downloadedItems = useDownloadedMedia();
  const { isConnected } = useNetInfo();
  const download =
    isConnected === false || __DEV__ ? downloadedItems.find((item) => item.id === id) : undefined;

  const { data, ...query } = useGetMediaQuery({
    variables: { id: id as string },
    skip: !id || (!!download && isConnected === false),
  });
  const { networkStatus, ...retryQuery } = useRetryQuery(query);
  const { data: relatedData } = useGetRelatedMediaQuery({
    variables: { id: id as string, ...preferences },
    skip:
      !id ||
      (!!download && isConnected === false) ||
      !data?.mediaItem ||
      data.mediaItem.__typename === MediaType.Playlist,
  });

  const [hubItems, setHubItems] = useState<IMedia[]>([]);
  const hubItemsRef = useRef(hubItems);
  hubItemsRef.current = hubItems;
  const [activeHubItemId, setActiveHubItemId] = useState<string>();

  useEffect(() => {
    if (!data?.mediaItem) return;
    const nextHubItems =
      data.mediaItem.__typename === MediaType.Playlist
        ? data.mediaItem.items
        : download
          ? downloadedItems
          : (relatedData?.relatedMedia?.items?.filter(
              (item) => item.__typename !== 'Brand',
            ) as IMedia[]) || [data.mediaItem];
    setHubItems(nextHubItems);
    const item = (nextHubItems as IMedia[]).find((i) => i.id === id);
    if (!item) {
      setActiveHubItemId(nextHubItems[0]?.id);
    } else {
      setActiveHubItemId((currentId) => (currentId ? currentId : id));
    }
  }, [id, data, download, downloadedItems, relatedData]);

  useEffect(() => {
    if (!download || isConnected) return;
    setHubItems(downloadedItems);
    const idInDownloads = downloadedItems.find((i) => i.id === id);
    setActiveHubItemId((currentId) =>
      currentId !== undefined ? currentId : idInDownloads ? id : downloadedItems[0]?.id,
    );
  }, [id, download, downloadedItems, isConnected]);

  const changeActiveHubItem = useCallback((nextItemId: string) => {
    if (!hubItemsRef.current) return;
    if (hubItemsRef.current.find((i) => i.id === nextItemId)) {
      setActiveHubItemId(nextItemId);
    }
  }, []);

  const activeIndexRef = useRef<number | undefined>(undefined);
  const activeHubItemIndex = useMemo(() => {
    const index = hubItems.findIndex((i) => i.id === activeHubItemId);
    activeIndexRef.current = index > -1 ? index : undefined;
    return activeIndexRef.current;
  }, [activeHubItemId, hubItems]);

  const setNextHubItemActive = useCallback(() => {
    if (!hubItemsRef.current || activeIndexRef.current === undefined) return;

    const nextIndex = (activeIndexRef.current + 1) % hubItemsRef.current.length;
    changeActiveHubItem(hubItemsRef.current[nextIndex].id);
  }, [changeActiveHubItem]);

  return {
    item: download || data?.mediaItem || undefined,
    activeHubItemIndex,
    activeHubItem:
      (activeHubItemIndex !== undefined && hubItems[activeHubItemIndex]) ||
      data?.mediaItem ||
      undefined,
    networkStatus: download ? NetworkStatus.ready : networkStatus,
    hubItems: hubItems || undefined,
    changeActiveHubItem,
    setNextHubItemActive,
    retryQuery,
  };
}
