import React from 'react';
import { IAgeClassification, IDangerousContentClassification } from '~/types';

import Age6Svg from '../images/classifications/age-6.svg';
import Age10Svg from '../images/classifications/age-10.svg';
import Age12Svg from '../images/classifications/age-12.svg';
import Age16Svg from '../images/classifications/age-16.svg';
import ContentAddictionSvg from '../images/classifications/content-addiction.svg';
import ContentDiscriminationSvg from '../images/classifications/content-discrimination.svg';
import ContentFearSvg from '../images/classifications/content-fear.svg';
import ContentLanguageSvg from '../images/classifications/content-language.svg';
import ContentSexSvg from '../images/classifications/content-sex.svg';
import ContentViolenceSvg from '../images/classifications/content-violence.svg';
import { useClassificationIconSize } from './use-classification-icon-size';

const ContentIconMap: Record<IDangerousContentClassification, string> = {
  DRUGS_OR_ADDICTION: ContentAddictionSvg,
  DISCRIMINATION: ContentDiscriminationSvg,
  FEAR: ContentFearSvg,
  FOUL_LANGUAGE: ContentLanguageSvg,
  SEX: ContentSexSvg,
  VIOLENCE: ContentViolenceSvg,
};
const AgeIconMap: Record<IAgeClassification, string> = {
  SIX: Age6Svg,
  TEN: Age10Svg,
  TWELVE: Age12Svg,
  SIXTEEN: Age16Svg,
  // ALL: AgeAllSvg,
};

export function AgeClassification({ classification }: { classification: IAgeClassification }) {
  const src = AgeIconMap[classification];
  const size = useClassificationIconSize();

  if (!src) {
    return null;
  }

  return <img width={size} height={size} src={src} />;
}

export function DangerousContentClassification({
  classification,
}: {
  classification: IDangerousContentClassification;
}) {
  const src = ContentIconMap[classification];
  const size = useClassificationIconSize();

  if (!src) {
    return null;
  }

  return <img width={size} height={size} src={src} />;
}
