import * as Types from '~/shared/api/models.main.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {"context":{"clientName":"main"}} as const;
export type IGQLGetRelatedBrandsQueryVariables = Types.Exact<{
  relatedToBrandIds?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
  language: Types.Scalars['String']['input'];
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  shouldIncludeBaseBrands?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type IGQLGetRelatedBrandsQueryData = (
  { __typename: 'Query' }
  & { brands: Array<(
    { __typename: 'Brand' }
    & Pick<Types.IGQLBrand, 'id' | 'slug' | 'name'>
    & { images: Types.Maybe<(
      { __typename: 'BrandPreviews' }
      & { medium: Types.Maybe<(
        { __typename: 'Asset' }
        & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
      )> }
    )>, banners: Types.Maybe<(
      { __typename: 'BrandBanners' }
      & { large: Types.Maybe<(
        { __typename: 'Asset' }
        & Pick<Types.IGQLAsset, 'source'>
      )>, medium: Types.Maybe<(
        { __typename: 'Asset' }
        & Pick<Types.IGQLAsset, 'source'>
      )> }
    )> }
  )> }
);


export const GetRelatedBrandsOperation = gql`
    query GetRelatedBrands($relatedToBrandIds: [ID!], $language: String!, $limit: Int, $shouldIncludeBaseBrands: Boolean) {
  brands(
    orderBaseBrands: $relatedToBrandIds
    language: $language
    limit: $limit
    shouldIncludeBaseBrands: $shouldIncludeBaseBrands
  ) {
    id
    slug
    name
    images {
      medium {
        source
        width
        height
      }
    }
    banners {
      large {
        source
      }
      medium {
        source
      }
    }
  }
}
    `;
export function useGetRelatedBrandsQuery(baseOptions: Apollo.QueryHookOptions<IGQLGetRelatedBrandsQueryData, IGQLGetRelatedBrandsQueryVariables> & ({ variables: IGQLGetRelatedBrandsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGQLGetRelatedBrandsQueryData, IGQLGetRelatedBrandsQueryVariables>(GetRelatedBrandsOperation, options);
      }
export function useGetRelatedBrandsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGQLGetRelatedBrandsQueryData, IGQLGetRelatedBrandsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGQLGetRelatedBrandsQueryData, IGQLGetRelatedBrandsQueryVariables>(GetRelatedBrandsOperation, options);
        }
export function useGetRelatedBrandsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGQLGetRelatedBrandsQueryData, IGQLGetRelatedBrandsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGQLGetRelatedBrandsQueryData, IGQLGetRelatedBrandsQueryVariables>(GetRelatedBrandsOperation, options);
        }
export type GetRelatedBrandsQueryHookResult = ReturnType<typeof useGetRelatedBrandsQuery>;
export type GetRelatedBrandsLazyQueryHookResult = ReturnType<typeof useGetRelatedBrandsLazyQuery>;
export type GetRelatedBrandsSuspenseQueryHookResult = ReturnType<typeof useGetRelatedBrandsSuspenseQuery>;
export type GetRelatedBrandsQueryResult = Apollo.QueryResult<IGQLGetRelatedBrandsQueryData, IGQLGetRelatedBrandsQueryVariables>;