import React, { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { AvatarName, avatarNames } from '~/assets/avatars';
import tw from '~/shared/theme';
import { Avatar } from '~/shared/ui/avatar';
import { Inline } from '~/shared/ui/spacing';
import { TextVariant } from '~/shared/ui/text-variant';
import { ProfileUpsertStepProps } from '../../types';

interface ProfileAvatarStepProps extends ProfileUpsertStepProps {}

const lastLine = [1, 2, 3, 4, 5, 6, 7, 8];
const avatarsWithoutGO = avatarNames.slice(0, -1);

export const ProfileAvatarStep: FunctionComponent<ProfileAvatarStepProps> = ({
  state,
  onChange,
}) => {
  const { t } = useTranslation();
  const { avatar } = state;
  const selectAvatar = (name: AvatarName) => {
    onChange(
      (prev) => ({
        ...prev,
        avatar: name,
      }),
      {
        isValid: true,
      },
    );
  };
  const avatarSize = tw`w-24 h-24 tablet:w-36 tablet:h-36`;

  useEffect(() => {
    if (avatar) {
      onChange(null, { isValid: true });
    }
    // Only do this on mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <View>
      <TextVariant variant="page-title" style={tw`text-center mb-4`}>
        {t('onboardingStep3Title')}
      </TextVariant>
      <Inline style={tw`flex-wrap items-center justify-center`}>
        {avatarsWithoutGO.map((name) => (
          <View key={name} style={[tw`m-4`, name === avatar && tw`z-10`]}>
            <Avatar
              name={name}
              onPress={() => selectAvatar(name)}
              selected={name === avatar}
              style={avatarSize}
            />
          </View>
        ))}
        {lastLine.map((dummy) => (
          <View key={dummy} style={[tw`mx-4`, avatarSize, tw`h-0`]} />
        ))}
      </Inline>
    </View>
  );
};
