import React, { FunctionComponent } from 'react';
import { StackScreenProps } from '@react-navigation/stack';
import { SettingsRoutes, SettingsStackParamList } from '~/navigation';
import { MenuBack, ScrollingMenuBar } from '~/shared/ui/menu';
import { SettingsLayout } from '~/widgets/settings-layout';
import { useTranslation } from 'react-i18next';
import {
  ProfileAgeStep,
  ProfileAvatarStep,
  ProfileBrandsStep,
  ProfileNameStep,
  useProfileUpsert,
} from '~/features/profiles/upsert-profile';
import tw from '~/shared/theme';
import { IProfile, useProfiles } from '~/entities/profiles';
import { FormWizardPager } from '~/shared/ui/form-wizard-pager';
import { Platform, View } from 'react-native';

const steps = [ProfileNameStep, ProfileAgeStep, ProfileAvatarStep, ProfileBrandsStep];

const pages = steps;

export const ProfileEditPage: FunctionComponent<
  StackScreenProps<SettingsStackParamList, SettingsRoutes.ProfileEdit>
> = ({ navigation, route }) => {
  const { updateProfile, items } = useProfiles();
  const profile = items.find((item) => item.id === route.params.id);

  const {
    activeStep: ActiveStep,
    activeStepIndex,
    nextStepEnabled,
    previousStepEnabled,
    finalNextStep,
    onNextStep,
    onPreviousStep,
    stepProps,
  } = useProfileUpsert({
    defaultState: profile,
    steps,
    onFinish: (state) => {
      updateProfile({ ...(profile as IProfile), ...state });
      navigation.navigate(SettingsRoutes.ProfileOverview);
    },
  });

  const { t } = useTranslation();
  const onBackPressed = () => {
    navigation.navigate(SettingsRoutes.ProfileOverview);
  };

  return (
    <SettingsLayout
      avoidKeyboard
      decorationTop={
        <ScrollingMenuBar>
          <MenuBack label={t('profilesLabel')} onPress={onBackPressed} />
        </ScrollingMenuBar>
      }
      contentContainerStyle={[
        tw`flex items-center justify-center max-w-5xl min-h-11/12`,
        Platform.OS === 'web' && { margin: 'auto' },
      ]}
      decorationAfter={
        <FormWizardPager
          page={activeStepIndex}
          pages={pages}
          previousDisabled={!previousStepEnabled}
          previousLabel={t('onboardingPreviousButtonLabel')}
          onPressPrevious={onPreviousStep}
          nextDisabled={!nextStepEnabled}
          nextLabel={t('onboardingNextButtonLabel')}
          completeLabel={t('onboardingFinalButtonLabel')}
          onPressNext={onNextStep}
          isFinalStep={finalNextStep}
        />
      }
    >
      <View style={tw`flex justify-center items-center`}>
        <ActiveStep {...stepProps} />
      </View>
    </SettingsLayout>
  );
};
