export function getParentChallenge(): {
  numberOne: number;
  numberTwo: number;
  operator: string;
  verificationResult: number;
} {
  const numberOne = Math.floor(Math.random() * 5) + 3;
  const numberTwo = Math.floor(Math.random() * 11) + 4;
  const verificationResult = Math.floor(numberOne * numberTwo);

  return {
    numberOne,
    numberTwo,
    operator: 'x',
    verificationResult,
  };
}
