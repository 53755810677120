import { MutableRefObject, useMemo, useRef } from 'react';
import { Animated, Dimensions, NativeScrollEvent, NativeSyntheticEvent } from 'react-native';

const WINDOW = Dimensions.get('window');

interface AnimatedPaging {
  onScroll: (event: NativeSyntheticEvent<NativeScrollEvent>) => void;
  page: Animated.AnimatedInterpolation<number>;
  pageRef: MutableRefObject<number>;
}

export function useAnimatedPaging(): AnimatedPaging {
  const x = useMemo(() => new Animated.Value(0), []);
  const width = useMemo(() => new Animated.Value(WINDOW.width), []);
  const page = Animated.divide(x, width);
  const pageRef = useRef(0);

  const onScroll = Animated.event<NativeScrollEvent>(
    [{ nativeEvent: { contentOffset: { x }, layoutMeasurement: { width } } }],
    {
      // Do not use native driver to be able to animate dot width and color.
      useNativeDriver: false,
      listener: (event) => {
        const { contentOffset, layoutMeasurement } = event.nativeEvent;
        pageRef.current = Math.round(contentOffset.x / layoutMeasurement.width);
      },
    },
  );

  return { onScroll, page, pageRef };
}
