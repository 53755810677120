import React, { FunctionComponent, useCallback, useRef, useState } from 'react';
import { View, Animated, Platform, ScrollView } from 'react-native';
import tw from '~/shared/theme';
import { useAnimatedPaging } from './useAnimatedPaging';
import { ExpandingDotPager } from '../expanding-dot-pager';
import { ArrowButton } from './arrow-button.component';
import { useFocusEffect } from '@react-navigation/native';

export interface CarouselProps {
  autoRotate?: number;
  children?: React.ReactNode;
}

export const Carousel: FunctionComponent<CarouselProps> = ({ autoRotate, children }) => {
  const scrollViewRef = useRef<ScrollView>(null);
  const { onScroll, page, pageRef } = useAnimatedPaging();
  const [measuredWidth, setMeasuredWidth] = useState<number>();

  // filter(Boolean) will filter out `null` children
  const childArray = React.Children.toArray(children).filter(Boolean);
  const totalPages = childArray.length;
  const scrollToIndex = useCallback(
    (index: number) => {
      if (scrollViewRef.current && measuredWidth) {
        scrollViewRef.current.scrollTo({
          x: index * measuredWidth,
        });
      }
    },
    [measuredWidth],
  );

  useFocusEffect(
    useCallback(() => {
      if (autoRotate) {
        let timeout: number;
        const reset = () => {
          clearTimeout(timeout);
          timeout = setTimeout(() => {
            const nextPage = (pageRef.current + 1) % totalPages;
            scrollToIndex(nextPage);

            // in fact, this should make very little difference, because animated scroll event will trigger reset as well
            reset();
          }, autoRotate) as unknown as number;
        };
        reset();
        const listenerId = page.addListener(() => {
          reset();
        });

        return () => {
          clearTimeout(timeout);
          page.removeListener(listenerId);
        };
      }
    }, [page, pageRef, autoRotate, scrollToIndex, totalPages]),
  );

  return (
    <>
      <View style={tw`w-full rounded-xl overflow-hidden`}>
        <Animated.ScrollView
          ref={scrollViewRef}
          style={tw`w-full flex-row rounded-xl snap-x-mandatory`}
          contentContainerStyle={tw`w-[${childArray.length * 100}%]`}
          horizontal
          showsHorizontalScrollIndicator={false}
          // NOTE: paging is not enabled because RN-web wraps children and breaks full-width layout
          pagingEnabled={Platform.OS !== 'web'}
          scrollEventThrottle={16}
          onScroll={onScroll}
          onLayout={(event) => setMeasuredWidth(event.nativeEvent.layout.width)}
        >
          {React.Children.map(
            children,
            (child) =>
              React.isValidElement(child) && <View style={[tw`flex-1 snap-start`]}>{child}</View>,
          )}
        </Animated.ScrollView>
        <ArrowButton
          direction="left"
          page={page}
          pages={childArray}
          onPress={() => scrollToIndex(pageRef.current - 1)}
        />
        <ArrowButton
          direction="right"
          page={page}
          pages={childArray}
          onPress={() => scrollToIndex(pageRef.current + 1)}
        />
      </View>
      <ExpandingDotPager
        pages={childArray}
        page={page}
        onDotPress={scrollToIndex}
        dot={{ size: 7 }}
      />
    </>
  );
};
