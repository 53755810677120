import { ApolloError } from '@apollo/client';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleProp, ViewStyle } from 'react-native';
import tw from '~/shared/theme';
import { PageError } from '../page-error';
import { PageErrorProps } from '../page-error/page-error.component';
import { Inline } from '../spacing';
import { Spinner } from './spinner.component';

interface PreloaderProps {
  loading: boolean;
  error?: ApolloError | Error | string;
  errorRetryButton?: PageErrorProps['retryButton'];
  empty?: boolean;
  shouldRenderSkeletonWhenEmpty?: boolean;
  emptyMessage?: string;
  renderSpinner?: () => React.ReactElement;
  renderError?: (message: string) => React.ReactElement;
  renderEmpty?: () => React.ReactElement;
  /** Render function called when no longer loading AND without error. */
  children: () => React.ReactNode;
  /** Styles to pass to the skeleton element, added to tw`rounded-xl bg-green-450 items-center justify-center` */
  skeletonStyle?: StyleProp<ViewStyle>;
  /** pre-rendered skeleton element, defaults to if no styles are passed `<Inline style={tw`items-center justify-center`} />`, otherwise to `<View />` */
  skeleton?: React.ReactElement;
  /** render a different skeleton for empty results, defaults to `skeleton` */
  emptySkeleton?: React.ReactElement;
}
interface SkeletonProps {
  children?: React.ReactElement;
  skeletonStyle?: StyleProp<ViewStyle>;
  skeleton?: React.ReactElement;
}

const Skeleton: FunctionComponent<SkeletonProps> = ({ children, skeleton, skeletonStyle }) => {
  if (skeleton || skeletonStyle) {
    const renderedSkeleton = skeleton || <View />;
    return React.cloneElement(renderedSkeleton, {
      style: [
        tw`rounded-xl bg-green-450`,
        tw`items-center justify-center`,
        skeletonStyle,
        renderedSkeleton.props.style,
      ],
      children,
    });
  }

  return <Inline style={tw`items-center justify-center`}>{children}</Inline>;
};

export const Preloader: FunctionComponent<PreloaderProps> = ({
  loading,
  error,
  empty,
  shouldRenderSkeletonWhenEmpty = true,
  renderEmpty,
  children,
  renderSpinner,
  renderError,
  errorRetryButton,
  emptySkeleton,
  skeleton,
  skeletonStyle,
  ...props
}) => {
  const { t } = useTranslation();
  const { emptyMessage = t('generalEmptyResultsMessage') } = props;
  const skeletonProps = {
    skeleton,
    skeletonStyle,
  };

  if (loading) {
    return <Skeleton {...skeletonProps}>{renderSpinner?.() || <Spinner />}</Skeleton>;
  }
  if (error) {
    const message = typeof error === 'string' ? error : error.message;
    return (
      <View style={tw`flex-1`}>
        <Skeleton {...skeletonProps} skeleton={emptySkeleton || skeletonProps.skeleton} />
        <View style={tw`absolute inset-0`}>
          {renderError?.(message) || (
            <PageError icon="error" title="" message={message} retryButton={errorRetryButton} />
          )}
        </View>
      </View>
    );
  }
  if (empty) {
    return (
      <View style={tw`flex-1`}>
        {shouldRenderSkeletonWhenEmpty && (
          <Skeleton {...skeletonProps} skeleton={emptySkeleton || skeletonProps.skeleton} />
        )}
        <View style={shouldRenderSkeletonWhenEmpty && tw`absolute inset-0`}>
          {renderEmpty?.() || <PageError message={emptyMessage} />}
        </View>
      </View>
    );
  }
  return <>{children()}</>;
};
