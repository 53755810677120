import React, { FunctionComponent, ReactElement } from 'react';
import {
  KeyboardAvoidingView,
  KeyboardAvoidingViewProps,
  Platform,
  ScrollViewProps,
  StyleProp,
  View,
  ViewStyle,
} from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import tw from '~/shared/theme';
import { Logo } from '~/shared/ui/logo';

interface CenteredLayoutProps extends ScrollViewProps {
  avoidKeyboard?: boolean;
  contentContainerStyle?: StyleProp<ViewStyle>;
  decorationTop?: ReactElement;
  decorationTopLeft?: ReactElement;
  decorationTopRight?: ReactElement;
  decorationBottom?: ReactElement;
  decorationBottomLeft?: ReactElement;
  decorationBottomRight?: ReactElement;
  decorationBackground?: ReactElement;
  decorationAfter?: ReactElement;
  decorationBefore?: ReactElement;
}

export const CenteredLayout: FunctionComponent<CenteredLayoutProps> = ({
  children,
  avoidKeyboard = false,
  contentContainerStyle = tw`max-w-5xl w-full`,
  decorationTop,
  decorationTopLeft = (
    <View style={tw`px-3 py-2 tablet:p-4`}>
      <Logo style={tw`w-34 h-18 tablet:w-44 tablet:h-24`} />
    </View>
  ),
  decorationTopRight,
  decorationBottomLeft,
  decorationBottomRight,
  decorationBottom,
  decorationBackground,
  decorationAfter,
  decorationBefore,
  ...props
}) => {
  // android doesn't seem to need this
  const shouldAvoidKeyboard = avoidKeyboard && Platform.OS === 'ios';
  const Wrapper = shouldAvoidKeyboard ? KeyboardAvoidingView : React.Fragment;
  const wrapperProps: KeyboardAvoidingViewProps = shouldAvoidKeyboard
    ? { behavior: Platform.OS === 'android' ? 'height' : 'padding', style: tw`flex-1` }
    : {};
  const insets = useSafeAreaInsets();
  const decoration = tw`absolute z-10`;
  const { left, right, top, bottom } = insets;
  return (
    <>
      {decorationBackground && <View style={tw`absolute inset-0 z-0`}>{decorationBackground}</View>}
      {decorationTop && <View style={[decoration, { left, top, right }]}>{decorationTop}</View>}
      {decorationTopLeft && <View style={[decoration, { top, left }]}>{decorationTopLeft}</View>}
      {decorationTopRight && <View style={[decoration, { right, top }]}>{decorationTopRight}</View>}
      {decorationBefore}

      <Wrapper {...wrapperProps}>
        <ScrollView
          style={tw`flex-1`}
          contentContainerStyle={[
            tw`px-5 py-24 tablet:py-36 items-center justify-center min-w-full min-h-full`,
            Platform.OS === 'web' && { margin: 'auto' },
            contentContainerStyle,
          ]}
          showsVerticalScrollIndicator={Platform.OS === 'web'}
          showsHorizontalScrollIndicator={false}
          {...props}
        >
          <View
            style={[
              tw`justify-center items-center`,
              {
                marginLeft: insets.left,
                marginRight: insets.right,
                // top and bottom seems unnecessary because scrollview goes past it
                // marginBottom: insets.bottom,
                // marginTop: insets.top,
              },
            ]}
          >
            {children}
          </View>
        </ScrollView>
      </Wrapper>

      {decorationAfter}
      {decorationBottom && (
        <View style={[decoration, { left, bottom, right }]}>{decorationBottom}</View>
      )}
      {decorationBottomLeft && (
        <View style={[decoration, { bottom, left }]}>{decorationBottomLeft}</View>
      )}
      {decorationBottomRight && (
        <View style={[decoration, { right, bottom }]}>{decorationBottomRight}</View>
      )}
    </>
  );
};
