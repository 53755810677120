import * as Types from '~/shared/api/models.main.generated';

import { gql } from '@apollo/client';
export type IGQLListBrandItemFragment = (
  { __typename: 'Brand' }
  & Pick<Types.IGQLBrand, 'id' | 'slug' | 'name'>
  & { images: Types.Maybe<(
    { __typename: 'BrandPreviews' }
    & { medium: Types.Maybe<(
      { __typename: 'Asset' }
      & Pick<Types.IGQLAsset, 'source' | 'width' | 'height'>
    )> }
  )> }
);

export const ListBrandItemFragmentDoc = gql`
    fragment ListBrandItem on Brand {
  __typename
  id
  slug
  name
  images {
    medium {
      source
      width
      height
    }
  }
}
    `;