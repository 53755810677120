import localforage from 'localforage';
import { __migration_IV2StorageModel } from './v2-storage.types';

localforage.config({
  name: 'Studio100GO',
  storeName: 'store',
});

export async function __migration_getV2State(): Promise<__migration_IV2StorageModel | null> {
  try {
    const value = await localforage.getItem('persist:studio100');
    if (typeof value === 'string') {
      const stateRaw = JSON.parse(value);
      const state = {
        app: typeof stateRaw.app === 'string' ? JSON.parse(stateRaw.app) : stateRaw.app,
        auth: typeof stateRaw.auth === 'string' ? JSON.parse(stateRaw.auth) : stateRaw.auth,
        profiles:
          typeof stateRaw.profiles === 'string' ? JSON.parse(stateRaw.profiles) : stateRaw.profiles,
        settings:
          typeof stateRaw.settings === 'string' ? JSON.parse(stateRaw.settings) : stateRaw.settings,
        media: typeof stateRaw.media === 'string' ? JSON.parse(stateRaw.media) : stateRaw.media,
      };

      if (state.auth && state.profiles) {
        return state;
      }
      return null;
    }

    return null;
  } catch (e) {
    return null;
  }
}

export async function __migration_clearV2State(): Promise<void> {
  try {
    await localforage.removeItem('persist:studio100');
  } catch (e) {}
}
