import React, { FunctionComponent } from 'react';
import { View, StyleSheet, ViewStyle } from 'react-native';
import { GradientProps } from './gradient.component';
import { variantColors } from './gradient.styles';

export const Gradient: FunctionComponent<GradientProps> = ({
  children,
  absoluteFill,
  variant,
  style,
  angle = 0,
}) => {
  return (
    <View
      style={[
        {
          backgroundImage: `linear-gradient(${angle}deg, ${variantColors(variant).join(', ')})`,
        } as ViewStyle,
        absoluteFill && StyleSheet.absoluteFill,
        style,
      ]}
    >
      {children}
    </View>
  );
};
