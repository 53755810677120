import React, { FunctionComponent } from 'react';
import tw from '~/shared/theme';
import { Icon, IconProps } from '~/shared/ui/icon';
import { IMediaType, ISongContentType, IVideoContentType, SongContentType } from '~/types';

type MediaTypeName = IMediaType;
export interface MediaTypeIconProps extends Omit<IconProps, 'name'> {
  type: MediaTypeName;
  contentType?: ISongContentType | IVideoContentType;
}

const iconConfig: Record<MediaTypeName, IconProps> = {
  Video: {
    name: 'play',
    color: tw.color('red'),
  },
  Song: {
    name: 'music',
    color: tw.color('orange'),
  },
  Book: {
    name: 'book',
    color: tw.color('blue'),
  },
  Game: {
    name: 'game',
    color: tw.color('mint'),
  },
  Playlist: {
    name: 'playlist',
    color: tw.color('red'),
  },
  Serie: {
    name: 'play',
    color: tw.color('red'),
  },
};
const contentTypeIconConfig: Partial<Record<ISongContentType | IVideoContentType, IconProps>> = {
  [SongContentType.Karaoke]: {
    name: 'microphone',
    color: tw.color('orange'),
  },
};

export const MediaTypeIcon: FunctionComponent<MediaTypeIconProps> = ({
  type,
  contentType,
  ...passedProps
}) => {
  const iconProps = (contentType && contentTypeIconConfig[contentType]) || iconConfig[type];
  return <Icon {...iconProps} {...passedProps} />;
};
