import React, { useRef, useState } from 'react';
import { DefaultTheme, LinkingOptions, NavigationContainer } from '@react-navigation/native';
import { Route } from '@react-navigation/routers';
import { I18nextProvider } from 'react-i18next';
import { StatusBar, View } from 'react-native';
import i18n from './assets/locale/i18n';
import RootNavigator from './navigation/root.navigator';
import { linkingConfig } from './navigation/linking';
import tw from './shared/theme';
import { Splash } from './features/splash';
import { useDeviceContext } from 'twrnc';
import { ApolloProvider } from '@apollo/client';
import client, { setClientHeader } from './shared/api/main.api';
import { useInitApp } from './features/app/init';
import { ParentCheckModal } from './features/guards/guard-parent-zone';
import { ProximusGateModal, ProximusValidationModal } from './features/guards/guard-proximus';
import { DownloadDeleteRequestModal } from './features/media/change-download-status';
import { ProfileDeleteRequestModal } from './features/profiles/delete-profile';
import { navigationRef } from './navigation/use-dynamic-link-to';
import { useViewer } from './entities/viewer';
import { analyticsTracker } from './shared/tracking/analytics';
import { logger } from './shared/tracking/logs';
import { Analytics } from './features/app/analytics';
import { useProximusEffect } from './entities/proximus/utils/use-proximus-effect';
import { ApproveCookies } from './features/approve-cookies';
import { GuardWebOnMobile } from './features/guards/force-native-app-on-mobile';
import { CTAToNativeAppPage } from './pages/cta-to-native-app.page';
import { GestureHandlerRootView } from 'react-native-gesture-handler';

setClientHeader('accept-language', i18n.language);
i18n.on('languageChanged', (lng) => {
  useViewer.getState().setLanguage(lng);
  setClientHeader('accept-language', lng);
});

StatusBar.setHidden(true);

const App: React.FC = () => {
  const routeRef = useRef<Route<string>>();
  const { initialized } = useInitApp();
  const { sync } = useProximusEffect();

  const [renderSplash, setRenderSplash] = useState(true);

  useDeviceContext(tw);

  const handleNavigationStateChange = () => {
    const previousRoute = routeRef.current;
    const currentRoute = navigationRef.current?.getCurrentRoute();

    if (!currentRoute) return;

    analyticsTracker.trackNavigationStateChange(previousRoute, currentRoute);

    if (previousRoute?.key !== currentRoute?.key) {
      logger.setScreenMeta(currentRoute?.name, currentRoute.params);
      sync();
    }

    routeRef.current = currentRoute;
  };

  const guardOnWebFallback = () => <CTAToNativeAppPage />;

  return (
    <I18nextProvider i18n={i18n}>
      <ApolloProvider client={client}>
        <GestureHandlerRootView style={tw`flex-1`}>
          <NavigationContainer
            ref={navigationRef}
            linking={
              {
                prefixes: ['studio100go://', 'studio100beta://', 'https://go.studio100.com'],
                config: linkingConfig,
              } as unknown as LinkingOptions<ReactNavigation.RootParamList>
            }
            fallback={<View style={tw`absolute top-0 left-0 w-full h-full bg-green-splash`} />}
            documentTitle={{
              formatter: () => `Studio 100 GO`,
              // formatter: (options, route) => `${options?.title ?? route?.name} - Studio 100 GO`,
            }}
            theme={{
              ...DefaultTheme,
              colors: {
                ...DefaultTheme.colors,
                background: tw.color('green') as string,
              },
            }}
            onStateChange={handleNavigationStateChange}
          >
            <GuardWebOnMobile fallback={guardOnWebFallback}>
              {initialized && (
                <>
                  <ProfileDeleteRequestModal />
                  <DownloadDeleteRequestModal />
                  <ParentCheckModal />
                  <ProximusGateModal />
                  <ProximusValidationModal />
                  <Analytics />
                  <RootNavigator />
                </>
              )}
              {(renderSplash || !initialized) && (
                <Splash onAnimationEnd={() => setRenderSplash(false)} loading={!initialized} />
              )}
            </GuardWebOnMobile>
          </NavigationContainer>

          <ApproveCookies />
        </GestureHandlerRootView>
      </ApolloProvider>
    </I18nextProvider>
  );
};

export default App;
